import * as fetchMock from 'fetch-mock';
import { url as urlGet } from '../services/validationEmailGet';
import {
  url as urlPost,
  ValidationEmailParams
} from '../services/validationEmailPost';
import ResponseCreator from './helpers/responseCreator';
import store from './helpers/store';
import withDelay from './helpers/withDelay';

(window as any).fixtures = (window as any).fixtures || {};
(window as any).fixtures.validationEmail = {
  _error: 'none',
  serverError() {
    this._error = 'serverError';
  }
};

fetchMock.get(
  `express:${process.env.REACT_APP_API_PATH}/${urlGet}`,
  url => {
    if ((window as any).fixtures.validationEmail._error === 'serverError') {
      return withDelay(
        new ResponseCreator()
          .addError('Server error', 'Server error')
          .setStatus(500)
          .getResponse()
      );
    }
    return withDelay(new ResponseCreator().getResponse());
  },
  {
    query: { url: 'http://localhost:3000/registro/codigo-email' }
  }
);

// TODO:
// attribute: event.error, error: Validation event expired
// attribute: authentication, error: User not found

fetchMock.post(
  `end:${process.env.REACT_APP_API_PATH}/${urlPost}`,
  (url, fetchData) => {
    const data = JSON.parse(fetchData.body as string) as ValidationEmailParams;

    if (data.code === 'invalid') {
      return withDelay({
        status: 400,
        body: {
          errors: [
            {
              attribute: 'event.error',
              error: 'Invalid Code'
            }
          ]
        }
      });
    }

    store.roles = ['validated'];
    return withDelay({
      status: 200,
      body: {
        payload: 'Message sent'
      }
    });
  }
);
