import React, { useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import styles from "./App.module.scss";
import Routes from "./Routes";
import ValidationRoutes from "./ValidationRoutes";
import AppInit from "./components/AppInit";
import ClearAllMessages from "./components/ClearAllMessages";
import FlashMessages from "./components/FlashMessages";
import Footer from "./components/Layout/Footer";
import Navbar from "./components/Navbar/Navbar";
import ScrollToTop from "./components/ScrollToTop";
import Security from "./components/Security";
import SideButtons from "./components/SideButton";
import SidebarMenu from "./components/SidebarMenu/SidebarMenu";
import TrackUser from "./components/TrackUser";
import ValidatePending from "./components/ValidatePending/ValidatePending";
import isLogged from "./helpers/isLogged";
import store from "./store/store";
import { ThemeProvider } from "./styles/styled-components";
import theme from "./styles/theme";

const AppContent = () => (
  <>
    <div className="d-flex w-100">
      <FlashMessages />
    </div>
    <div className="d-flex w-100">
      <ValidatePending />
    </div>
  </>
);

const App = () => {
  const pageWrapId = "page-wrap";
  const [open, setOpen] = useState(false);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AppInit>
          <BrowserRouter>
            <Security>
              <ClearAllMessages />
              <TrackUser />
              <ScrollToTop>
                <div className={styles.app}>
                  <SideButtons />
                  <Switch>
                    <Route
                      path="/validar-identidad"
                      render={() => (
                        <>
                          <AppContent />
                          <section className={`${styles.appContent}`}>
                            <ValidationRoutes />
                          </section>
                        </>
                      )}
                    />
                    <Route
                      path="/"
                      render={() => (
                        <>
                          <div className="d-flex w-100 align-items-stretch">
                            {isLogged() &&
                              <SidebarMenu
                                open={open}
                                pageWrapId={pageWrapId}
                                onToggle={(open: boolean) => { setOpen(open) }}
                              />
                            }
                            <div id={pageWrapId} className="w-100 text-center">
                              {isLogged() &&
                                <Navbar onToggle={() => { setOpen(!open) }} />
                              }
                              <AppContent />
                              <section className={styles.appContent}>
                                <Routes />
                              </section>
                            </div>
                          </div>
                          {isLogged() && <Footer />}
                        </>
                      )}
                    />
                  </Switch>
                </div>
              </ScrollToTop>
            </Security>
          </BrowserRouter>
        </AppInit>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
