import { Reducer } from 'redux';
import { UIAction, UIState } from './types';

export const initialState: UIState = {
  showWppButton: true,
  hasPermissions: false,
  showLogOutValidation: true,
}

const reducer: Reducer<UIState, UIAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case 'UI/SHOW_WPP_BUTTON':
      return {
        ...state,
        showWppButton: action.show
      }
    case 'UI/SET_HAS_PERMISSIONS':
      return {
        ...state,
        hasPermissions: action.hasPermissions
      }
    case 'UI/SHOW_LOG_OUT_VALIDATION':
      return {
        ...state,
        showLogOutValidation: action.show
      }
    case 'UI/CLEAR_STORE':
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default reducer;
