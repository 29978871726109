import React from 'react';
import Loading from './Loading';
import styles from './Loading.module.scss';

const LoadingSite = () => (
  <div className={styles.siteLoader}>
    <Loading />
    <div className={styles.siteLoaderMessage}>Validando</div>
  </div>
);

export default LoadingSite;
