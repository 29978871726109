import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import RedirectIfLoggedInRoute from "./components/RedirectIfLoggedInRoute";
import ForgotPasswordView from "./views/Account/ForgotPasswordView";
import LogInView from "./views/Account/LogInView";
import LogOutView from "./views/Account/LogOutView";
import PreferencesView from "./views/Account/PreferencesView";
import ResetPasswordView from "./views/Account/ResetPasswordView";
import ResetPhoneView from "./views/Account/ResetPhoneView";
import AlertsView from "./views/Alerts/AlertsView";
import ContactView from "./views/Contact/ContactView";
import DashboardView from "./views/Dashboard/DashboardView";
import FootprintsView from "./views/Footprints/FootprintsView";
import NotFound from "./views/NotFound";
import PlanBuyConfirmView from "./views/Plans/PlanBuyConfirmView";
import PlanBuyView from "./views/Plans/PlanBuyView";
import PlansView from "./views/Plans/PlansView";
import ProductsView from "./views/Products/ProductsView";
import ReasonView from "./views/Reason/ReasonView";
import ReportsView from "./views/ShareReport/ReportsView";
import RequestReportView from "./views/ShareReport/RequestReportView";
import ShareReportView from "./views/ShareReport/ShareReportView";
import NequiPaymentView from "./views/ShareReport/containers/NequiPaymentView";
import PaymentResult from "./views/ShareReport/containers/PaymentResult";
import PaymentView from "./views/ShareReport/containers/PaymentView";
import BasicInfoView from "./views/Signup/BasicInfoView";
import EmailValidationView from "./views/Signup/EmailValidationView";
import SignupView from "./views/Signup/SignupView";
import FixReportView from "./views/UserServices/FixReportView";
import IdentityValidationView from "./views/Validation/IdentityValidationView";
import ValidationView from "./views/Validation/ValidationView";

const Routes = () => (
  <Switch>
    <Redirect from="/" to="/dashboard" exact />

    {/* Login */}
    <RedirectIfLoggedInRoute path="/login" component={LogInView} />

    {/* Logout */}
    <Route path="/logout" component={LogOutView} />

    {/* Recovery */}
    <Route path="/recuperar-telefono" component={ResetPhoneView} />
    <Route path="/asignar-contrasena" component={ResetPasswordView} />
    <Route path="/recuperar-contrasena" component={ForgotPasswordView} />
    <Route path="/recuperar-contrasena-email" component={ResetPasswordView} />

    {/* Contact */}
    <PrivateRoute path="/contactanos" component={ContactView} />

    {/* Signup */}
    <RedirectIfLoggedInRoute
      path="/registro"
      component={SignupView}
      exact
    />
    <Route path="/registro/codigo-email" component={EmailValidationView} />

    {/* Dashboard */}
    <PrivateRoute path="/dashboard" component={DashboardView} />

    {/* Plans */}
    <Route path="/planes" component={PlansView} />
    <PrivateRoute
      path="/comprar"
      roles="validated"
      component={PlanBuyView}
    />
    <PrivateRoute
      path="/confirmar/compra"
      roles="validated"
      component={PlanBuyConfirmView}
    />

    {/* Validation */}
    <PrivateRoute
      roles="validated"
      path="/validacion-identidad"
      component={IdentityValidationView}
    />
    <PrivateRoute
      roles="validated"
      path="/validacion"
      notRoles={["identity"]}
      component={ValidationView}
      subscriptionProtected
    />

    {/* Account preferences */}
    <PrivateRoute
      roles="validated"
      path="/preferencias/:tab"
      component={PreferencesView}
    />

    {/* Basic Info */}
    <PrivateRoute
      roles="validated"
      path="/informacion-basica"
      notRoles={["basic_info"]}
      component={BasicInfoView}
    />

    {/* Alerts */}
    <PrivateRoute
      roles="validated"
      path="/alertas"
      component={AlertsView}
    />

    {/* Footprint */}
    <PrivateRoute
      roles="validated"
      path="/consultas"
      component={FootprintsView}
    />

    {/* Reports */}
    <PrivateRoute
      roles="validated"
      path="/compartir-reporte"
      component={ShareReportView}
      subscriptionProtected
    />
    <PrivateRoute
      roles="validated"
      path="/reportes/:tab"
      component={ReportsView}
    />
    <PrivateRoute
      roles="validated"
      path="/solicitar-reporte"
      component={RequestReportView}
    />

    {/* Products */}
    <PrivateRoute
      roles="report"
      path="/productos/:tab"
      component={ProductsView}
    />

    {/* Fix report */}
    <PrivateRoute
      roles="validated"
      path="/corregir-historial"
      component={FixReportView}
      subscriptionProtected
    />

    {/* Reason */}
    <PrivateRoute
      roles="validated"
      path="/motivo/:reason"
      component={ReasonView}
    />

    {/* Nequi */}
    <PrivateRoute path="/payment" component={PaymentView} />
    <PrivateRoute path="/nequi-payment" component={NequiPaymentView}
    />
    <PrivateRoute path="/payment-result" component={PaymentResult} />
    <Route component={NotFound} />
  </Switch>
);

export default Routes;
