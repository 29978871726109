import api from "./api";

export interface SendOtpParams {
  document: string;
}

export const url = 'send-otp';

const sendOtpPost = async (
  data: SendOtpParams,
  authenticated = false
): Promise<any> => {
  return await api.post(url, authenticated, data);
};

export default sendOtpPost;
