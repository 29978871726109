import api from "./api";

export interface Alert {
  code: string;
  datetime: string;
  description: string;
  title: string;
  type: string;
}

export interface Soat {
  ValidFrom: string;
  ValidUntil: string;
  company: string;
  policyNumber: string;
  status: string;
}

export interface Info {
  brand: string;
  model: string;
  vehicleType: string;
  year: string;
  soat: Soat;
}

export interface Vehicle {
  licenseDate: string;
  licensePlate: string;
  type: string;
  info: Info;
  alerts: Alert[];
}

export interface Vehicles {
  vehicles: Vehicle[];
}

export interface JudicialProcess {
  processId: string;
  lastUpdate: string;
  processDate: string;
  legalOffice: string;
  peopleInvolved: string[];
}

export interface JudicialProcesses {
  processList: JudicialProcess[];
}

export interface Alerts {
  alerts: Alert[];
}

export interface AlertsResponse {
  da: Alerts;
  vl: Vehicles;
  jp: JudicialProcesses;
}

export const vehicleList = "vehicleList";
export const driverAlerts = "driverAlerts";
export const judicialProcess = "judicialProcesses";

const alertsGet = async (): Promise<AlertsResponse> => {
  const da = await api.get(driverAlerts, true);
  const vl = await api.get(vehicleList, true);
  const jp = { processList: [] };
  // const jp = await api.get(judicialProcess, true);

  return { da, vl, jp };
};

export default alertsGet;
