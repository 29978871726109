import api from './api';

export interface FixReportParams {
  message: string;
}

export const url = 'reports/fix';

const reportsFixPost = (postData: FixReportParams) => {
  return api.post(url, true, { ...postData });
};

export default reportsFixPost;
