import { put, select, takeLeading } from "redux-saga/effects";
import { getFootprints as transformFootprints } from "../../helpers/transformReport";
import footprintsGet from "../../services/footprintsGet";
import { setFetchFootprintsFailed, setFetchFootprintsSucceeded } from "../footprints/action";
import { FootprintsState } from "../footprints/types";
import { AppState } from "../store";

const getFootprints = (store: AppState) => store.footprints;

function* fetchFootprints() {
  try {
    const footprintsState: FootprintsState = yield select(getFootprints);
    if (footprintsState.status !== "loaded") {
      const footprints = yield footprintsGet();
      const payload = footprints.payload;
      const footprintsTransformed = transformFootprints(payload.HuellaConsulta);
      yield put(setFetchFootprintsSucceeded(footprintsTransformed));
    }
  } catch (error) {
    yield put(setFetchFootprintsFailed(error));
    return;
  }
}

export default function* watchFetchFootprints() {
  yield takeLeading("footprints/FETCH_INFO", fetchFootprints);
}
