import api from './api';

interface Period {
  periodoCotizacion: number;
}

export interface Detail {
  period: string;
  baseIncome: number;
  contributorDetail: string;
}

export interface SocialSecurityResponse {
  periods: Period[];
  details: Detail[];
}

export const url = 'reports/social-security-info';

const reportsSocialSecurityInfoGet = async (): Promise<
  SocialSecurityResponse
> => {
  return await api.get(url, true);
};

export default reportsSocialSecurityInfoGet;
