import React from "react";
import Page from "../../components/Layout/Page";
import SubscriptionRequired from "../../components/Subscription/SubscriptionRequired";
import AlertsContainer from "./containers/AlertsContainer";

const AlertsView = () => {
  return (
    <Page back>
      <SubscriptionRequired
        title="Mis alertas"
        component={AlertsContainer}
      />
    </Page>
  );
};

export default AlertsView;