import React from 'react';
import { Route, Switch } from 'react-router';
import NotFound from './views/NotFound';
import SignupView from './views/Signup/SignupView';

const ValidationRoutes = () => (
  <Switch>
    <Route
      path="/validar-identidad/registro/:from/:user/:callback"
      component={SignupView}
    />
    <Route component={NotFound} />
  </Switch>
);

export default ValidationRoutes;
