import * as fetchMock from 'fetch-mock';
import { BasicInfoParams, url as urlGet } from '../services/basicInfoGet';
import { url as urlPost } from '../services/basicInfoPost';
import ResponseCreator from './helpers/responseCreator';
import store from './helpers/store';
import withDelay from './helpers/withDelay';

const initialFormValues = {
  birthDate: '01/01/1990',
  gender: 'M',
  stateId: '5',
  cityId: '5002',
  activityType: 'employee',
  company: '',
  dependents: '2-3',
  employer: 'Acme',
  houseType: 'family',
  job: '',
  monthlyIncome: '2-4',
  socioeconomicLevel: 3
};

fetchMock.get(
  `end:${process.env.REACT_APP_API_PATH}/${urlGet}`,
  (url, fetchData) => {
    // body: store.preferences.basicInfo || initialFormValues
    return withDelay(
      new ResponseCreator().setPayload(initialFormValues).getResponse()
    );
  }
);

fetchMock.post(
  `end:${process.env.REACT_APP_API_PATH}/${urlPost}`,
  (url, fetchData) => {
    const data = JSON.parse(fetchData.body as string) as BasicInfoParams;

    store.basicInfoSet = true;
    store.preferences = { basicInfo: data };
    store.roles = ['basic_info'];
    return withDelay(new ResponseCreator().getResponse());
  }
);
