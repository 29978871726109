import { Reducer } from "redux";
import { ScoresAction, ScoresState } from "./types";

const initialState: ScoresState = {
  status: "init"
};

const reducer: Reducer<ScoresState, ScoresAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "scores/FETCH_INFO":
      if (state.status === "loaded") {
        return {
          ...state
          // No updates this information
          // status: "updating"
        };
      } else {
        return {
          status: "loading"
        };
      }
    case "scores/FETCH_INFO_SUCCEEDED":
      return {
        status: "loaded",
        payload: action.scores
      };
    case "scores/FETCH_INFO_FAILED":
      return {
        status: "error",
        error: action.error
      };
    case "scores/CLEAR_STORE":
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default reducer;
