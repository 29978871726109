import { put, select, takeLeading } from "redux-saga/effects";
import alertsGet from "../../services/alertsGet";
import { setFetchAlertsFailed, setFetchAlertsSucceeded } from "../alerts/actions";
import { AlertsState } from "../alerts/types";
import { AppState } from "../store";

const getAlerts = (store: AppState) => store.alerts;

function* fetchAlerts() {
  try {
    const alertsState: AlertsState = yield select(getAlerts);
    if (alertsState.status !== "loaded") {
      const alerts = yield alertsGet();
      yield put(setFetchAlertsSucceeded(alerts));
    }
  } catch (error) {
    yield put(setFetchAlertsFailed(error));
    return;
  }
}

export default function* watchFetchAlerts() {
  yield takeLeading("alerts/FETCH_INFO", fetchAlerts);
}
