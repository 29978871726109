import React from 'react';
import { NavLink } from 'react-router-dom';
import Page from '../components/Layout/Page';
import styles from './NotFound.module.scss';

const NotFound = () => {
  return (
    <Page>
      <div className="eb-card">
        <div className={styles.container}>
          <p className={styles.title}>
            404
          </p>
          <div>
            <p className={styles.subtitle}>
              Página no encontrada
            </p>
            <p className={styles.text}>
              Lo sentimos, no encontramos la página que estás buscando.
            </p>
          </div>
          <NavLink
            to={"/"}
            className="btn btn-primary"
          >
            Volver al inicio
          </NavLink>
        </div>
      </div>
    </Page>
  )
}

export default NotFound;
