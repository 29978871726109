import api from './api';

export interface CreditsIdParams {
  offerId: string;
}

export const url = 'credits';

const creditsIdGet = async ({ offerId }: CreditsIdParams) => {
  await api.get(`${url}/${offerId}`, true);
};

export default creditsIdGet;
