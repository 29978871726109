class ApiError extends Error {
  code: number;
  response: any;

  constructor(message: string, code: number, response: any) {
    super(message);
    Object.setPrototypeOf(this, ApiError.prototype);
    this.code = code;
    this.response = response;
  }
}

export default ApiError;
