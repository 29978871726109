import * as fetchMock from 'fetch-mock';
import { ReportSummaryResponse, url } from '../services/reportsSummaryGet';
import withDelay from './helpers/withDelay';

const payload: ReportSummaryResponse = {
  date: '17/11/2018 11:48:59',
  creditCards: [
    {
      name: 'SERFINANSA- SERVICIOS FINANCIE',
      overdue: false
    },
    {
      name: 'SERFINANSA- SERVICIOS FINANCIE',
      overdue: false
    },
    {
      name: 'TUYA S.A. COMPAÑIA  DE  FINANC',
      overdue: true
    }
  ],
  credits: [
    {
      name: 'AV VILLAS',
      overdue: false
    }
  ],
  contractsAndServices: [
    {
      name: 'C.I. DOLCE S.A.',
      overdue: false
    },
    {
      name: 'COMCEL - COMUNICACION CELULAR',
      overdue: true
    }
  ],
  accounts: [
    {
      name: 'DAVIVIENDA S.A.'
    },
    {
      name: 'DAVIVIENDA S.A.'
    },
    {
      name: 'AV VILLAS'
    }
  ],
  debtStatus: {
    upToDate: 115000,
    overdue: 911111
  },
  debtShare: [
    { name: 'Tarjetas de crédito', value: 29 },
    { name: 'Sector financiero', value: 0 },
    { name: 'Sector real', value: 36 }
  ]
};

fetchMock.get(`end:${process.env.REACT_APP_API_PATH}/${url}`, () => {
  return withDelay({
    status: 200,
    body: {
      payload
    }
  });
});
