import api from './api';

export interface TokenParams {
  payload: { token: string };
}

export const url = 'refresh';

const refreshGet = async (): Promise<TokenParams> => {
  return await api.get(url, true);
};

export default refreshGet;
