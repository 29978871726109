// Tabla 02 - Estado de obligación - ESTOBLIG
// TransUnion - Tablas de convenciones - Información Comercial - Versión 1.34

const estadoObligacion = {
  // Cuentas
  ACEMB: 'Activa Embargada',
  INACT: 'Inactiva',
  INEMB: 'Inactiva Embargada',
  CMALM: 'Mal Manejo',
  NORMA: 'Normal',
  SALDA: 'Saldada',
  SEXTR: 'Saldada Extravió Robo',
  CUNIL: 'Saldada Unilateralmente',
  CUNIE: 'Saldada Por La Entidad',
  SVOL: 'Saldada Voluntariamente',
  INCON: 'Inconsistente',
  'NO RE': 'No Reportada',

  // Sector financiero y solidario
  VIGE: 'Vigente',
  SALD: 'Saldado',
  REES: 'Reestructurada',
  RECU: 'Recuperado',
  REAC: 'Reactivado',
  NREP: 'No Reportado',
  NORE: 'NoRe',
  L550: 'Ley 550',
  IRRE: 'Irrecuperable',
  DUDO: 'Dudoso Recaudo',
  DIFI: 'Difícil Cobro',
  CUNI: 'Cancelada Unilateralmente',
  CVOL: 'Cancelada Voluntariamente',
  CROB: 'Cancelada Extravio o robo',
  CONT: 'Contingencia',
  CONC: 'Concordato',
  CMOR: 'Cancelada Deuda Morosa',
  CMMM: 'Cancelado Mal Manejo Por Mora',
  CMAL: 'Cancelada Mal Manejo',
  CFAL: 'Cancelada Fallecimiento',
  CVTE: 'Cancelada Voluntariamente Por Un Tercero',
  CAST: 'Castigada',
  '1116': 'Proceso Reorganización Ley 1116',
  LINS: 'Ley 1564 de 2012 insolvencia PNNC',

  // Sector asegurado
  APAG: '  Anulada por falta de pago',
  APDA: 'Anulada por perdida daños',
  APHU: 'Anulada por perdida hurto',
  APTO: 'Anulada por pérdida total',
  ASAS: 'Anulada por solicitud del asegurado',
  ASCO: 'Anulada por solicitud de la compañía',
  // CAST: 'Castigada',
  CJUR: 'Cobro jurídico',
  FINA: 'Financiada',
  PEND: 'Pendiente',
  PROR: 'Prorrogada',
  RESC: 'Rescindida',
  // SALD: 'Saldada',

  // Sector real
  // VIGE: 'Vigente',
  SUSP: 'Suspendida por la entidad',
  // SALD: 'Saldada',
  REFI: 'Refinanciado',
  // RECU: 'Cartera recuperada',
  // NORE: 'NoRe',
  // L550: 'Ley 550',
  INAC: 'Inactivo',
  // DUDO: 'Dudoso recaudo',
  // CROB: 'Cancelada por extravió robo',
  // CMOR: 'Cancelada por mora',
  // CAST: 'Castigada',
  // '1116': 'Ley 1116',
  // LINS: 'Ley 1564 de 2012 insolvencia PNNC',
  JURI: 'Jurídico',
  PREJ: 'Prejurídico'
};

export default estadoObligacion;
