import React from "react";
import { Col, Container, Row } from "reactstrap";
import styles from "./Footer.module.scss";

const Footer = () => (
  <footer className={`${styles.Footer} mt-auto`}>
    <Container>
      <div className={styles.pages}>
        <div className={styles.half}>
          <a
            href={`${process.env.REACT_APP_CMS}/preguntas-frecuentes`}
            target="_blank"
          >
            Preguntas frecuentes
          </a>
          <a
            href={`${process.env.REACT_APP_CMS}/terminos-y-condiciones`}
            target="_blank"
          >
            Términos y condiciones
          </a>
        </div>
        <div className={styles.half}>
          <a
            href={`${process.env.REACT_APP_CMS}/politicas-de-privacidad`}
            target="_blank"
          >
            Política de privacidad
          </a>
          <a
            href={`${process.env.REACT_APP_CMS}/autorizaciones-y-declaraciones`}
            target="_blank"
          >
            Autorizaciones y declaraciones
          </a>
        </div>
      </div>
      <Row>
        <Col className="text-white mt-4">
          © {new Date().getUTCFullYear()} enbanca
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
