import React from 'react';
import { Link } from 'react-router-dom';

const UserDisabledMessage = () => {
  return (
    <>
      Por razones de seguridad tu usuario ha sido desactivado. Por favor ingresa{' '}
      <Link to="/contacto">aquí</Link> y escríbenos para reactivar tu usuario.
    </>
  );
};

export default UserDisabledMessage;
