import { Message, MessageAction, MessageType } from './types';

export const sendMessage = (
  message: string,
  messageType: MessageType,
  options = { 
    time: 5000 
  }
): MessageAction => ({
  type: 'message/SEND_MESSAGE',
  message,
  messageType,
  options,
});

export const registerMessage = (message: Message): MessageAction => ({
  type: 'message/REGISTER_MESSAGE',
  message
});

/**
 * message must be a reference to one of the messages in the state, because the
 * method only do a shallow comparison
 */
export const clearMessage = (message: Message): MessageAction => ({
  type: 'message/CLEAR_MESSAGE',
  message
});


export const clearAllMessages = (): MessageAction => ({
  type: 'message/CLEAR_ALL_MESSAGES',
})
