import { delay } from "q";
import React from "react";
import Error from "../../../components/UI/Error";
import Loading from "../../../components/UI/Loading";
import basicInfoGet from '../../../services/basicInfoGet';
import creditsGet, { CreditResponse } from "../../../services/creditsGet";
import merchantsGet, { Merchant } from "../../../services/merchantsGet";
import merchantsProductsGet from "../../../services/merchantsProductsGet";
import merchantsProductSubtypesGet, { ProductType } from "../../../services/merchantsProductsSubtypeGet";
import ContainerState from "../../../types/ContainerState";
import ProductItem from "../components/ProductItem";
import ProductsFilter, { FilterValues } from "../components/ProductsFilter";

interface Props {
  mode: "advise" | "all";
}

interface State extends ContainerState {
  products: CreditResponse[];
  allProducts: CreditResponse[];
  merchantsProducts: CreditResponse[];
  productsTypes: ProductType[];
  merchants: Merchant[];
  userInfo: Object;
}

const staticProducts: CreditResponse[] = [
  /*   {
      productName: "JUANCHO TE PRESTA",
      productId: "113",
      offerName: "Static JUANCHO TE PRESTA",
      offerId: "static-JUANCHO TE PRESTA",
      merchantName: "JUANCHO TE PRESTA",
      merchantId: "40",
      productType: "Créditos",
      subProductType: "Libre inversión",
      description: "Prestamos hasta 2 veces tu salario",
      imageUrl: "17c5a9e3-f796-4d9f-a268-a39c9f763a44.png",
      requestLink:
        "https://juanchotepresta.com/?utm_source=aliado&utm_channel=web&utm_campaign=JTP_enbanca",
      minRequirements: "Mayor de 18, celular a su nombre, ingresos demostrables",
      feeType: "",
      associatedCosts: "",
      getUserInfo: false,
      isInternalForm: false,
    }, */
  {
    productName: "Préstamo Salvavidas",
    productId: "91",
    offerName: "Static NEQUI",
    offerId: "static-NEQUI",
    merchantName: "NEQUI",
    merchantId: "24",
    productType: "Créditos",
    subProductType: "Crédito de emergencia",
    description:
      "Préstamo para cuando la quincena no alcanza, imprevistos y olvidos.",
    imageUrl: "9410d922-a5ea-4cd4-8dfd-a5a3345870b6.png",
    requestLink:
      "https://www.nequi.com.co/?utm_source=aliado&utm_channel=web&utm_campaign=Salvavidas_enbanca",
    minRequirements:
      "Estar activo en Nequi y tener la opción Salvavidas en la app (No todos los usuarios la tienen, depende del nivel de uso). Seguro de vida ($1.450 al mes). No tener otro préstamos salvavidas activo",
    feeType: "",
    associatedCosts: "",
    getUserInfo: false,
    isInternalForm: false,
  },
  {
    productName: "Servicio de Ahorro 100% Digital",
    productId: "128",
    offerName: "static PROTECCION",
    offerId: "static-PROTECCION",
    merchantName: "PROTECCIÓN",
    merchantId: "45",
    productType: "ahorro",
    subProductType: "ahorro programado",
    description: "¡Comienza a actuar por tus propósitos! con un servicio de ahorro 100% digital que se ajusta a tus necesidades; para tu tranquilidad financiera y la de tu familia.",
    imageUrl: "ec51f694-8470-44d3-ba55-45330bad93ea.png",
    requestLink:
      "https://www.proteccion.com.co/afiliacion/ahorro?utm_source=Enbanca&utm_medium=referral&utm_campaign=AfiliacionDigitalAhorro",
    minRequirements: "Aportes desde $20.000, rentabilidad 3% Promedio, beneficios tributarios",
    feeType: "",
    associatedCosts: "",
    getUserInfo: false,
    isInternalForm: false
  },
  {
    productName: "Catálogo Digital",
    productId: "146",
    offerName: "static FLIPCAT",
    offerId: "static-FLIPCAT",
    merchantName: "FLIPCAT",
    merchantId: "55",
    productType: "",
    subProductType: "",
    description: "¡Crea tu propio catálogo digital y empieza a vender en línea hoy!Comienza tu primer mes gratis y por ser usuario de enbanca tienes además un descuento en los siguientes 3 meses ($45.000 el trimestre)",
    imageUrl: "bfc5b9e0-e13d-4702-b132-cce465eea989.jpeg",
    requestLink:
      "https://flipcat.io/enbanca/?utm_source=enbanca_channel=enbanca",
    minRequirements: "Producto para emprendedores",
    feeType: "",
    associatedCosts: "",
    getUserInfo: false,
    isInternalForm: false
  }
];

class ProductsListContainer extends React.Component<Props, State> {
  readonly state: Readonly<State> = {
    products: [],
    allProducts: [],
    merchantsProducts: [],
    loading: true,
    productsTypes: [],
    merchants: [],
    userInfo: {},
  };

  async componentDidMount() {
    try {
      const merchants = await merchantsGet();
      const productsTypes = await merchantsProductSubtypesGet();
      const allProducts = await merchantsProductsGet();
      const products = await creditsGet();
      const basicInfo = await basicInfoGet();
      this.setState({
        products: products.length ? products : staticProducts,
        allProducts,
        merchantsProducts: allProducts,
        productsTypes,
        merchants,
        userInfo: basicInfo,
        loading: false
      });
    } catch (error) {
      // console.log(error);
      // this.setState({ loading: false, error: "Ocurrió un error inesperado" });
    }

    try {
      const href = window.location.href;
      const queryParam = href.split("?")[1];
      if (queryParam) {
        const elements: HTMLElement[] = document.querySelectorAll(`#${queryParam}`) as any;
        if (elements.length) {
          await delay(500);
          window.scrollTo(0, elements[elements.length - 1].offsetTop + 200)
        }
      }
    } catch { }
  }

  setProducts = (prdt: CreditResponse[], values: FilterValues) => {
    const products: CreditResponse[] = prdt.filter(
      product =>
        (!values.productType || product.productType === values.productType) &&
        (!values.subProductType ||
          product.subProductType === values.subProductType) &&
        (!values.merchant || product.merchantId.toString() === values.merchant)
    );
    this.setState({
      merchantsProducts: products
    });
    return true;
  };

  handleFilter = (values: FilterValues) => {
    this.setProducts(this.state.allProducts, values);
  };


  render() {
    if (this.state.loading) {
      return <Loading />;
    } else if (this.state.error) {
      return <Error>{this.state.error}</Error>;
    }
    return (
      <>
        {this.props.mode === "advise" &&
          this.state.products.map(product => (
            <ProductItem data={product} key={product.productId} />
          ))}
        {this.props.mode === "all" && (
          <>
            <ProductsFilter
              merchants={this.state.merchants}
              productsType={this.state.productsTypes}
              onFilter={this.handleFilter}
            />
            {this.state.merchantsProducts.length ? (
              this.state.merchantsProducts.map(product => (
                <ProductItem data={product} key={product.productId} />
              ))
            ) : (
              <p className="m-3">Sin resultados</p>
            )}
          </>
        )}
      </>
    );
  }
}

export default ProductsListContainer;
