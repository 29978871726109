import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { NavLink } from "react-router-dom";

interface Tab {
  to: string;
  label: string;
};

interface Props extends RouteComponentProps {
  tabs: Tab[];
}

const Tabs = ({ tabs, location }: Props) => {
  return (
    <div
      className="d-flex"
      style={{ gap: "0.75rem" }}
    >
      {tabs.map(({ to, label }) => (
        <NavLink
          to={to}
          key={to}
          className={`btn ${location.pathname === to ? "btn-primary" : "btn-outline-secondary"}`}
        >
          {label}
        </NavLink>
      ))}
    </div>
  )
}

export default withRouter(Tabs);