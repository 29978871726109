import { useEffect, useState } from "react";
import serviceBuilder from "../helpers/serviceBuilder";
import { ServiceStatus } from "../types/ServiceStatus";

export interface Report {
  date: string;
  document: string;
  documentStatus: string;
  lastname: string;
  name: string;
  email: string;
  phone: string;
  validated: boolean;
  financialProducts: string;
  city: string;
  scoreRating: string;
  seniorityBuro: string;
}

export interface Payload {
  id: string;
  message: string;
  report: Report;
}

export interface SharedReportParams {
  id: string | "preview";
}

export const url = "reports/shared";

const useReportsSharedIdGet = ({ id }: SharedReportParams) => {
  const [reportsShared, setReportsShared] = useState<ServiceStatus<Payload>>({
    status: "init"
  });

  useEffect(() => {
    const { start, abort } = serviceBuilder<SharedReportParams, Payload>(
      "get",
      {
        url: `${url}/${id}`,
        set: setReportsShared,
        auth: true
      }
    );
    start();
    return () => {
      abort();
    };
  }, [id]);

  return reportsShared;
};

export default useReportsSharedIdGet;
