import { ProductSummary } from "../types/report/ProductSummary";
import api from "./api";

export interface Overdue {
  lender: string;
  urlRedirect: string;
  urlImage: string;
}

export interface NubloqInfo {
  typeOfQuote: string[];
  lastQuoteDate: string;
  empLast3M: number;
}

export interface ReportSummaryResponse {
  date: string;
  creditCards?: ProductSummary[];
  credits?: ProductSummary[];
  contractsAndServices?: ProductSummary[];
  accounts?: ProductSummary[];
  debtStatus: {
    upToDate: number;
    overdue: number;
  };
  debtShare: Array<{
    name: string;
    value: number;
  }>;
  overdues?: Overdue[] | null;
  nbqInfo?: NubloqInfo | null;
}

export const url = "reports/summary";

const reportsSummaryGet = async (): Promise<ReportSummaryResponse> => {
  return await api.get(url, true);
};

export default reportsSummaryGet;
