import * as fetchMock from 'fetch-mock';
import { FixReportParams } from '../services/reportsFixPost';
import ResponseCreator from './helpers/responseCreator';
import withDelay from './helpers/withDelay';

fetchMock.post(`end:${process.env.REACT_APP_API_PATH}/_}`, (_, fetchData) => {
  const data = JSON.parse(fetchData.body as string) as FixReportParams;

  if (data.message === 'Error') {
    console.log('fixture error');
    return withDelay(
      new ResponseCreator().addError('Error', 'Unexpected error').getResponse()
    );
  }
  return withDelay(new ResponseCreator().getResponse());
});
