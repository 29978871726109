import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./BackButton.module.scss";
import Icon from "./Icon";

const BackButton = () => {
  return (
    <NavLink
      to={"/dashboard"}
      className={`btn btn-outline-secondary ${styles.btn}`}
    >
      <Icon icon="reply" />
      <span className={styles.label}>
        Volver
      </span>
    </NavLink>
  )
}

export default BackButton;