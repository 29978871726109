import captureMessage from "../helpers/captureMessage";
import uuid from "../helpers/uuid";
import ApiError from "../types/ApiError";
import { ServiceError } from "../types/ServiceError";
import { ServiceReturn } from "../types/ServiceReturn";
import api from "./api";

export interface SignupParams {
  email: string;
  phone: string;
  code: string;
  name: string;
  lastname: string;
  document: string;
  documentType: string;
  acceptAuthorization: boolean;
  acceptTerms: boolean;
  affiliate?: any;
}

export interface SignupResponse {
  token: string;
}

export const url = "signup";

const signupPost = async (
  postData: SignupParams
): Promise<ServiceReturn<SignupResponse>> => {
  let result;
  try {
    result = await api.post(url, false, { ...postData, uuid: uuid.get() });
    return Promise.resolve(result);
  } catch (error) {
    if (error instanceof ApiError) {
      const errors = (error.response.errors || []) as ServiceError[];
      captureMessage("Signup post failed", {
        uuid: uuid.get(),
        name: postData.name,
        email: postData.email,
        phone: postData.phone,
        lastname: postData.lastname,
        document: postData.document,
        errorCode: error.code,
        errorResponse: JSON.stringify(errors),
      })
      const unqUserUUIDError = errors.some(
        ({ error }) => error.includes("unq_user__uuid")
      );
      if (unqUserUUIDError) {
        result = await api.post(url, false, {
          ...postData,
          uuid: uuid.get(true)
        });
        return Promise.resolve(result);
      }
    }
    throw error;
  }
};

export default signupPost;
