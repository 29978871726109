import * as fetchMock from 'fetch-mock';
import { url } from '../services/notificationPreferencesGet';
import { AccountParams } from '../services/preferencesAccountPost';
import store from './helpers/store';
import withDelay from './helpers/withDelay';

fetchMock.get(
  `end:${process.env.REACT_APP_API_PATH}/${url}`,
  (_, fetchData) => {
    const { notifications } = store.preferences;

    return withDelay({ status: 200, body: { ...notifications } }, 2000);
  }
);

fetchMock.post(
  `end:${process.env.REACT_APP_API_PATH}/${url}`,
  (_, fetchData) => {
    const data = JSON.parse(fetchData.body as string) as AccountParams;
    store.preferences = { notifications: data };

    return withDelay({ status: 200, body: {} }, 200);
  }
);
