import api from './api';
import { BasicInfoParams } from './basicInfoGet';

export interface ResultError {
  success: false;
  errors: Array<{
    attribute: string;
    error: string;
  }>;
}

export const url = 'basic-info';

const basicInfoPost = async (postData: BasicInfoParams): Promise<null> => {
  return await api.post(url, true, postData);
};

export default basicInfoPost;
