import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loading from "../../../components/UI/Loading";
import tipoEntidad from "../../../dictionaries/report/tipoEntidad";
import { fetchFootprints as fetchFootprintsAction } from "../../../store/footprints/action";
import { FootprintsState } from "../../../store/footprints/types";
import { AppState } from "../../../store/store";
import FootprintsTable, { Data } from "../components/FootprintsTable";
import styles from "./FootprintsContainer.module.scss";

export interface Props {
  footprintsState: FootprintsState;
  fetchFootprints(): void;
}

const FootprintsContainer = ({ footprintsState, fetchFootprints }: Props) => {
  const [data, setData] = useState<Data[]>([]);

  useEffect(() => {
    fetchFootprints();
  }, []);

  useEffect(() => {
    if (footprintsState.status === "loaded") {
      const footprints = footprintsState.payload;
      const data: Data[] = footprints.map(footprint => ({
        date: footprint.fechaConsulta,
        entity: footprint.nombreEntidad,
        entityType: tipoEntidad[footprint.nombreTipoEntidad] || footprint.nombreTipoEntidad,
        city: footprint.ciudad,
        reason: footprint.motivoConsulta,
      }))
      setData(data);
    }
  }, [footprintsState]);

  return (
    <div className="eb-card">
      <p className={styles.title}>
        Historial de consultas
      </p>
      <div className={styles.container}>
        {footprintsState.status === "loading" && <Loading />}
        {footprintsState.status === "loaded" &&
          <FootprintsTable data={data} />
        }
      </div>
    </div>
  )
}

const mapStateToProps = (state: AppState) => {
  return {
    footprintsState: state.footprints,
  };
};

const mapDispatchToProps = {
  fetchFootprints: fetchFootprintsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(FootprintsContainer);