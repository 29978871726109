// Tabla 14 - Tipo fideicomiso
// TransUnion - Tablas de convenciones - Información Comercial - Versión 1.34

const tipoFideicomiso = {
  FADM: 'Fideicomiso De Administración',
  FIGA: 'Fiducia De Garantía',
  FINM: 'Fideicomiso Inmobiliario',
  FINV: 'Fideicomiso De Inversión',
  FOCE: 'Fondo De Cesantía',
  FOPE: 'Fondos De Pensiones',
  NOFI: 'No Es Fideicomiso',
  OTRO: 'Otros Fideicomisos',
  PCAX: 'Prima Media Caxdac',
  PISS: 'Prima Media Iss',
  TITU: 'Titularización'
};

export default tipoFideicomiso;
