import Products, { ProductsDetail } from "../types/report/Products";

interface Segment extends ProductsDetail {
  key: "owned" | "coSigned" | "closed";
  label: string;
}

export const getSegments = (products: Products) => {
  const segmentProducts: Segment[] = [
    { label: "Productos (Principal)", key: "owned" },
    { label: "Productos (Codeudor y otros)", key: "coSigned" },
    { label: "Productos (Cerrados)", key: "closed" },
  ];

  const segments = segmentProducts.map(segmentProduct => {
    let segment: Segment = { ...segmentProduct };
    const detail: ProductsDetail = products[segment.key] || {};
    const upToDate = detail.upToDate || [];
    const overdue = detail.overdue || [];
    if (upToDate.length) segment = { ...segment, upToDate };
    if (overdue.length) segment = { ...segment, overdue };
    return segment;
  })

  return segments.filter(segment => segment.upToDate || segment.overdue);
}