import { Form, Formik } from "formik";
import React, { useEffect, useReducer } from "react";
import Card from "reactstrap/lib/Card";
import * as yup from "yup";
import SelectField from "../../../components/Form/SelectField";
import { Merchant } from "../../../services/merchantsGet";
import { ProductType } from "../../../services/merchantsProductsSubtypeGet";

export interface FilterValues {
  merchant: string;
  productType: string;
  subProductType: string;
}

interface Props {
  merchants: Merchant[];
  productsType: ProductType[];
  onFilter(filterValues: FilterValues): void;
}

const initialState: FilterValues = {
  merchant: "",
  productType: "",
  subProductType: ""
};

const reducer = (
  currentState: FilterValues,
  action: { target: { name: string; value: string } }
) => {
  return {
    ...currentState,
    [action.target.name]: action.target.value
  };
};

const ProductsFilter = ({
  merchants,
  productsType,
  onFilter,
  ...restProps
}: Props) => {
  const [valuesState, valuesDispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    valuesDispatch({ target: { name: "subProductType", value: "" } });
  }, [valuesState.productType]);

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.persist();
    valuesDispatch(e);
  };

  useEffect(() => {
    onFilter(valuesState);
  }, [valuesState]);

  return (
    <Card {...restProps}>
      <Formik
        initialValues={valuesState}
        validationSchema={yup.object({
          productType: yup.string(),
          subProductType: yup.string(),
          merchant: yup.string()
        })}
        onSubmit={() => { }}
      >
        <Form>
          <div className="mx-3 mt-3">
            <SelectField
              label="Tipo de producto"
              name="productType"
              onChange={handleSelectChange}
              options={[
                { label: "Todos", value: "" },
                ...productsType.map(type => ({
                  label: type.name,
                  value: type.value
                }))
              ]}
            />
            {productsType.filter(
              type =>
                type.value === valuesState.productType &&
                type.subProductsType.length > 0
            ).length > 0 && (
                <SelectField
                  label="Descripción"
                  name="subProductType"
                  onChange={handleSelectChange}
                  options={[
                    { label: "Todos", value: "" },
                    ...productsType
                      .find(type => type.value === valuesState.productType)!
                      .subProductsType.map(subType => ({
                        label: subType.label,
                        value: subType.value
                      }))
                  ]}
                />
              )}
            <SelectField
              label="Entidad"
              name="merchant"
              onChange={handleSelectChange}
              options={[
                { label: "Todos", value: "" },
                ...merchants
                  .filter(merchant => !merchant.disabled)
                  .map(merchant => ({
                    label: merchant.label,
                    value: merchant.value
                  }))
              ]}
            />
          </div>
        </Form>
      </Formik>
    </Card>
  );
};

export default ProductsFilter;
