import html2PDF from 'jspdf-html2canvas';
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styles from "./PaymentBill.module.scss";

interface Data {
  label: string;
  value: string;
}

interface Props {
  data: Data[];
  approved: boolean;
}

const PaymentBill = ({ data, approved }: Props) => {
  const [isPrinting, setIsPrinting] = useState(false);

  const handlePrint = async () => {
    const element = document.getElementById("payment-bill");
    if (element) {
      const output = `Factura-Enbanca.pdf`;
      setIsPrinting(true);
      await html2PDF(element, {
        watermark({ pdf, pageNumber, totalPageNumber }) {
          // pdf: jsPDF instance
          pdf.setFontSize(10);
          pdf.setTextColor('#667085');
          pdf.text("Enbanca", 20, pdf.internal.pageSize.height - 20)
          // pdf.text(`Enbanca (${pageNumber}/${totalPageNumber})`, 20, pdf.internal.pageSize.height - 20);
        },
        margin: {
          top: 20,
          left: 20,
          right: 20,
          bottom: 0,
        },
        // output,
        success(pdf) {
          pdf.save(output);
          setIsPrinting(false);
        },
      });
    }
  }
  return (
    <>
      <div id="payment-bill">
        <p className={styles.title}>
          Resumen de la compra
        </p>
        <div className={styles.container}>
          {data.filter(({ value }) => value).map(({ label, value }, i) => (
            <div
              key={label}
              className={styles.element}
            >
              <p className={styles.label}>
                {label}
              </p>
              <p className={`
                ${styles.value}
                ${!i ? (approved ? styles.approved : styles.rejected) : ""}
              `}>
                {value}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.buttons}>
        <NavLink to="/dashboard">
          <button className={`btn btn-outline-primary ${styles.button}`}>
            {approved ? "Ver mi perfil de crédito" : "Volver al inicio"}
          </button>
        </NavLink>
        <button
          className={`btn btn-primary ${styles.button}`}
          onClick={handlePrint}
          disabled={isPrinting}
        >
          {isPrinting && <div className="spinner-border spinner-border-sm mr-2" role="status" />}
          Imprimir
        </button>
      </div>
    </>
  )
}

export default PaymentBill;