import { put, select, takeLeading } from "redux-saga/effects";
import jwt from "../../helpers/jwt";
import reportsSocialSecurityInfoGet from "../../services/reportsSocialSecurityInfoGet";
import {
  setFetchInfoFailed,
  setFetchInfoSucceeded
} from "../socialSecurity/actions";
import { SocialSecurityState } from "../socialSecurity/types";
import store, { AppState } from "../store";

const getSocialSecurity = (store: AppState) => store.socialSecurityInfo;

function* fetchSocialSecurityInfo() {
  try {
    if (jwt.decode(store.getState().security.token).roles.includes("identity")) {
      const socialSecurityState: SocialSecurityState = yield select(
        getSocialSecurity
      );
      if (socialSecurityState.status !== "loaded") {
        const socialSecurityInfo = yield reportsSocialSecurityInfoGet();
        yield put(setFetchInfoSucceeded(socialSecurityInfo.payload));
      }
    }
  } catch (error) {
    yield put(setFetchInfoFailed(error));
    return;
  }
}

export default function* watchFetchSocialSecurityInfo() {
  yield takeLeading("socialSecurity/FETCH_INFO", fetchSocialSecurityInfo);
}
