import * as fetchMock from 'fetch-mock';
import { url } from '../services/electronicIDAutorizationGet';
import ResponseCreator from './helpers/responseCreator';
import withDelay from './helpers/withDelay';

fetchMock.get(`end:/${url}`, () => {
  return withDelay(
    new ResponseCreator()
      .setPayload({
        id: 'f2d73581-7cf5-4f85-b73b-3fdd00d89fb6',
        authorization:
          'CmEj7F8995wzuMzkxu3HqQizWqlO8b7grjB3k1Yd7nLC8qAO69JX3eYt-V8MOk2cSZ0x36rXafjMO-W2bqIoD_gKk-5yVSYc2fHwEl1BPI4='
      })
      .getResponse()
  );
});
