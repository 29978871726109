import { ErrorMessage, Field, FieldProps } from "formik";
import React from "react";
import { CustomInput, FormFeedback, FormGroup } from "reactstrap";
import styled from "../../styles/styled-components";

const FormGroupStyled = styled(FormGroup)`
  margin-bottom: 0px !important;
`;

export interface Props {
  label?: string;
  name: string;
  description?: string;
  hideLabel?: boolean;
  children?: React.ReactNode;
}

const CheckboxField: React.FC<Props> = ({
  label,
  name,
  description,
  hideLabel,
  children
}) => {
  const renderInput = ({ field, form }: FieldProps) => {
    return (
      <>
        <CustomInput
          {...field}
          id={`field-${field.name}`}
          name={field.name}
          type="checkbox"
          checked={field.value}
          label={children as any}
          invalid={form.touched[field.name] && !!form.errors[field.name]}
        >
          <ErrorMessage name={name} component={FormFeedback} />
        </CustomInput>
      </>
    );
  };
  return (
    <FormGroup>
      <FormGroupStyled>
        <Field name={name} component={renderInput} />
      </FormGroupStyled>
      {!!description && <div className="description">{description}</div>}
    </FormGroup>
  );
};

CheckboxField.defaultProps = {
  hideLabel: false
};

export default CheckboxField;
