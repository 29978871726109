import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import delay from "../helpers/delay";
import { clearMessage as clearMessageAction } from "../store/message/actions";
import { Message } from "../store/message/types";
import { AppState } from "../store/store";
import styles from "./FlashMessages.module.scss";
import Icon from "./UI/Icon";

interface Props extends React.FC {
  messages: Message[];
  clearMessage: (message: Message) => void;
}

export const FlashMessages = ({ messages, clearMessage }: Props) => {
  const handleClose = async (msg: Message) => {
    document
      .getElementById(msg.message)!
      .setAttribute(
        "class",
        `${styles[msg.messageType]} ${styles.toast} ${styles.close} ${styles.message
        }`
      );
    await delay(500);
    clearMessage(msg);
  };

  return (
    <div className={styles.container} data-testid="flash-messages">
      {messages.map(message => (
        <div
          id={message.message}
          key={message.message}
          className={`
            ${styles[message.messageType]} ${styles.toast} ${styles.open} ${styles.message}
          `}
        >
          <p className="m-0">
            {message.message}
          </p>
          <Icon
            icon="close"
            className={styles.icon}
            onClick={() => handleClose(message)}
          />
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  messages: state.message.messages
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearMessage: (message: Message) => dispatch(clearMessageAction(message))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FlashMessages);
