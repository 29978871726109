
import React from "react";
import { slide as Menu } from 'react-burger-menu';
import styles from "./SibebarMenu.module.scss";
import SidebarMenuContent from "./SidebarMenuContent";

export interface Props {
  open: boolean;
  pageWrapId: string;
  onToggle: (open: boolean) => void;
}

const SideBarMenu = ({ open, onToggle, pageWrapId }: Props) => {
  return (
    <>
      <nav className={`${styles.sidebar} ${!open && styles.hidden}`}>
        <SidebarMenuContent />
      </nav>
      <div className="d-md-none">
        <Menu
          width={280}
          isOpen={open}
          pageWrapId={pageWrapId}
          customCrossIcon={false}
          customBurgerIcon={false}
          onStateChange={(state: any) => onToggle(state.isOpen)}
        >
          <SidebarMenuContent onToggle={() => onToggle(false)} />
        </Menu>
      </div>
    </>
  )
};

export default SideBarMenu;
