/**
 *
 * @param {string} documentId
 * @param {string} password
 */
const loginRoles = (documentId, password) => {
  if (password === 'valid' || password === 'v' || password === 'Validpass1#') {
    if (documentId === '1' || documentId === '11111111') {
      return ['user'];
    }

    if (documentId === '2' || documentId === '22222222') {
      return ['user', 'validated'];
    }

    if (documentId === '3' || documentId === '33333333') {
      return ['user', 'validated', 'report'];
    }

    if (documentId === '4' || documentId === '44444444') {
      return ['user', 'validated', 'identity', 'report'];
    }

    if (documentId === '5' || documentId === '55555555') {
      return ['user', 'validated', 'identity', 'report', 'credit'];
    }

    if (documentId === '6' || documentId === '66666666') {
      return ['user', 'validated', 'identity', 'credit'];
    }

    if (documentId === '9' || documentId === 'eid' || documentId === '99999999') {
      return [
        'user',
        'validated',
        'identity',
        'report',
        'credit',
        'basic_info'
      ];
    }
  }
};

export default loginRoles;
