import React from 'react';
import Alert from 'reactstrap/lib/Alert';

export interface Props {
  children: React.ReactNode;
  className?: string;
}

const Success = ({ children, className }: Props) => (
  <Alert
    color="success text-success"
    className={className}
  >
    {children}
  </Alert>
);

export default Success;
