import React from "react";
import { Link } from "react-router-dom";

const LoginAttemptsMessage = () => {
  return (
    <>
      Has excedido el máximo número de intentos fallidos, puedes volver a
      intentarlo en 24 horas. Si has olvidado tu contraseña, haz clic{" "}
      <Link to="/recuperar-contrasena">aquí</Link>.
    </>
  );
};

export default LoginAttemptsMessage;
