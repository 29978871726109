import api from "./api";

export interface Score {
  fecha: string;
  puntaje: number;
  ts: number;
}

export interface ScoresResponse {
  puntajes: Score[];
}

export const url = "myreport/scoreHistory";

const scoresGet = async (): Promise<ScoresResponse> => {
  return await api.get(`${url}`, true);
};

export default scoresGet;
