import React from "react";
import styles from "./Icon.module.scss";

export interface Props {
  icon: string;
  id?: string;
  fill?: boolean;
  className?: string;
  onClick?: () => void;
}

const Icon = ({ id, icon, fill, className, onClick }: Props) => {
  return (
    <span
      id={id}
      className={`material-symbols-outlined ${fill ? styles.fill : ""} ${className || ""}`}
      onClick={onClick}
    >
      {icon}
    </span>
  )
};

export default Icon;