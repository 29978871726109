import React, { useRef, useState } from "react";
import Icon from "../../../../components/UI/Icon";
import delay from "../../../../helpers/delay";
import styles from "./DashboardTools.module.scss";
import OffersContainer from "./Offers/OffersContainer";
import OverduesContainer from "./Overdues/OverduesContainer";
import ScoreContainer from "./Score/ScoreContainer";
//import SocialSecurityContainer from "./SocialSecurity/SocialSecurityContainer";

type Key = "score" | "products" | "security_social" | "overdues";

interface Tool {
  key: Key;
  label: string;
  icon: string;
  color: string;
  component: React.ReactNode;
};

const DashboardTools = () => {
  const ref = useRef<HTMLHRElement>(null);

  const [tool, setTool] = useState<Key>();
  const [component, setComponent] = useState<React.ReactNode>();

  const tools: Tool[] = [
    {
      key: "score",
      label: "Sobre mi puntaje",
      icon: "readiness_score",
      color: "violet",
      component: <ScoreContainer />,
    },
    {
      key: "products",
      label: "Productos para ti",
      icon: "monetization_on",
      color: "green",
      component: <OffersContainer />,
    },
    // {
    //   key: "security_social",
    //   label: "Seguridad social",
    //   icon: "shield_person",
    //   color: "orange",
    //   component: <SocialSecurityContainer />,
    // },
    {
      key: "overdues",
      label: "Novedades con aliados",
      icon: "account_balance",
      color: "red",
      component: <OverduesContainer />,
    },
  ];

  const selectTool = async (key: Key, component: React.ReactNode) => {
    setTool(key);
    setComponent(component);
    await delay(50);
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.tools}>
        {tools.map(({ key, label, icon, color, component }) => (
          <div
            key={key}
            className={`eb-card ${styles.tool} ${key === tool ? styles.active : ""}`}
            onClick={() => selectTool(key, component)}
          >
            <div className={`${styles.shape} ${styles[color]}`}>
              <Icon icon={icon} />
            </div>
            <p className={styles.label}>
              {label}
            </p>
          </div>
        ))}
      </div>
      {tool && <hr ref={ref} className={styles.hr} />}
      {component}
    </div>
  )
}

export default DashboardTools;