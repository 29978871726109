import React from "react";
import Icon from "../../../components/UI/Icon";
import styles from "./ValidationStatusField.module.scss";

interface Props {
  isValidating: boolean;
  isValid: boolean;
}

const ValidationStatusField = ({ isValid, isValidating }: Props) => {
  if (isValid) {
    return (
      <Icon icon="check" className={styles.icon} />
    )
  }

  if (isValidating) {
    return (
      <div className={styles.icon}>
        <div className="spinner-border spinner-border-sm" role="status" />
      </div>
    )
  }

  return <></>
}

export default ValidationStatusField;