import jwt from "../../helpers/jwt";
import uuid from "../../helpers/uuid";
import { SecurityAction } from "./types";

export const setToken = (token: string): SecurityAction => {
  const { roles, sub } = jwt.decode(token);
  uuid.set(sub);
  return {
    type: "security/SET_TOKEN",
    token,
    roles,
    userId: sub,
    tokenIssueDate: new Date()
  };
};

export const logout = (): SecurityAction => {
  return {
    type: "security/LOGOUT"
  };
};

export const setFingerprint = (fingerprint: object): SecurityAction => {
  return {
    type: "security/SET_FINGERPRINT",
    fingerprint
  };
};

if ((window as any).Cypress) {
  (window as any).redux = (window as any).redux || {};
  (window as any).redux.actions = (window as any).redux.actions || {};
  (window as any).redux.actions.security = {
    setToken,
    logout,
    setFingerprint
  };
}
