import * as qs from "query-string";
import captureMessage from "../helpers/captureMessage";
import jwt from "../helpers/jwt";
import retryFetch, { RequestInitRetry } from "../helpers/retryFetch";
import { getDeviceId } from "../helpers/zipps";
import { sendMessage } from "../store/message/actions";
import { logout } from "../store/security/actions";
import store from "../store/store";
import ApiError from "../types/ApiError";

const API_URL: string = `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH
  }`;

const MERCHANTS_URL: string = `${process.env.REACT_APP_MERCHANT_API_HOST}`;

const api = (method: "POST" | "GET") => async (
  endpoint: string,
  authenticated: boolean,
  data?: {},
  fromMerchants?: boolean
) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json; charset=utf-8");

  if (authenticated) {
    const token = store.getState().security.token;
    const timestamp = Math.round(new Date().getTime() / 1000);
    if (
      token &&
      (timestamp < jwt.decode(token).exp ||
        process.env.NODE_ENV === "development")
    ) {
      headers.append("Authorization", `Bearer ${token}`);
    } else {
      store.dispatch(logout());
      throw new ApiError("No token or expired token", 401, undefined);
    }
  }

  const security = store.getState().security;
  if (security.fingerprint && !fromMerchants) {
    const keys = Object.keys(security.fingerprint);
    for (const key of keys) {
      headers.append(
        "X-" + key.charAt(0).toUpperCase() + key.slice(1),
        security.fingerprint[key]
      );
    }
  }

  // Zipps Device id
  if (!fromMerchants) {
    headers.append("X-Device-Id", await getDeviceId())
  }

  const options: RequestInitRetry = {
    method,
    cache: "no-cache",
    headers,
    retries: parseInt(process.env.REACT_APP_API_RETRIES || "3", 10)
  };

  let queryString = "";
  if (typeof data === "object") {
    if (method === "GET") {
      queryString = `?${qs.stringify(data)}`;
    } else if (method === "POST") {
      options.body = JSON.stringify(data);
    } else {
      throw new Error("Only GET and POST are implemented");
    }
  }

  try {
    if (process.env.NODE_ENV === "development") {
      //console.log("FETCH", `/${endpoint}${queryString}`, options);
    }
    const response = await retryFetch(
      `${fromMerchants ? MERCHANTS_URL : API_URL}/${endpoint}${queryString}`,
      options
    );
    if (process.env.NODE_ENV === "development") {
      //console.log("RESPONSE", `/${endpoint}${queryString}`, response);
    }
    if (response.ok) {
      return await response.json();
    } else {
      if (response.status === 403) {
        store.dispatch(
          sendMessage(
            "Tu usuario ha sido bloqueado temporalmente. Por favor ponte en contacto con nosotros.",
            "error",
            { time: 0 }
          )
        );
        /* Redireccionamiento a logout cuando se obtiene error 403 */
        //store.dispatch(logout());
        throw new ApiError(
          response.statusText,
          response.status,
          "Blocked user"
        );
      }
      const responseData = await response.json();
      if (response.status === 401) {
        store.dispatch(logout());
        throw new ApiError(
          response.statusText,
          response.status,
          "Unauthorized user"
        );
      }
      throw new ApiError(response.statusText, response.status, responseData);
    }
  } catch (error) {
    if (error instanceof ApiError) {
      const userStore = store.getState().user;
      const userData = userStore.status === "loaded" ? userStore.payload : null;
      captureMessage(
        "Api Error",
        { response: error.response },
        {
          code: error.code,
          endpoint: `/${endpoint}${queryString}`,
          document: userData ? userData.documentNumber : "",
        }
      )
    }
    throw error;
  }
};

export default {
  get: api("GET"),
  post: api("POST")
};
