import api from './api';

export interface ChangeEmailParams {
  email: string;
  code: string;
  url: string;
}

export const url = 'preferences/change-email';

const preferencesChangeEmailPost = async (
  data: ChangeEmailParams
): Promise<Response> => {
  return await api.post(url, true, data);
};

export default preferencesChangeEmailPost;
