import * as fetchMock from 'fetch-mock';
import { ForgotPasswordParams, url } from '../services/usersForgotPasswordPost';
import ResponseCreator from './helpers/responseCreator';

fetchMock.post(
  `end:${process.env.REACT_APP_API_PATH}/${url}`,
  (_, response) => {
    const data = JSON.parse(response.body as string) as ForgotPasswordParams;
    if (data.type === 'email') {
      if (data.value === 'test3@example.com') {
        return new ResponseCreator().getResponse();
      } else if (data.value === 'test2@example.com') {
        const result = new ResponseCreator()
          .addError('User.forgotPassword', 'Email not validated')
          .getResponse();
        return result;
      } else {
        const result = new ResponseCreator()
          .addError('User.forgotPassword', 'Email not found')
          .getResponse();
        return result;
      }
    } else {
      if (!data.value.startsWith('57')) {
        return new ResponseCreator()
          .addError('User.forgotPassword', 'INVALID PHONE')
          .getResponse();
      }
      if (data.value === '573017221662') {
        return new ResponseCreator().getResponse();
      } else if (data.value === '573223326926') {
        return new ResponseCreator()
          .addError('User.forgotPassword', 'Phone not validated')
          .getResponse();
      } else {
        return new ResponseCreator()
          .addError('User.forgotPassword', 'Phone not found')
          .getResponse();
      }
    }
    return new ResponseCreator().getResponse();
  }
);
