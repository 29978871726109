import api from './api';

export interface ElectronicIDAutorizationResponse {
  id: string;
  authorization: string;
}

export const url = 'electronic-id/autorization';

const electronicIDAutorizationGet = async (): Promise<
  ElectronicIDAutorizationResponse
> => {
  const response = await api.get(url, true);
  return response.payload;
};

export default electronicIDAutorizationGet;
