import * as fetchMock from 'fetch-mock';
import { url } from '../services/creditsGet';
import ResponseCreator from './helpers/responseCreator';
import withDelay from './helpers/withDelay';

const credits = [
  {
    id: '4a0bd8c8-8175-44d5-9942-8a338bf29212',
    itemId: 33,
    type: 'offer',
    merchantId: '3',
    name: 'NEGOCIO-SEMPLI',
    description: 'NEGOCIO-SEMPLI',
    productId: 76,
    imageUrl: '322a58f6-ab41-491e-9af1-e1dda414bd91.png',
    initDate: '2019-08-02T00:00:00.000-05:00',
    finDate: '2019-08-31T23:59:59.000-05:00',
    product: {
      id: 76,
      name: 'Crédito Negocio',
      merchantId: '3',
      merchantName: 'SEMPLI',
      productType: 'creditos',
      subProductType: 'negocio',
      description:
        'Crédito simple y oportuno para pequeñas y medianas empresas',
      rateFrom: null,
      rateTo: null,
      termFrom: 6,
      amountFrom: 3.0e7,
      amountTo: 3.0e8,
      associatedCosts: '',
      allCost: null,
      feeType: '',
      minRequirements:
        'Empresas formalmente constituidas\nCon, al menos, 300 millones de pesos en ventas en los últimos 12 meses',
      minIncomes: '',
      imageUrl: '8b2d886c-cf24-4324-b5c6-800b04d9ae88.png',
      requestLink: 'https://www.sempli.co/registro'
    }
  },
  {
    id: '72a63fee-f9ab-47a7-8468-70764262a55d',
    itemId: 44,
    type: 'offer',
    merchantId: '27',
    name: 'SEGUROS-VIAJE',
    description: 'SEGUROS-VIAJE',
    productId: 95,
    imageUrl: '945c7a81-28f5-4c07-8727-f8d57063e432.svg+xml',
    initDate: '2019-08-02T00:00:00.000-05:00',
    finDate: '2019-08-31T23:59:59.000-05:00',
    product: {
      id: 95,
      name: 'Viaje',
      merchantId: '27',
      merchantName: 'SURA',
      productType: 'seguros',
      subProductType: '',
      description:
        'Disfruta tu viaje tranquilo porque, pase lo que pase, cuentas con nuestro acompañamiento para que tu salud y bienestar financiero estén asegurados adonde quiera que vayas.',
      rateFrom: null,
      rateTo: null,
      termFrom: null,
      amountFrom: null,
      amountTo: null,
      associatedCosts: '',
      allCost: null,
      feeType: '',
      minRequirements: '',
      minIncomes: '',
      imageUrl: 'e5ea9cda-8d80-4bf7-ac0c-ef628815c199.png',
      requestLink: 'https://www.suraenlinea.com/v2/sura/viajes/cotizar'
    }
  },
  {
    id: '58fa9c83-5957-4652-b488-caf0e5de209d',
    itemId: 32,
    type: 'offer',
    merchantId: '13',
    name: 'NEGOCIO-GULUNGO',
    description: 'NEGOCIO-GULUNGO',
    productId: 73,
    imageUrl: '3dabb662-db52-4b66-b24b-3cbefcabe7ea.png',
    initDate: '2019-08-02T00:00:00.000-05:00',
    finDate: '2019-08-31T23:59:59.000-05:00',
    product: {
      id: 73,
      name: 'Crédito para Negocio',
      merchantId: '13',
      merchantName: 'GULUNGO',
      productType: 'creditos',
      subProductType: 'negocio',
      description:
        '¡Pymes sin lí­mites! Solicitud en tres pasos:\n1. Solicitud 100% en línea\n2. Adjuntar documentación\n3. Elegir monto, plazo y fecha de pago\n\nEl crédito no puede sobrepasar las ventas mensuales del negocio',
      rateFrom: null,
      rateTo: null,
      termFrom: null,
      amountFrom: 5000000.0,
      amountTo: 1.5e8,
      associatedCosts: '',
      allCost: null,
      feeType: '',
      minRequirements:
        'Facturación mensual\nMás de 10 millones de pesos mensuales en ingreso.\n\n18 meses de operación\nContar con estados financieros firmados de mínimo 18 meses de operación.\n\nFormalización\nDebe estar registrada en cámara de comercio, pagar seguridad social y tener la contabilidad al dí­a.\n\nEl crédito no puede sobrepasar las ventas mensuales del negocio',
      minIncomes: '',
      imageUrl: 'a952c504-f707-4982-a410-e3e0eea26b33.png',
      requestLink: 'https://www.gulungo.com/'
    }
  },
  {
    id: '9287f484-387b-4500-a3dc-e717219406f5',
    itemId: 31,
    type: 'offer',
    merchantId: '9',
    name: 'NEGOCIO-CREZCAMOS',
    description: 'NEGOCIO-CREZCAMOS',
    productId: 67,
    imageUrl: 'ebca0aad-cfa1-47ba-b169-6ff0d32e0696.png',
    initDate: '2019-08-02T00:00:00.000-05:00',
    finDate: '2019-08-31T23:59:59.000-05:00',
    product: {
      id: 67,
      name: 'CREDINEGOCIO',
      merchantId: '9',
      merchantName: 'CREZCAMOS',
      productType: 'creditos',
      subProductType: 'negocio',
      description: 'Monto mínimo: 20 % de 1 SMMLV - Máximo: 120 SMMLV',
      rateFrom: 54.98,
      rateTo: null,
      termFrom: 3,
      amountFrom: null,
      amountTo: null,
      associatedCosts: '',
      allCost: null,
      feeType: '',
      minRequirements: '',
      minIncomes: '',
      imageUrl: 'c1f38db6-324e-4b7a-9b44-3bac38f914a0.png',
      requestLink: 'https://crezcamos.com/secciones-80-s/credinegocio.htm'
    }
  }
];

fetchMock.get(`end:${process.env.REACT_APP_API_PATH}/${url}`, () => {
  return withDelay(new ResponseCreator().setPayload(credits).getResponse());
});

fetchMock.get(
  `express:${process.env.REACT_APP_API_PATH}/${url}/:product`,
  () => {
    return withDelay(new ResponseCreator().getResponse());
  }
);
