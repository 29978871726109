import {
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  Filler,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { capitalize } from '../../../../helpers/capitalize';
import { formatDate } from '../../../../helpers/date';
import { Score } from "../../../../services/scoresGet";
import styles from "./ScoreChart.module.scss";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
);

interface Props {
  scores: Score[];
}

const ScoreChart = ({ scores }: Props) => {
  const [data, setData] = useState<ChartData<"line">>({ labels: [], datasets: [] });
  const [months, setMonths] = useState<string[]>([]);

  useEffect(() => {
    const orderScores = [...scores].reverse();
    // const labels = orderScores.map(({ fecha }) => capitalize(formatDate(fecha, { in: "YYYY-MM-DD[T]HH:mm:ss.SSSZ" })));
    const labels = orderScores.map(({ fecha }) => fecha);
    const months = labels.map(date => capitalize(formatDate(date, { in: "YYYY-MM-DD", out: "MMM" })));
    setMonths(months);
    const data = orderScores.map(({ puntaje }) => puntaje);
    const dataset = {
      data,
      fill: true,
      label: " Puntaje",
      tension: 0.4, // curve
      pointRadius: 3,
      pointHoverRadius: 5,
      borderColor: "#3B82F6",
      pointBackgroundColor: "#3B82F6",
      // backgroundColor: "#3B82F6",
      // @ts-ignore: Parameter 'context' implicitly has an 'any' type
      backgroundColor: (context) => {
        const chart = context.chart;
        if (!chart) return
        const { ctx, chartArea: { top, bottom } } = chart;
        const gradient = ctx.createLinearGradient(0, top, 0, bottom);
        gradient.addColorStop(0, "rgba(59, 130, 246, 0.4)")
        gradient.addColorStop(0.2, "rgba(59, 130, 246, 0.2)")
        gradient.addColorStop(1, "rgba(59, 130, 246, 0)")
        return gradient
      }
    };
    setData({ labels, datasets: [dataset] });
  }, [scores]);

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        min: 0,
        max: 1000,
        grid: {
          offset: false,
          drawBorder: false,
          // @ts-ignore: Parameter 'context' implicitly has an 'any' type
          color: (context) => {
            if (context.tick.$context.index % 2 === 0) return "#E0E2E7"
            return "#F9F9FC"
          }
        },
        ticks: {
          stepSize: 250,
          // @ts-ignore: Parameter 'context' implicitly has an 'any' type
          color: (context) => {
            if (context.tick.$context.index % 2 === 0) return "#667085"
            return "#ffffff"
          },
          // @ts-ignore: Parameter 'value' implicitly has an 'any' type
          callback: (value) => value,
          font: {
            size: 12,
            weight: "400",
          }
        },
      },
      x: {
        grid: {
          offset: true,
          drawBorder: false,
          display: false,
          color: "#ffffff"
        },
        ticks: {
          color: "#667085",
          font: {
            size: 12,
            weight: "400",
          },
          // @ts-ignore: Parameter 'index' implicitly has an 'any' type
          callback: (index) => months[index] || ""
        }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          // @ts-ignore: Parameter 'context' implicitly has an 'any' type
          title: (context) => {
            const label = context[0].label;
            return formatDate(label, { in: "YYYY-MM-DD" })
          }
        },
      }
    }
  }

  return (
    <div className={styles.container}>
      <Line
        data={data}
        options={options}
        className={styles.chart}
      />
    </div>
  )
}

export default ScoreChart;