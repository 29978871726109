import React from "react";
import { PlanResponse } from "../../../services/plansGet";
import Plan from "./Plan";
import styles from "./Plans.module.scss";
import SmallPlan from "./SmallPlan";

interface Props {
  plans: PlanResponse[];
  small?: boolean;
}
const Plans = ({ plans, small }: Props) => {
  // Response plans: 30, 90, 180 | Ordered plans: 90, 30, 180
  const orderedPlans = [...plans];
  const firstPlan = orderedPlans.shift();
  if (firstPlan) orderedPlans.splice(1, 0, firstPlan);

  return (
    <div className={styles.plans}>
      {orderedPlans.map(plan => (
        small
          ? <SmallPlan key={plan.uuid} plan={plan} />
          : <Plan key={plan.uuid} plan={plan} />
      ))}
    </div>
  )
}

export default Plans;