import React from 'react';
import styles from "./TemplateForm.module.scss";

interface Props {
  title: string;
  children: React.ReactNode;
}

const TemplateForm = ({ title, children }: Props) => {
  const logo = require("../../../images/logos/logo-enbanca.png");
  const logoWhite = require("../../../images/logos/logo-enbanca-white.png");
  return (
    <>
      <div className="position-relative">
        <div className={styles.banner} aria-hidden="true" />
        <img
          src={logoWhite}
          className="d-md-none position-relative"
          style={{ paddingTop: "2rem" }}
        />
        <div className="px-3 d-flex" style={{ paddingTop: "2rem" }}>
          <div className={`${styles.card} shadow`}>
            <h2 className={styles.title}>
              {title}
            </h2>
            {children}
          </div>
        </div>
      </div>
      <img
        src={logo}
        className="d-none d-md-block mx-auto position-relative"
        style={{ marginTop: "4rem" }}
      />
      <span className={styles.footer}>
        © {new Date().getUTCFullYear()} app.enbanca.co - Todos los derechos reservados
      </span>
    </>
  )
}

export default TemplateForm;
