import React from "react";
import Page from "../../components/Layout/Page";
import ShareReportContainer from "./containers/ShareReportContainer";

const ShareReportView = () => (
  <Page back>
    <ShareReportContainer />
  </Page>
);

export default ShareReportView;
