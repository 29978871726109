import { ReportSummaryResponse } from "../../services/reportsSummaryGet";
import { ReportSummaryAction } from "./types";

export const fetchReportSummary = (): ReportSummaryAction => ({
  type: "reportSummary/FETCH_INFO"
});

export const setFetchInfoSucceeded = (
  reportSummaryInfo: ReportSummaryResponse
): ReportSummaryAction => ({
  type: "reportSummary/FETCH_INFO_SUCCEEDED",
  reportSummaryInfo
});

export const setFetchInfoFailed = (error: any): ReportSummaryAction => ({
  type: "reportSummary/FETCH_INFO_FAILED",
  error
});
