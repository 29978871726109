import { useEffect, useState } from 'react';
import serviceBuilder from '../helpers/serviceBuilder';
import { ServiceStatus } from '../types/ServiceStatus';

export interface Report {
  id: string;
  name: string;
  message: string;
  viewed: boolean;
  email?: string;
  phone?: string;
  sendMethod: 'phone' | 'email';
}

export interface Payload {
  reports: Report[];
}

export const url = 'reports/sent';

const useReportsSentGet = () => {
  const [reportsSent, setReportsSent] = useState<ServiceStatus<Payload>>({
    status: 'init'
  });

  useEffect(() => {
    const { start, abort } = serviceBuilder<{}, Payload>('get', {
      url,
      set: setReportsSent,
      auth: true
    });
    start();
    return () => abort();
  }, []);

  return reportsSent;
};

export default useReportsSentGet;
