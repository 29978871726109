import React from "react";
import Page from "../../components/Layout/Page";
import ValidationContainerInterview from "./containers/ValidationContainerInterview";
// import ValidationContainer from "./containers/ValidationContainer";

const ValidationView = () => {
  return (
    <Page>
      <div className="eb-card">
        <ValidationContainerInterview />
        {/* <ValidationContainer /> */}
      </div>
    </Page>
  )
}

export default ValidationView;