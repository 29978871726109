import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { PaymentGateway as PaymentGatewayType } from '../../../services/paymentsGatewayGet';
import { PaymentsGatewayState } from '../../../store/paymentsGateway/types';
import { AppState } from '../../../store/store';
import styles from "./PaymentGateway.module.scss";

interface Props {
  paymentsGatewayState: PaymentsGatewayState;
  onSelected: (code: string) => void;
}

const PaymentGateway = ({ paymentsGatewayState, onSelected }: Props) => {
  const [selected, setSelected] = useState("");
  const [paymentsGateway, setPaymentsGateway] = useState<PaymentGatewayType[]>([]);

  useEffect(() => {
    if (paymentsGatewayState.status === "loaded") {
      const { payload } = paymentsGatewayState;
      const { paymentsGateway } = payload;
      setPaymentsGateway(paymentsGateway);
    }
  }, [paymentsGatewayState.status])

  const handleSelect = (code: string) => {
    setSelected(code);
    onSelected(code);
  }

  return (
    <div className={`eb-card ${styles.container}`}>
      <p className="m-0">
        Seleccione un método de pago
      </p>
      <div className={styles.methods}>
        {paymentsGateway.map(({ code, name, paymentMethod, urlImage }) => (
          <div
            key={code}
            className={`${styles.method} ${code === selected ? styles.selected : ""}`}
            onClick={() => handleSelect(code)}
          >
            <div className={styles.shape}>
              <img src={urlImage} alt={name} />
            </div>
            <div className={styles.info}>
              <p className={styles.name}>
                {name}
              </p>
              <p className={styles.options}>
                {paymentMethod}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  paymentsGatewayState: state.paymentsGateway,
});


export default connect(mapStateToProps)(PaymentGateway);