import { Form, Formik, FormikActions, FormikProps } from "formik";
import React from "react";
import { Input, InputGroup, InputGroupAddon } from "reactstrap";
import * as yup from "yup";
import InputField from "../../../components/Form/InputField";
import RadioGroup from "../../../components/Form/RadioGroup";
import SubmitButton from "../../../components/Form/SubmitButton";
import formatOnlyNumbers from "../../../helpers/formatOnlyNumbers";
import processSubmitErrors from "../../../helpers/processSubmitErrors";
import { emailRegex, phoneRegex } from "../../../helpers/valid";
import reportsShareRequestPost, {
  ReportsRequestParams
} from "../../../services/reportsShareRequestPost";
import styles from "./RequestReportForm.module.scss";

interface FormValues {
  sendMethod: "email" | "phone";
  name: string;
  email: string;
  phone: string;
}

const RequestReportForm = () => {
  const initialValues: FormValues = {
    sendMethod: "email",
    name: "",
    email: "",
    phone: "",
  };

  const validationSchema = yup.object().shape({
    sendMethod: yup
      .string()
      .required("Elija una opción"),
    name: yup
      .string()
      .required("Se requiere el nombre del destinatario"),
    email: yup
      .string()
      .when("sendMethod", {
        is: value => value === "email",
        then: yup
          .string()
          .required("El correo es requerido")
          .email("El correo no es válido")
          .matches(emailRegex, "El correo no es válido")
      }),
    phone: yup
      .string()
      .when("sendMethod", {
        is: value => value === "phone",
        then: yup
          .string()
          .required("El teléfono es requerido")
          .length(10, "El móvil debe ser de 10 dígitos")
          .matches(phoneRegex, "El número de móvil no es válido")
      }),
  });

  const methods = [
    { name: "Correo", value: "email" },
    { name: "Teléfono móvil", value: "phone" },
  ];

  const handleSubmit = async (
    values: FormValues,
    {
      setErrors,
      setStatus,
      setSubmitting,
      resetForm
    }: FormikActions<FormValues>
  ) => {
    try {
      const sendMethod = values.sendMethod;
      const data: ReportsRequestParams = {
        sendMethod,
        name: values.name,
        email: sendMethod === "email" ? values.email : undefined,
        phone: sendMethod === "phone" ? `57${values.phone}` : undefined,
      };
      setStatus({ successMessage: "" });
      await reportsShareRequestPost(data);
      resetForm();
      setStatus({ successMessage: "Tu solicitud ha sido enviada" });
    } catch (error) {
      processSubmitErrors(error, values, setErrors, setStatus);
    }
    setSubmitting(false);
  }

  return (
    <>
      <p className={styles.title}>
        Solicitar el reporte crediticio a un conocido
      </p>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        render={({ values }: FormikProps<FormValues>) => (
          <Form className={styles.form}>
            <RadioGroup
              name="sendMethod"
              label="¿Cómo deseas solicitar el reporte?"
              options={methods}
              className={styles.field}
            />
            <div className={styles.grid}>
              <InputField
                name="name"
                label="Nombre"
                className={styles.field}
              />
              {values.sendMethod === "email"
                ? (
                  <InputField
                    name="email"
                    label="Correo"
                    className={styles.field}
                  />
                )
                : (
                  <InputField
                    name="phone"
                    label="Teléfono móvil"
                    type="tel"
                    formatValue={formatOnlyNumbers}
                    className={styles.field}
                    render={props => (
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          57
                        </InputGroupAddon>
                        <Input {...props} />
                      </InputGroup>
                    )}
                  />
                )
              }
            </div>
            <div className={styles.container}>
              <SubmitButton
                id="request-report"
                text="Solicitar reporte"
                className={styles.button}
              />
            </div>
          </Form>
        )}
      />
    </>
  );
};

export default RequestReportForm;
