import * as fetchMock from 'fetch-mock';
import { url as urlGet } from '../services/validationMobileGet';
import {
  url as urlPost,
  ValidationMobileParams
} from '../services/validationMobilePost';
import ResponseCreator from './helpers/responseCreator';
import store from './helpers/store';
import withDelay from './helpers/withDelay';

(window as any).fixtures = (window as any).fixtures || {};
(window as any).fixtures.validationMobile = {
  _error: 'none',
  serverError() {
    this._error = 'serverError';
  }
};

fetchMock.get(`end:${process.env.REACT_APP_API_PATH}/${urlGet}`, url => {
  if ((window as any).fixtures.validationMobile._error === 'serverError') {
    return withDelay(
      new ResponseCreator()
        .addError('Server error', 'Server error')
        .setStatus(500)
        .getResponse()
    );
  }
  return withDelay({ status: 200, body: {} });
});

fetchMock.post(
  `end:${process.env.REACT_APP_API_PATH}/${urlPost}`,
  (url, fetchData) => {
    const data = JSON.parse(fetchData.body as string) as ValidationMobileParams;

    if (data.code === 'invalid') {
      return withDelay(
        new ResponseCreator().addError('code', 'Invalid code').getResponse()
      );
    }

    if (data.code === 'expired') {
      return withDelay(
        new ResponseCreator()
          .addError('code', 'Validation event expired')
          .getResponse()
      );
    }

    store.roles = ['validated'];
    return withDelay({
      status: 200,
      body: {
        valid: true
      }
    });
  }
);
