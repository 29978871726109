import errorMessages from '../dictionaries/errorMessages';
import { ServiceError } from '../types/ServiceError';

const translateError = (error: string | ServiceError[]) => {
  return Array.isArray(error)
    ? error.map(errorItem => errorMessages[errorItem.error] || errorItem.error)
    : errorMessages[error] || error;
};

export default translateError;
