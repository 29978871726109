import { useEffect, useState } from "react";
import serviceBuilder from "../helpers/serviceBuilder";
import { ServiceStatus } from "../types/ServiceStatus";

export interface SharedReports {
  id: string;
  ownerName: string;
  message: string;
}

export interface Payload {
  reports: SharedReports[];
}

export const url = "reports/shared";

const useReportsSharedGet = () => {
  const [reportsShared, setReportsShared] = useState<ServiceStatus<Payload>>({
    status: "init"
  });

  useEffect(() => {
    const { start, abort } = serviceBuilder<{}, Payload>("get", {
      url,
      set: setReportsShared,
      auth: true
    });
    start();
    return () => {
      abort();
    };
  }, []);

  return reportsShared;
};

export default useReportsSharedGet;
