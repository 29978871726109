import React from 'react';
import { RouteComponentProps } from 'react-router';
import Button from 'reactstrap/lib/Button';
import styled from 'styled-components';
import Error from "../../../images/error.png";
import Success from "../../../images/success.png";
import { Buttons, GeneralContent } from "./PaymentView";


const SuccesIcon = styled.div`
  height: 100px;
  width: 100px;
  margin-bottom:-25px;
  background-image: url(${Success});
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 700px) {
    height: 200px;
    width: 200px; 
  }
`;

const ErrorIcon = styled.div`
  height: 100px;
  width: 100px;
  margin-bottom:-25px;
  background-image: url(${Error});
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 700px) {
    height: 200px;
    width: 200px;
  }
`;



export const TextBox = styled.div`
display:flex;
flex-direction:column;
align-items:center;
box-shadow: 1px -1px 13px -12px rgba(0,0,0,0.75);
background:#fff;
margin-left:20%;
margin-right:20%;
padding:10px;
border-radius:5px;
color:#1e2c59;

`;




const PaymentResult = ({ history }: RouteComponentProps) => {

    return (
        <GeneralContent >
            <SuccesIcon />
            <TextBox>
                Muchas gracias por tu pago, tu historial de crédito se ha actualizado.
                <br />
                <br />
                <Buttons >
                    <Button onClick={() => { history.push('/dashboard') }} color="primary">Continuar</Button>
                </Buttons>
            </TextBox>
            <ErrorIcon />
            <TextBox>
                Tu pago no ha sido aprobado, recuerda que si deseas actualizar tu historial de crédito debes aceptar el pago en el mensaje que te llegó a tu cuenta de NEQUI.
                <br />
                <br />
                <Buttons >
                    <Button color="primary">Volver</Button>
                </Buttons>
            </TextBox>

        </GeneralContent>
    );

};
export default PaymentResult