import React from "react";
import { formatDate } from "../../../helpers/date";
import styles from "./Alert.module.scss";

export interface Props {
  type?: string;
  title: string;
  datetime: string;
  description: string;
}

const Alert = ({ type, title, datetime, description }: Props) => {
  // YYYY-MM-DD[T]HH:mm:ss.SSS[Z] - YYYY-MM-DD[T]HH:nn:ss (dates with one, two and three miliseconds)
  const _datetime = datetime.slice(0, 19);

  return (
    <>
      <hr className={styles.hr} />
      <div className={styles.container}>
        <p className={styles.title}>
          {title}
        </p>
        <div>
          <p className={styles.description}>
            {description}
          </p>
          <div className={`${styles.date} ${type === "success" ? styles.success : ""}`}>
            {formatDate(
              _datetime,
              {
                in: "YYYY-MM-DD[T]HH:mm:ss",
                out: "DD [de] MMMM YYYY - h:mm A"
              }
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Alert;


