import React from "react";
import Page from "../../components/Layout/Page";
import PlanBuyContainer from "./containers/PlanBuyContainer";

const PlanBuyView = () => {
  return (
    <Page back>
      <div className="eb-card">
        <PlanBuyContainer />
      </div>
    </Page>
  );
};

export default PlanBuyView;
