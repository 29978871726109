import * as fetchMock from 'fetch-mock';
import {
  ReportsRequestHistoryPayload,
  url
} from '../services/useReportsRequestHistoryGet';
import ResponseCreator from './helpers/responseCreator';
import withDelay from './helpers/withDelay';

fetchMock.get(`end:${process.env.REACT_APP_API_PATH}/${url}`, () => {
  return withDelay(new ResponseCreator().setPayload(history).getResponse());
});

const history: ReportsRequestHistoryPayload[] = [
  {
    email: 'pedro.perez@example.com',
    dateCreated: '01-02-2019',
    status: 'sent'
  },
  {
    phone: '573001234567',
    dateCreated: '02-03-2019',
    dateUpdated: '04-03-2019',
    status: 'acepted'
  },
  {
    email: 'juan.jimenez@example.com',
    dateCreated: '03-04-2019',
    dateUpdated: '10-04-2019',
    status: 'rejected'
  }
];
