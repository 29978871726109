import v4 from 'uuid/v4';

// let uuid: string | null;
// if (localStorage) {
//   uuid = localStorage.getItem('uuid');
// } else {
//   uuid = document.cookie.replace(
//     /(?:(?:^|.*;\s*)uuid\s*\=\s*([^;]*).*$)|^.*$/,
//     '$1'
//   );
// }

// if (!uuid) {
//   uuid = v4();
//   if (localStorage) {
//     localStorage.setItem('uuid', uuid);
//   } else {
//     document.cookie = `uuid=${uuid};max-age=31536000`; // max-age: 1 year
//   }
// }

const getUUID = (forceNew: boolean) => {
  let uuid: string | null;
  if (localStorage) {
    uuid = localStorage.getItem('uuid');
  } else {
    uuid = document.cookie.replace(
      /(?:(?:^|.*;\s*)uuid\s*\=\s*([^;]*).*$)|^.*$/,
      '$1'
    );
  }

  if (!uuid || forceNew) {
    uuid = v4();
    setUUID(uuid);
  }
  return uuid;
};

const setUUID = (uuid: string) => {
  if (localStorage) {
    localStorage.setItem('uuid', uuid);
  } else {
    document.cookie = `uuid=${uuid};max-age=31536000`; // max-age: 1 year
  }
};

// export default uuid as string;
export default {
  get: (forceNew = false) => getUUID(forceNew),
  set: setUUID
};
