import React, { useEffect, useState } from "react";
import Icon from "../../../../components/UI/Icon";
import Loading from "../../../../components/UI/Loading";
import ProMeter from "../../../../components/UI/ProMeter";
import { capitalize } from "../../../../helpers/capitalize";
import { profileLabelByScore, rangeLabelByScore } from "../../../../helpers/scoreLevels";
import { Score as ScoreType } from "../../../../services/scoresGet";
import styles from "./Score.module.scss";

interface Props {
  plan: string;
  scores: ScoreType[];
}

interface ScoreData {
  icon: string;
  value: number;
  label: string;
  range: string;
  color: string;
  trend: number;
  previousScore: ScoreType | null;
}

const Score = ({ plan, scores }: Props) => {
  const [score, setScore] = useState<ScoreData | null>(null);

  useEffect(() => {
    if (scores.length) {
      const value = scores[0].puntaje;
      const label = capitalize(profileLabelByScore(value));
      const range = rangeLabelByScore(value);
      const previousScore = scores.length > 1 ? scores[1] : null;
      const trend = previousScore
        ? value - previousScore.puntaje
        : 0;
      let icon = "remove";
      let color = styles.equal;
      if (trend > 0) {
        icon = "arrow_upward_alt";
        color = styles.increase;
      }
      else if (trend < 0) {
        icon = "arrow_downward_alt";
        color = styles.decrease;
      }
      setScore({ icon, value, label, range, color, trend, previousScore });
    }
  }, [scores]);

  return (
    <div className={`eb-card ${styles.container}`}>
      <div className={styles.header}>
        <p className={styles.title}>
          Puntaje de crédito
        </p>
        <div className={styles.subscription}>
          <p className={styles.plan}>
            {plan}
          </p>
          <Icon
            icon="check_circle"
            className={styles.icon}
            fill
          />
        </div>
      </div>
      {score
        ? (
          <>
            <ProMeter score={score.value} />
            <div className={styles.info}>
              <div className={styles.element}>
                <p className={styles.text}>
                  {score.label}
                </p>
                <p className={styles.hint}>
                  {score.range}
                </p>
              </div>
              <div className={styles.element}>
                <div>
                  {score.previousScore && (
                    <p className={styles.text}>
                      {Math.abs(score.trend)} pts
                    </p>
                  )}
                  <Icon
                    icon={score.icon}
                    className={score.color}
                  />
                </div>
                <p className={styles.hint}>
                  Tendencia
                </p>
              </div>
            </div>
          </>
        )
        : <Loading />
      }
    </div>
  )
}

export default Score;