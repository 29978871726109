import Question from '../types/Question';
import api from './api';

export interface SecurityQuestionParams {
  questionNumber: number;
}

export const url = 'security-question';

const securityQuestionPost = async (
  postData: SecurityQuestionParams
): Promise<Question> => {
  return await api.post(url, true, postData);
};

export default securityQuestionPost;
