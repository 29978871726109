// Tabla 12 - Marca tarjeta
// TransUnion - Tablas de convenciones - Información Comercial - Versión 1.34

const marcaTarjeta = {
  AGR: 'Agropecuaria',
  AMX: 'American Express',
  COL: 'Bcsc Colmedica',
  CAF: 'Cafetera',
  CLA: 'Clasica',
  CLP: 'Colpatria',
  CON: 'Conquista',
  CRE: 'Credibanco Visa',
  CRP: 'Crediproyecto',
  DIN: 'Diners',
  EXI: 'Exito',
  FAL: 'Falabella',
  GYF: 'Giros Y Finanzas La 14',
  INV: 'Invercar',
  TLP: 'La Polar',
  MAS: 'Master Card',
  NOE: 'No Especificada',
  OTR: 'Otro',
  SFN: 'Serfinansa',
  SUF: 'Sufi',
  VIS: 'Visa',
  CLTV: 'Colectiva',
  INDV: 'Individual'
};

export default marcaTarjeta;
