import React from "react";
import { NavLink } from "react-router-dom";
import { Button } from "reactstrap";
import Icon from "../../../components/UI/Icon";
import styles from "./Banner.module.scss";

const Banner = () => {
  const logo = require("../../../images/logos/logo-enbanca-blue-sm.png");
  const benefits = [
    { icon: "readiness_score", color: "violet", benefit: "Historial y puntaje de crédito" },
    { icon: "p2p", color: "green", benefit: "Compartir tu reporte" },
    { icon: "admin_panel_settings", color: "blue", benefit: "Historial de pagos a seguridad social" },
    { icon: "car_crash", color: "red", benefit: "Información y alertas de tus vehículos" },
    { icon: "credit_card_heart", color: "orange", benefit: "Productos financieros de acuerdo a tu perfil" },
  ];

  return (
    <div className={`eb-card ${styles.container}`}>
      <img
        src={logo}
        alt="Enbanca"
        className={styles.picture}
      />
      <p className={styles.title}>
        Inicia sesión para adquirir un servicio
      </p>
      <p className={styles.description}>
        Explora los beneficios de adquirir un servicio con Enbanca y empieza a
        aprovechar el poder de tu información de manera fácil y segura.
      </p>
      <NavLink
        to={"/login"}
      >
        <Button
          color="primary"
          className={styles.button}
        >
          Iniciar sesión
        </Button>
      </NavLink>
      <div className={styles.benefits}>
        {benefits.map(({ icon, color, benefit }) => (
          <div
            key={icon}
            className={styles.benefit}
          >
            <Icon
              icon={icon}
              className={styles[color]}
            />
            <p className={styles.text}>
              {benefit}
            </p>
          </div>

        ))}
      </div>
    </div>
  )
};

export default Banner;