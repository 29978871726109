import * as fetchMock from 'fetch-mock';
import { url } from '../services/reportsDeleteSentPost';
import withDelay from './helpers/withDelay';

fetchMock.post(`end:${process.env.REACT_APP_API_PATH}/${url}`, () => {
  return withDelay(
    {
      status: 200,
      body: {}
    },
    1000
  );
});
