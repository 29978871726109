import React from 'react';

export interface Wizard {
  steps: number;
  step: number;
  increase: () => void;
  decrease: () => void;
  setStep: (step: number) => void;
  setSteps: (steps: number) => void;
}

const useWizard = (initialSteps: number = 1, initialStep = 1): Wizard => {
  const [step, setInnerStep] = React.useState(initialStep);
  const [steps, setSteps] = React.useState(initialSteps);

  const increase = React.useCallback(() => {
    if (step < steps) {
      setInnerStep(prevStep => prevStep + 1);
    }
  }, [step, steps]);

  const decrease = React.useCallback(() => {
    if (step > 0) {
      setInnerStep(prevStep => prevStep - 1);
    }
  }, [step]);

  const setStep = React.useCallback(
    (newStep: number) => {
      if (newStep > 0 && newStep <= steps) {
        setInnerStep(newStep);
      }
    },
    [step, steps]
  );

  React.useEffect(() => {
    const isMobile =
      typeof window.orientation !== 'undefined' ||
      (navigator.userAgent.indexOf('IEMobile') !== -1 &&
        navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i));
    if (isMobile) {
      window.scrollTo(0, 0);
    }
  }, [step]);

  return {
    steps,
    step,
    increase,
    decrease,
    setStep,
    setSteps
  };
};

export default useWizard;
