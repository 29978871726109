import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import clearAuthToken from "../helpers/clearAuthToken";
import setAuthToken from "../helpers/setAuthToken";
import UIReducer from "./UI/reducers";
import { UIState } from "./UI/types";
import alertsReducer from "./alerts/reducers";
import { AlertsState } from "./alerts/types";
import footprintsReducer from "./footprints/reducers";
import { FootprintsState } from "./footprints/types";
import messageReducer from "./message/reducers";
import { MessageState } from "./message/types";
import paymentsGatewayReducer from "./paymentsGateway/reducers";
import { PaymentsGatewayState } from "./paymentsGateway/types";
import PlansReducer from "./plans/reducers";
import { PlansState } from "./plans/types";
import reportSummaryReducer from "./reportSummary/reducers";
import { ReportSummaryState } from "./reportSummary/types";
import rootSaga from "./sagas";
import scoresReducer from "./scores/reducers";
import { ScoresState } from "./scores/types";
import securityReducer from "./security/reducers";
import { SecurityState } from "./security/types";
import socialSecurityReducer from "./socialSecurity/reducers";
import { SocialSecurityState } from "./socialSecurity/types";
import userReducer from "./user/reducers";
import { UserState } from "./user/types";

const sagaMiddleware = createSagaMiddleware();

export interface AppState {
  security: SecurityState;
  user: UserState;
  socialSecurityInfo: SocialSecurityState;
  message: MessageState;
  reportSummary: ReportSummaryState;
  plans: PlansState;
  footprints: FootprintsState;
  alerts: AlertsState;
  scores: ScoresState;
  ui: UIState;
  paymentsGateway: PaymentsGatewayState;
}

const rootReducer = combineReducers<AppState>({
  security: securityReducer,
  user: userReducer,
  socialSecurityInfo: socialSecurityReducer,
  message: messageReducer,
  reportSummary: reportSummaryReducer,
  plans: PlansReducer,
  footprints: footprintsReducer,
  alerts: alertsReducer,
  scores: scoresReducer,
  ui: UIReducer,
  paymentsGateway: paymentsGatewayReducer
});

const composeEnhancers =
  typeof window === "object" &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;
const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware));

const store = createStore(rootReducer, enhancer);

sagaMiddleware.run(rootSaga);

// https://michaelwashburnjr.com/best-way-to-store-tokens-redux/
store.subscribe(() => {
  const token = store.getState().security.token;
  if (token) {
    setAuthToken(token);
  } else {
    clearAuthToken();
  }
});

if ((window as any).Cypress) {
  (window as any).redux = (window as any).redux || {};
  (window as any).redux.store = store;
}

export default store;
