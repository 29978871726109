import LoginAttemptsMessage from "../components/ErrorMessages/LoginAttemptsMessage";
import UserDisabledMessage from "../components/ErrorMessages/UserDisabledMessage";

const errorMessages = {
  // login
  "Bad credentials": "Los datos ingresados no son correctos",
  "User is disabled": UserDisabledMessage(),
  "User does not exist": "El usuario no existe",
  "Login attempts exceeded": LoginAttemptsMessage(),
  "Not data found": "No es posible enviarte un código",
  "Bad request": "Sucedió un error, inténtalo más tarde",
  // signup
  "Email already exists in the repository":
    "Ya existe un usuario con este correo",
  "Phone already exists in the repository":
    "Ya existe un usuario con este teléfono",
  "Document already exists in the repository":
    "Ya existe un usuario con este documento",
  "Too many request":
    "No es posible realizar más intentos, tu código de validación ya ha sido enviado",
  "Invalid phone number input":
    "Debes usar el mismo teléfono que usas con las entidades bancarias",
  "Invalid phone or code":
    "El código de WhatsApp que ingresaste no es correcto",
  "Invalid phone code": "El código de WhatsApp que ingresaste no es correcto",
  "Name validation error":
    "El nombre o apellidos no corresponden al número de documento que ingresaste",
  // validation/mobile (get)
  "User not found": "No se encontró el usuario",
  "Pending code validation": "Ocurrió un error enviando el código",
  // validation/mobile (post)
  "Invalid code": "El código no es correcto",
  "Invalid Code": "El código no es correcto",
  "Phone not found in store":
    "Debes usar el mismo teléfono que usas con las entidades bancarias",
  "Validation event expired": "El código ingresado ha expirado",
  // users/forgot-password
  "Email not found":
    "No hay un usuario registrado con el correo electrónico ingresado",
  "Email not validated":
    "El correo que ingresaste no ha sido validado. Intenta recuperar tu contraseña con tu número de móvil.",
  "Phone not found":
    "No hay un usuario registrado con el número de teléfono ingresado",
  "Phone not validated":
    "El número de móvil que ingresaste no ha sido validado. Intenta recuperar tu contraseña con tu correo.",
  // preferences/account
  "Bad password": "La contraseña o código es incorrecto",
  "Input form name not match with transunion name":
    "Los datos ingresados no corresponden al documento de identidad con el que se registró",
  "Transunion name validation fail":
    "Los datos ingresados no corresponden al documento de identidad con el que se registró",
  "User does not have registered information":
    "Tu documento de identidad no tiene cuentas asociadas en las centrales de información financiera. Si quieres empezar a construir tu historial de crédito te invitamos a visitar nuestra sección de educación financiera.",
  "User does not have initialized session":
    "Pensando en tu seguridad y en la privacidad de tu información el formulario ha sido bloqueado y se habilitará de nuevo en: 24 horas",
  // preferences/change-email
  "Email has already been validated":
    "El correo ya ha sido validado. No es posible modificarlo por el momento.",
  // preferences/change-phone
  "Phone has already been validated":
    "El teléfono ya ha sido validado. No es posible modificarlo por el momento.",
  // users/change-password
  "Invalid password": "La contraseña no es correcta",
  // security-questions
  TUEC009:
    "Pensando en tu seguridad y en la privacidad de tu información el formulario ha sido bloqueado y se habilitará de nuevo en: 24 horas",
  TUEC010:
    "Tu usuario ha sido bloqueado porque las respuestas que diste no coinciden con la información que tienes en la central de información, lastimosamente sin completar tu proceso de validación de identidad no podemos darte acceso a la información de historial de crédito",
  // redeem-code
  "promoCode not found": "El código no es válido",
  "promoCode has expired": "El código ya expiró",
  "promoCode was already redeemed by user": "Ya redimiste este código",
  "promoCode exceeded the maximum allowed": "El código ya no es válido",
  "promoCode is not associated with selected plan":
    "El código no es válido para el plan seleccionado"
};

export default errorMessages;
