import {
  ArcElement,
  Chart as ChartJS
} from "chart.js";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import percentageFormat from "../../../../helpers/percentageFormat";
import styles from "./DebtShareChart.module.scss";

ChartJS.register(ArcElement);

export interface Props {
  value: number;
}

const DebtShareChart = ({ value }: Props) => {
  const data = {
    datasets: [
      {
        label: "",
        data: [value, 100 - value],
        backgroundColor: ["#3B82F6", "#E0E2E7"],
        // borderColor: ["#3B82F6", "#e0e2e7"],
        borderWidth: 0, // 1
        cutout: "60%", // 50% Defult
      }
    ],
  };

  const options = {
    responsive: true,
    rotation: 270,
    circumference: 180,
    plugins: {
      tooltip: {
        enabled: false,
      },
    },
  };

  const doughnutLabel = {
    id: "doughnutLabel",
    // @ts-ignore: Parameter 'cart' implicitly has an 'any' type
    afterDatasetsDraw: (chart) => {
      const { ctx } = chart;
      const centerX = chart.getDatasetMeta(0).data[0].x;
      const centerY = chart.getDatasetMeta(0).data[0].y;
      ctx.save();
      ctx.textAlign = "center";
      ctx.font = "500 20px 'Inter'";
      ctx.fillStyle = "#667580";
      // ctx.textBaseline = "middle";
      ctx.fillText(percentageFormat(value), centerX, centerY);
    }
  };

  return (
    <div className={styles.container}>
      <Doughnut
        data={data}
        options={options}
        className={styles.chart}
        plugins={[doughnutLabel]}
      />
    </div>
  );
};

export default DebtShareChart;
