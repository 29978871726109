import { Reducer } from 'redux';
import { UserAction, UserState } from './types';

const initialState: UserState = {
  status: 'init'
};

const reducer: Reducer<UserState, UserAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'user/FETCH_INFO':
      if (state.status === 'loaded') {
        return {
          ...state,
          status: 'updating'
        };
      } else {
        return {
          status: 'loading'
        };
      }
    case 'user/FETCH_INFO_SUCCEEDED':
      return {
        status: 'loaded',
        payload: action.userInfo
      };
    case 'user/FETCH_INFO_FAILED':
      return {
        status: 'error'
      };
    case 'user/REASON_QUESTION_ANSWERED':
      if (state.status === 'loaded') {
        return {
          ...state,
          payload: {
            ...state.payload,
            reasonQuestionAnswered: true
          }
        };
      } else {
        return state;
      }
    case 'user/CHANGE_EMAIL':
      if (state.status === 'loaded') {
        return {
          ...state,
          payload: {
            ...state.payload,
            email: action.email
          }
        };
      } else {
        return state;
      }
    case 'user/CHANGE_PHONE':
      if (state.status === 'loaded') {
        return {
          ...state,
          payload: {
            ...state.payload,
            phone: action.phone
          }
        };
      } else {
        return state;
      }
    case 'security/LOGOUT':
      return initialState;
    default:
      return state;
  }
};

export default reducer;
