import * as fetchMock from "fetch-mock";
import Rol from "../enums/Rol";
import jwt from "../helpers/jwt";
import tokenExpiration from "../helpers/tokenExpiration";
import { LoginParams, LoginResponse, url } from "../services/loginPost";
import { ServiceReturn } from "../types/ServiceReturn";
import loginRoles from "./helpers/loginRoles";
import ResponseCreator from "./helpers/responseCreator";
import store from "./helpers/store";
import withDelay from "./helpers/withDelay";

(window as any).fixtures = (window as any).fixtures || {};
(window as any).fixtures.login = {
  _errors: 0,
  increaseErrors() {
    this._errors++;
  },
  resetErrors() {
    this._errors = 0;
  }
};

/**
 * - Usuarios:
 *  - `1`: el usuario no ha validado ni el correo ni el teléfono.
 *  - `2`: el usuario validó el teléfono y tiene el rol `user`.
 *  - `3`: el usuario validó el email y tiene el rol `user`.
 *  - `4`: el usuario validó su identidad con las
 *    preguntas de seguridad, eligió ver el historial y tiene los roles `user`,
 *    `validated`, `identity` y `report`.
 *  - `5`: el usuario validó su identidad con las
 *    preguntas de seguridad, eligió ver los créditos y tiene los roles `user`,
 *    `6`, `identity` y `credit`.
 *  - `7`: no validó identidad con las preguntas de seguridad, eligió ver los créditos
 *     y tiene los roles `user`, `validated` y `credit`
 *  - `8`: tiene todos los permisos, pero requiere electronic id
 *  - `123`: el usuario respondió el formulario de
 *    preguntas básicas y tiene los roles `user`, `report`, `credit` y `basic_info`.
 * - Password: los password válidos son `v`y `valid`.
 */
fetchMock.post(
  `end:${process.env.REACT_APP_API_PATH}/${url}`,
  (_, fetchData) => {
    const data = JSON.parse(fetchData.body as string) as LoginParams;

    if (data.documentId === "500") {
      return withDelay(
        new ResponseCreator()
          .addError("Server error", "Server error")
          .setStatus(500)
          .getResponse()
      );
    } else if (data.documentId === "403") {
      return withDelay(
        new ResponseCreator()
          .addError("Login error", "Login error")
          .setStatus(403)
          .getResponse()
      );
    } else if (data.documentId === "401") {
      return withDelay(
        new ResponseCreator()
          .addError("Login error", "Login error")
          .setStatus(401)
          .getResponse()
      );
    }

    if (data.password === "wrongpassword") {
      console.log((window as any).fixtures.login._errors);
      if ((window as any).fixtures.login._errors >= 2) {
        (window as any).fixtures.login.resetErrors();
        return withDelay(
          new ResponseCreator()
            .addError("login.attempts", "Login attempts exceeded")
            .getResponse()
        );
      } else {
        (window as any).fixtures.login.increaseErrors();
      }
    }

    store.resetRoles();

    const roles = loginRoles(data.documentId, data.password) as Rol[];
    const eid = data.documentId === "8" || undefined;

    if (roles) {
      store.roles = roles;
      const body: ServiceReturn<LoginResponse> = {
        payload: {
          token: jwt.encode(roles as Rol[], tokenExpiration(), "fake-uuid", eid)
        }
      };
      return withDelay({
        status: 200,
        body
      });
    }

    const bodyError: ServiceReturn = {};

    switch (data.documentId) {
      case "disabled":
        bodyError.errors = [
          {
            attribute: "login.login",
            error: "User is disabled"
          }
        ];
        break;
      case "notvalidated":
        bodyError.errors = [
          {
            attribute: "login.login",
            error: "User not validated"
          }
        ];
        break;
      case "notexist":
      case "00000000":
        bodyError.errors = [
          {
            attribute: "login.login",
            error: "User does not exist"
          }
        ];
        break;

      default:
        bodyError.errors = [
          {
            attribute: "login.login",
            error: "Bad credentials"
          }
        ];
        break;
    }

    return withDelay({
      status: 400,
      body: bodyError
    });
  }
);
