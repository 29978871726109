interface ScoreLevel {
  range: number[];
  riskLabel: string;
  profileLabel: string;
  rangeLabel: string;
}

const scoreLevels: { [key: string]: ScoreLevel } = {
  alto: {
    range: [780, Infinity],
    riskLabel: "bajo",
    profileLabel: "alto",
    rangeLabel: "780 - 1000",
  },
  "medio-alto": {
    range: [740, 779],
    riskLabel: "medio bajo",
    profileLabel: "medio alto",
    rangeLabel: "740 - 779",
  },
  medio: {
    range: [630, 739],
    riskLabel: "medio",
    profileLabel: "medio",
    rangeLabel: "630 - 739",
  },
  "medio-bajo": {
    range: [520, 629],
    riskLabel: "medio alto",
    profileLabel: "medio bajo",
    rangeLabel: "520 - 629",
  },
  "bajo-sin-historial": {
    range: [-Infinity, 519],
    riskLabel: "alto",
    profileLabel: "bajo",
    rangeLabel: "0 - 519",
    // riskLabel: "alto o sin historial",
    // profileLabel: "bajo o sin historial"
  }
};

function isBetween(value: number, bottom: number, top: number) {
  return value >= bottom && value <= top;
}

function mean(top: number, bottom: number) {
  return Math.abs(top + bottom) / 2;
}

function scoreLevelByScore(score: number): ScoreLevel {
  return Object.values(scoreLevels).find(scoreLevel =>
    isBetween(score, scoreLevel.range[0], scoreLevel.range[1])
  )!;
}

export function profileLabelByScore(score: number): string {
  return scoreLevelByScore(score).profileLabel;
}

export function riskLabelByScore(score: number): string {
  return scoreLevelByScore(score).riskLabel;
}

export function rangeLabelByScore(score: number): string {
  return scoreLevelByScore(score).rangeLabel;
}

export function scoreApproximate(scoreLabel: string): number | undefined {
  const scoreLevel = scoreLevels[scoreLabel];
  if (!scoreLevel) return undefined;
  const [bottom, top] = scoreLevel.range;
  return mean(Math.min(top, 999), Math.max(bottom, 0));
}
