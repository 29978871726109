import React from 'react';
import styles from './SignupInfo.module.scss';

const SignupInfo = () => {
  const logo = require("../../../images/logos/logo-enbanca-white-sm.png");
  return (
    <div className={styles.registerInfo}>
      <img src={logo} alt="Enbanca" className={styles.logo} />
      <h2 className={styles.title}>
        Regístrate y adquiere un plan para conocer tu historial de crédito
      </h2>
      <div className={styles.divider} />
      <p className={styles.text}>
        Descubre cómo lo interpretan los bancos y cómo puedes mejorarlo para
        obtener el crédito que necesitas
      </p>
    </div>
  )
};

export default SignupInfo;
