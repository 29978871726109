import fetchMock from 'fetch-mock';
import { SecurityQuestionParams, url } from '../services/securityQuestionPost';
import ResponseCreator from './helpers/responseCreator';
import withDelay from './helpers/withDelay';

// TODO
// Implementar los errores
// "notSessionInitialized", "User does not have initialized session" > no rellenó el formulario, retornarlo
// "questionNumberOutOfBound", "Question number out of bound" > Pregunta por fuera del rango

(window as any).fixtures = (window as any).fixtures || {};
(window as any).fixtures.securityQuestion = {
  _error: 'none',
  setNotSessionInitialized() {
    this._error = 'notSessionInitialized';
  },
  questionNumberOutOfBound() {
    this._error = 'questionNumberOutOfBound';
  }
};

fetchMock.post(
  `end:${process.env.REACT_APP_API_PATH}/${url}`,
  (_, fetchData) => {
    const data = JSON.parse(fetchData.body as string) as SecurityQuestionParams;
    const question = questions[data.questionNumber];

    let response;
    switch ((window as any).fixtures.securityQuestion._error) {
      case 'notSessionInitialized':
        response = new ResponseCreator()
          .addError(
            'notSessionInitialized',
            'User does not have initialized session'
          )
          .getResponse();
        break;
      case 'questionNumberOutOfBound':
        response = new ResponseCreator()
          .addError('questionNumberOutOfBound', 'Question number out of bound')
          .getResponse();
        break;
      default:
        response = new ResponseCreator()
          .setBody({
            ...question,
            totalQuestions: questions.length
          })
          .getResponse();
        break;
    }

    return withDelay(response);
  }
);

const questions = [
  {
    id: '1',
    label:
      '¿Con cuál de las siguientes entidades usted tiene una cuenta de ahorros?',
    answers: [
      {
        id: '7521075',
        label: 'AV VILLAS'
      },
      {
        id: '7521076',
        label: 'GNB SUDAMERIS'
      },
      {
        id: '7521074',
        label: 'BANCO FINANDINA'
      },
      {
        id: '7521077',
        label: 'NINGUNA DE LAS ANTERIORES'
      }
    ]
  },
  {
    id: '2',
    label:
      '¿Cuál de las siguientes marcas propias de tarjetas de crédito usted adquirió en los últimos seis meses?',
    answers: [
      {
        id: '7521061',
        label: 'EXITO'
      },
      {
        id: '7521062',
        label: 'SERFINANZA'
      },
      {
        id: '7521063',
        label: 'FALABELLA'
      },
      {
        id: '7521064',
        label: 'LA POLAR'
      },
      {
        id: '7521065',
        label: 'Ninguna de las anteriores'
      }
    ]
  },
  {
    id: '3',
    label:
      '¿Con qué entidad adquirió un crédito de vivienda en los últimos seis meses?',
    answers: [
      {
        id: '7521070',
        label: 'AV VILLAS'
      },
      {
        id: '7521072',
        label: 'CONFINANCIERA S.A.'
      },
      {
        id: '7521071',
        label: 'INVERSORA PICHINCHA S.A.'
      },
      {
        id: '7521073',
        label: 'NINGUNA DE LAS ANTERIORES'
      }
    ]
  },
  {
    id: '4',
    label:
      'En los últimos seis meses, ¿cuál de las siguientes entidades le ha desembolsado un crédito de vivienda y un microcrédito?',
    answers: [
      {
        id: '7521066',
        label: 'BANCA DE INVERSIONES BANCOLOMBIA CORPORACION FINANCIERA'
      },
      {
        id: '7521068',
        label: 'THE ROYAL BANK OF SCOTLAND COLOMBIA S.A.'
      },
      {
        id: '7521067',
        label: 'COLTEFINANCIERA'
      },
      {
        id: '7521069',
        label: 'NINGUNA DE LAS ANTERIORES'
      }
    ]
  }
];
