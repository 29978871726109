import { Field, FieldProps } from 'formik';
import React from 'react';
import { Input } from 'reactstrap';
import FieldContainer from './FieldContainer';
import styles from './formStyles.module.scss';

export interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label: string;
  name: string;
  options: Array<{
    label?: string;
    value: any;
  }>;
  className?: string;
}

const SelectField = ({ label, name, options, className, ...props }: Props) => {
  const Select: React.FC<
    FieldProps & React.SelectHTMLAttributes<HTMLSelectElement>
  > = ({ form, field, children, onChange }) => {
    return (
      <Input
        type="select"
        id={`field-${name}`}
        invalid={form.touched[field.name] && !!form.errors[field.name]}
        {...field}
        onChange={(
          event: React.ChangeEvent<HTMLSelectElement> &
            React.ChangeEvent<HTMLInputElement>
        ) => {
          field.onChange(event);
          if (onChange) {
            onChange(event);
          }
        }}
        className={styles.input}
      >
        {children}
      </Input>
    );
  };

  return (
    <FieldContainer className={className} label={label} name={name}>
      <Field name={name} {...props} component={Select}>
        {options.map(option => (
          <option value={option.value} key={option.value}>
            {option.label ? option.label : option.value}
          </option>
        ))}
      </Field>
    </FieldContainer>
  );
};

export default SelectField;
