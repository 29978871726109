import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Button } from "reactstrap";
import styles from "./CodeNotFound.module.scss";

const CodeNotFound = ({ history }: RouteComponentProps) => {
  const icon = require("../../../images/failed.svg");
  return (
    <>
      <img src={icon} />
      <p className={styles.label}>
        No tienes un código de verifiación.<br />
        Vuelve al inicio para comenzar de nuevo
      </p>
      <Button
        color="primary"
        className="px-5 rounded-pill"
        onClick={() => history.push("/")}
      >
        Volver
      </Button>
    </>
  )
}

export default withRouter(CodeNotFound);
