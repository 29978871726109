import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Button } from "reactstrap";
import styles from "./CodeSentEmail.module.scss";

const CodeSentEmail = ({ history }: RouteComponentProps) => {
  const icon = require("../../../images/success.svg");
  return (
    <>
      <img src={icon} />
      <p className={styles.label}>
        Te enviamos un enlace a tu correo.<br />
        Haz clic en el para asignar una nueva contraseña
      </p>
      <Button
        color="primary"
        className="px-5 rounded-pill"
        onClick={() => history.push("/")}
      >
        Volver
      </Button>
    </>
  )
}

export default withRouter(CodeSentEmail);
