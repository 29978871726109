import dayjs from "dayjs";
import { Form, Formik, FormikActions, FormikErrors } from "formik";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import * as yup from "yup";
import CheckboxField from "../../../components/Form/CheckboxField";
import FormErrors from "../../../components/Form/FormErrors";
import SubmitButton from "../../../components/Form/SubmitButton";
import Wizard from "../../../components/Wizard";
// import { capitalizeEachWord } from "../../../helpers/capitalize";
import processSubmitErrors from "../../../helpers/processSubmitErrors";
import { ReactComponent as Whatsapp } from "../../../images/whatsapp.svg";
import ApiError from "../../../types/ApiError";
import { ServiceError } from "../../../types/ServiceError";
// import SideButtonRegister from "../SideButtonRegister";
import EmailValidationField from "./EmailValidationField";
import NameValidation from "./NameValidation";
import PhoneValidationField from "./PhoneValidationField";
import styles from "./SignupForm.module.scss";

// tslint:disable-next-line:no-var-requires
dayjs.extend(require("dayjs-ext/plugin/customParseFormat"));

export interface FormValues {
  email: string;
  phone: string;
  code: string;
  name: string;
  lastname: string;
  document: string;
  documentType: string;
  // documentExpeditionDate: string;
  acceptTerms: boolean;
  acceptAuthorization: boolean;
  acceptWhatsappMsg: boolean;
}

export interface Props {
  onSubmit: (data: FormValues) => void;
}

const SignupForm = ({ onSubmit }: Props) => {
  const [showDocumentError, setShowDocumentError] = useState(false);
  const [isFormValidating, setIsFormValidating] = useState(false);
  const [passStep, setPassStep] = useState(false);
  const [isValidName, setIsValidName] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState(false);

  const initialValues: FormValues = {
    email: "",
    phone: "",
    code: "",
    name: "",
    lastname: "",
    document: "",
    // documentExpeditionDate: "",
    documentType: "CC",
    acceptTerms: false,
    acceptAuthorization: false,
    acceptWhatsappMsg: false
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required("El correo es requerido")
      .email("El correo no es válido")
      .matches(/^[a-zA-Z0-9@!#$%&'*+-/=?^_`{|}~]*$/, "El correo no es válido"),
    phone: yup
      .string()
      .required("El móvil es requerido")
      .length(10, "El número de móvil debe ser de 10 dígitos")
      .matches(/\d{10}/, "El número de móvil no es válido"),
    code: yup
      .string()
      .length(6, "El código debe ser de 6 dígitos")
      .matches(/\d{6}/, "El código no es válido")
      .required("El código es requerido"),
    name: yup.string().required("Los nombres son requeridos"),
    lastname: yup.string().required("Los apellidos son requeridos"),
    document: yup.string().required("El documento de identidad es requerido"),
    acceptTerms: yup
      .bool()
      .oneOf(
        [true],
        "Debe aceptar la política de tratamiento de información personal"
      ),
    acceptAuthorization: yup
      .bool()
      .oneOf([true], "Debe aceptar las autorizaciones y declaraciones"),
    // acceptWhatsappMsg: yup
    //   .bool()
    // .oneOf([true], "Debe aceptar que enbanca le envié mensajes de Whatsapp")
  });

  const fieldMap = {
    document: 1,
    documentType: 1,
    name: 1,
    lastname: 1,
    acceptTerms: 1,
    acceptAuthorization: 1,
    // documentExpeditionDate: 1,
    email: 3,
    phone: 3,
    code: 3,
    acceptWhatsappMsg: 3
  };

  const afterValidate = (errors: object) => {
    const firstRequired = Math.min(
      ...Object.keys(errors).map((field: string) => {
        return fieldMap[field];
      })
    );
    wizard.step !== firstRequired && wizard.setStep(firstRequired);
  };

  const messageIfDocumentExist = (
    error: any,
    setFieldError: (field: string, value: string) => void
  ) => {
    if (error instanceof ApiError && error.response.errors) {
      const errors = error.response.errors as ServiceError[];
      if (
        errors.find(
          errorItem =>
            errorItem.error === "Document already exists in the repository"
        )
      ) {
        setShowDocumentError(true);
      }
    }
  };
  const totalSteps = 2;
  const wizard = Wizard.useWizard(totalSteps);

  const onFormSubmit = async (
    values: FormValues,
    {
      setErrors,
      setStatus,
      setSubmitting,
      setFieldError
    }: FormikActions<FormValues>
  ) => {
    try {
      setShowDocumentError(false);
      await onSubmit({
        ...values,
        phone: values.phone.startsWith("57")
          ? values.phone
          : "57" + values.phone
      });
    } catch (error) {
      processSubmitErrors(error, values, setErrors, setStatus);
      messageIfDocumentExist(error, setFieldError);
      setSubmitting(false);
      if (error instanceof ApiError && error.response.errors) {
        const firstStepError = Math.min(
          ...error.response.errors
            .map((error: any) => {
              return error.attribute;
            })
            .map((field: string) => {
              return fieldMap[field];
            })
        );
        wizard.step !== firstStepError && wizard.setStep(firstStepError);
      }
    }
  };

  const previusStep = () => {
    wizard.setStep(wizard.step - 1);
  };
  const nextStep = () => {
    wizard.setStep(wizard.step + 1);
    setPassStep(true);
  };

  const isDisabled = (step: number, values: FormValues, errors: FormikErrors<FormValues>) => {
    if (step === 1) {
      if (!Object.keys(errors).length && !passStep)
        return true
      // if (errors.document || errors.documentExpeditionDate || errors.name || errors.lastname || !isValidName || !values.acceptAuthorization || !values.acceptTerms)
      if (errors.document || errors.name || errors.lastname || !isValidName || !values.acceptAuthorization || !values.acceptTerms)
        return true
    } else if (step === 2) {
      if (!values.acceptWhatsappMsg || !isValidEmail || !isValidPhone)
        return true
    }
    return false
  }

  return (
    <div className="RegisterForm">
      <Formik
        initialValues={initialValues}
        onSubmit={onFormSubmit}
        validationSchema={validationSchema}
        validateOnChange={false}
        render={({ values, errors, isValidating, setFieldError }) => (
          <Form>
            {/* <SideButtonRegister
              step={wizard.step}
              document={values.document}
              documentType={values.documentType}
              name={capitalizeEachWord(values.name + " " + values.lastname)}
            /> */}
            {isValidating && afterValidate(errors)}
            <Wizard.Steps wizard={wizard}>
              <Wizard.Step step={1}>
                <NameValidation
                  documentType={values.documentType}
                  // documentExpeditionDate={values.documentExpeditionDate}
                  document={values.document}
                  name={values.name}
                  lastname={values.lastname}
                  setIsValidated={(value) => setIsValidName(value)}
                  setIsValidating={(value) => setIsFormValidating(value)}
                />
                <CheckboxField name="acceptTerms">
                  Acepto la{" "}
                  <a
                    href={`${process.env.REACT_APP_CMS
                      }/politicas-de-privacidad/`}
                    target="_blank"
                  >
                    política de tratamiento de información personal
                  </a>
                </CheckboxField>
                <CheckboxField name="acceptAuthorization">
                  Acepto las{" "}
                  <a
                    href={`${process.env.REACT_APP_CMS
                      }/autorizaciones-y-declaraciones/`}
                    target="_blank"
                  >
                    autorizaciones y declaraciones
                  </a>
                </CheckboxField>
                {showDocumentError && (
                  <div className={styles.documentButtons}>
                    <Button
                      className={styles.button}
                      color="primary"
                      tag={Link}
                      to="/login"
                    >
                      Ingresar
                    </Button>
                    <Button
                      className={styles.button}
                      color="primary"
                      tag={Link}
                      to="/recuperar-contrasena"
                    >
                      Recuperar contraseña
                    </Button>
                  </div>
                )}
              </Wizard.Step>
              <Wizard.Step step={2}>
                <EmailValidationField
                  email={values.email}
                  setIsValidated={valid => setIsValidEmail(valid)}
                  setFieldError={error => setFieldError("email", error)}
                />
                <CheckboxField name="acceptWhatsappMsg">
                  Acepto que Enbanca me envie mensajes de
                  <Whatsapp className={styles.whatsappIcon} />
                  Whatsapp
                </CheckboxField>
                {!values.acceptWhatsappMsg && isValidPhone &&
                  <p
                    className="text-danger"
                    style={{ marginTop: "-0.75rem" }}
                  >
                    Debe aceptar que Enbanca te envíe mensajes de Whatsapp
                  </p>
                }
                <PhoneValidationField
                  phone={values.phone}
                  sendDisabled={values.acceptWhatsappMsg}
                  setIsValidated={valid => setIsValidPhone(valid)}
                  setFieldError={error => setFieldError("phone", error)}
                />
              </Wizard.Step>
              <FormErrors />
            </Wizard.Steps>
            <div className={styles.stepButtons}>
              {wizard.step !== 1 && (
                <div>
                  <Button
                    outline
                    className={styles.button}
                    color="primary"
                    onClick={previusStep}
                    id={`form-back-${wizard.step}`}
                  >
                    Anterior
                  </Button>
                </div>
              )}
              <div>
                {wizard.step === totalSteps ? (
                  <SubmitButton
                    id="signup"
                    className={styles.button}
                    text="Crear cuenta"
                    disabled={isDisabled(wizard.step, values, errors)}
                  />
                ) : (
                  <Button
                    id={`form-${wizard.step}`}
                    className={styles.button}
                    color="primary"
                    onClick={nextStep}
                    disabled={isDisabled(wizard.step, values, errors)}
                  >
                    {isFormValidating && <div className="spinner-border spinner-border-sm mr-2" role="status" />}
                    Siguiente
                  </Button>
                )}
              </div>
            </div>
          </Form>
        )}
      />
    </div>
  );
};

SignupForm.defaultProps = {
  saving: false
};

export default SignupForm;
