import { ServiceReturn } from '../types/ServiceReturn';
import api from './api';

export interface UserInfoResult {
  user: {
    documentType: string;
    documentNumber: string;
    name: string;
    lastname: string;
    gender: string;
    birthdate: string;
    email: string;
    phone: string;
    emailValidated: boolean;
    phoneValidated: boolean;
    identityValidated: boolean;
    reasonQuestionAnswered: boolean;
    newSentReports: number;
    newSharedReports: number;
  };
}

export const url = 'users/me';

const usersMeGet = async (): Promise<ServiceReturn<UserInfoResult>> => {
  return await api.get(url, true);
};

export default usersMeGet;
