import React, { useState } from "react";
import InputField from "../../../components/Form/InputField";
import formatEmail from "../../../helpers/formatEmail";
import { validEmail } from "../../../helpers/valid";
import { validateEmail } from "../../../helpers/zipps";
import ValidationStatusField from "./ValidationStatusField";

interface Props {
  email: string;
  setIsValidated: (validated: boolean) => void;
  setFieldError: (error: string) => void;
}

const EmailValidationField = ({ email, setIsValidated, setFieldError }: Props) => {
  const [isValid, setIsValid] = useState(false);
  const [lastValue, setLastValue] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [isValidating, setIsValidating] = useState(false);

  const handleBlur = async () => {
    setLastValue(email);
    if (email) {
      if (email !== lastValue) {
        if (validEmail(email)) {
          setIsValidating(true);
          setIsValid(false);
          setIsValidated(false);
          const { valid, exceededAttemps } = await validateEmail(email);
          setDisabled(exceededAttemps)
          setFieldError(valid ? "" : "El correo que ingresaste no existe o no es válido");
          setIsValidating(false);
          setIsValid(valid);
          setIsValidated(valid);
        } else {
          setIsValid(false);
          setFieldError("El correo no es válido");
          setIsValidated(false);
        }
      }
    } else {
      setIsValid(false);
      setIsValidated(false);
    }
  }

  return (
    <div onBlur={handleBlur}>
      <InputField
        name="email"
        label="Correo"
        type="email"
        formatValue={formatEmail}
        onBlur={handleBlur}
        disabled={disabled}
        innerDivClassName="position-relative"
        rightSlot={<ValidationStatusField isValid={isValid} isValidating={isValidating} />}
      />
    </div>
  );
};

export default EmailValidationField;
