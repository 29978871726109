import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loading from "../../../components/UI/Loading";
import isLogged from "../../../helpers/isLogged";
import { PlanResponse } from "../../../services/plansGet";
import { fetchPlans as fetchPlansAction } from "../../../store/plans/action";
import { PlansState } from "../../../store/plans/types";
import { AppState } from "../../../store/store";
import Banner from "../components/Banner";
import Plans from "../components/Plans";
import styles from "./PlansContainer.module.scss";

interface Props {
  plansState: PlansState;
  fetchPlans(): void;
}

const PlansContainer = ({ plansState, fetchPlans }: Props) => {
  const userIsLogged = isLogged();
  const [plans, setPlans] = useState<PlanResponse[]>([]);

  useEffect(() => {
    fetchPlans();
  }, []);

  useEffect(() => {
    if (plansState.status === "loaded") {
      const plans = plansState.payload;
      setPlans(plans);
    }
  }, [plansState.status]);

  return (
    <div className={styles.container}>
      {!userIsLogged && <Banner />}
      {plansState.status === "loading" && <Loading />}
      <Plans plans={plans} />
    </div>
  )
}

const mapStateToProps = (state: AppState) => {
  return {
    plansState: state.plans,
  };
};

const mapDispatchToProps = {
  fetchPlans: fetchPlansAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlansContainer);