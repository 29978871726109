import { useEffect, useState } from 'react';
import serviceBuilder from '../helpers/serviceBuilder';
import { ServiceStatus } from '../types/ServiceStatus';

export interface ReportsRequestHistoryPayload {
  email?: string;
  phone?: string;
  dateCreated: string;
  dateUpdated?: string;
  status: 'sent' | 'acepted' | 'rejected';
}

export const url = 'reports/request-history';

const useReportsRequestHistoryGet = () => {
  const [reportsRequestHistory, setReportsRequestHistory] = useState<
    ServiceStatus<ReportsRequestHistoryPayload[]>
  >({
    status: 'init'
  });

  useEffect(() => {
    const { start, abort } = serviceBuilder<{}, ReportsRequestHistoryPayload[]>(
      'get',
      {
        url,
        set: setReportsRequestHistory,
        auth: true
      }
    );
    start();
    return () => abort();
  }, []);

  return reportsRequestHistory;
};

export default useReportsRequestHistoryGet;
