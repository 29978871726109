import React from 'react';
import { RouteComponentProps } from 'react-router';
import Button from 'reactstrap/lib/Button';
import styled from 'styled-components';
import Payment from "../../../images/imageNequi.png";
import { Buttons, GeneralContent } from "./PaymentView";


const NequiPaymentIcon = styled.div`
  height: 100px;
  width: 100px;
  margin-bottom:-10px;
  background-image: url(${Payment});
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 700px) {
    height: 200px;
    width: 200px;
  }
`;


export const TextBox = styled.div`
display:flex;
flex-direction:column;
align-items:center;
box-shadow: 1px -1px 13px -12px rgba(0,0,0,0.75);
background:#fff;
margin-left:20%;
margin-right:20%;
padding:10px;
border-radius:5px;
color:#1e2c59;

`;




const NequiPaymentView = ({ history }: RouteComponentProps) => {

  return (
    <GeneralContent >
      <NequiPaymentIcon />
      <TextBox>
        Te va a llegar un mensaje de NEQUI donde debes "aceptar" el pago.   Por favor oprime
        el botón confirmar cuando hayas aceptado el pago en NEQUI.
        <br />
        <br />
        <Buttons >
          <Button color="primary" onClick={() => { history.push('/payment-result') }}>Confirmar</Button>
        </Buttons>

      </TextBox>

    </GeneralContent>
  );

};
export default NequiPaymentView