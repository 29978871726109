import React, { useEffect } from "react";
import Page from "../../components/Layout/Page";
import IdentityValidationContainer from "./containers/IdentityValidationContainer";

const IdentityValidationView = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://id.zipps.in/index.js";
    script.type = "module";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <Page>
      <div className="eb-card">
        <IdentityValidationContainer />
      </div>
    </Page>
  )
}

export default IdentityValidationView;