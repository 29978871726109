import AssuredReason from "../enums/AssuredReason";
import CreditReason from "../enums/CreditReason";
import SaveReason from "../enums/SaveReason";

export interface Reason<T = AssuredReason | SaveReason | CreditReason> {
  reason: T;
  label: string;
  icon: string;
}

export const assureds: Reason<AssuredReason>[] = [
  { reason: "home", label: "Vivienda", icon: "home" },
  { reason: "vehicle", label: "Vehículo", icon: "directions_car" },
];

export const saves: Reason<SaveReason>[] = [
  { reason: "savings_account", label: "Cuenta ahorro", icon: "savings" },
  { reason: "debit_card", label: "Tarjeta débito", icon: "credit_card" },
  { reason: "scheduled_savings", label: "Ahorro programado", icon: "calendar_clock" },
  { reason: "investment", label: "Inversión", icon: "monitoring" },
];

export const credits: Reason<CreditReason>[] = [
  { reason: "home", label: "Vivienda", icon: "home" },
  { reason: "vehicle", label: "Vehículo", icon: "directions_car" },
  { reason: "education", label: "Educación", icon: "school" },
  { reason: "consumer", label: "Consumo", icon: "shopping_cart" },
  { reason: "business", label: "Negocio", icon: "apartment" },
  { reason: "free_investment", label: "Libre inversión", icon: "store" },
  { reason: "credit_card", label: "Tarjeta de crédito", icon: "credit_card" },
  { reason: "emergency_loan", label: "Crédito de emergencia", icon: "emergency_home" },
  { reason: "repurchase_credits", label: "Recomprar mis créditos", icon: "price_change" },
  { reason: "other", label: "Otro", icon: "question_mark" },
];
