export class PromiseCancelError extends Error {
  constructor(message = 'Promise cancelled') {
    super(message);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, PromiseCancelError);
    }
  }
}

export type AsyncSetState<S, K extends keyof S = keyof S> =
  ((state: Pick<S, K> | Pick<S, any>) => Promise<void>) & { _hasCanceled: boolean };

/** Wrap React.Component.setState into a cancelable Promise */
export function createAsyncSetState<S, K extends keyof S>(self: React.Component<any, S>):
  AsyncSetState<S, K>
{
  async function asyncSetState(state: Pick<S, K>) {
    if (!asyncSetState._hasCanceled) self.setState(state);
    else throw new PromiseCancelError();
  }
  asyncSetState._hasCanceled = false;

  return asyncSetState;
}

export function cancelAsyncSetState<S, K extends keyof S>(asyncSetState?: AsyncSetState<S, K>) {
  if (asyncSetState) {
    asyncSetState._hasCanceled = true;
  }
}