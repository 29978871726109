import api from './api';

export interface ResetPasswordParams {
  phone?: string;
  code: string;
  password: string;
}

export const url = 'users/reset-password';

const usersResetPasswordPost = (
  postData: ResetPasswordParams
): Promise<void> => {
  return api.post(url, false, postData);
};

export default usersResetPasswordPost;
