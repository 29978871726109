import { ErrorMessage, Field, Form, Formik, FormikActions, FormikProps } from 'formik';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { FormFeedback, FormGroup, Input } from 'reactstrap';
import * as yup from 'yup';
import FormErrors from '../../../components/Form/FormErrors';
import SubmitButton from '../../../components/Form/SubmitButton';
import processSubmitErrors from '../../../helpers/processSubmitErrors';
import usersResetPasswordPost from '../../../services/usersResetPasswordPost';
import styles from "./ResetPasswordForm.module.scss";

interface Props extends RouteComponentProps {
  code: string;
  phone?: string;
  path?: string;
}

interface FormValues {
  password: string;
  repeatPassword: string;
}

const ResetPasswordForm = ({ code, phone, path, history }: Props) => {
  const initialValues: FormValues = { password: "", repeatPassword: "" };
  const validationSchema = yup.object().shape({
    password: yup.string()
      .required('La contraseña es requerida')
      .min(8, 'La contraseña debe contener al menos ocho caracteres'),
    repeatPassword: yup.string()
      .required('La validación de la contraseña es requerida')
      .oneOf([yup.ref('password')], 'La contraseña no coincide')
  })

  const handleSubmit = async (
    values: FormValues,
    { setErrors, setStatus, setSubmitting }: FormikActions<FormValues>
  ) => {
    try {
      const phoneWithPrefix = phone
        ? phone.startsWith('57')
          ? phone
          : '57' + phone
        : undefined;
      await usersResetPasswordPost({
        code,
        phone: phoneWithPrefix,
        password: values.password
      });
      history.push(path || "/");
    } catch (error) {
      processSubmitErrors(error, values, setErrors, setStatus);
    }
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      render={({ values, errors, touched }: FormikProps<FormValues>) => (
        <Form>
          <FormGroup>
            <p className={styles.label}>
              Nueva contraseña
            </p>
            <Input
              type="password"
              name="password"
              tag={Field}
              placeholder="**********"
              invalid={!!(errors.password && touched.password)}
              autoFocus
            />
            <p className={styles.hint}>
              Debe tener al menos 8 caracteres
            </p>
            <ErrorMessage name="password" component={FormFeedback} />
          </FormGroup>
          <FormGroup>
            <p className={styles.label}>
              Confirmar contraseña
            </p>
            <Input
              type="password"
              name="repeatPassword"
              tag={Field}
              placeholder="**********"
              invalid={!!(errors.repeatPassword && touched.repeatPassword)}
            />
            <ErrorMessage name="repeatPassword" component={FormFeedback} />
          </FormGroup>
          <FormErrors />
          <SubmitButton
            className={`${styles.btn} px-5 rounded-pill`}
            text="Enviar"
            disabled={
              (!!(errors.password && touched.password) || !values.password) ||
              (!!(errors.repeatPassword && touched.repeatPassword) || !values.repeatPassword)
            }
          />
        </Form>
      )}
    />
  );
};

export default withRouter(ResetPasswordForm);
