import api from './api';

export interface ChangePhoneParams {
  phone: string;
  password: string;
}

export const url = 'preferences/change-phone';

const preferencesChangePhonePost = async (
  data: ChangePhoneParams
): Promise<Response> => {
  return await api.post(url, true, data);
};

export default preferencesChangePhonePost;
