import { UserInfoResult } from '../../services/usersMeGet';
import { UserAction } from './types';

export const fetchUserInfo = (): UserAction => {
  return {
    type: 'user/FETCH_INFO'
  };
};

export const setFetchInfoSucceeded = (
  userInfo: UserInfoResult['user']
): UserAction => {
  return {
    type: 'user/FETCH_INFO_SUCCEEDED',
    userInfo
  };
};

export const setFetchInfoFailed = (): UserAction => {
  return {
    type: 'user/FETCH_INFO_FAILED'
  };
};

export const reasonQuestionAnswered = (): UserAction => {
  return {
    type: 'user/REASON_QUESTION_ANSWERED'
  };
};

export const changeEmail = (email: string): UserAction => {
  return {
    type: 'user/CHANGE_EMAIL',
    email
  };
};

export const changePhone = (phone: string): UserAction => {
  return {
    type: 'user/CHANGE_PHONE',
    phone
  };
};
