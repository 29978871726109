import uuid from '../helpers/uuid';
import api from './api';

export interface NavigationData {
  url: string;
}

export const url = 'users/tracing';

const usersTracingPost = async (postData: NavigationData): Promise<void> => {
  return await api.post(url, false, { ...postData, uuid: uuid.get() });
};

export default usersTracingPost;
