import React from "react";
import Page from "../../components/Layout/Page";
import ContactContainer from "./containers/ContactContainer";

const ContactView = () => {
  return (
    <Page back>
      <div className="eb-card">
        <ContactContainer />
      </div>
    </Page>
  )
}

export default ContactView;