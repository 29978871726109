import api from './api';

// TODO: remove

export interface ValidationEmailParams {
  url: string;
}

export const url = 'validation/email';

export const validationEmailGet = async (
  data: ValidationEmailParams
): Promise<void> => {
  return await api.get(url, true, data);
};
