import { put, select, takeLeading } from "redux-saga/effects";
import paymentsGatewayGet from "../../services/paymentsGatewayGet";
import {
  setFetchInfoFailed,
  setFetchInfoSucceeded
} from "../paymentsGateway/actions";
import { PaymentsGatewayState } from "../paymentsGateway/types";
import { AppState } from "../store";

const getPaymentsGateway = (store: AppState) => store.paymentsGateway;

function* fetchPaymentsGateway() {
  try {
    const paymentsGatewayState: PaymentsGatewayState = yield select(
      getPaymentsGateway
    );
    if (paymentsGatewayState.status !== "loaded") {
      const paymentsGateway = yield paymentsGatewayGet();
      yield put(setFetchInfoSucceeded(paymentsGateway.payload));
    }
  } catch (error) {
    yield put(setFetchInfoFailed(error));
    return;
  }
}

export default function* watchFetchPaymentsGateway() {
  yield takeLeading("paymentsGateway/FETCH_INFO", fetchPaymentsGateway);
}
