import { ErrorMessage, Field, FieldProps } from 'formik';
import React from 'react';
import { CustomInput, FormFeedback } from 'reactstrap';
import slugify from '../../helpers/slugify';
import FieldContainer from './FieldContainer';
import styles from './formStyles.module.scss';

export interface Props {
  label: string;
  name: string;
  options: Array<{
    name: string;
    value: string;
  }>;
  className?: string;
}

const RadioGroup = ({ label, name, options, className, ...props }: Props) => {
  const renderInput = (option: any, last: boolean) => ({ field, form }: FieldProps) => {
    return (
      <CustomInput
        id={slugify(name + option.name)}
        type="radio"
        {...field}
        name={name}
        value={option.value}
        // tslint:disable-next-line: triple-equals
        checked={field.value == option.value}
        label={option.name}
        invalid={form.touched[field.name] && !!form.errors[field.name]}
        className={`${styles.input} d-flex align-items-center ${!last ? "mb-2" : ""}`}
      />
    );
  };
  return (
    <FieldContainer
      className={className}
      label={label}
      name={name}
    >
      {options.map((option, i) => (
        <Field
          key={option.value}
          name={name}
          component={renderInput(option, i === options.length - 1)}
        />
      ))}
      <ErrorMessage
        name={name}
        component={FormFeedback}
      />
    </FieldContainer>
  );
};

export default RadioGroup;
