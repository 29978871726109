import React, { useState } from "react";
import Icon from "../../../components/UI/Icon";
import Loading from "../../../components/UI/Loading";
import { sharedReportGet } from "../../../services/sharedReportGet";
import { SharedReports } from "../../../services/useReportsSharedGet";
import { Report as ReportType } from "../../../services/useReportsSharedIdGet";
import Report from "./Report";
import styles from "./SharedReport.module.scss";

const SharedReport = ({ id, ownerName, message }: SharedReports) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState<ReportType | null>(null);

  const handleClick = async () => {
    try {
      if (open) setOpen(false);
      else {
        setOpen(true);
        if (!report) {
          setLoading(true);
          const data = await sharedReportGet({ id });
          setReport(data.report);
          setLoading(false);
        }
      }
    } catch {
      setOpen(false);
      setLoading(false);
    }
  }

  return (
    <div className="eb-card">
      <div
        className={styles.container}
        onClick={handleClick}
      >
        <div className={styles.user}>
          <div className={styles.shape}>
            <Icon icon="person" className="text-primary" />
          </div>
          <div className={styles.grid}>
            <p className={styles.name}>
              {ownerName}
            </p>
            <p className={styles.message}>
              {message}
            </p>
          </div>
        </div>
        <Icon icon={open ? "expand_less" : "expand_more"} className={styles.icon} />
      </div>
      {open && (
        <>
          <hr className={styles.hr} />
          {loading && <Loading />}
          {!loading && report && <Report report={report} />}
        </>
      )}
    </div>
  )
}

export default SharedReport;