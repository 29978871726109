import React from 'react';
import { CardBody, CardImg, CardTitle } from 'reactstrap';
import CardFooter from 'reactstrap/lib/CardFooter';
import styles from './Card.module.scss';

export enum ShadowLevel {
  Small = 'small-shadow',
  Medium = 'medium-shadow'
}

export interface Props {
  title?: string;
  titleTag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  headerImage?: string;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  showEmptyFooter?: boolean;
  className?: string;
  id?: string;
  style?: React.CSSProperties;
  shadowLevel?: ShadowLevel;
}

const Card: React.SFC<Props> = ({
  title,
  titleTag,
  headerImage,
  children,
  footer,
  showEmptyFooter,
  className,
  id,
  style,
  shadowLevel
}) => {
  const shadow =
    shadowLevel === ShadowLevel.Small
      ? styles.smallShadow
      : styles.mediumShadow;
  return (
    <div
      className={`${styles.card} ${className} ${shadow}`}
      style={style}
      id={id}
    >
      {!!headerImage && <CardImg top width="100%" src={headerImage} />}
      <CardBody>
        {!!title && (
          <CardTitle tag={titleTag ? titleTag : 'h6'}>{title}</CardTitle>
        )}
        {children}
      </CardBody>
      {showEmptyFooter ? (
        <CardBody className={`${styles.footer} no-content`} />
      ) : (
        !!footer && <CardFooter>{footer}</CardFooter>
      )}
    </div>
  );
};

Card.defaultProps = {
  className: '',
  shadowLevel: ShadowLevel.Medium
};

export default Card;
