import React, { useState } from "react";
import { Error, Result } from "../../../types/ZippsID";
import styles from "./ZippsID.module.scss";

interface Props {
  uuid: string;
  onError: (error: Error) => void;
  onCompleted: (result: Result) => void;
}

const ZippsID = ({ uuid, onError, onCompleted }: Props) => {
  const [loading, setLoading] = useState(true);

  const handleReady = () => setLoading(false);
  const handleError = ({ detail }: CustomEventInit<Error>) => detail && onError(detail);
  const handleCompleted = ({ detail }: CustomEventInit<Result>) => detail && onCompleted(detail);

  window.addEventListener("validation-ready", handleReady);
  window.addEventListener("validation-error", handleError);
  window.addEventListener("validation-completed", handleCompleted);

  return (
    <div className={styles.container}>
      {loading && (
        <>
          <div
            className={`spinner-border text-primary ${styles.spinner}`}
            role="status"
          />
          <p className={styles.text}>
            Cargando
          </p>
        </>
      )}
      <zipps-id uuid={uuid} />
    </div>
  )
};

export default ZippsID;