import React from "react";
import { NavLink } from "react-router-dom";
import Icon from "../../../../components/UI/Icon";
import styles from "./DashboardHeader.module.scss";

const DashboardHeader = () => {
  const data = [
    { to: "/reportes/recibidos", icon: "problem", label: "Reportes que me han compartido" },
    { to: "/solicitar-reporte", icon: "folder_shared", label: "Solicitar que me compartan un reporte" },
    { to: "/compartir-reporte", icon: "reply", label: "Compartir mi reporte", small: "Compartir" },
  ];

  return (
    <div className={styles.container}>
      {data.map(({ to, label, icon, small }) => (
        <NavLink
          key={to}
          to={to}
          className={`btn btn-outline-secondary ${styles.btn}`}
        >
          <Icon icon={icon} />
          <span className={styles.text}>
            {label}
          </span>
          {small && (
            <span className={styles.small}>
              {small}
            </span>
          )}
        </NavLink>
      ))}
    </div>
  )
}

export default DashboardHeader;