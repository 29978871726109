import api from "./api";
import { Report } from "./useReportsSharedIdGet";

export interface Params {
  id: string | "preview";
}

interface SharedReportResponse {
  report: Report;
}

export const url = "reports/shared";

export const sharedReportGet = async (
  { id }: Params
): Promise<SharedReportResponse> => {
  const reponse = await api.get(`${url}/${id}`, true);
  return reponse.payload;
};
