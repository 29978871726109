// Tabla 35 - Estado del titular
// TransUnion - Tablas de convenciones - Información Comercial - Versión 1.34

const estadoTitular = {
  CONC: 'Concordato',
  LFOR: 'Liquidación Forzosa',
  LVOL: 'Liquidación Voluntaria',
  PREO: 'Proceso De Reorganización',
  OTRO: 'Otra',
  NORM: 'Normal'
};

export default estadoTitular;
