import React from "react";
import Icon from "../../../../../components/UI/Icon";
import styles from "./Info.module.scss";

const Info = () => {
  const info = [
    {
      icon: "readiness_score",
      title: "¿Qué es el puntaje crediticio?",
      description: "Tu puntaje crediticio es una medida que indica la probabilidad que tienes de cumplir con tus obligaciones financieras en el futuro. Cuanto más alto sea este puntaje, mayor confianza le transmitirás a las entidades financieras.",
    },
    {
      icon: "price_check",
      title: "¿Para qué sirve?",
      description: "Las entidades bancarias tienen en cuenta tu puntaje crediticio como uno de los elementos importantes al evaluar tus solicitudes de préstamos.",
    },
    {
      icon: "search_insights",
      title: "¿Cómo se determina o de qué depende?",
      description: "El puntaje crediticio se calcula considerando varios factores, entre ellos:",
      items: [
        "Tu experiencia crediticia.",
        "Cuánto puedes permitirte endeudarte.",
        "Qué tipos de productos financieros utilizas y cómo los manejas.",
        "Si pagas tus deudas puntualmente.",
      ],
    },
    {
      icon: "trending_up",
      title: "¿Cómo se puede mejorar?",
      items: [
        "Verifica tu historial de crédito regularmente.",
        "No te atrases con los pagos.",
        "Utiliza el crédito de manera responsable.",
        "Mantén tus cuentas abiertas y activas.",
        "Solicita aumentos de límite de crédito con moderación.",
        "Mantén una diversificación de productos.",
        "Evita cerrar cuentas recientes.",
        "Evita abrir demasiadas cuentas nuevas.",
        "Piénsalo bien antes de ser codeudor.",
      ],
    },
  ];

  return (
    <div className={styles.container}>
      {info.map(({ icon, title, description, items }) => (
        <div
          key={title}
          className={`eb-card ${styles.content}`}
        >
          <div className={styles.shape}>
            <Icon icon={icon} className={styles.icon} />
          </div>
          <p className={styles.title}>
            {title}
          </p>
          <div className={styles.info}>
            <p className={styles.description}>
              {description || ""}
            </p>
            <ul className={styles.items}>
              {(items || []).map((item) => (
                <li key={item}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Info;