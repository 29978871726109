import React from "react";
// import styles from './PasswordPreferences.module.css';
import PasswordForm from "../components/PasswordForm";
import styles from "./PreferencesContainer.module.scss";

const PasswordPreferencesContainer = () => {
  return (
    <div className="eb-card">
      <p className={styles.title}>
        Contaseña
      </p>
      <PasswordForm />
    </div>
  );
};

export default PasswordPreferencesContainer;
