import { Dispatch } from "redux";
import { clearUI } from "../store/UI/actions";
import { clearAlerts } from "../store/alerts/actions";
import { clearFootprints } from "../store/footprints/action";
import { clearScore } from "../store/scores/actions";
import { clearSocialSecurityInfo } from "../store/socialSecurity/actions";

export const clearStore = (dispatch: Dispatch) => {
  dispatch(clearUI());
  dispatch(clearScore());
  dispatch(clearAlerts());
  dispatch(clearFootprints());
  dispatch(clearSocialSecurityInfo());
};
