import React from "react";
import { Route, Switch } from "react-router";
import Page from "../../components/Layout/Page";
import Tabs from "../../components/UI/Tabs";
import ProductsListContainer from "./containers/ProductsListContainer";

const ProductsView = () => {
  const tabs = [
    { to: "/productos/recomendados", label: "Recomendados" },
    { to: "/productos/todos", label: "Todos" },
  ];

  return (
    <Page back>
      <Tabs tabs={tabs} />
      <Switch>
        <Route path="/productos/recomendados">
          <ProductsListContainer mode="advise" />
        </Route>
        <Route path="/productos/todos">
          <ProductsListContainer mode="all" />
        </Route>
      </Switch>
    </Page>
  )
}

export default ProductsView;