// Tabla 04 - Modalidad de crédito - MODA
// TransUnion - Tablas de convenciones - Información Comercial - Versión 1.34

const modalidadCredito = {
  // Sector financiero
  CIAL: 'Comercial',
  CONS: 'Consumo',
  MICT: 'Microcrédito',
  OTRO: 'Otro',
  VIVI: 'Vivienda',
  // Sector asegurado
  ASEG: 'Asegurado',
  RECO: 'Recobro'
};

export default modalidadCredito;
