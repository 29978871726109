import { ErrorMessage, Field, Form, Formik, FormikActions, FormikProps } from 'formik';
import React, { useState } from 'react';
import { FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import InputGroupText from 'reactstrap/lib/InputGroupText';
import * as yup from 'yup';
import FormErrors from '../../../components/Form/FormErrors';
import SubmitButton from '../../../components/Form/SubmitButton';
import processSubmitErrors from '../../../helpers/processSubmitErrors';
import usersForgotPasswordPost, {
  ForgotPasswordParams
} from '../../../services/usersForgotPasswordPost';
import styles from "./ForgotPasswordForm.module.scss";

interface Props {
  type: ForgotPasswordParams['type'];
  success: (phone: string) => void;
}

interface FormValues {
  email: string;
  phone: string;
}

const ForgotPasswordForm = ({ type, success }: Props) => {
  const [currentError, setCurrentError] = useState<typeof type>();
  const initialValues: FormValues = { email: "", phone: "" };
  const validationShema = yup.object().shape({
    email: type === "email"
      ? yup.string().email('El correo no es válido')
        .matches(
          /^[a-zA-Z0-9@!#$%&'*+-/=?^_`{|}~]*$/,
          'El correo no es válido'
        )
        .required('El correo es requerido')
      : yup.string(),
    phone: type === "phone"
      ? yup.string()
        .length(10, 'El móvil debe ser de 10 dígitos')
        .matches(/\d{10}/, 'El número de móvil no es válido')
        .required('Debe ingresar el teléfono')
      : yup.string()
  })

  const handleSubmit = async (
    values: FormValues,
    { setErrors, setStatus, setSubmitting }: FormikActions<FormValues>
  ) => {
    try {
      const url =
        type === 'email'
          ? `${location.origin}/recuperar-contrasena-email`
          : undefined;
      const phone =
        values.phone && values.phone.startsWith('57')
          ? values.phone
          : '57' + values.phone;

      const value = (type === 'email' ? values.email : phone)!;

      await usersForgotPasswordPost({ type, value, url });
      // If type is phone, the parent will need the phone value later
      success(type === 'phone' ? values.phone! : "");
    } catch (error) {
      setCurrentError(type);
      processSubmitErrors(error, values, setErrors, setStatus);
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationShema}
      onSubmit={handleSubmit}
      render={({ values, errors, touched }: FormikProps<FormValues>) => (
        <Form>
          {type === "email" && (
            <FormGroup>
              <p className={styles.label}>
                Correo electrónico
              </p>
              <Input
                type="email"
                name="email"
                tag={Field}
                placeholder="ejemplo@gmail.com"
                autoCapitalize="off"
                invalid={!!(errors.email && touched.email)}
                autoFocus
              />
              <p className={styles.hint}>
                Sigue los pasos del correo que recibirás para restablecer tu contraseña
              </p>
              <ErrorMessage name="email" component={FormFeedback} />
            </FormGroup>
          )}
          {type === "phone" && (
            <FormGroup>
              <p className={styles.label}>
                Teléfono móvil
              </p>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText className={styles.addon}>
                    +57
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="tel"
                  name="phone"
                  tag={Field}
                  placeholder="1234567890"
                  invalid={!!(errors.phone && touched.phone)}
                  autoFocus
                />
                <p className={styles.hint}>
                  Te enviaremos un código a tu WhatsApp para restablecer tu contraseña
                </p>
                <ErrorMessage name="phone" component={FormFeedback} />
              </InputGroup>
            </FormGroup>
          )}
          {type === currentError && <FormErrors />}
          <SubmitButton
            className={`${styles.btn} px-5 rounded-pill`}
            text="Enviar"
            disabled={
              (type === "email" && (!!(errors.email && touched.email) || !values.email)) ||
              (type === "phone" && (!!(errors.phone && touched.phone) || !values.phone))
            }
          />
        </Form>
      )}
    />
  )
};
export default ForgotPasswordForm;
