import fetchMock from 'fetch-mock';
import { url } from '../services/securityQuestionEnabledPost';
import ResponseCreator from './helpers/responseCreator';
import withDelay from './helpers/withDelay';

const w = window as any;
w.fixtures = w.fixtures || {};
w.fixtures.securityQuestionEnabled = {
  _enabled: true,
  _requireElectronicID: false,
  _requestFullName: false,
  setEnabled(value: boolean) {
    this._enabled = value;
  },
  setRequireElectronicID(value: boolean) {
    this._requireElectronicID = value;
  },
  setRequestFullName(value: boolean) {
    this._requestFullName = value;
  }
};

fetchMock.post(`end:${process.env.REACT_APP_API_PATH}/${url}`, () => {
  return withDelay(
    new ResponseCreator()
      .setBody({
        enabled: w.fixtures.securityQuestionEnabled._enabled,
        requestFullName: w.fixtures.securityQuestionEnabled._requestFullName,
        requireElectronicID:
          w.fixtures.securityQuestionEnabled._requireElectronicID,
        waitTime: w.fixtures.securityQuestionEnabled._enabled
          ? 1000 * 60 * 60
          : undefined
      })
      .getResponse()
  );
});
