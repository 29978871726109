// Tabla 06 - Periodicidad
// TransUnion - Tablas de convenciones - Información Comercial - Versión 1.34

const periodicidad = {
  VNC: 'Al vencimiento',
  ANU: 'Anual',
  ANF: 'Anual Fija',
  ANV: 'Anual Variable',
  BMN: 'Bimensual',
  BMF: 'Bimensual Fija',
  BMV: 'Bimensual Variable',
  CAT: 'Catorcenal',
  CMT: 'Cuatrimestral',
  DEC: 'Decadal',
  MEN: 'Mensual',
  MNF: 'Mensual Fija',
  MNV: 'Mensual Variable',
  OTR: 'Otra',
  QUI: 'Quincenal',
  QNF: 'Quincenal Fija',
  QNV: 'Quincenal Variable',
  SNL: 'Semanal',
  SNF: 'Semanal Fija',
  SNV: 'Semanal Variable',
  STR: 'Semestral',
  STF: 'Semestral Fija',
  STV: 'Semestral Variable',
  TRI: 'Trimestral',
  TRF: 'Trimestral Fija',
  TRV: 'Trimestral Variable',
  BST: 'Bimestral'
};

export default periodicidad;
