import * as fetchMock from 'fetch-mock';
import { AccountParams, url } from '../services/preferencesAccountPost';
import ResponseCreator from './helpers/responseCreator';
import store from './helpers/store';
import withDelay from './helpers/withDelay';

// TODO
// "NAME_NOT_MATCH", "Input form name not match with transunion name" > Los datos del formulario no coinciden con los datos de transuniont, así que no se pueden mostrar preguntas
// El anterior error debe llamar de nuevo a security question enabled para indicarle al usuario que debe esperar x cantidad de tiempo
// "WITHOUT_INFO", "User does not have registered information" > El usuario no existe en transunion

fetchMock.get(
  `end:${process.env.REACT_APP_API_PATH}/${url}`,
  (_, fetchData) => {
    const { account } = store.preferences;

    return withDelay({ status: 200, body: { ...account } }, 2000);
  }
);

fetchMock.post(
  `end:${process.env.REACT_APP_API_PATH}/${url}`,
  (_, fetchData) => {
    const data = JSON.parse(fetchData.body as string) as AccountParams;
    store.preferences = { account: data };

    if (data.name === 'Non Valid' || data.lastname === 'Non Valid') {
      store.roles = ['credit'];
      return withDelay(
        new ResponseCreator()
          .addError(
            'NAME_NOT_MATCH',
            'Input form name not match with transunion name'
          )
          .getResponse()
      );
    }

    if (data.name === 'Non Existent' || data.lastname === 'Non Existent') {
      store.roles = ['credit'];
      return withDelay(
        new ResponseCreator()
          .addError('WITHOUT_INFO', 'User does not have registered information')
          .getResponse()
      );
    }

    return withDelay({ status: 200, body: {} });
  }
);
