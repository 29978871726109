import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import NoSubscription from "../../../../components/Subscription/NoSubscription";
import isSubscribed from "../../../../helpers/isSubscribed";
import jwt from "../../../../helpers/jwt";
import { Score as ScoreType } from "../../../../services/scoresGet";
import { fetchScore as fetchScoreAction } from '../../../../store/scores/actions';
import { ScoresState } from "../../../../store/scores/types";
import { AppState } from "../../../../store/store";
import styles from "./DashboardScore.module.scss";
import Score from "./Score";
import ScoreFree from "./ScoreFree";
import ScoreHistory from "./ScoreHistory";

interface Props {
  token: string;
  scoresState: ScoresState;
  fetchScore(): void;
}

const DashboardScore = ({ token, scoresState, fetchScore }: Props) => {
  const userIsSubscribed = isSubscribed();
  const decodedToken = jwt.decode(token);
  const plan = decodedToken.cpl;
  const [scores, setScores] = useState<ScoreType[]>([]);

  useEffect(() => {
    if (userIsSubscribed) {
      fetchScore();
    }
  }, []);

  useEffect(() => {
    if (scoresState.status === "loaded") {
      const { puntajes: scores } = scoresState.payload;
      // Some reports were requested simultaneously ("date": Date without time)
      // YYYY-MM-DD[T]HH:mm:ss.SSSZ - YYYY-MM-DD (some dates may not have milliseconds)
      const mapScores = scores.map(score => ({ ...score, fecha: score.fecha.slice(0, 10) }));
      const uniqueScores: ScoreType[] = [...new Map(mapScores.map(score => [score.fecha, score])).values()]
      setScores(uniqueScores);
    }
  }, [scoresState]);

  return (
    <div className={styles.container}>
      {userIsSubscribed
        ? (
          <>
            <Score plan={plan} scores={scores} />
            <ScoreHistory scores={scores} />
          </>
        )
        : (
          <>
            <ScoreFree />
            <NoSubscription />
          </>
        )
      }
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  token: state.security.token || "",
  scoresState: state.scores,
});

const mapDispatchToProps = {
  fetchScore: fetchScoreAction
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardScore);