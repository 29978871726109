import * as fetchMock from 'fetch-mock';
import Intent from '../enums/Intent';
import jwt from '../helpers/jwt';
import tokenExpiration from '../helpers/tokenExpiration';
import { url } from '../services/intentPost';
import store from './helpers/store';

fetchMock.post(
  `end:${process.env.REACT_APP_API_PATH}/${url}`,
  (url, fetchData) => {
    const data = JSON.parse(fetchData.body as string) as { intent: Intent };
    store.intent = data.intent;
    store.roles = [
      ...store.roles,
      data.intent === 'report' ? 'report' : 'credit'
    ];
    (window as any).token.roles = store.roles;
    return {
      status: 200,
      body: {
        payload: {
          token: jwt.encode(store.roles, tokenExpiration(), 'fake-uuid')
        }
      }
    };
  }
);
