import React, { useState } from "react";
import { Button, FormGroup, Input, InputGroup, InputGroupAddon, Label } from "reactstrap";
import translateError from "../../../helpers/translateError";
import redeemCodePost from "../../../services/redeemCodePost";
import ApiError from "../../../types/ApiError";
import { ServiceError } from "../../../types/ServiceError";
import styles from "./PaymentCode.module.scss";

export interface OnRedeemParams {
  code: string;
  teaser: string;
  value: number;
}

interface Props {
  planUuid: string;
  onRedeem: (params: OnRedeemParams) => void;
}

const PaymentCode = ({ planUuid, onRedeem }: Props) => {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleApply = async () => {
    try {
      setLoading(true);
      setError("");
      const { payload } = await redeemCodePost({ code, planUuid });
      if (payload)
        onRedeem({ code, teaser: payload.teaser, value: payload.value });
    } catch (e) {
      if (
        e instanceof ApiError &&
        e.code !== 500 &&
        e.response &&
        e.response.errors &&
        e.response.errors.length
      ) {
        const errors = e.response.errors as ServiceError[];
        const translateFirstError = translateError(errors[0].error) as string;
        setError(translateFirstError);
      } else {
        const errorMsg = "Ocurrió un error inesperado";
        setError(errorMsg);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="eb-card">
      <FormGroup className="m-0">
        <Label className="mb-2">
          Cupón de descuento
        </Label>
        <InputGroup>
          <Input
            type="text"
            placeholder="Ingresa aquí tu cupón"
            value={code}
            onChange={e => setCode(e.target.value)}
            invalid={!!error}
          />
          <InputGroupAddon
            addonType="append"
            className={styles.addon}
          >
            <Button
              id="redeem"
              onClick={handleApply}
              color="primary"
              disabled={code.length < 5 || loading}
            >
              Aplicar
            </Button>
          </InputGroupAddon>
        </InputGroup>
        <small className={styles.error}>
          {error}
        </small>
      </FormGroup>
    </div>
  )
}

export default PaymentCode;