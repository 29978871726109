export interface Microphone {
  haveMicrophone: boolean;
  allowMicrophone: boolean;
}

export default async (): Promise<Microphone> => {
  const response: Microphone = {
    haveMicrophone: false,
    allowMicrophone: false
  };
  if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
    return response;
  }
  const devices = await navigator.mediaDevices.enumerateDevices();
  response.haveMicrophone = !!devices.find(device => device.kind === 'audioinput');
  response.allowMicrophone = await navigator.mediaDevices
    .getUserMedia({ audio: true })
    .then(mediaStream => {
      mediaStream.getTracks().forEach(track => track.stop());
      return true;
    })
    .catch(() => {
      return false;
    });
  return response;
};
