import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Data, getHref } from "../../../helpers/apiWhatsApp";
import { capitalizeEachWord } from "../../../helpers/capitalize";
import { AppState } from "../../../store/store";
import styles from "./Interview.module.scss";

interface Props {
  user: {
    name: string;
    uuid: string;
    document: string;
    documentType: string;
  };
}

const Interview = ({ user }: Props) => {
  const msg = "Deseo validar mi identidad";
  const [data, setData] = useState<Data[]>([]);

  useEffect(() => {
    setData([
      { label: "Nombre", value: capitalizeEachWord(user.name) },
      { label: user.documentType, value: user.document },
      { label: "Usuario", value: user.uuid },
    ])
  }, [user]);

  return (
    <>
      <p className={styles.notice}>
        <strong>
          Nuestro proceso de validación de identidad se realiza de lunes a viernes, de 8:30 a.m. a 6:00 p.m.
        </strong>
      </p>
      <p className={styles.text}>
        Para continuar con el proceso de validación, comunícate con un agente haciendo click en el siguiente botón.
      </p>
      <a
        className="btn btn-primary"
        href={getHref(msg, data)}
        rel="noopener noreferrer"
        target="_blank"
        title="WhatsApp"
      >
        Continuar
      </a>
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  user:
    state.user.status === "loaded"
      ? {
        name: `${state.user.payload.name} ${state.user.payload.lastname}`,
        uuid: state.security.userId,
        document: state.user.payload.documentNumber,
        documentType: state.user.payload.documentType,
      }
      : {
        name: "",
        uuid: state.security.userId,
        document: "",
        documentType: "",
      }
});

export default connect(mapStateToProps)(Interview);