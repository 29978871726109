import dayjs from "dayjs";
import "dayjs/locale/es";

interface Format {
  in?: string;
  out?: string;
}
const defaultFormat: Format = {
  in: "DD/MM/YYYY",
  out: "D [de] MMMM YYYY"
};

export const formatDate = (date: string, format?: Format) => {
  const options = { ...defaultFormat, ...format };
  return dayjs(date, options.in).locale("es").format(options.out)
}
