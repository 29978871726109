import { ServiceReturn } from "../types/ServiceReturn";
import api from "./api";

export interface PlanTransactionParams {
  planUuid: string;
  promoCode: string;
  paymentGateway: string;
}

export type PlanTransactionResponse = Record<string, string>;

export const url = "plans/transaction/new";

const planTransactionPost = async (
  postData: PlanTransactionParams
): Promise<ServiceReturn<PlanTransactionResponse>> => {
  return await api.post(`${url}`, true, { ...postData });
};

export default planTransactionPost;
