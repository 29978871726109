import * as fetchMock from 'fetch-mock';
import { ChangePasswordParams, url } from '../services/usersChangePasswordPost';
import ResponseCreator from './helpers/responseCreator';
import withDelay from './helpers/withDelay';

fetchMock.post(
  `end:${process.env.REACT_APP_API_PATH}/${url}`,
  (_, response) => {
    const data = JSON.parse(response.body as string) as ChangePasswordParams;

    if (data.oldPassword === 'invalid') {
      return withDelay(
        new ResponseCreator()
          .addError('oldPassword', 'Invalid password')
          .setStatus(403)
          .getResponse()
      );
    }

    return withDelay(new ResponseCreator().getResponse());
  }
);
