import { ServiceReturn } from '../types/ServiceReturn';
import api from './api';

export interface Params {
  id: string;
}

export const url = 'reports/delete-sent';

export const reportsDeleteSentPost = async ({
  id
}: Params): Promise<ServiceReturn<null>> => {
  return await api.post(url, true, { id });
};
