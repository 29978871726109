import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Button } from "reactstrap";
import { Dispatch } from "redux";
import Icon from "../../../components/UI/Icon";
import Modal from "../../../components/UI/Modal";
import refreshToken from "../../../helpers/refreshToken";
import { showWppButton } from "../../../store/UI/actions";
import styles from "./ModalSuccessCode.module.scss";

interface Props extends RouteComponentProps {
  teaser: string;
  setShowWppButton: (show: boolean) => void;
}

const ModalSuccessCode = ({ teaser, setShowWppButton, history }: Props) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setShowWppButton(false);
  }, []);

  const close = async () => {
    setLoading(true);
    await refreshToken();
    setShowWppButton(true);
    setLoading(false);
    history.push("/dashboard");
  }

  return (
    <Modal backdrop="static" onToggle={close} centered>
      <div className={styles.box}>
        <div className={styles.banner}>
          <Icon
            icon="check_circle"
            className={styles.icon}
            fill
          />
        </div>
        <div className={styles.wrapper}>
          <p className={styles.title}>
            Aplicamos tu código
          </p>
          <p className={styles.teaser}>
            <span>
              {teaser}{" "}
            </span>
            te invitó esta vez!
          </p>
          <p className={styles.description}>
            No pierdas la oportunidad de sacarle jugo a tu historial
            crediticio para empoderarte de tus finanzas personales
          </p>
          <Button
            color="primary"
            onClick={close}
            disabled={loading}
            className={styles.button}
          >
            {loading && <div className="spinner-border spinner-border-sm mr-2" role="status" />}
            Comenzar
          </Button>
        </div>
      </div>
    </Modal>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setShowWppButton: (show: boolean) => dispatch(showWppButton(show)),
  }
}

export default connect(null, mapDispatchToProps)(withRouter(ModalSuccessCode));
