import React from "react";
import { Product } from "../../../../services/useReportsDetailGet";
import styles from "./DashboardReport.module.scss";
import ProductReport from "./ProductReport";

interface ProductReport {
  icon: string;
  label: string;
  product: Product;
}

const DashboardReport = () => {
  const productsReport: ProductReport[] = [
    { product: "accounts", label: "Mis cuentas", icon: "account_balance_wallet" },
    { product: "credit-card", label: "Mis Tarjetas de Crédito", icon: "credit_card" },
    { product: "credit", label: "Mis créditos", icon: "account_balance" },
    { product: "contracts-services", label: "Mis Contratos y Servicios", icon: "receipt_long" },
  ];

  return (
    <div className="eb-card">
      <div className={styles.container}>
        {productsReport.map(productReport => (
          <ProductReport
            key={productReport.product}
            {...productReport}
          />
        ))}
      </div>
    </div>
  )
}

export default DashboardReport;