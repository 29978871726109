import React, { useEffect, useState } from "react";
import Loading from "../../../components/UI/Loading";
import useReportsSharedGet, {
  SharedReports
} from "../../../services/useReportsSharedGet";
import NoReport from "../components/NoReport";
import SharedReport from "../components/SharedReport";

const SharedReportsContainer = () => {
  const service = useReportsSharedGet();
  const [reports, setReports] = useState<SharedReports[]>([]);

  useEffect(() => {
    if (service.status === "loaded") {
      setReports(service.payload.reports);
    }
  }, [service]);

  if (service.status === "loading")
    return <Loading />

  if (service.status === "loaded") {
    if (!reports.length)
      return <NoReport />

    return (
      <div
        className="d-flex flex-column"
        style={{ gap: "1rem" }}
      >
        {reports.map(report => (
          <SharedReport
            key={report.id}
            {...report}
          />
        ))}
      </div>
    )
  }

  return <></>
};

export default SharedReportsContainer;
