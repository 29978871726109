import React, { useState } from 'react';
import { Input, InputGroup, InputGroupAddon } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import InputField from '../../../components/Form/InputField';
import ErrorMessages from '../../../components/UI/ErrorMessages';
import delay from '../../../helpers/delay';
import formatOnlyNumbers from '../../../helpers/formatOnlyNumbers';
import translateError from '../../../helpers/translateError';
import { validPhone } from '../../../helpers/valid';
import { validatePhone } from '../../../helpers/zipps';
import messageWhatsappPost from '../../../services/messageWhatsappPost';
import ApiError from '../../../types/ApiError';
import { ServiceError } from '../../../types/ServiceError';
import styles from './PhoneValidation.module.scss';
import ValidationStatusField from './ValidationStatusField';

const delayAttemptTime = 30; // 30s

interface Props {
  phone: string;
  setIsValidated: (validated: boolean) => void;
  setFieldError: (error: string) => void;
  sendDisabled?: boolean;
  authenticated?: boolean;
}

type Status = 'init' | 'sending' | 'sent' | 'error';

const PhoneValidationField = ({
  phone,
  setIsValidated,
  setFieldError,
  sendDisabled = false,
  authenticated = false,
}: Props) => {
  const [sendStatus, setSendStatus] = useState<Status>('init');
  const [error, setError] = useState<string[]>([]);
  const [timeLeft, setTimeLeft] = useState<number>(delayAttemptTime);
  const [delayAttempt, setDelayAttempt] = useState<boolean>(false);

  const [isValid, setIsValid] = useState(false);
  const [lastValue, setLastValue] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [isValidating, setIsValidating] = useState(false);

  const handleBlur = async () => {
    setLastValue(phone);
    if (phone) {
      if (phone !== lastValue) {
        if (validPhone(phone)) {
          setIsValidating(true);
          setIsValid(false);
          setIsValidated(false);
          const phoneNumber = `57${phone}`;
          const { valid, exceededAttemps } = await validatePhone(phoneNumber);
          setDisabled(exceededAttemps)
          setFieldError(valid ? "" : "El número que ingresaste no existe o no es válido");
          setIsValidating(false);
          setIsValid(valid);
          setIsValidated(valid);
        } else {
          setIsValid(false);
          setFieldError("El número de móvil no es válido");
          setIsValidated(false);
        }
      }
    } else {
      setIsValid(false);
      setIsValidated(false);
    }
  }

  const sendCodeValidation = async () => {
    if (phone.length !== 10) return;
    try {
      setSendStatus('sending');
      const phoneNumber = `57${phone}`;
      await messageWhatsappPost({ phone: phoneNumber }, authenticated);
      setSendStatus('sent');
      setError([]);
      setDelayAttempt(true);
      setTimeLeft(delayAttemptTime);
      await setInterval(
        () => (timeLeft > 0 ? setTimeLeft(prev => prev - 1) : clearInterval()),
        1000
      );
      await delay(delayAttemptTime * 1000);
      setDelayAttempt(false);
    } catch (error) {
      if (
        error instanceof ApiError &&
        error.response &&
        error.response.errors
      ) {
        const serviceErrors = error.response.errors as ServiceError[];
        const errors = serviceErrors.map((errorItem: ServiceError) =>
          translateError(errorItem.error)
        );
        if (errors.length) {
          setError(errors);
        } else {
          setError(['Ocurrió un error inesperado, inténtalo más tarde']);
        }
      } else {
        setError(['Ocurrió un error inesperado, inténtalo más tarde']);
      }
      setSendStatus('error');
    }
  };

  return (
    <>
      <div onBlur={handleBlur}>
        <InputField
          name="phone"
          label="Móvil"
          type="tel"
          formatValue={formatOnlyNumbers}
          onBlur={handleBlur}
          disabled={disabled}
          description={
            sendStatus === 'sent'
              ? 'Tu código ha sido enviado'
              : "Solicita un código por WhatsApp para continuar"
          }
          render={props => (
            <div className={styles.phoneValidation}>
              <InputGroup className='position-relative'>
                <InputGroupAddon addonType="prepend">57</InputGroupAddon>
                <Input {...props} />
                <ValidationStatusField isValid={isValid} isValidating={isValidating} />
              </InputGroup>
              {sendDisabled && isValid && (
                <Button
                  id={"form-otp"}
                  color="primary"
                  className={styles.button}
                  disabled={sendStatus === 'sending' || !sendDisabled || delayAttempt || !isValid || isValidating}
                  onClick={() => sendCodeValidation()}
                >
                  {
                    sendStatus === 'sending'
                      ? 'Enviando'
                      : delayAttempt
                        ? timeLeft
                        : 'Enviar código'
                  }
                </Button>
              )}
            </div>
          )}
        />
      </div>
      {!!error && <ErrorMessages errorMessages={error} />}
      <InputField
        name={"code"}
        label="Código"
        description="Ingresa el código que te hemos enviado a WhatsApp"
        disabled={isValidating || !isValid}
      />
    </>
  );
};

export default PhoneValidationField;
