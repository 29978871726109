import { delay, put, takeEvery } from 'redux-saga/effects';
import { clearMessage, registerMessage } from '../message/actions';
import { Message, SendMessage } from '../message/types';

function* clearMessageAfterTime(action: SendMessage) {
  const { message, messageType, options } = action;
  const currentMessage: Message = { message, messageType };
  yield put(registerMessage(currentMessage));
  if (options.time && options.time !== 0) {
    yield delay(options.time);
    yield put(clearMessage(currentMessage));
  }
}

export default function* watchSendMessage() {
  yield takeEvery('message/SEND_MESSAGE', clearMessageAfterTime);
}
