import Intent from '../../enums/Intent';
import Rol from '../../enums/Rol';
import getAuthToken from '../../helpers/getAuthToken';
import jwt from '../../helpers/jwt';

interface Value {
  [field: string]: any;
}

export interface Store {
  intent?: Intent;
  basicInfoSet: boolean;
  creditReasonSet: boolean;
  roles: Rol[];
  preferences: {
    basicInfo: Value;
    account: Value;
    notifications: Value;
  };
}

/**
 * This object simulates a minimum backend persistence when needed
 */
const store = {
  data: <Store>{
    intent: undefined,
    basicInfoSet: false,
    creditReasonSet: false,
    roles: [],
    preferences: {
      basicInfo: {},
      account: {},
      notifications: {
        advertising: 'none',
        alerts: 'none'
      }
    }
  },
  get intent(): Intent | undefined {
    console.log('get intent:', this.data.intent);
    return this.data.intent;
  },
  set intent(value: Intent | undefined) {
    console.log('set intent:', value);
    this.data.intent = value;
  },
  get basicInfoSet() {
    console.log('get basicInfoSet:', this.data.basicInfoSet);
    return this.data.basicInfoSet;
  },
  set basicInfoSet(value: boolean) {
    console.log('set basicInfoSet:', value);
    this.data.basicInfoSet = value;
  },
  set creditReasonSet(value: boolean) {
    console.log('set creditReasonSet:', value);
    this.data.creditReasonSet = value;
  },
  get creditReasonSet() {
    console.log('get creditReasonSet:', this.data.creditReasonSet);
    return this.data.creditReasonSet;
  },
  get roles(): Rol[] {
    console.log('get roles:', this.data.roles);
    return this.data.roles;
  },
  set roles(value: Rol[]) {
    console.log('set roles:', value);
    // Roles aren't lost, so new roles are merged with old ones
    this.data.roles = this.data.roles.concat(
      value.filter(v => this.data.roles.indexOf(v) < 0)
    );
  },
  resetRoles() {
    this.data.roles = [];
  },
  set preferences({ account, notifications, basicInfo }: Value) {
    if (account) {
      this.data.preferences.account = account;
    }
    if (notifications) {
      this.data.preferences.notifications = notifications;
    }
    if (basicInfo) {
      this.data.preferences.basicInfo = basicInfo;
    }
    console.log('set preferences', this.data.preferences);
  },
  get preferences() {
    return this.data.preferences;
  }
};

// Initialize roles with saved token
store.resetRoles();
const token = getAuthToken();
if (token) {
  const { roles } = jwt.decode(token);
  store.data.roles = roles;
}

(window as any).fixtureStore = store;

export default store;
