import React from "react";
import { Table } from "reactstrap";
import calificacion from "../../../../dictionaries/report/calificacion";
import claseTarjeta from "../../../../dictionaries/report/claseTarjeta";
import estadoContrato from "../../../../dictionaries/report/estadoContrato";
import estadoObligacion from "../../../../dictionaries/report/estadoObligacion";
import estadoTitular from "../../../../dictionaries/report/estadoTitular";
import lineaCredito from "../../../../dictionaries/report/lineaCredito";
import marcaTarjeta from "../../../../dictionaries/report/marcaTarjeta";
import modalidadCredito from "../../../../dictionaries/report/modalidadCredito";
import modoExtincion from "../../../../dictionaries/report/modoExtincion";
import naturalezaReestructuracion from "../../../../dictionaries/report/naturalezaReestructuracion";
import periodicidad from "../../../../dictionaries/report/periodicidad";
import plazo from "../../../../dictionaries/report/plazo";
import tipoContrato from "../../../../dictionaries/report/tipoContrato";
import tipoEntidad from "../../../../dictionaries/report/tipoEntidad";
import tipoFideicomiso from "../../../../dictionaries/report/tipoFideicomiso";
import tipoGarantia from "../../../../dictionaries/report/tipoGarantia";
import tipoMoneda from "../../../../dictionaries/report/tipoMoneda";
import tipoPago from "../../../../dictionaries/report/tipoPago";
import vigencia from "../../../../dictionaries/report/vigencia";
import { formatDate } from "../../../../helpers/date";
import moneyFormat from "../../../../helpers/moneyFormat";
import Behavior from "./Behavior";
import DebtShareChart from "./DebtShareChart";
import styles from "./ProductDetail.module.scss";

export interface Props {
  product: any;
}

// Render a row
const renderRow = (label: string, value: string) => (
  <tr>
    <td className={styles.text}>
      {label}
    </td>
    <td className={styles.text}>
      {value}
    </td>
  </tr>
);


const rowBuilder = (product: any) => (transform?: (value: any) => any) => (
  key: string,
  label: string,
  dictionary?: { [key: string]: string }
) => {
  if (dictionary && transform)
    throw new Error("Dictionary and transform parameters can't be combined");

  if (!(key in product))
    return;

  if (typeof product[key] === "string" && !product[key].trim())
    return;

  if (dictionary) {
    const value = dictionary[product[key].trim()] || product[key];
    return renderRow(label, value);
  } else
    return renderRow(label, transform ? transform(product[key]) : product[key]);
};

const moneyFormatThousand = (value: number) => moneyFormat(value * 1000);

const ProductDetail = ({ product }: Props) => {
  const row = rowBuilder(product)();
  const rowDate = rowBuilder(product)(formatDate);
  const rowMoney = rowBuilder(product)(moneyFormatThousand);

  return (
    <div className={styles.container}>
      <div className={styles.data}>
        <p className={styles.label}>
          Detalles de la cuenta
        </p>
        <Table
          size="sm"
          className={styles.table}
        >
          <tbody>
            {row("TipoContrato", "Tipo de contrato", tipoContrato)}
            {row("EstadoContrato", "Estado del contrato", estadoContrato)}
            {row("NumeroObligacion", "Número de obligación")}
            {row("EstadoObligacion", "Estado de obligación", estadoObligacion)}
            {row("ModalidadCredito", "Modalidad de crédito", modalidadCredito)}
            {row("LineaCredito", "Línea de crédito", lineaCredito)}
            {row("Periodicidad", "Periodicidad", periodicidad)}
            {rowDate("FechaApertura", "Fecha de apertura")}
            {rowDate("FechaTerminacion", "Fecha de terminación")}
            {row("Calificacion", "Calificación", calificacion)}
            {rowMoney("ValorInicial", "Valor inicial")}
            {rowMoney("SaldoObligacion", "Saldo obligación")}
            {rowMoney("ValorMora", "Valor mora")}
            {rowMoney("ValorCuota", "Valor cuota")}
            {row("TipoMoneda", "Tipo de moneda", tipoMoneda)}
            {row("CuotasCanceladas", "Cuotas canceladas")}
            {row("TipoGarantia", "Tipo de garantía", tipoGarantia)}
            {row("CubrimientoGarantia", "Cubrimiento de garantía")}
            {row("MoraMaxima", "Mora máxima")}
            {row("ProbabilidadNoPago", "Probabilidad de no pago")}
            {rowDate("FechaCorte", "Fecha de corte")}
            {row("ModoExtincion", "Modo de extinción", modoExtincion)}
            {rowDate("FechaPago", "Fecha de pago")}
            {rowDate("FechaPermanencia", "Fecha de permanencia")}
            {row("NumeroReestructuraciones", "Número de reestructuraciones")}
            {row("NaturalezaReestructuracion", "Naturaleza de reestructuración", naturalezaReestructuracion)}
            {row("MarcaTarjeta", "Marca de la tarjeta", marcaTarjeta)}
            {row("ClaseTarjeta", "Clase de la tarjeta", claseTarjeta)}
            {row("TipoFideicomiso", "Tipo de fideicomiso", tipoFideicomiso)}
            {row("TipoEntidadOriginadora|tera", "Tipo de entidad originadora de cartera", tipoEntidad)}
            {row("EntidadOriginadoraCartera", "Entidad originadora de cartera")}
            {row("NumeroFideicomiso", "Número de fideicomiso")}
            {row("ChequesDevueltos", "Cheques devueltos")}
            {row("Plazo", "Plazo", plazo)}
            {row("DiasCartera", "Dias de cartera")}
            {row("TipoPago", "Tipo de pago", tipoPago)}
            {row("EstadoTitular", "Estado titular", estadoTitular)}
            {row("NumeroCuotasPactadas", "Número de cuotas pactadas")}
            {row("NumeroCuotasMora", "Cuotas en mora")}
            {rowMoney("ValorCargoFijo", "Valor cargo fijo")}
            {row("ClausulaPermanencia", "Cláusula de permanencia")}
            {row("Reestructurado", "Reestructurado")}
            {row("Vigencia", "Vigencia", vigencia)}
            {row("NumeroMesesContrato", "Número de meses contrato")}
            {row("NumeroMesesClausula", "Número de meses cláusula")}
          </tbody>
        </Table>
      </div>
      <div className={styles.data}>
        {typeof product.ParticipacionDeuda === "number" && (
          <div className={styles.grid}>
            <p className={styles.label}>
              Participación de la deuda
            </p>
            <DebtShareChart value={product.ParticipacionDeuda} />
          </div>
        )}
        <div className={styles.grid}>
          <p className={styles.label}>
            Entidad
          </p>
          <div className={styles.content}>
            <p className={styles.text}>
              {product.NombreEntidad}
            </p>
            <p className={styles.text}>
              <span>
                Tipo de entidad:&nbsp;
              </span>
              {tipoEntidad[product.TipoEntidad] || ""}
            </p>
            <p className={styles.text}>
              <span>
                Ciudad:&nbsp;
              </span>
              {product.Ciudad}
            </p>
            <p className={styles.text}>
              <span>
                Sucursal:&nbsp;
              </span>
              {product.Sucursal}
            </p>
          </div>
        </div>
        <div className={styles.grid}>
          <p className={styles.label}>
            Comportamiento
          </p>
          <Behavior data={product.Comportamientos} />
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
