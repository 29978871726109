import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loading from "../../../components/UI/Loading";
import { Alert, JudicialProcess, Vehicle } from "../../../services/alertsGet";
import { fetchAlerts as fetchAlertsAction } from "../../../store/alerts/actions";
import { AlertsState } from "../../../store/alerts/types";
import { AppState } from "../../../store/store";
import DriverAlerts from "../components/DriverAlerts";
import VehiclesAlerts from "../components/VehiclesAlerts";
import styles from "./AlertsContainer.module.scss";
// import JudicialAlerts from "../components/JudicialAlerts";

export interface Props {
  alertsState: AlertsState;
  fetchAlerts(): void;
}

const AlertsContainer = ({ alertsState, fetchAlerts }: Props) => {
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [driverAlerts, setDriverAlerts] = useState<Alert[]>([]);
  const [judicialProcesses, setJudicialProcesses] = useState<JudicialProcess[]>([]);

  useEffect(() => {
    fetchAlerts();
  }, []);

  useEffect(() => {
    if (alertsState.status === "loaded") {
      const { da, vl, jp } = alertsState.payload;
      setDriverAlerts(da.alerts);
      setVehicles(vl.vehicles);
      setJudicialProcesses(jp.processList);
    }
  }, [alertsState]);

  return (
    <>
      {alertsState.status === "loading" && <Loading />}
      {alertsState.status === "loaded" && (
        <div className={styles.container}>
          <VehiclesAlerts vehicles={vehicles} />
          <DriverAlerts alerts={driverAlerts} />
          {/* <JudicialAlerts judicialProcesses={judicialProcesses} /> */}
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state: AppState) => {
  return {
    alertsState: state.alerts,
  };
};

const mapDispatchToProps = {
  fetchAlerts: fetchAlertsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertsContainer);