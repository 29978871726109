// Tabla 11 - Naturaleza de la reestructuración - NATREES
// TransUnion - Tablas de convenciones - Información Comercial - Versión 1.34

const naturalezaReestructuracion = {
  ACUE: 'Acuerdo acreedores o privado',
  CONC: 'Concordato',
  EXTR: 'Extraordinaria',
  L116: 'Ley 1116',
  L546: 'Ley 546',
  L550: 'Ley 550',
  L617: 'Ley 617',
  ORDI: 'Ordinaria',
  OTRA: 'Otra'
};

export default naturalezaReestructuracion;
