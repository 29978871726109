import type { PlanTransactionResponse } from "../services/planTransactionPost";
import type {
  Transaction,
  TransactionEventData,
  TransactionPaymentData,
  WompiResponse
} from "../types/Transaction";
import { formatMoney } from "./money";

const isWompi = (paymentGateway: string) => paymentGateway === "WO";
const isPayzen = (paymentGateway: string) => paymentGateway === "PA";

const getStatus = (paymentGateway: string, status: string) => {
  if (isPayzen(paymentGateway)) {
    if (status === "CAPTURED" || status === "ACCEPTED") return "APROBADA";
    else if (status === "REFUSED") return "RECHAZADA";
    else if (status === "ABANDONED") return "ABANDONADA";
    else if (status === "AUTHORISED") return "AUTORIZADA";
    else if (status === "AUTHORISED_TO_VALIDATE")
      return "AUTORIZADA POR VALIDACIÓN";
    else if (status === "CANCELLED") return "CANCELADA";
    else if (status === "CAPTURE_FAILED" || status === "NOT_CREATED")
      return "ERROR";
    else if (status === "EXPIRED") return "EXPIRADA";
    else if (status === "SUSPENDED") return "SUSPENDIDA";
    else if (status === "UNDER_VERIFICATION") return "VERIFICACIÓN EN CURSO";
    else if (status === "WAITING_AUTHORISATION")
      return "EN ESPERA DE AUTORIZACIÓN";
    else if (status === "WAITING_AUTHORISATION_TO_VALIDATE")
      return "EN ESPERA VALIDACIÓN Y AUTORIZACIÓN";
    else if (status === "WAITING_FOR_PAYMENT") return "EN ESPERA DEL PAGO";
  } else {
    if (status === "APPROVED") return "APROBADA";
    else if (status === "DECLINED") return "RECHAZADA";
    else if (status === "VOIDED") return "ANULADA";
    else if (status === "ERROR") return "ERROR";
  }
  return "SIN DEFINIR";
};

export const getNewTransactionEventData = (
  paymentGateway: string,
  payload: PlanTransactionResponse
) => {
  // Payzen
  if (isPayzen(paymentGateway)) {
    return {
      name: `${payload.vads_cust_first_name} ${payload.vads_cust_last_name}`,
      email: payload.vads_cust_email,
      phone: payload.vads_cust_cell_phone,
      amount: +payload.vads_amount / 100
    };
  } else {
    return {
      name: payload["customer-data:full-name"],
      email: payload["customer-data:email"],
      phone: payload["customer-data:phone-number"],
      amount: +payload["amount-in-cents"] / 100
    };
  }
};

export const redirectToPaymentGateway = (payload: PlanTransactionResponse) => {
  const form = document.createElement("form");
  form.method = payload.method;
  form.action = payload.action;

  delete payload.method;
  delete payload.action;

  for (let [key, value] of Object.entries(payload)) {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = key;
    input.value = value;
    form.appendChild(input);
  }

  document.body.appendChild(form);
  form.submit();
};

export const getTransaction = async (queryString: string) => {
  const urlParams = new URLSearchParams(queryString);
  const gtw = urlParams.get("gtw") || "";
  const date = Math.round(Date.now() / 1000);

  if (isPayzen(gtw)) {
    // Queryparams data
    const vadsStatus = urlParams.get("vads_trans_status") || "";
    const vadsAmount = urlParams.get("vads_amount") || 0;
    const vadsUuid = urlParams.get("vads_trans_uuid") || "";
    const vadsOrder = urlParams.get("vads_order_id") || "";
    const vadsName = urlParams.get("vads_cust_name") || "";
    const vadsEmail = urlParams.get("vads_cust_email") || "";
    const vadsPhone = urlParams.get("vads_cust_cell_phone") || "";
    const vadsTransId = urlParams.get("vads_trans_id") || "";

    if (!vadsTransId) return null;

    const ebStatus = getStatus(gtw, vadsStatus);
    const approved = ebStatus === "APROBADA";
    const paymentData: TransactionPaymentData[] = [
      { label: "Estado", value: ebStatus },
      { label: "Monto", value: formatMoney(+vadsAmount / 100) },
      { label: "Transacción", value: vadsUuid },
      { label: "Referencia de pago", value: vadsOrder },
      { label: "Usuario", value: vadsName },
      { label: "Email", value: vadsEmail },
      { label: "Celular", value: vadsPhone },
      { label: "Método de pago", value: "Payzen" }
    ];
    const eventData: TransactionEventData = {
      gtw,
      date,
      ebStatus,
      name: vadsName,
      email: vadsEmail,
      phone: vadsPhone,
      status: vadsStatus,
      amount: +vadsAmount / 100,
    };

    const transaction: Transaction = {
      id: vadsOrder,
      approved,
      paymentData,
      eventData
    };
    return transaction;
  } else if (isWompi(gtw)) {
    const id = urlParams.get("id") || "";

    if (!id) return null;

    let wompiResponse: WompiResponse;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WOMPI_API_URL}/transactions/${id}`
      );
      if (!response.ok) return null;
      wompiResponse = await response.json();
    } catch {
      return null;
    }

    const {
      amount_in_cents,
      reference,
      status,
      status_message
    } = wompiResponse.data;

    const ebStatus = getStatus(gtw, status);
    const approved = ebStatus === "APROBADA";
    const paymentData: TransactionPaymentData[] = [
      { label: "Estado", value: ebStatus },
      { label: "Monto", value: formatMoney(amount_in_cents / 100) },
      { label: "Transacción", value: id },
      { label: "Referencia de pago", value: reference },
      { label: "Descripción", value: status_message || "" },
      { label: "Método de pago", value: "Wompi" }
    ];
    const eventData: TransactionEventData = {
      gtw,
      date,
      status,
      ebStatus,
      amount: amount_in_cents / 100,
    };

    const transaction: Transaction = { id, approved, paymentData, eventData };
    return transaction;
  }
  return null;
};
