import * as fetchMock from 'fetch-mock';
import { url } from '../services/validateCodeWaPost';
import ResponseCreator from './helpers/responseCreator';
import withDelay from './helpers/withDelay';

fetchMock.post(`end:${process.env.REACT_APP_API_PATH}/${url}`, () => {
  return withDelay(
    new ResponseCreator().setPayload({ token: 'xxx' }).getResponse()
  );
});
