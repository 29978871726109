import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Legend, legends } from "../../../../helpers/behavior";
import { formatDate } from "../../../../helpers/date";
import { ReportSummaryState } from "../../../../store/reportSummary/types";
import { AppState } from "../../../../store/store";
import styles from "./Behavior.module.scss";

export interface Props {
  data: string;
  reportSummary: ReportSummaryState;
}

const Behavior = ({ data, reportSummary }: Props) => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [dataArranged, setDataArranged] = useState<string[][]>([]);
  const [filteredLegend, setFilteredLegend] = useState<Legend[]>([]);

  useEffect(() => {
    if (reportSummary.status === "loaded" || reportSummary.status === "updating") {
      const report = reportSummary.payload;
      const dateFormat = formatDate(report.date, { in: "DD/MM/YYYY HH:mm:ss", out: "YYYY-MM-DD" });
      const reportDate = new Date(dateFormat);
      setYear(reportDate.getFullYear());
      let months = reportDate.getMonth();
      const dataArray = data.includes("|")
        ? data
          .replace(/\|$/, "")
          .split("|")
          .map(item => item.trim())
        : data.split("").map(item => item.trim());
      const dataArrayMutable = dataArray.slice();
      let provisionalData: string[][] = [];
      while (dataArrayMutable.length) {
        if (dataArrayMutable.length > months) {
          const spliceCount = dataArrayMutable.length - (months);
          const appendData = dataArrayMutable.splice(spliceCount);
          provisionalData = [...provisionalData, appendData];
          months = 12;
        } else {
          const fillArray = new Array(months - dataArrayMutable.length).fill("");
          const appendData = [...fillArray, ...dataArrayMutable.splice(0)];
          provisionalData = [...provisionalData, appendData]
        }
      }
      setDataArranged(provisionalData.reverse());
      // Legend
      const uniqueKeys = dataArray.filter((value, index, array) => array.indexOf(value) === index);
      setFilteredLegend(legends.filter(item => uniqueKeys.find(legendItem => legendItem === item.value)));
    }
  }, [reportSummary]);

  return (
    <div>
      <div className={styles.boxes}>
        {dataArranged.map((yearBox, yearIndex) => (
          <div
            key={yearIndex}
            className={styles.yearBox}
          >
            <div className={styles.yearTitle}>
              {year - (dataArranged.length - yearIndex) + 1}
            </div>
            <div className={styles.months}>
              {yearBox.map((month, monthIndex) => (
                <div
                  key={monthIndex}
                  className={`${styles.box} ${styles[`tag-${month}`]}`}
                >
                  {month}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.legend}>
        {filteredLegend.map(item => (
          <div
            key={item.value}
            className={styles.grid}
          >
            <div className={`${styles.box} ${styles[`tag-${item.value}`]}`}>
              {item.value}
            </div>
            <p className={styles.textLegend}>
              {item.label}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  reportSummary: state.reportSummary,
});

export default connect(mapStateToProps)(Behavior);