// Tabla 15 - Tipo entidad - TIPOENT
// TransUnion - Tablas de convenciones - Información Comercial - Versión 1.34

const tipoEntidad = {
  AADA: 'Actividades de servicio administrativos y de apoyo',
  AAME: 'Alquiler y arrendamiento de otros tipos de maquinaria, equipo',
  ACUL: 'Actividades de esparcimiento, culturales y deportivas',
  AEDI: 'Actividades de edición e impresión y de reproducción de gr',
  AFIS: 'Actividades financieras y de seguros',
  AGDE: 'Alm. General de deposito',
  AGEN: 'Act comple y aux al trasporte act de agencia de viajes',
  AGRI: 'Agricultura, ganaderia, caza, silvicultura y pesca',
  AGSE: 'Agencias de seguros',
  AINF: 'Actividades auxiliares y de intermediación financieras',
  AINM: 'Actividades de alquiler, empresariales y inmobiliarias',
  AMAQ: 'Alquiler de maquinaria y equipo sin operarios y de efectos p',
  AMUT: 'Asociaciones mutualistas',
  ANCP: 'Actividades de asociaciones NCP',
  APCT: 'Actividades profesionales científicas y técnicas',
  APUB: 'Administración pública y defensa, planes de seguridad',
  ASCS: 'Actividades de servicios comunitarios sociales y perso',
  ASDA: 'Fabricación de maquinaria y aparatos eléctricos NCP',
  ASEG: 'Asesores de seguros',
  ASEI: 'Actividades de servicios de información',
  ASIP: 'Actividades de seguridad e investigación privada',
  AVOT: 'Actividades de las agencias de viajes operadores turísticos',
  BCH: 'Banco central hipotecario',
  BCO: 'Banco',
  BREP: 'Banco de la republica',
  CAPC: 'Cooperativas de aportes y créditos',
  CAVI: 'Corp. De ahorro y vivienda',
  CCAM: 'Casas de cambio',
  CCEL: 'Compañías de telefonía celular',
  CCIO: 'Cámaras de comercio',
  CCMP: 'Caja de compensación',
  CEAC: 'Cooperativas especializadas de ahorro y crédito',
  CEDA: 'Cooperativas especializadas diferentes de ahorro y crédito',
  CFRP: 'Coquización fabri de produc de la ref del petróleo',
  CIFI: 'Cifin buro de crédito',
  CMAC:
    'Compañías de leasing CLEA Cooperativas multiactivas o integrales con sección de ahorro',
  CMSA: 'Cooperativas multiactivas o integrales sin sección de ahorro',
  COMU: 'Compañías de financiamiento COMF Comunicaciones',
  CONS: 'Construcción',
  COOF: 'Convenios CONV Cooperativa financiera',
  COOP: 'Cooperativas',
  CREA: 'Comercio al por mayor y al por menor CPMM Compañía reaseguradora',
  CRFI: 'Corporación financiera',
  CRSE: 'Corredor de seguros',
  CSEG: 'Cia. De seguros generales',
  CSEV: 'Cia. De seguros de vida',
  CTRA: 'Cooperativas de trabajo asociado',
  DTAR: 'Distribución de agua evacuación y tratamiento de aguas resid',
  EARS: 'Entid adm. Régimen sol prima media',
  ELEC: 'Agua, gas y electricidad',
  ENSE: 'Enseñanza',
  EPRB: 'Entidades prueba',
  ESTA: 'Entidad estatal',
  EXMI: 'Explotación de minas y canteras',
  FABR: 'Fabricación de vehículos automotores remolques y semirremolque',
  FBMS: 'Fabricación de muebles colchones y somieres',
  FCAU: 'Fabricación de productos de caucho y de plástico',
  FCUE: 'Fabricación de artículos de cuero, de talabartería y guarne',
  FEMP: 'Fondos de empleados',
  FIDU: 'Fiduciarias',
  FMAQ: 'Fabricación de maquinaria y equipo',
  FMEM: 'Fabricación de productos elaborados de metal excepto maquina',
  FMET: 'Fabricación de productos metalúrgicos básicos',
  FMIV: 'Fondos mutuos de inversión vigilados',
  FOMI: 'Fabricación de otros productos minerales no metalicos',
  FOTR: 'Fabricación de otros tipos de equipos de transporte',
  FPAP: 'Fabricación de papel, cartón y productos derivados',
  FPCE: 'Fondo de pensiones y cesantías',
  FQUI: 'Fabricación de sustancias y productos químicos',
  FTEX: 'Fabricación de productos textiles',
  FVEH: 'Fabricación de vehículos automotores, remolques y semirremolque',
  GOBE:
    'Fabricación de prendas de vestir, preparado y teñido de piel FVES Gobernaciones',

  GREM: 'Gremios',
  IACO:
    'Hoteles y restaurantes HORE Instituciones auxiliares del cooperativismo',
  INFO: 'Informática y actividades conexas',
  MANU: 'Institución oficial especi IOFE Industria manufacturera',
  MUNI: 'Municipios',
  OACS: 'Otras actividades empresariales OACE Otras actividades de servicio',
  OCGS: 'Org.coop.de grado superior',
  OEXT: 'Org. Financiero del exterior',
  OSEG: 'Organismo de segundo grado',
  OTEG: 'Organismo de tercer grado',
  OTRO: 'Otros',
  PRUE: 'Entidades prueba',
  SCAP: 'Sociedades de capitalización',
  SCON: 'Servicios de consultoría',
  SCOO: 'Sociedades cooperativas',
  SPUB: 'Servicios públicos',
  SSAL: 'Servicios sociales y de salud',
  TRAN: 'Transporte y almacenamiento'
};

export default tipoEntidad;
