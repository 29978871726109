import { ServiceReturn } from '../types/ServiceReturn';
import api from './api';

export interface BasicInfoParams {
  gender: 'M' | 'F' | '';
  birthDate: string;
  stateId: string;
  cityId: string;
  dependents: '1' | '2-3' | '3-' | '';
  houseType: 'own' | 'rent' | 'family' | '';
  activityType:
    | 'employee'
    | 'independent'
    | 'microentrepreneur'
    | 'pensioner'
    | 'housewife'
    | 'student'
    | 'looking-job'
    | 'other'
    | '';
  employer?: string;
  job?: string;
  company?: string;
  monthlyIncome: '0-1' | '1-2' | '2-4' | '4-' | '';
  socioeconomicLevel: string;
}

export const url = 'basic-info';

const basicInfoGet = async (): Promise<ServiceReturn<BasicInfoParams>> => {
  return await api.get(url, true);
};

export default basicInfoGet;
