import api from './api';

export interface Params {
  videoId?: string;
  error?: string;
}

export const url = 'preferences/account/eid';

const preferencesAccountEIDPost = async (data: Params): Promise<Response> => {
  return await api.post(url, true, data);
};

export default preferencesAccountEIDPost;
