import api from "./api";

export interface PaymentGateway {
  code: string;
  name: string;
  urlImage: string;
  paymentMethod: string;
}

export interface PaymentsGatewayResponse {
  paymentsGateway: PaymentGateway[];
}

export const url = "plans/transaction/payment-gateway";

const PaymentsGatewayGet = async (): Promise<PaymentsGatewayResponse> => {
  return await api.get(`${url}`, true);
};

export default PaymentsGatewayGet;
