import React from 'react';
import {
  AsyncSetState,
  cancelAsyncSetState,
  createAsyncSetState,
  PromiseCancelError
} from '../helpers/asyncSetState';
import Error from './UI/Error';
import Loading from './UI/Loading';

export interface Props<T, E> {
  initialize?: () => Promise<T>;
  complete?: (result: T) => React.ReactNode;
  error: (error: E) => React.ReactNode;
  children?: React.ReactNode;
}

enum Status {
  Success,
  Error,
  Loading
}

interface State<T, E> {
  status: Status;
  result: T;
  error: E;
}

class OnMounted<T = void, E = void> extends React.Component<
  Props<T, E>,
  State<T, E>
> {
  static defaultProps = {
    error: (message: string) => <Error>{message}</Error>
  };

  state: State<T, E> = {
    status: Status.Loading,
    result: undefined as any,
    error: undefined as any
  };

  asyncSetState?: AsyncSetState<State<T, E>>;

  async componentDidMount() {
    this.asyncSetState = createAsyncSetState(this);

    try {
      if (this.props.initialize) {
        const result = await this.props.initialize();
        await this.asyncSetState({ status: Status.Success, result });
      } else {
        await this.asyncSetState({ status: Status.Success });
      }
    } catch (error) {
      if (!(error instanceof PromiseCancelError)) {
        await this.asyncSetState({ error });
      }
    }
  }

  componentWillUnmount() {
    cancelAsyncSetState(this.asyncSetState);
  }

  render() {
    const { status, result, error } = this.state;

    return (
      <>
        {status === Status.Success &&
          (this.props.children || this.props.complete!(result))}
        {status === Status.Error && this.props.error(error)}
        {status === Status.Loading && <Loading />}
      </>
    );
  }
}

export default OnMounted;
