import Answer from '../types/Answer';
import api from './api';

export interface SecurityAnswerParams extends Answer {}

export interface SecurityAnswerResult {
  verified: boolean;
  waitTime?: number;
}

export const url = 'security-answer';

const securityAnswerPost = async (
  postData: SecurityAnswerParams
): Promise<SecurityAnswerResult> => {
  const result = await api.post(url, true, postData);
  return Promise.resolve(result);
};

export default securityAnswerPost;
