import React, { useState } from "react";
import { connect } from "react-redux";
import { Tooltip } from "reactstrap";
import styled from "styled-components";
import Card from "../../../components/UI/Card";
import Icon from "../../../components/UI/Icon";
import copyToClipboard from "../../../helpers/copyToClipboard";
import slugify from "../../../helpers/slugify";
import { track } from "../../../helpers/track";
import { CreditResponse } from "../../../services/creditsGet";
import creditsIdGet from "../../../services/creditsIdGet";
import { sendMessage } from "../../../store/message/actions";
import store, { AppState } from "../../../store/store";
import { UserState } from "../../../store/user/types";
import styles from "./ProductItem.module.scss";

interface Props {
  data: CreditResponse;
  user: UserState;
  userId: string;
}

const ModalContainer = styled.div`
  .modal-contenido {
    background-color: #ffff;
    width: 380px;
    padding: 10px 20px;
    margin: 20% auto;
    position: relative;
    text-align: Center;
    border-radius: 9px;
  }
  .merchant-modal {
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    margin-left: -1rem;
    pointer-events: none;
    transition: all 1s;
    .item-end {
      margin-left: 100%;
    }
  }
  #information:target {
    opacity: 1;
    pointer-events: auto;
  }

  .color {
    color: #fffff;
  }
`;
const ProductItem = ({ data, userId, user }: Props) => {
  const [moreInfo, setMoreInfo] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const {
    merchantName,
    // offerName,
    offerId,
    productName,
    productType,
    subProductType,
    description,
    imageUrl,
    requestLink,
    minRequirements,
    feeType,
    associatedCosts,
    getUserInfo
  } = data;

  // const utm = {
  //   utm_source: 'enbanca',
  //   utm_medium: 'app',
  //   utm_campaign: slugify(offerName)
  // };
  // const requestLinkUTM = `${requestLink}?${qs.stringify(utm)}`;

  const requestLinkUTM = requestLink;

  const hasMoreInfo = !!minRequirements || !!feeType || !!associatedCosts;

  const handleClick = () => {
    creditsIdGet({ offerId });
    const properties = {
      productName,
      merchantName,
      productType,
      subProductType,
      description,
      imageUrl: `${process.env.REACT_APP_PRODUCT_IMAGES}/${imageUrl}`,
      requestLink: requestLink || ""
    };
    track({ userId, event: "VIEW_MERCHANT_OFFER", properties });
  };

  const productTypeName =
    productType.charAt(0).toUpperCase() + productType.slice(1);
  const subProductTypeName =
    subProductType.charAt(0).toUpperCase() +
    subProductType.slice(1).replace("_", " ");
  const feeTypeName =
    feeType.charAt(0).toUpperCase() + feeType.slice(1).replace("_", " ");

  return (
    <Card
      className={styles.creditItemCard}
      id={slugify(`${productName}-${merchantName}`)}
    >
      <ModalContainer>
        <div className={styles.creditItem}>
          <div className={styles.summary}>
            <div>
              <div className={styles.head}>
                <h3 className={styles.title}>{productName}</h3>
                <h4 className={styles.subtitle}>{merchantName}</h4>
              </div>
              <p className={styles.group}>
                {productTypeName} - {subProductTypeName}
              </p>
            </div>
            <img
              className={styles.image}
              src={`${process.env.REACT_APP_PRODUCT_IMAGES}/${imageUrl}`}
              alt=""
            />
          </div>

          <div className={styles.description}>{description}</div>

          <div className={styles.actions}>
            {window.location.pathname.includes("productos") && (
              <>
                <input
                  id={`url-${slugify(`${productName}-${merchantName}`)}`}
                  type="text"
                  className={styles.urlInput}
                  value={`${window.location.href.split("?")[0]}?${slugify(
                    `${productName}-${merchantName}`
                  )}`}
                  readOnly
                />
                <div
                  className={styles.clipboard}
                  id={`clipboard-${slugify(`${productName}-${merchantName}`)}`}
                  onClick={() => {
                    const copied = copyToClipboard(document.querySelector(
                      `#url-${slugify(`${productName}-${merchantName}`)}`
                    ) as HTMLInputElement);
                    if (copied) {
                      store.dispatch(
                        sendMessage(
                          "Enlace copiado al portapapeles",
                          "success",
                          {
                            time: 2000
                          }
                        )
                      );
                    } else {
                      store.dispatch(
                        sendMessage(
                          "Ocurrio un error copiando el enlace",
                          "error",
                          {
                            time: 2000
                          }
                        )
                      );
                    }
                  }}
                >
                  <Icon icon="content_copy" />
                </div>
                <Tooltip
                  placement="right"
                  isOpen={tooltipOpen}
                  target={`clipboard-${slugify(
                    `${productName}-${merchantName}`
                  )}`}
                  toggle={toggle}
                >
                  Copiar enlace
                </Tooltip>
              </>
            )}
            {hasMoreInfo && (
              <span
                className={`${styles.moreInfoButton} ${moreInfo ? styles.open : ""
                  }`}
                onClick={() => setMoreInfo(prevMoreInfo => !prevMoreInfo)}
              >
                Más información
              </span>
            )}
            {(requestLink &&
              (getUserInfo === true && (
                <>
                  <a className="btn btn-primary" href="#information">
                    {" "}
                    Solicitar{" "}
                  </a>
                  <div id="information" className="merchant-modal">
                    <div className="modal-contenido">
                      <a className="item-end" href="#">
                        x
                      </a>

                      <p>
                        Estoy de acuerdo en ser contactado por la entidad{" "}
                        <b>{merchantName} </b>para continuar con la solicitud
                        del crédito.
                      </p>
                      <a
                        href="#"
                        onClick={handleClick}
                        className="color btn btn-primary"
                      >
                        Aceptar
                      </a>
                    </div>
                  </div>
                </>
              ))) || (
                <a
                  href={requestLinkUTM}
                  target="_blank"
                  className="btn btn-primary"
                  onClick={handleClick}
                >
                  Solicitar
                </a>
              )}
          </div>
          {moreInfo && (
            <div className={styles.moreInfo}>
              {!!minRequirements && (
                <div className={styles.minimumRequirements}>
                  <h5>Requisitos mínimos</h5>
                  <div className={styles.minimumRequirementsText}>
                    {minRequirements}
                  </div>
                </div>
              )}
              {!!associatedCosts && (
                <div className={styles.associatedCosts}>
                  <h5>Costos asociados</h5>
                  <div className={styles.associatedCostsText}>
                    {associatedCosts}
                  </div>
                </div>
              )}
              {!!feeTypeName && (
                <div className={styles.feeType}>
                  <h5>Tipo de cuota</h5>
                  <div className={styles.feeTypeText}>{feeTypeName}</div>
                </div>
              )}
            </div>
          )}
        </div>
      </ModalContainer>
    </Card>
  );
};

const mapStateToProps = (state: AppState) => ({
  userId: state.security.userId,
  user: state.user
});

export default connect(mapStateToProps)(ProductItem);
