// Tabla 10 - Modo de extinción - MODO EXT
// TransUnion - Tablas de convenciones - Información Comercial - Versión 1.34

const modoExtincion = {
  COM: 'Compensación',
  CON: 'Confusión',
  DAN: 'Dación en pago',
  CES: 'Dada en cesión',
  DME: 'Devolución de mercancía',
  MAN: 'Mandatorio de pago',
  NOV: 'Novación',
  PRE: 'Prescripción',
  PAC: 'Prescripción de la acción cambiaria',
  PAE: 'Prescripción de la acción ejecutiva',
  PAO: 'Prescripción de la acción ordinaria',
  EJE: 'Proceso ejecutivo',
  PEE: 'Proceso ejecutivo con excepción',
  REM: 'Remisión',
  '': 'Sin modo de extinción',
  TRN: 'Transacción',
  VOL: 'Voluntario',
  REF: 'Refinanciación'
};

export default modoExtincion;
