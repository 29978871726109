import React from "react";
import styles from "./Meter.module.scss";
interface Props {
  score: number;
  label?: string;
}

const ProMeter = ({ score, label }: Props) => {
  let gradientColor1 = "";
  let gradientColor2 = "";
  let barColor = "";
  const radius = 60;
  if (score < 510) {
    gradientColor1 = "#fd4444";
    gradientColor2 = "#FFCCCC";
    if (score >= 480) {
      barColor = "#fd4444";
    } else {
      barColor = "#FF6666";
    }
  } else if (score < 730) {
    gradientColor1 = "#fcc226";
    gradientColor2 = "#FFE6B3";
    barColor = gradientColor1;
  } else if (score < 770) {
    gradientColor1 = "#3B80C5";
    gradientColor2 = "#C8DBF9";
    barColor = gradientColor1;
  } else {
    gradientColor1 = "#14B8A6";
    gradientColor2 = "#A1ECE2";
    barColor = gradientColor1;
  }
  const strokeWidth = radius * 0.1;
  const innerRadius2 = radius - radius * 0.1;
  const innerRadius = radius - strokeWidth / 1;
  const circumference = innerRadius * 2 * Math.PI;
  const arc = circumference * (270 / 360);
  const dashArray = `${arc} ${circumference}`;
  const transform = `rotate(135, ${radius}, ${radius})`;
  const percentNormalized = Math.min(Math.max(score, 0), 1000);
  const offset = arc - (percentNormalized / 1000) * arc;
  const angle = (((120 - offset + 270) * 1.03) % 360) * (Math.PI / 180);
  const centerX = radius + innerRadius2 * Math.cos(angle);
  const centerY = radius + innerRadius2 * Math.sin(angle);
  const colorRect = "#ffffff";
  const gradientId = "barGradient";

  return (
    <div className={styles.content__meter}>
      <div className={styles.meter}>
        <div className={styles.card}>
          <svg height={radius * 2} width={radius * 2} className="svg1">
            <defs>
              <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" stopColor={gradientColor1} />
                <stop offset="100%" stopColor={gradientColor2} />
              </linearGradient>
            </defs>
            <circle
              cx={radius}
              cy={radius}
              fill="transparent"
              r={innerRadius}
              stroke="#D9D9D9"
              strokeWidth={strokeWidth}
              strokeDasharray={dashArray}
              transform={transform}
            ></circle>
            <circle
              cx={radius}
              cy={radius}
              fill="transparent"
              r={innerRadius}
              stroke={`url(#${gradientId})`}
              strokeDasharray={dashArray}
              strokeDashoffset={offset}
              strokeWidth={strokeWidth}
              style={{
                transition: "stroke-dashoffset 0.1s",
              }}
              transform={transform}
            ></circle>
            <rect
              x={radius - strokeWidth + 7}
              y={radius - innerRadius - 11}
              width={3}
              height={25}
              fill={colorRect}
              transform={`rotate(${3}, ${radius}, ${radius})`}
            ></rect>
            <rect
              x={radius - strokeWidth + 2}
              y={radius - innerRadius - 12}
              width={3}
              height={25}
              fill={colorRect}
              transform={`rotate(${40}, ${radius}, ${radius})`}
            ></rect>
            <rect
              x={radius - strokeWidth + 3}
              y={radius - innerRadius - 11}
              width={3}
              height={25}
              fill={colorRect}
              transform={`rotate(${65}, ${radius}, ${radius})`}
            ></rect>
            <rect
              x={radius - strokeWidth + 5}
              y={radius - innerRadius - 11}
              width={3}
              height={25}
              fill={colorRect}
              transform={`rotate(${75}, ${radius}, ${radius})`}
            ></rect>
            <circle
              cx={centerX}
              cy={centerY}
              r={strokeWidth}
              fill={barColor}
              style={{
                transition: "stroke-dashoffset 0.3s",
              }}
            ></circle>
            <circle
              cx={centerX}
              cy={centerY}
              r={strokeWidth / 2.2}
              fill="white"
              style={{
                transition: "stroke-dashoffset 0.3s",
              }}
            ></circle>
            <text
              x={radius}
              y={radius}
              textAnchor="middle"
              alignmentBaseline="middle"
              // fill="#333A6D"
              // fill={barColor}
              className={styles.label}
            >
              {typeof label !== "undefined" ? label : score}
            </text>
          </svg>
          <svg height={radius * 2.2} width={radius * 2.2} className={styles.svg2}>
            <text
              x={radius - strokeWidth + 9}
              y={radius - innerRadius + 2}
              textAnchor="middle"
              alignmentBaseline="middle"
              className={styles.ranges}
              transform={`rotate(${3}, ${radius}, ${radius})`}
            >
              520
            </text>
            <text
              x={radius - strokeWidth + 11}
              y={radius - innerRadius}
              textAnchor="middle"
              alignmentBaseline="middle"
              className={styles.ranges}
              transform={`rotate(${35}, ${radius}, ${radius})`}
            >
              630
            </text>
            <text
              x={radius - strokeWidth + 14}
              y={radius - innerRadius - 3}
              textAnchor="middle"
              alignmentBaseline="middle"
              className={styles.ranges}
              transform={`rotate(${60}, ${radius}, ${radius})`}
            >
              740
            </text>
            <text
              x={radius - strokeWidth + 14}
              y={radius - innerRadius - 7}
              textAnchor="middle"
              alignmentBaseline="middle"
              className={styles.ranges}
              transform={`rotate(${80}, ${radius}, ${radius})`}
            >
              780
            </text>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default ProMeter;
