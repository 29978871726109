import React, { useState } from "react";
import Icon from "../../../components/UI/Icon";
import ProMeter from "../../../components/UI/ProMeter";
import { capitalize } from "../../../helpers/capitalize";
import { profileLabelByScore, scoreApproximate } from "../../../helpers/scoreLevels";
import { Report as ReportType } from "../../../services/useReportsSharedIdGet";
import styles from "./Report.module.scss";

interface Props {
  report: ReportType;
  collapsible?: boolean;
}

const Report = ({ report, collapsible }: Props) => {
  const [open, setOpen] = useState(!collapsible);
  const score = scoreApproximate(report.scoreRating) || 0;
  const data = [
    { label: "Documento de identidad:", value: report.document },
    { label: "Estado del documento de identidad:", value: report.documentStatus },
    { label: "Apellidos:", value: report.lastname },
    { label: "Nombres:", value: report.name },
    { label: "Email:", value: report.email },
    { label: "ID:", value: report.validated ? "Validado" : "No validado" },
    { label: "Ciudad:", value: report.city },
    {
      label: "Productos financieros activos:",
      value: `${report.financialProducts} (activos y pasivos)`
    },
  ];
  return (
    <div className={styles.container}>
      <div className={styles.score}>
        <ProMeter score={score} label="" />
        <p className={styles.rating}>
          Perfil de crédito: {capitalize(profileLabelByScore(score))}
        </p>
      </div>
      <div className="d-md-none my-3 w-100">
        {collapsible
          ? (
            <button
              className={`btn btn-outline-secondary ${styles.button}`}
              onClick={() => setOpen(!open)}
            >
              Ver información personal
              <Icon icon={open ? "expand_less" : "expand_more"} />
            </button>
          )
          : <hr className={styles.hr} />
        }
      </div>
      <div className={`${styles.data} ${open ? styles.active : ""}`}>
        {data.map(({ label, value }) => (
          <div
            key={label}
            className={styles.element}
          >
            <p className={styles.label}>
              {label}
            </p>
            <p className={styles.value}>
              {value}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Report;