import React from "react";
import { getSegments } from "../../../../helpers/segments";
import ProductsType from "../../../../types/report/Products";
import Product from "./Product";
import styles from "./Products.module.scss";

interface Props {
  products: ProductsType;
}

const Products = ({ products }: Props) => {
  const segments = getSegments(products);

  if (segments.length) {
    return (
      <>
        {segments.map(({ key, label, upToDate, overdue }) => (
          <div
            key={key}
            className={styles.container}
          >
            <div className={styles.header}>
              <p className={styles.title}>
                {label}
              </p>
            </div>
            {(upToDate || []).map((product, i) => <Product key={i} product={product} closed={key === "closed"} />)}
            {(overdue || []).map((product, i) => <Product key={i} product={product} overdue />)}
          </div>
        ))}
      </>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p className={styles.title}>
          Productos
        </p>
      </div>
      <div className={styles.grid}>
        <p className={styles.label}>
          No tienes productos
        </p>
        <p className={styles.description}>
          En este momento no tienes productos para mostrar
        </p>
      </div>
    </div>
  )
}

export default Products