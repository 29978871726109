// Tabla 08 - Tipo de garantía - TIPOGAR
// TransUnion - Tablas de convenciones - Información Comercial - Versión 1.34

const tipoGarantia = {
  FGCE: 'Fondo de Garantías Comunitarias Ezquina',
  FAG: 'FAG (Fondo agropecuario de garantías)',
  IDON: 'Idónea',
  CART: 'Idónea Cartas De Crédito',
  FMER: 'Idónea Contrato Irrevocable De Fiducia Mercantil De Garantía,',
  HIPO: 'Idónea Contratos de hipoteca',
  PREN: 'Idónea Contratos de Prenda',
  FONA: 'Idónea Garantía Otorgadas Por El Fondo Nacional De Garantía S.A.',
  NACI: 'Idónea Garantía Soberana De La Nación (Ley 617 De 2000)',
  IOTR: 'Idónea Otras Garantías',
  TERR: 'Idónea Pignoración De Rentas De Entidades Territoriales Y',
  LEAS: 'Leasing',
  NOID: 'No Idónea',
  OTRA: 'Otra',
  PERS: 'Personal',
  PLIB: 'Personal con Libranza',
  PSLI: 'Personal sin Libranza',
  RLIB: 'Real con Libranza',
  RSLI: 'Real sin Libranza',
  '': 'Sin Garantía'
};

export default tipoGarantia;
