import dayjs from "dayjs";
import { date } from "yup";

export const maxDate = new Date();

export const over18 = (() => {
  const date = new Date();
  date.setFullYear(maxDate.getFullYear() - 18);
  return date;
})();

export const under90 = (() => {
  const date = new Date();
  date.setFullYear(maxDate.getFullYear() - 90);
  return date;
})();

export const dateSchema = date()
  .nullable()
  .transform((value, originalValue) => {
    const transformDate = dayjs(originalValue, "DD/MM/YYYY");
    if (transformDate.isValid()) {
      const date = transformDate.toDate();
      if (date <= maxDate) return date;
    }
    return null
  })
  .required("Ingrese una fecha válida")
  .min(under90, "Debes tener menos de 90 años")
  .max(over18, "Debes ser mayor de edad");
