import * as fetchMock from 'fetch-mock';
import { url } from '../services/reasonPost';
import store from './helpers/store';

fetchMock.post(`end:${process.env.REACT_APP_API_PATH}/${url}`, () => {
  store.creditReasonSet = true;
  store.roles = ['credit'];
  return {
    status: 200,
    body: {}
  };
});
