import api from "./api";

export interface Offer {
  productName: string;
  offerId: string;
  merchantName: string;
  productType: string;
  subProductType: string;
  description: string;
  imageUrl: string;
  requestLink: string;
  minRequirements: string;
  feeType: string;
  associatedCosts: string;
}

export const url = "offers/public/list/active";

const offersGet = async (): Promise<Offer[]> => {
  const response = await api.get(url, false, { type: "app" }, true);
  return response.payload;
};

export default offersGet;
