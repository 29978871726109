import { Field, FieldProps } from 'formik';
import React from 'react';
import ErrorMessages from '../UI/ErrorMessages';

const FormErrors = () => (
  <Field>
    {({ form }: FieldProps) => (
      <>
        {!!form.status && !!form.status.errors && (
          <ErrorMessages errorMessages={form.status.errors} />
        )}
      </>
    )}
  </Field>
);

export default FormErrors;
