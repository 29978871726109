import { useEffect, useState } from 'react';
import serviceBuilder from '../helpers/serviceBuilder';
import { ServiceStatus } from '../types/ServiceStatus';

export const url = 'validation/mobile';

const useValidationMobileGet = () => {
  const [service, setService] = useState<ServiceStatus>({
    status: 'init'
  });

  const { start, abort } = serviceBuilder('get', {
    url,
    set: setService
  });

  useEffect(() => {
    return () => {
      abort();
    };
  }, []);

  return {
    service,
    sendCode: start
  };
};

export default useValidationMobileGet;
