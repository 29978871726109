import { put, select, takeLeading } from "redux-saga/effects";
import reportsSummaryGet from "../../services/reportsSummaryGet";
import {
  setFetchInfoFailed,
  setFetchInfoSucceeded
} from "../reportSummary/actions";
import { ReportSummaryState } from "../reportSummary/types";
import { AppState } from "../store";

const getReportSummary = (store: AppState) => store.reportSummary;

function* fetchReportSummaryInfo() {
  try {
    const reportSummaryState: ReportSummaryState = yield select(
      getReportSummary
    );
    if (reportSummaryState.status !== "loaded") {
      const reportSummaryInfo = yield reportsSummaryGet();
      yield put(setFetchInfoSucceeded(reportSummaryInfo.payload));
    }
  } catch (error) {
    yield put(setFetchInfoFailed(error));
    return;
  }
}

export default function* watchFetchReportSummary() {
  yield takeLeading("reportSummary/FETCH_INFO", fetchReportSummaryInfo);
}
