import { PlanResponse } from "../../services/plansGet";
import { PlansAction } from "./types";

export const fetchPlans = (): PlansAction => ({
  type: "plans/FETCH_INFO"
});

export const setFetchPlansSucceeded = (
  plans: PlanResponse[]
): PlansAction => ({
  type: "plans/FETCH_INFO_SUCCEEDED",
  plans,
});

export const setFetchPlansFailed = (error: any): PlansAction => ({
  type: "plans/FETCH_INFO_FAILED",
  error
});
