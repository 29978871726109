import React from 'react';
import TemplateForm from './components/TemplateForm';
import ForgotPasswordContainer from './containers/ForgotPasswordContainer';

const ForgotPasswordView = () => {
  return (
    <TemplateForm title="Recuperar contraseña">
      <ForgotPasswordContainer />
    </TemplateForm>
  );
};

export default ForgotPasswordView;
