import React from 'react';
import TemplateForm from './components/TemplateForm';
import LogInContainer from './containers/LogInContainer';

const LogInView: React.FC<{}> = () => (
  <TemplateForm title="Iniciar sesión">
    <LogInContainer />
  </TemplateForm>
);

export default LogInView;
