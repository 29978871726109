import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps, withRouter } from "react-router";
import { PlanResponse } from "../../../services/plansGet";
import { fetchPaymentsGateway as fetchPaymentsGatewayAction } from "../../../store/paymentsGateway/actions";
import ModalSuccessCode from "../components/ModalSuccessCode";
import Payment from "../components/Payment";
import styles from "./PlanBuyContainer.module.scss";

export interface Props extends RouteComponentProps {
  fetchPaymentsGateway(): void;
}

const PlanBuyContainer = ({ fetchPaymentsGateway, location }: Props) => {
  const [teaser, setTeaser] = useState("");

  useEffect(() => {
    fetchPaymentsGateway();
  }, []);

  const plan = location.state && location.state.plan
    ? location.state.plan as PlanResponse
    : undefined

  if (!plan)
    return <Redirect to="/planes" />

  return (
    <>
      <p className={styles.title}>
        Confirmar compra
      </p>
      <Payment plan={plan} onSuccess={setTeaser} />
      {teaser && <ModalSuccessCode teaser={teaser} />}
    </>
  )
}

const mapDispatchToProps = {
  fetchPaymentsGateway: fetchPaymentsGatewayAction,
};

export default connect(null, mapDispatchToProps)(withRouter(PlanBuyContainer));
