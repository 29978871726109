import ThemeInterface from './themeDefinition';

const blue = {
  primary200: '#0f8fff',
  primary300: '#1d89f9',
  primary400: '#3452ff',
  primary500: '#0a56d1',
  primary600: '#4563c5',
  primary700: '#003388',
  primary800: '#32396c'
};

const theme: ThemeInterface = {
  ...blue,
  gray150: '#f3f6f8',
  gray250: '#e3e3e3',
  gray650: '#555555',

  blue: '#0a56d1',
  red: '#e94a40',
  orange: '#fd7e14',
  green: '#0de2b8',
  cyan: '#0f8fff'
};

export default theme;
