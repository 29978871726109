import { Field, FieldProps } from "formik";
import React from "react";
import OTPInput from "react-otp-input";
import { Button, FormGroup, FormText } from "reactstrap";
import Icon from "../../../components/UI/Icon";
import Modal from "../../../components/UI/Modal";
import styles from "./ModalOtp.module.scss";

interface Props {
  title: string;
  onClose: () => void;
  onConfirm: () => void;
}

const ModalOtp = ({ title, onClose, onConfirm }: Props) => {
  return (
    <Modal onToggle={onClose} centered>
      <div className={styles.box}>
        <div className={styles.banner}>
          <Icon
            icon="mail"
            className={styles.icon}
            fill
          />
        </div>
        <div className={styles.wrapper}>
          <p className={styles.title}>
            {title}
          </p>
          <Field name={"code"}>
            {({ form, field }: FieldProps) => (
              <>
                <FormGroup className="m-0">
                  <OTPInput
                    value={field.value}
                    onChange={(value) => form.setFieldValue("code", value)}
                    numInputs={6}
                    renderSeparator={<span className={styles.separatorCode}>-</span>}
                    renderInput={(props) => <input {...props} />}
                    containerStyle={styles.containerCode}
                    inputStyle={form.errors.code ? styles.inputErrorCode : styles.inputCode}
                    inputType="tel"
                  />
                  <div className={styles.invalidField}>
                    {form.errors.code}
                  </div>
                  <FormText className={styles.hint}>
                    Ingresa el código que te hemos enviado a tu WhatsApp
                  </FormText>
                </FormGroup>
                <Button
                  color="primary"
                  onClick={onConfirm}
                  className={styles.button}
                  disabled={!!form.errors.code || form.isSubmitting}
                >
                  {form.isSubmitting && <div className="spinner-border spinner-border-sm mr-2" role="status" />}
                  {form.isSubmitting ? 'Enviando' : 'Enviar'}
                </Button>
              </>
            )}
          </Field>
        </div>
      </div>
    </Modal>
  )
}

export default ModalOtp;
