import api from './api';

export interface IntentParams {
  intent: string;
}

export const url = 'intent';

const intentPost = async (postData: IntentParams): Promise<void> => {
  await api.post(url, true, { ...postData });
  return Promise.resolve();
};

export default intentPost;
