import { Form, Formik, FormikActions } from "formik";
import React from "react";
import * as yup from "yup";
import FormErrors from "../../../components/Form/FormErrors";
import FormSuccess from "../../../components/Form/FormSuccess";
import InputField from "../../../components/Form/InputField";
import SubmitButton from "../../../components/Form/SubmitButton";
import processSubmitErrors from "../../../helpers/processSubmitErrors";
import usersChangePasswordPost from "../../../services/usersChangePasswordPost";
import styles from "./PasswordForm.module.scss";

interface FormValues {
  oldPassword: string;
  newPassword: string;
  repeatNewPassword: string;
}

const PasswordForm = () => {
  const initialValues: FormValues = {
    oldPassword: "",
    newPassword: "",
    repeatNewPassword: ""
  };

  const validationSchema = yup.object<FormValues>({
    oldPassword: yup.string().required("La contraseña es requerida"),
    newPassword: yup
      .string()
      .required("La contraseña es requerida")
      .min(8, "La contraseña debe contener al menos ocho caracteres"),
    repeatNewPassword: yup
      .string()
      .required("La validación de la contraseña es requerida")
      .oneOf([yup.ref("newPassword")], "La contraseña no coincide")
  });

  const hanldeSubmt = async (
    values: FormValues,
    {
      setErrors,
      setStatus,
      setSubmitting,
      resetForm
    }: FormikActions<FormValues>
  ) => {
    try {
      const { oldPassword, newPassword } = values;
      await usersChangePasswordPost({ oldPassword, newPassword });
      resetForm();
      setStatus({ successMessage: "Tu contraseña ha sido modificada." });
    } catch (error) {
      processSubmitErrors(error, values, setErrors, setStatus);
    }
    setSubmitting(false);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={hanldeSubmt}
      >
        <Form className={styles.form}>
          <div className={styles.grid}>
            <InputField
              type="password"
              name="oldPassword"
              label="Contraseña actual"
              className={styles.field}
            />
            <div className={styles.field} />
          </div>
          <div className={styles.grid}>
            <InputField
              type="password"
              name="newPassword"
              label="Contraseña nueva"
              className={styles.field}
            />
            <InputField
              type="password"
              name="repeatNewPassword"
              label="Repetir contraseña"
              className={styles.field}
            />
          </div>
          <FormErrors />
          <FormSuccess />
          <div className={styles.container}>
            <SubmitButton
              text="Actualizar contraseña"
              className={styles.button}
            />
          </div>
        </Form>
      </Formik>
    </>
  );
}

export default PasswordForm;
