import { PaymentsGatewayResponse } from "../../services/paymentsGatewayGet";
import { PaymentsGatewayAction } from "./types";

export const fetchPaymentsGateway = (): PaymentsGatewayAction => ({
  type: "paymentsGateway/FETCH_INFO"
});

export const setFetchInfoSucceeded = (
  paymentsGateway: PaymentsGatewayResponse
): PaymentsGatewayAction => ({
  type: "paymentsGateway/FETCH_INFO_SUCCEEDED",
  paymentsGateway
});

export const setFetchInfoFailed = (error: any): PaymentsGatewayAction => ({
  type: "paymentsGateway/FETCH_INFO_FAILED",
  error
});
