import React from "react";
import Page from "../../components/Layout/Page";
import ProfilePreferencesContainer from "../Account/containers/ProfilePreferencesContainer";
import styles from "./BasicInfoView.module.scss";

const BasicInfoView = () => {
  return (
    <Page>
      <div className={styles.container}>
        <div className="eb-card">
          <p className={styles.title}>
            Información básica
          </p>
          <p className={styles.description}>
            Completa la siguiente información para ver tu perfil de crédito
          </p>
        </div>
        <ProfilePreferencesContainer />
      </div>
    </Page>
  );
}

export default BasicInfoView;
