import api from './api';

type NotificationType = 'email' | 'phone' | 'none';

export interface NotificationsData {
  alerts: NotificationType;
  advertising: NotificationType;
}

export const url = 'preferences/notifications';

const notificationPreferencesGet = async (): Promise<NotificationsData> => {
  return await api.get(url, true);
};

export default notificationPreferencesGet;
