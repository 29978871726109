import React from "react";
import Page from "../../components/Layout/Page";
import EmailValidationContainer from "./containers/EmailValidationContainer";

const EmailValidationView = () => (
  <Page>
    <div className="eb-card">
      <EmailValidationContainer />
    </div>
  </Page>
);

export default EmailValidationView;
