import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Form, Formik, FormikActions } from "formik";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import * as yup from "yup";
import ErrorFocus from "../../../components/Form/ErrorFocus";
import FieldVisible from "../../../components/Form/FieldVisible";
import FormatInputField from "../../../components/Form/FormatInputField";
import InputField from "../../../components/Form/InputField";
import SelectField from "../../../components/Form/SelectField";
import SubmitButton from "../../../components/Form/SubmitButton";
import { activityTypes, dependents, genders, houseTypes, monthlyIncomes, socioEconomicLevels } from "../../../helpers/basicInfo";
import { dateSchema } from "../../../helpers/dateSchema";
import locations from "../../../helpers/locations";
import processSubmitErrors from "../../../helpers/processSubmitErrors";
import refreshToken from "../../../helpers/refreshToken";
import { updateMetadata } from "../../../helpers/zipps";
import { BasicInfoParams } from "../../../services/basicInfoGet";
import basicInfoPost from "../../../services/basicInfoPost";
import styles from "./BasicInfoForm.module.scss";

// tslint:disable-next-line:no-var-requires
dayjs.extend(customParseFormat);

export interface FormValues extends BasicInfoParams { }

export interface Props extends RouteComponentProps {
  initialUser?: boolean;
  initialValues?: FormValues;
}

const initialFormValues: BasicInfoParams = {
  birthDate: "",
  gender: "",
  stateId: "",
  cityId: "",
  dependents: "",
  houseType: "",
  activityType: "",
  employer: "",
  job: "",
  company: "",
  monthlyIncome: "",
  socioeconomicLevel: ""
};

const BasicInfoForm = ({
  initialUser,
  initialValues = initialFormValues,
  history,
  location,
}: Props) => {

  const [cities, setCities] = useState<Array<{ label: string; value: string }>>([]);
  const states = locations.map(location => ({
    label: location.n,
    value: location.c
  }));
  const emptyOption = { label: "Seleccione un valor", value: "" };

  const setCitiesByState = (stateValue: string) => {
    const stateCode = Number(stateValue);
    const state = locations.find(location => location.c === stateCode);
    if (state) {
      const citiesOptions = state.m.map(city => ({
        label: city.n,
        value: city.c.toString()
      }));
      setCities(citiesOptions);
    }
  };

  const handleStateChange = (event: React.FormEvent<HTMLSelectElement>) => {
    setCitiesByState(event.currentTarget.value);
  };

  useEffect(() => {
    if (initialValues.stateId) {
      setCitiesByState(initialValues.stateId);
    }
  }, []);

  const validationSchema = yup.object({
    birthDate: dateSchema,
    gender: yup.string().required("El género es requerido"),
    stateId: yup.string().required("El departamento es requerido"),
    cityId: yup.string().required("La ciudad es requerida"),
    dependents: yup
      .string()
      .required("El número de personas a cargo es requerido"),
    houseType: yup.string().required("El tipo de vivienda es requerido"),
    activityType: yup
      .string()
      .required("La actividad económica es requerida"),
    employer: yup.string().when("activityType", {
      is: (val: string) => ["employee"].includes(val),
      then: yup.string().required("El nombre del empleador es requerido")
    }),
    job: yup.string().when("activityType", {
      is: (val: string) => ["independent", "other"].includes(val),
      then: yup.string().required("El nombre del oficio es requerido")
    }),
    company: yup.string().when("activityType", {
      is: (val: string) => ["microentrepreneur"].includes(val),
      then: yup.string().required("El nombre de la empresa es requerido")
    }),
    monthlyIncome: yup
      .string()
      .required("El nivel de ingresos es requerido"),
    socioeconomicLevel: yup.string().required("El estrato es requerido")
  });

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, setErrors, setStatus }: FormikActions<FormValues>
  ) => {
    try {
      setStatus({ success: false });
      await updateMetadata(values);
      await basicInfoPost(values);
      await refreshToken();
      if (location.pathname !== "/informacion-basica") {
        history.push("/dashboard");
      }
      setStatus({ success: true });
    } catch (error) {
      processSubmitErrors(error, values, setErrors, setStatus);
    }
    setSubmitting(false);
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form className={styles.form}>
          <div className="eb-card">
            <p className={styles.title}>
              Perfil
            </p>
            <div className={styles.grid}>
              <FormatInputField
                name="birthDate"
                label="Fecha de nacimiento"
                placeholder="DD/MM/AAAA"
                cleaveOptions={{ date: true, datePattern: ["d", "m", "Y"] }}
                className={styles.field}
              />
              <SelectField
                label="Género"
                name="gender"
                options={[emptyOption, ...genders]}
                className={styles.field}
              />

            </div>
          </div>
          <div className="eb-card">
            <p className={styles.title}>
              Ubicación de residencia
            </p>
            <div className={styles.grid}>
              <SelectField
                name="stateId"
                label="Departamento"
                options={[emptyOption, ...states]}
                onChange={handleStateChange}
                className={styles.field}
              />
              <SelectField
                name="cityId"
                label="Ciudad"
                options={[emptyOption, ...cities]}
                className={styles.field}
              />
            </div>
          </div>
          <div className="eb-card">
            <p className={styles.title}>
              Vivienda
            </p>
            <div className={styles.grid}>
              <SelectField
                name="houseType"
                label="Tipo de vivienda"
                options={[emptyOption, ...houseTypes]}
                className={styles.field}
              />
              <SelectField
                name="socioeconomicLevel"
                label="Estrato"
                options={[emptyOption, ...socioEconomicLevels]}
                className={styles.field}
              />
            </div>
          </div>
          <div className="eb-card">
            <p className={styles.title}>
              Familia
            </p>
            <div className={styles.grid}>
              <SelectField
                label="Personas a cargo"
                name="dependents"
                options={[emptyOption, ...dependents]}
                className={styles.field}
              />
            </div>
          </div>
          <div className="eb-card">
            <p className={styles.title}>
              Actividad económica
            </p>
            <div className={styles.grid}>
              <SelectField
                label="Tipo de actividad económica"
                name="activityType"
                options={[emptyOption, ...activityTypes]}
                className={styles.field}
              />

              <FieldVisible
                visible={(values: FormValues) =>
                  ["employee"].includes(values.activityType)
                }
              >
                <InputField
                  name="employer"
                  label="Empleador"
                  className={styles.field}
                />
              </FieldVisible>

              <FieldVisible
                visible={(values: FormValues) =>
                  ["independent", "other"].includes(values.activityType)
                }
              >
                <InputField
                  name="job"
                  label="Oficio"
                  className={styles.field}
                />
              </FieldVisible>

              <FieldVisible
                visible={(values: FormValues) =>
                  ["microentrepreneur"].includes(values.activityType)
                }
              >
                <InputField
                  name="company"
                  label="Nombre de la empresa"
                  className={styles.field}
                />
              </FieldVisible>
            </div>
          </div>
          <div className="eb-card d-flex flex-column">
            <p className={styles.title}>
              Situacion económica
            </p>
            <div className={styles.grid}>
              <SelectField
                label="Nivel de ingresos al mes"
                name="monthlyIncome"
                options={[emptyOption, ...monthlyIncomes]}
                className={styles.field}
              />
            </div>
            <div className={styles.container}>
              <SubmitButton
                id="basic-info"
                text="Actualizar información"
                disabledIfNotValid={initialUser}
                className={styles.button}
              />
            </div>
          </div>
          <ErrorFocus />
        </Form>
      </Formik>
    </>
  );
};

export default withRouter(BasicInfoForm);
