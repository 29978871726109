import React from "react";
import Icon from "../../../components/UI/Icon";
import { Report } from "../../../services/useReportsSentGet";
import styles from "./SentReport.module.scss";

interface Props extends Report {
  onDelete: (id: string) => void;
}

const SentReport = ({
  id,
  name,
  email,
  phone,
  viewed,
  message,
  sendMethod,
  onDelete,
}: Props) => {
  return (
    <div className="eb-card">
      <div className={styles.container}>
        <div className={styles.report}>
          <div className={`${styles.shape} ${viewed ? styles.viewed : ""}`}>
            <Icon icon={viewed ? "done_all" : "schedule"} />
          </div>
          <div className={styles.grid}>
            <p className={styles.name}>
              {name}&nbsp;
              <span>
                | {sendMethod === "phone" ? `+57 ${(phone || "").slice(2)}` : email}
              </span>
            </p>
            <p className={`${styles.description} ${viewed ? styles.viewed : ""}`}>
              {viewed ? "Vio tu reporte" : "No ha visto tu reporte"}
            </p>
            {message && (
              <div className={styles.content}>
                <Icon
                  icon="chat"
                  className={styles.icon}
                />
                <p className={styles.text}>
                  {message}
                </p>
              </div>
            )}
          </div>
        </div>
        <div
          className={styles.delete}
          onClick={() => onDelete(id)}
        >
          <Icon icon="delete" />
        </div>
      </div>
    </div>
  )
}

export default SentReport;