export interface Camera {
  haveCamera: boolean;
  allowCamera: boolean;
}

export default async (): Promise<Camera> => {
  const response: Camera = {
    haveCamera: false,
    allowCamera: false
  };
  if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
    return response;
  }
  const devices = await navigator.mediaDevices.enumerateDevices();
  response.haveCamera = !!devices.find(device => device.kind === 'videoinput');
  response.allowCamera = await navigator.mediaDevices
    .getUserMedia({ video: true })
    .then(mediaStream => {
      mediaStream.getTracks().forEach(track => track.stop());
      return true;
    })
    .catch(() => {
      return false;
    });
  return response;
};
