import React from 'react';
import IdleTimer from 'react-idle-timer';
import { RouteComponentProps, withRouter } from 'react-router';
import isLogged from '../helpers/isLogged';
import refreshToken from '../helpers/refreshToken';

interface Props extends RouteComponentProps {
  children: React.ReactNode;
}

const Security = ({ history, children }: Props) => {
  return (
    <IdleTimer
      // onIdle={() => {
      //   if (isLogged()) {
      //     history.push('/logout');
      //   }
      // }}
      // timeout={
      //   1000 *
      //   60 *
      //   parseInt(process.env.REACT_APP_LOGOUT_IF_IDLE_IN_MINUTES || '15', 10)
      // }
      onAction={() => {
        if (isLogged()) {
          try {
            refreshToken(true);
          } catch (error) {
            history.push('/logout');
          }
        }
      }}
      throttle={
        1000 *
        60 *
        parseInt(
          process.env.REACT_APP_AUTO_REFRESH_TOKEN_IN_MINUTES || '10',
          10
        )
      }
    >
      {children}
    </IdleTimer>
  );
};

export default withRouter(Security);
