import React from "react";
import Page from "../../components/Layout/Page";
import isLogged from "../../helpers/isLogged";
import PlansContainer from "./containers/PlansContainer";

const PlansView = () => {
  const userIsLogged = isLogged();
  return (
    <Page back={userIsLogged}>
      <PlansContainer />
    </Page>
  );
};

export default PlansView;
