import { CleaveOptions } from 'cleave.js/options';
import Cleave from 'cleave.js/react';
import { Field, FieldProps } from 'formik';
import React from 'react';
import { InputType } from 'reactstrap/lib/Input';
import FieldContainer from './FieldContainer';

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
  type?: InputType;
  visible?: boolean | ((values: {}) => boolean);
  description?: string;
  cleaveOptions: CleaveOptions;
  className?: string;
}

const FormatInputField = ({
  label,
  name,
  visible = true,
  type = 'text',
  description,
  cleaveOptions,
  className,
  ...restProps
}: Props) => {
  const renderField = ({ field, form }: FieldProps) => {
    const invalid = form.touched[field.name] && !!form.errors[field.name];
    const isVisible =
      visible instanceof Function ? visible(form.values) : visible;

    if (!isVisible) {
      return null;
    }

    return (
      <FieldContainer
        label={label}
        name={name}
        className={className}
        description={description}
      >
        <Cleave
          {...restProps}
          {...field}
          type={type}
          id={`field-${name}`}
          className={`form-control ${invalid ? 'is-invalid' : ''}`}
          options={cleaveOptions!}
        />
      </FieldContainer>
    );
  };

  return <Field name={name}>{(props: FieldProps) => renderField(props)}</Field>;
};

export default FormatInputField;
