import React from 'react';
import OnMounted from '../../../components/OnMounted';
import basicInfoGet, { BasicInfoParams } from '../../../services/basicInfoGet';
import store from '../../../store/store';
import { ServiceReturn } from '../../../types/ServiceReturn';
import BasicInfoForm from '../../Signup/components/BasicInfoForm';

const ProfilePreferencesContainer = () => {

  const initialize = async () => {
    const initialData: ServiceReturn<BasicInfoParams> = {
      payload: {
        birthDate: '',
        gender: '',
        stateId: '',
        cityId: '',
        dependents: '',
        houseType: '',
        activityType: '',
        employer: '',
        job: '',
        company: '',
        monthlyIncome: '',
        socioeconomicLevel: ''
      }
    };
    try {
      const data = await basicInfoGet();
      return store.getState().security.roles.includes('basic_info')
        ? data
        : initialData;
    } catch (e) {
      return initialData;
    }
  };

  return (
    <OnMounted
      initialize={initialize}
      complete={(result: ServiceReturn<BasicInfoParams>) => (
        <BasicInfoForm
          initialValues={result.payload}
          initialUser={!((result.payload || {}).birthDate)}
        />
      )}
    />
  );
};

export default ProfilePreferencesContainer;
