export default [
  {
    n: 'Amazonas',
    c: 91,
    m: [
      { n: 'El Encanto', c: 91263 },
      { n: 'La Chorrera', c: 91405 },
      { n: 'La Pedrera', c: 91407 },
      { n: 'La Victoria', c: 91430 },
      { n: 'Leticia', c: 91001 },
      { n: 'Miriti Paraná', c: 91460 },
      { n: 'Puerto Alegría', c: 91530 },
      { n: 'Puerto Arica', c: 91536 },
      { n: 'Puerto Nariño', c: 91540 },
      { n: 'Puerto Santander', c: 91669 },
      { n: 'Tarapacá', c: 91798 }
    ]
  },
  {
    n: 'Antioquia',
    c: 5,
    m: [
      { n: 'Abejorral', c: 5002 },
      { n: 'Abriaquí', c: 5004 },
      { n: 'Alejandría', c: 5021 },
      { n: 'Amagá', c: 5030 },
      { n: 'Amalfi', c: 5031 },
      { n: 'Andes', c: 5034 },
      { n: 'Angelópolis', c: 5036 },
      { n: 'Angostura', c: 5038 },
      { n: 'Anorí', c: 5040 },
      { n: 'Anza', c: 5044 },
      { n: 'Apartadó', c: 5045 },
      { n: 'Arboletes', c: 5051 },
      { n: 'Argelia', c: 5055 },
      { n: 'Armenia', c: 5059 },
      { n: 'Barbosa', c: 5079 },
      { n: 'Bello', c: 5088 },
      { n: 'Belmira', c: 5086 },
      { n: 'Betania', c: 5091 },
      { n: 'Betulia', c: 5093 },
      { n: 'Briceño', c: 5107 },
      { n: 'Buriticá', c: 5113 },
      { n: 'Caicedo', c: 5125 },
      { n: 'Caldas', c: 5129 },
      { n: 'Campamento', c: 5134 },
      { n: 'Caracolí', c: 5142 },
      { n: 'Caramanta', c: 5145 },
      { n: 'Carepa', c: 5147 },
      { n: 'Carolina', c: 5150 },
      { n: 'Caucasia', c: 5154 },
      { n: 'Cañasgordas', c: 5138 },
      { n: 'Chigorodó', c: 5172 },
      { n: 'Cisneros', c: 5190 },
      { n: 'Ciudad Bolívar', c: 5101 },
      { n: 'Cocorná', c: 5197 },
      { n: 'Concepción', c: 5206 },
      { n: 'Concordia', c: 5209 },
      { n: 'Copacabana', c: 5212 },
      { n: 'Cáceres', c: 5120 },
      { n: 'Dabeiba', c: 5234 },
      { n: 'Don Matías', c: 5237 },
      { n: 'Ebéjico', c: 5240 },
      { n: 'El Bagre', c: 5250 },
      { n: 'El Carmen de Viboral', c: 5148 },
      { n: 'El Santuario', c: 5697 },
      { n: 'Entrerrios', c: 5264 },
      { n: 'Envigado', c: 5266 },
      { n: 'Fredonia', c: 5282 },
      { n: 'Frontino', c: 5284 },
      { n: 'Giraldo', c: 5306 },
      { n: 'Girardota', c: 5308 },
      { n: 'Granada', c: 5313 },
      { n: 'Guadalupe', c: 5315 },
      { n: 'Guarne', c: 5318 },
      { n: 'Guatapé', c: 5321 },
      { n: 'Gómez Plata', c: 5310 },
      { n: 'Heliconia', c: 5347 },
      { n: 'Hispania', c: 5353 },
      { n: 'Itagui', c: 5360 },
      { n: 'Ituango', c: 5361 },
      { n: 'Jardín', c: 5364 },
      { n: 'Jericó', c: 5368 },
      { n: 'La Ceja', c: 5376 },
      { n: 'La Estrella', c: 5380 },
      { n: 'La Pintada', c: 5390 },
      { n: 'La Unión', c: 5400 },
      { n: 'Liborina', c: 5411 },
      { n: 'Maceo', c: 5425 },
      { n: 'Marinilla', c: 5440 },
      { n: 'Medellín', c: 5001 },
      { n: 'Montebello', c: 5467 },
      { n: 'Murindó', c: 5475 },
      { n: 'Mutatá', c: 5480 },
      { n: 'Nariño', c: 5483 },
      { n: 'Nechí', c: 5495 },
      { n: 'Necoclí', c: 5490 },
      { n: 'Olaya', c: 5501 },
      { n: 'Peque', c: 5543 },
      { n: 'Peñol', c: 5541 },
      { n: 'Pueblorrico', c: 5576 },
      { n: 'Puerto Berrío', c: 5579 },
      { n: 'Puerto Nare', c: 5585 },
      { n: 'Puerto Triunfo', c: 5591 },
      { n: 'Remedios', c: 5604 },
      { n: 'Retiro', c: 5607 },
      { n: 'Rionegro', c: 5615 },
      { n: 'Sabanalarga', c: 5628 },
      { n: 'Sabaneta', c: 5631 },
      { n: 'Salgar', c: 5642 },
      { n: 'San Andrés de Cuerquía', c: 5647 },
      { n: 'San Carlos', c: 5649 },
      { n: 'San Francisco', c: 5652 },
      { n: 'San Jerónimo', c: 5656 },
      { n: 'San José de La Montaña', c: 5658 },
      { n: 'San Juan de Urabá', c: 5659 },
      { n: 'San Luis', c: 5660 },
      { n: 'San Pedro', c: 5664 },
      { n: 'San Pedro de Uraba', c: 5665 },
      { n: 'San Rafael', c: 5667 },
      { n: 'San Roque', c: 5670 },
      { n: 'San Vicente', c: 5674 },
      { n: 'Santa Bárbara', c: 5679 },
      { n: 'Santa Rosa de Osos', c: 5686 },
      { n: 'Santafé de Antioquia', c: 5042 },
      { n: 'Santo Domingo', c: 5690 },
      { n: 'Segovia', c: 5736 },
      { n: 'Sonsón', c: 5756 },
      { n: 'Sopetrán', c: 5761 },
      { n: 'Tarazá', c: 5790 },
      { n: 'Tarso', c: 5792 },
      { n: 'Titiribí', c: 5809 },
      { n: 'Toledo', c: 5819 },
      { n: 'Turbo', c: 5837 },
      { n: 'Támesis', c: 5789 },
      { n: 'Uramita', c: 5842 },
      { n: 'Urrao', c: 5847 },
      { n: 'Valdivia', c: 5854 },
      { n: 'Valparaíso', c: 5856 },
      { n: 'Vegachí', c: 5858 },
      { n: 'Venecia', c: 5861 },
      { n: 'Vigía del Fuerte', c: 5873 },
      { n: 'Yalí', c: 5885 },
      { n: 'Yarumal', c: 5887 },
      { n: 'Yolombó', c: 5890 },
      { n: 'Yondó', c: 5893 },
      { n: 'Zaragoza', c: 5895 }
    ]
  },
  {
    n: 'Arauca',
    c: 81,
    m: [
      { n: 'Arauca', c: 81001 },
      { n: 'Arauquita', c: 81065 },
      { n: 'Cravo Norte', c: 81220 },
      { n: 'Fortul', c: 81300 },
      { n: 'Puerto Rondón', c: 81591 },
      { n: 'Saravena', c: 81736 },
      { n: 'Tame', c: 81794 }
    ]
  },
  {
    n: 'Archipiélago de San Andrés, Providencia y Santa Catalina',
    c: 88,
    m: [{ n: 'Providencia', c: 88564 }, { n: 'San Andrés', c: 88001 }]
  },
  {
    n: 'Atlántico',
    c: 8,
    m: [
      { n: 'Baranoa', c: 8078 },
      { n: 'Barranquilla', c: 8001 },
      { n: 'Campo de La Cruz', c: 8137 },
      { n: 'Candelaria', c: 8141 },
      { n: 'Galapa', c: 8296 },
      { n: 'Juan de Acosta', c: 8372 },
      { n: 'Luruaco', c: 8421 },
      { n: 'Malambo', c: 8433 },
      { n: 'Manatí', c: 8436 },
      { n: 'Palmar de Varela', c: 8520 },
      { n: 'Piojó', c: 8549 },
      { n: 'Polonuevo', c: 8558 },
      { n: 'Ponedera', c: 8560 },
      { n: 'Puerto Colombia', c: 8573 },
      { n: 'Repelón', c: 8606 },
      { n: 'Sabanagrande', c: 8634 },
      { n: 'Sabanalarga', c: 8638 },
      { n: 'Santa Lucía', c: 8675 },
      { n: 'Santo Tomás', c: 8685 },
      { n: 'Soledad', c: 8758 },
      { n: 'Suan', c: 8770 },
      { n: 'Tubará', c: 8832 },
      { n: 'Usiacurí', c: 8849 }
    ]
  },
  { n: 'Bogotá D.C.', c: 11, m: [{ n: 'Bogotá D.C.', c: 11001 }] },
  {
    n: 'Bolívar',
    c: 13,
    m: [
      { n: 'Achí', c: 13006 },
      { n: 'Altos del Rosario', c: 13030 },
      { n: 'Arenal', c: 13042 },
      { n: 'Arjona', c: 13052 },
      { n: 'Arroyohondo', c: 13062 },
      { n: 'Barranco de Loba', c: 13074 },
      { n: 'Calamar', c: 13140 },
      { n: 'Cantagallo', c: 13160 },
      { n: 'Cartagena', c: 13001 },
      { n: 'Cicuco', c: 13188 },
      { n: 'Clemencia', c: 13222 },
      { n: 'Córdoba', c: 13212 },
      { n: 'El Carmen de Bolívar', c: 13244 },
      { n: 'El Guamo', c: 13248 },
      { n: 'El Peñón', c: 13268 },
      { n: 'Hatillo de Loba', c: 13300 },
      { n: 'Magangué', c: 13430 },
      { n: 'Mahates', c: 13433 },
      { n: 'Margarita', c: 13440 },
      { n: 'María la Baja', c: 13442 },
      { n: 'Mompós', c: 13468 },
      { n: 'Montecristo', c: 13458 },
      { n: 'Morales', c: 13473 },
      { n: 'Norosí', c: 13490 },
      { n: 'Pinillos', c: 13549 },
      { n: 'Regidor', c: 13580 },
      { n: 'Río Viejo', c: 13600 },
      { n: 'San Cristóbal', c: 13620 },
      { n: 'San Estanislao', c: 13647 },
      { n: 'San Fernando', c: 13650 },
      { n: 'San Jacinto', c: 13654 },
      { n: 'San Jacinto del Cauca', c: 13655 },
      { n: 'San Juan Nepomuceno', c: 13657 },
      { n: 'San Martín de Loba', c: 13667 },
      { n: 'San Pablo de Borbur', c: 13670 },
      { n: 'Santa Catalina', c: 13673 },
      { n: 'Santa Rosa', c: 13683 },
      { n: 'Santa Rosa del Sur', c: 13688 },
      { n: 'Simití', c: 13744 },
      { n: 'Soplaviento', c: 13760 },
      { n: 'Talaigua Nuevo', c: 13780 },
      { n: 'Tiquisio', c: 13810 },
      { n: 'Turbaco', c: 13836 },
      { n: 'Turbaná', c: 13838 },
      { n: 'Villanueva', c: 13873 },
      { n: 'Zambrano', c: 13894 }
    ]
  },
  {
    n: 'Boyacá',
    c: 15,
    m: [
      { n: 'Almeida', c: 15022 },
      { n: 'Aquitania', c: 15047 },
      { n: 'Arcabuco', c: 15051 },
      { n: 'Belén', c: 15087 },
      { n: 'Berbeo', c: 15090 },
      { n: 'Betéitiva', c: 15092 },
      { n: 'Boavita', c: 15097 },
      { n: 'Boyacá', c: 15104 },
      { n: 'Briceño', c: 15106 },
      { n: 'Buena Vista', c: 15109 },
      { n: 'Busbanzá', c: 15114 },
      { n: 'Caldas', c: 15131 },
      { n: 'Campohermoso', c: 15135 },
      { n: 'Cerinza', c: 15162 },
      { n: 'Chinavita', c: 15172 },
      { n: 'Chiquinquirá', c: 15176 },
      { n: 'Chiscas', c: 15180 },
      { n: 'Chita', c: 15183 },
      { n: 'Chitaraque', c: 15185 },
      { n: 'Chivatá', c: 15187 },
      { n: 'Chivor', c: 15236 },
      { n: 'Chíquiza', c: 15232 },
      { n: 'Ciénega', c: 15189 },
      { n: 'Coper', c: 15212 },
      { n: 'Corrales', c: 15215 },
      { n: 'Covarachía', c: 15218 },
      { n: 'Cubará', c: 15223 },
      { n: 'Cucaita', c: 15224 },
      { n: 'Cuítiva', c: 15226 },
      { n: 'Cómbita', c: 15204 },
      { n: 'Duitama', c: 15238 },
      { n: 'El Cocuy', c: 15244 },
      { n: 'El Espino', c: 15248 },
      { n: 'Firavitoba', c: 15272 },
      { n: 'Floresta', c: 15276 },
      { n: 'Gachantivá', c: 15293 },
      { n: 'Gameza', c: 15296 },
      { n: 'Garagoa', c: 15299 },
      { n: 'Guacamayas', c: 15317 },
      { n: 'Guateque', c: 15322 },
      { n: 'Guayatá', c: 15325 },
      { n: 'Güicán', c: 15332 },
      { n: 'Iza', c: 15362 },
      { n: 'Jenesano', c: 15367 },
      { n: 'Jericó', c: 15368 },
      { n: 'La Capilla', c: 15380 },
      { n: 'La Uvita', c: 15403 },
      { n: 'La Victoria', c: 15401 },
      { n: 'Labranzagrande', c: 15377 },
      { n: 'Macanal', c: 15425 },
      { n: 'Maripí', c: 15442 },
      { n: 'Miraflores', c: 15455 },
      { n: 'Mongua', c: 15464 },
      { n: 'Monguí', c: 15466 },
      { n: 'Moniquirá', c: 15469 },
      { n: 'Motavita', c: 15476 },
      { n: 'Muzo', c: 15480 },
      { n: 'Nobsa', c: 15491 },
      { n: 'Nuevo Colón', c: 15494 },
      { n: 'Oicatá', c: 15500 },
      { n: 'Otanche', c: 15507 },
      { n: 'Pachavita', c: 15511 },
      { n: 'Paipa', c: 15516 },
      { n: 'Pajarito', c: 15518 },
      { n: 'Panqueba', c: 15522 },
      { n: 'Pauna', c: 15531 },
      { n: 'Paya', c: 15533 },
      { n: 'Paz de Río', c: 15537 },
      { n: 'Pesca', c: 15542 },
      { n: 'Pisba', c: 15550 },
      { n: 'Puerto Boyacá', c: 15572 },
      { n: 'Páez', c: 15514 },
      { n: 'Quípama', c: 15580 },
      { n: 'Ramiriquí', c: 15599 },
      { n: 'Rondón', c: 15621 },
      { n: 'Ráquira', c: 15600 },
      { n: 'Saboyá', c: 15632 },
      { n: 'Samacá', c: 15646 },
      { n: 'San Eduardo', c: 15660 },
      { n: 'San José de Pare', c: 15664 },
      { n: 'San Luis de Gaceno', c: 15667 },
      { n: 'San Mateo', c: 15673 },
      { n: 'San Miguel de Sema', c: 15676 },
      { n: 'San Pablo de Borbur', c: 15681 },
      { n: 'Santa María', c: 15690 },
      { n: 'Santa Rosa de Viterbo', c: 15693 },
      { n: 'Santa Sofía', c: 15696 },
      { n: 'Santana', c: 15686 },
      { n: 'Sativanorte', c: 15720 },
      { n: 'Sativasur', c: 15723 },
      { n: 'Siachoque', c: 15740 },
      { n: 'Soatá', c: 15753 },
      { n: 'Socha', c: 15757 },
      { n: 'Socotá', c: 15755 },
      { n: 'Sogamoso', c: 15759 },
      { n: 'Somondoco', c: 15761 },
      { n: 'Sora', c: 15762 },
      { n: 'Soracá', c: 15764 },
      { n: 'Sotaquirá', c: 15763 },
      { n: 'Susacón', c: 15774 },
      { n: 'Sutamarchán', c: 15776 },
      { n: 'Sutatenza', c: 15778 },
      { n: 'Sáchica', c: 15638 },
      { n: 'Tasco', c: 15790 },
      { n: 'Tenza', c: 15798 },
      { n: 'Tibaná', c: 15804 },
      { n: 'Tibasosa', c: 15806 },
      { n: 'Tinjacá', c: 15808 },
      { n: 'Tipacoque', c: 15810 },
      { n: 'Toca', c: 15814 },
      { n: 'Togüí', c: 15816 },
      { n: 'Tota', c: 15822 },
      { n: 'Tunja', c: 15001 },
      { n: 'Tununguá', c: 15832 },
      { n: 'Turmequé', c: 15835 },
      { n: 'Tuta', c: 15837 },
      { n: 'Tutazá', c: 15839 },
      { n: 'Tópaga', c: 15820 },
      { n: 'Umbita', c: 15842 },
      { n: 'Ventaquemada', c: 15861 },
      { n: 'Villa de Leyva', c: 15407 },
      { n: 'Viracachá', c: 15879 },
      { n: 'Zetaquira', c: 15897 }
    ]
  },
  {
    n: 'Caldas',
    c: 17,
    m: [
      { n: 'Aguadas', c: 17013 },
      { n: 'Anserma', c: 17042 },
      { n: 'Aranzazu', c: 17050 },
      { n: 'Belalcázar', c: 17088 },
      { n: 'Chinchiná', c: 17174 },
      { n: 'Filadelfia', c: 17272 },
      { n: 'La Dorada', c: 17380 },
      { n: 'La Merced', c: 17388 },
      { n: 'Manizales', c: 17001 },
      { n: 'Manzanares', c: 17433 },
      { n: 'Marmato', c: 17442 },
      { n: 'Marquetalia', c: 17444 },
      { n: 'Marulanda', c: 17446 },
      { n: 'Neira', c: 17486 },
      { n: 'Norcasia', c: 17495 },
      { n: 'Palestina', c: 17524 },
      { n: 'Pensilvania', c: 17541 },
      { n: 'Pácora', c: 17513 },
      { n: 'Riosucio', c: 17614 },
      { n: 'Risaralda', c: 17616 },
      { n: 'Salamina', c: 17653 },
      { n: 'Samaná', c: 17662 },
      { n: 'San José', c: 17665 },
      { n: 'Supía', c: 17777 },
      { n: 'Victoria', c: 17867 },
      { n: 'Villamaría', c: 17873 },
      { n: 'Viterbo', c: 17877 }
    ]
  },
  {
    n: 'Caquetá',
    c: 18,
    m: [
      { n: 'Albania', c: 18029 },
      { n: 'Belén de Los Andaquies', c: 18094 },
      { n: 'Cartagena del Chairá', c: 18150 },
      { n: 'Curillo', c: 18205 },
      { n: 'El Doncello', c: 18247 },
      { n: 'El Paujil', c: 18256 },
      { n: 'Florencia', c: 18001 },
      { n: 'La Montañita', c: 18410 },
      { n: 'Milán', c: 18460 },
      { n: 'Morelia', c: 18479 },
      { n: 'Puerto Rico', c: 18592 },
      { n: 'San José del Fragua', c: 18610 },
      { n: 'San Vicente del Caguán', c: 18753 },
      { n: 'Solano', c: 18756 },
      { n: 'Solita', c: 18785 },
      { n: 'Valparaíso', c: 18860 }
    ]
  },
  {
    n: 'Casanare',
    c: 85,
    m: [
      { n: 'Aguazul', c: 85010 },
      { n: 'Chámeza', c: 85015 },
      { n: 'Hato Corozal', c: 85125 },
      { n: 'La Salina', c: 85136 },
      { n: 'Maní', c: 85139 },
      { n: 'Monterrey', c: 85162 },
      { n: 'Nunchía', c: 85225 },
      { n: 'Orocué', c: 85230 },
      { n: 'Paz de Ariporo', c: 85250 },
      { n: 'Pore', c: 85263 },
      { n: 'Recetor', c: 85279 },
      { n: 'Sabanalarga', c: 85300 },
      { n: 'San Luis de Gaceno', c: 85325 },
      { n: 'Sácama', c: 85315 },
      { n: 'Tauramena', c: 85410 },
      { n: 'Trinidad', c: 85430 },
      { n: 'Támara', c: 85400 },
      { n: 'Villanueva', c: 85440 },
      { n: 'Yopal', c: 85001 }
    ]
  },
  {
    n: 'Cauca',
    c: 19,
    m: [
      { n: 'Almaguer', c: 19022 },
      { n: 'Argelia', c: 19050 },
      { n: 'Balboa', c: 19075 },
      { n: 'Bolívar', c: 19100 },
      { n: 'Buenos Aires', c: 19110 },
      { n: 'Cajibío', c: 19130 },
      { n: 'Caldono', c: 19137 },
      { n: 'Caloto', c: 19142 },
      { n: 'Corinto', c: 19212 },
      { n: 'El Tambo', c: 19256 },
      { n: 'Florencia', c: 19290 },
      { n: 'Guachené', c: 19300 },
      { n: 'Guapi', c: 19318 },
      { n: 'Inzá', c: 19355 },
      { n: 'Jambaló', c: 19364 },
      { n: 'La Sierra', c: 19392 },
      { n: 'La Vega', c: 19397 },
      { n: 'López', c: 19418 },
      { n: 'Mercaderes', c: 19450 },
      { n: 'Miranda', c: 19455 },
      { n: 'Morales', c: 19473 },
      { n: 'Padilla', c: 19513 },
      { n: 'Patía', c: 19532 },
      { n: 'Piamonte', c: 19533 },
      { n: 'Piendamó', c: 19548 },
      { n: 'Popayán', c: 19001 },
      { n: 'Puerto Tejada', c: 19573 },
      { n: 'Puracé', c: 19585 },
      { n: 'Páez', c: 19517 },
      { n: 'Rosas', c: 19622 },
      { n: 'San Sebastián', c: 19693 },
      { n: 'Santa Rosa', c: 19701 },
      { n: 'Santander de Quilichao', c: 19698 },
      { n: 'Silvia', c: 19743 },
      { n: 'Sotara', c: 19760 },
      { n: 'Sucre', c: 19785 },
      { n: 'Suárez', c: 19780 },
      { n: 'Timbiquí', c: 19809 },
      { n: 'Timbío', c: 19807 },
      { n: 'Toribio', c: 19821 },
      { n: 'Totoró', c: 19824 },
      { n: 'Villa Rica', c: 19845 }
    ]
  },
  {
    n: 'Cesar',
    c: 20,
    m: [
      { n: 'Aguachica', c: 20011 },
      { n: 'Agustín Codazzi', c: 20013 },
      { n: 'Astrea', c: 20032 },
      { n: 'Becerril', c: 20045 },
      { n: 'Bosconia', c: 20060 },
      { n: 'Chimichagua', c: 20175 },
      { n: 'Chiriguaná', c: 20178 },
      { n: 'Curumaní', c: 20228 },
      { n: 'El Copey', c: 20238 },
      { n: 'El Paso', c: 20250 },
      { n: 'Gamarra', c: 20295 },
      { n: 'González', c: 20310 },
      { n: 'La Gloria', c: 20383 },
      { n: 'La Jagua de Ibirico', c: 20400 },
      { n: 'La Paz', c: 20621 },
      { n: 'Manaure', c: 20443 },
      { n: 'Pailitas', c: 20517 },
      { n: 'Pelaya', c: 20550 },
      { n: 'Pueblo Bello', c: 20570 },
      { n: 'Río de Oro', c: 20614 },
      { n: 'San Alberto', c: 20710 },
      { n: 'San Diego', c: 20750 },
      { n: 'San Martín', c: 20770 },
      { n: 'Tamalameque', c: 20787 },
      { n: 'Valledupar', c: 20001 }
    ]
  },
  {
    n: 'Chocó',
    c: 27,
    m: [
      { n: 'Acandí', c: 27006 },
      { n: 'Alto Baudo', c: 27025 },
      { n: 'Atrato', c: 27050 },
      { n: 'Bagadó', c: 27073 },
      { n: 'Bahía Solano', c: 27075 },
      { n: 'Bajo Baudó', c: 27077 },
      { n: 'Belén de Bajira', c: 27086 },
      { n: 'Bojaya', c: 27099 },
      { n: 'Carmen del Darien', c: 27150 },
      { n: 'Condoto', c: 27205 },
      { n: 'Cértegui', c: 27160 },
      { n: 'El Cantón del San Pablo', c: 27135 },
      { n: 'El Carmen de Atrato', c: 27245 },
      { n: 'El Litoral del San Juan', c: 27250 },
      { n: 'Istmina', c: 27361 },
      { n: 'Juradó', c: 27372 },
      { n: 'Lloró', c: 27413 },
      { n: 'Medio Atrato', c: 27425 },
      { n: 'Medio Baudó', c: 27430 },
      { n: 'Medio San Juan', c: 27450 },
      { n: 'Nuquí', c: 27495 },
      { n: 'Nóvita', c: 27491 },
      { n: 'Quibdó', c: 27001 },
      { n: 'Riosucio', c: 27615 },
      { n: 'Río Iro', c: 27580 },
      { n: 'Río Quito', c: 27600 },
      { n: 'San José del Palmar', c: 27660 },
      { n: 'Sipí', c: 27745 },
      { n: 'Tadó', c: 27787 },
      { n: 'Unguía', c: 27800 },
      { n: 'Unión Panamericana', c: 27810 }
    ]
  },
  {
    n: 'Cundinamarca',
    c: 25,
    m: [
      { n: 'Agua de Dios', c: 25001 },
      { n: 'Albán', c: 25019 },
      { n: 'Anapoima', c: 25035 },
      { n: 'Anolaima', c: 25040 },
      { n: 'Apulo', c: 25599 },
      { n: 'Arbeláez', c: 25053 },
      { n: 'Beltrán', c: 25086 },
      { n: 'Bituima', c: 25095 },
      { n: 'Bojacá', c: 25099 },
      { n: 'Cabrera', c: 25120 },
      { n: 'Cachipay', c: 25123 },
      { n: 'Cajicá', c: 25126 },
      { n: 'Caparrapí', c: 25148 },
      { n: 'Caqueza', c: 25151 },
      { n: 'Carmen de Carupa', c: 25154 },
      { n: 'Chaguaní', c: 25168 },
      { n: 'Chipaque', c: 25178 },
      { n: 'Choachí', c: 25181 },
      { n: 'Chocontá', c: 25183 },
      { n: 'Chía', c: 25175 },
      { n: 'Cogua', c: 25200 },
      { n: 'Cota', c: 25214 },
      { n: 'Cucunubá', c: 25224 },
      { n: 'El Colegio', c: 25245 },
      { n: 'El Peñón', c: 25258 },
      { n: 'El Rosal', c: 25260 },
      { n: 'Facatativá', c: 25269 },
      { n: 'Fomeque', c: 25279 },
      { n: 'Fosca', c: 25281 },
      { n: 'Funza', c: 25286 },
      { n: 'Fusagasugá', c: 25290 },
      { n: 'Fúquene', c: 25288 },
      { n: 'Gachala', c: 25293 },
      { n: 'Gachancipá', c: 25295 },
      { n: 'Gachetá', c: 25297 },
      { n: 'Gama', c: 25299 },
      { n: 'Girardot', c: 25307 },
      { n: 'Granada', c: 25312 },
      { n: 'Guachetá', c: 25317 },
      { n: 'Guaduas', c: 25320 },
      { n: 'Guasca', c: 25322 },
      { n: 'Guataquí', c: 25324 },
      { n: 'Guatavita', c: 25326 },
      { n: 'Guayabal de Siquima', c: 25328 },
      { n: 'Guayabetal', c: 25335 },
      { n: 'Gutiérrez', c: 25339 },
      { n: 'Jerusalén', c: 25368 },
      { n: 'Junín', c: 25372 },
      { n: 'La Calera', c: 25377 },
      { n: 'La Mesa', c: 25386 },
      { n: 'La Palma', c: 25394 },
      { n: 'La Peña', c: 25398 },
      { n: 'La Vega', c: 25402 },
      { n: 'Lenguazaque', c: 25407 },
      { n: 'Macheta', c: 25426 },
      { n: 'Madrid', c: 25430 },
      { n: 'Manta', c: 25436 },
      { n: 'Medina', c: 25438 },
      { n: 'Mosquera', c: 25473 },
      { n: 'Nariño', c: 25483 },
      { n: 'Nemocón', c: 25486 },
      { n: 'Nilo', c: 25488 },
      { n: 'Nimaima', c: 25489 },
      { n: 'Nocaima', c: 25491 },
      { n: 'Pacho', c: 25513 },
      { n: 'Paime', c: 25518 },
      { n: 'Pandi', c: 25524 },
      { n: 'Paratebueno', c: 25530 },
      { n: 'Pasca', c: 25535 },
      { n: 'Puerto Salgar', c: 25572 },
      { n: 'Pulí', c: 25580 },
      { n: 'Quebradanegra', c: 25592 },
      { n: 'Quetame', c: 25594 },
      { n: 'Quipile', c: 25596 },
      { n: 'Ricaurte', c: 25612 },
      { n: 'San Antonio del Tequendama', c: 25645 },
      { n: 'San Bernardo', c: 25649 },
      { n: 'San Cayetano', c: 25653 },
      { n: 'San Francisco', c: 25658 },
      { n: 'San Juan de Río Seco', c: 25662 },
      { n: 'Sasaima', c: 25718 },
      { n: 'Sesquilé', c: 25736 },
      { n: 'Sibaté', c: 25740 },
      { n: 'Silvania', c: 25743 },
      { n: 'Simijaca', c: 25745 },
      { n: 'Soacha', c: 25754 },
      { n: 'Sopó', c: 25758 },
      { n: 'Subachoque', c: 25769 },
      { n: 'Suesca', c: 25772 },
      { n: 'Supatá', c: 25777 },
      { n: 'Susa', c: 25779 },
      { n: 'Sutatausa', c: 25781 },
      { n: 'Tabio', c: 25785 },
      { n: 'Tausa', c: 25793 },
      { n: 'Tena', c: 25797 },
      { n: 'Tenjo', c: 25799 },
      { n: 'Tibacuy', c: 25805 },
      { n: 'Tibirita', c: 25807 },
      { n: 'Tocaima', c: 25815 },
      { n: 'Tocancipá', c: 25817 },
      { n: 'Topaipí', c: 25823 },
      { n: 'Ubalá', c: 25839 },
      { n: 'Ubaque', c: 25841 },
      { n: 'Une', c: 25845 },
      { n: 'Venecia', c: 25506 },
      { n: 'Vergara', c: 25862 },
      { n: 'Vianí', c: 25867 },
      { n: 'Villa de San Diego de Ubate', c: 25843 },
      { n: 'Villagómez', c: 25871 },
      { n: 'Villapinzón', c: 25873 },
      { n: 'Villeta', c: 25875 },
      { n: 'Viotá', c: 25878 },
      { n: 'Yacopí', c: 25885 },
      { n: 'Zipacón', c: 25898 },
      { n: 'Zipaquirá', c: 25899 },
      { n: 'Útica', c: 25851 }
    ]
  },
  {
    n: 'Córdoba',
    c: 23,
    m: [
      { n: 'Ayapel', c: 23068 },
      { n: 'Buenavista', c: 23079 },
      { n: 'Canalete', c: 23090 },
      { n: 'Cereté', c: 23162 },
      { n: 'Chimá', c: 23168 },
      { n: 'Chinú', c: 23182 },
      { n: 'Ciénaga de Oro', c: 23189 },
      { n: 'Cotorra', c: 23300 },
      { n: 'La Apartada', c: 23350 },
      { n: 'Lorica', c: 23417 },
      { n: 'Los Córdobas', c: 23419 },
      { n: 'Momil', c: 23464 },
      { n: 'Montelíbano', c: 23466 },
      { n: 'Montería', c: 23001 },
      { n: 'Moñitos', c: 23500 },
      { n: 'Planeta Rica', c: 23555 },
      { n: 'Pueblo Nuevo', c: 23570 },
      { n: 'Puerto Escondido', c: 23574 },
      { n: 'Puerto Libertador', c: 23580 },
      { n: 'Purísima', c: 23586 },
      { n: 'Sahagún', c: 23660 },
      { n: 'San Andrés Sotavento', c: 23670 },
      { n: 'San Antero', c: 23672 },
      { n: 'San Bernardo del Viento', c: 23675 },
      { n: 'San Carlos', c: 23678 },
      { n: 'San José de Uré', c: 23682 },
      { n: 'San Pelayo', c: 23686 },
      { n: 'Tierralta', c: 23807 },
      { n: 'Tuchín', c: 23815 },
      { n: 'Valencia', c: 23855 }
    ]
  },
  {
    n: 'Guainía',
    c: 94,
    m: [
      { n: 'Barranco Minas', c: 94343 },
      { n: 'Cacahual', c: 94886 },
      { n: 'Inírida', c: 94001 },
      { n: 'La Guadalupe', c: 94885 },
      { n: 'Mapiripana', c: 94663 },
      { n: 'Morichal', c: 94888 },
      { n: 'Pana Pana', c: 94887 },
      { n: 'Puerto Colombia', c: 94884 },
      { n: 'San Felipe', c: 94883 }
    ]
  },
  {
    n: 'Guaviare',
    c: 95,
    m: [
      { n: 'Calamar', c: 95015 },
      { n: 'El Retorno', c: 95025 },
      { n: 'Miraflores', c: 95200 },
      { n: 'San José del Guaviare', c: 95001 }
    ]
  },
  {
    n: 'Huila',
    c: 41,
    m: [
      { n: 'Acevedo', c: 41006 },
      { n: 'Agrado', c: 41013 },
      { n: 'Aipe', c: 41016 },
      { n: 'Algeciras', c: 41020 },
      { n: 'Altamira', c: 41026 },
      { n: 'Baraya', c: 41078 },
      { n: 'Campoalegre', c: 41132 },
      { n: 'Colombia', c: 41206 },
      { n: 'Elías', c: 41244 },
      { n: 'Garzón', c: 41298 },
      { n: 'Gigante', c: 41306 },
      { n: 'Guadalupe', c: 41319 },
      { n: 'Hobo', c: 41349 },
      { n: 'Iquira', c: 41357 },
      { n: 'Isnos', c: 41359 },
      { n: 'La Argentina', c: 41378 },
      { n: 'La Plata', c: 41396 },
      { n: 'Neiva', c: 41001 },
      { n: 'Nátaga', c: 41483 },
      { n: 'Oporapa', c: 41503 },
      { n: 'Paicol', c: 41518 },
      { n: 'Palermo', c: 41524 },
      { n: 'Palestina', c: 41530 },
      { n: 'Pital', c: 41548 },
      { n: 'Pitalito', c: 41551 },
      { n: 'Rivera', c: 41615 },
      { n: 'Saladoblanco', c: 41660 },
      { n: 'San Agustín', c: 41668 },
      { n: 'Santa María', c: 41676 },
      { n: 'Suaza', c: 41770 },
      { n: 'Tarqui', c: 41791 },
      { n: 'Tello', c: 41799 },
      { n: 'Teruel', c: 41801 },
      { n: 'Tesalia', c: 41797 },
      { n: 'Timaná', c: 41807 },
      { n: 'Villavieja', c: 41872 },
      { n: 'Yaguará', c: 41885 }
    ]
  },
  {
    n: 'La Guajira',
    c: 44,
    m: [
      { n: 'Albania', c: 44035 },
      { n: 'Barrancas', c: 44078 },
      { n: 'Dibula', c: 44090 },
      { n: 'Distracción', c: 44098 },
      { n: 'El Molino', c: 44110 },
      { n: 'Fonseca', c: 44279 },
      { n: 'Hatonuevo', c: 44378 },
      { n: 'La Jagua del Pilar', c: 44420 },
      { n: 'Maicao', c: 44430 },
      { n: 'Manaure', c: 44560 },
      { n: 'Riohacha', c: 44001 },
      { n: 'San Juan del Cesar', c: 44650 },
      { n: 'Uribia', c: 44847 },
      { n: 'Urumita', c: 44855 },
      { n: 'Villanueva', c: 44874 }
    ]
  },
  {
    n: 'Magdalena',
    c: 47,
    m: [
      { n: 'Algarrobo', c: 47030 },
      { n: 'Aracataca', c: 47053 },
      { n: 'Ariguaní', c: 47058 },
      { n: 'Cerro San Antonio', c: 47161 },
      { n: 'Chivolo', c: 47170 },
      { n: 'Ciénaga', c: 47189 },
      { n: 'Concordia', c: 47205 },
      { n: 'El Banco', c: 47245 },
      { n: 'El Piñon', c: 47258 },
      { n: 'El Retén', c: 47268 },
      { n: 'Fundación', c: 47288 },
      { n: 'Guamal', c: 47318 },
      { n: 'Nueva Granada', c: 47460 },
      { n: 'Pedraza', c: 47541 },
      { n: 'Pijiño del Carmen', c: 47545 },
      { n: 'Pivijay', c: 47551 },
      { n: 'Plato', c: 47555 },
      { n: 'Pueblo Viejo', c: 47570 },
      { n: 'Remolino', c: 47605 },
      { n: 'Sabanas de San Angel', c: 47660 },
      { n: 'Salamina', c: 47675 },
      { n: 'San Sebastián de Buenavista', c: 47692 },
      { n: 'San Zenón', c: 47703 },
      { n: 'Santa Ana', c: 47707 },
      { n: 'Santa Bárbara de Pinto', c: 47720 },
      { n: 'Santa Marta', c: 47001 },
      { n: 'Sitionuevo', c: 47745 },
      { n: 'Tenerife', c: 47798 },
      { n: 'Zapayán', c: 47960 },
      { n: 'Zona Bananera', c: 47980 }
    ]
  },
  {
    n: 'Meta',
    c: 50,
    m: [
      { n: 'Acacias', c: 50006 },
      { n: 'Barranca de Upía', c: 50110 },
      { n: 'Cabuyaro', c: 50124 },
      { n: 'Castilla la Nueva', c: 50150 },
      { n: 'Cubarral', c: 50223 },
      { n: 'Cumaral', c: 50226 },
      { n: 'El Calvario', c: 50245 },
      { n: 'El Castillo', c: 50251 },
      { n: 'El Dorado', c: 50270 },
      { n: 'Fuente de Oro', c: 50287 },
      { n: 'Granada', c: 50313 },
      { n: 'Guamal', c: 50318 },
      { n: 'La Macarena', c: 50350 },
      { n: 'Lejanías', c: 50400 },
      { n: 'Mapiripán', c: 50325 },
      { n: 'Mesetas', c: 50330 },
      { n: 'Puerto Concordia', c: 50450 },
      { n: 'Puerto Gaitán', c: 50568 },
      { n: 'Puerto Lleras', c: 50577 },
      { n: 'Puerto López', c: 50573 },
      { n: 'Puerto Rico', c: 50590 },
      { n: 'Restrepo', c: 50606 },
      { n: 'San Carlos de Guaroa', c: 50680 },
      { n: 'San Juan de Arama', c: 50683 },
      { n: 'San Juanito', c: 50686 },
      { n: 'San Martín', c: 50689 },
      { n: 'Uribe', c: 50370 },
      { n: 'Villavicencio', c: 50001 },
      { n: 'Vista Hermosa', c: 50711 }
    ]
  },
  {
    n: 'Nariño',
    c: 52,
    m: [
      { n: 'Albán', c: 52019 },
      { n: 'Aldana', c: 52022 },
      { n: 'Ancuyá', c: 52036 },
      { n: 'Arboleda', c: 52051 },
      { n: 'Barbacoas', c: 52079 },
      { n: 'Belén', c: 52083 },
      { n: 'Buesaco', c: 52110 },
      { n: 'Chachagüí', c: 52240 },
      { n: 'Colón', c: 52203 },
      { n: 'Consaca', c: 52207 },
      { n: 'Contadero', c: 52210 },
      { n: 'Cuaspud', c: 52224 },
      { n: 'Cumbal', c: 52227 },
      { n: 'Cumbitara', c: 52233 },
      { n: 'Córdoba', c: 52215 },
      { n: 'El Charco', c: 52250 },
      { n: 'El Peñol', c: 52254 },
      { n: 'El Rosario', c: 52256 },
      { n: 'El Tablón de Gómez', c: 52258 },
      { n: 'El Tambo', c: 52260 },
      { n: 'Francisco Pizarro', c: 52520 },
      { n: 'Funes', c: 52287 },
      { n: 'Guachucal', c: 52317 },
      { n: 'Guaitarilla', c: 52320 },
      { n: 'Gualmatán', c: 52323 },
      { n: 'Iles', c: 52352 },
      { n: 'Imués', c: 52354 },
      { n: 'Ipiales', c: 52356 },
      { n: 'La Cruz', c: 52378 },
      { n: 'La Florida', c: 52381 },
      { n: 'La Llanada', c: 52385 },
      { n: 'La Tola', c: 52390 },
      { n: 'La Unión', c: 52399 },
      { n: 'Leiva', c: 52405 },
      { n: 'Linares', c: 52411 },
      { n: 'Los Andes', c: 52418 },
      { n: 'Magüí', c: 52427 },
      { n: 'Mallama', c: 52435 },
      { n: 'Mosquera', c: 52473 },
      { n: 'Nariño', c: 52480 },
      { n: 'Olaya Herrera', c: 52490 },
      { n: 'Ospina', c: 52506 },
      { n: 'Pasto', c: 52001 },
      { n: 'Policarpa', c: 52540 },
      { n: 'Potosí', c: 52560 },
      { n: 'Providencia', c: 52565 },
      { n: 'Puerres', c: 52573 },
      { n: 'Pupiales', c: 52585 },
      { n: 'Ricaurte', c: 52612 },
      { n: 'Roberto Payán', c: 52621 },
      { n: 'Samaniego', c: 52678 },
      { n: 'San Andrés de Tumaco', c: 52835 },
      { n: 'San Bernardo', c: 52685 },
      { n: 'San Lorenzo', c: 52687 },
      { n: 'San Pablo', c: 52693 },
      { n: 'San Pedro de Cartago', c: 52694 },
      { n: 'Sandoná', c: 52683 },
      { n: 'Santa Bárbara', c: 52696 },
      { n: 'Santacruz', c: 52699 },
      { n: 'Sapuyes', c: 52720 },
      { n: 'Taminango', c: 52786 },
      { n: 'Tangua', c: 52788 },
      { n: 'Túquerres', c: 52838 },
      { n: 'Yacuanquer', c: 52885 }
    ]
  },
  {
    n: 'Norte de Santander',
    c: 54,
    m: [
      { n: 'Abrego', c: 54003 },
      { n: 'Arboledas', c: 54051 },
      { n: 'Bochalema', c: 54099 },
      { n: 'Bucarasica', c: 54109 },
      { n: 'Cachirá', c: 54128 },
      { n: 'Chinácota', c: 54172 },
      { n: 'Chitagá', c: 54174 },
      { n: 'Convención', c: 54206 },
      { n: 'Cucutilla', c: 54223 },
      { n: 'Cácota', c: 54125 },
      { n: 'Cúcuta', c: 54001 },
      { n: 'Durania', c: 54239 },
      { n: 'El Carmen', c: 54245 },
      { n: 'El Tarra', c: 54250 },
      { n: 'El Zulia', c: 54261 },
      { n: 'Gramalote', c: 54313 },
      { n: 'Hacarí', c: 54344 },
      { n: 'Herrán', c: 54347 },
      { n: 'La Esperanza', c: 54385 },
      { n: 'La Playa', c: 54398 },
      { n: 'Labateca', c: 54377 },
      { n: 'Los Patios', c: 54405 },
      { n: 'Lourdes', c: 54418 },
      { n: 'Mutiscua', c: 54480 },
      { n: 'Ocaña', c: 54498 },
      { n: 'Pamplona', c: 54518 },
      { n: 'Pamplonita', c: 54520 },
      { n: 'Puerto Santander', c: 54553 },
      { n: 'Ragonvalia', c: 54599 },
      { n: 'Salazar', c: 54660 },
      { n: 'San Calixto', c: 54670 },
      { n: 'San Cayetano', c: 54673 },
      { n: 'Santiago', c: 54680 },
      { n: 'Sardinata', c: 54720 },
      { n: 'Silos', c: 54743 },
      { n: 'Teorama', c: 54800 },
      { n: 'Tibú', c: 54810 },
      { n: 'Toledo', c: 54820 },
      { n: 'Villa Caro', c: 54871 },
      { n: 'Villa del Rosario', c: 54874 }
    ]
  },
  {
    n: 'Putumayo',
    c: 86,
    m: [
      { n: 'Colón', c: 86219 },
      { n: 'Leguízamo', c: 86573 },
      { n: 'Mocoa', c: 86001 },
      { n: 'Orito', c: 86320 },
      { n: 'Puerto Asís', c: 86568 },
      { n: 'Puerto Caicedo', c: 86569 },
      { n: 'Puerto Guzmán', c: 86571 },
      { n: 'San Francisco', c: 86755 },
      { n: 'San Miguel', c: 86757 },
      { n: 'Santiago', c: 86760 },
      { n: 'Sibundoy', c: 86749 },
      { n: 'Valle de Guamez', c: 86865 },
      { n: 'Villagarzón', c: 86885 }
    ]
  },
  {
    n: 'Quindío',
    c: 63,
    m: [
      { n: 'Armenia', c: 63001 },
      { n: 'Buenavista', c: 63111 },
      { n: 'Calarcá', c: 63130 },
      { n: 'Circasia', c: 63190 },
      { n: 'Córdoba', c: 63212 },
      { n: 'Filandia', c: 63272 },
      { n: 'Génova', c: 63302 },
      { n: 'La Tebaida', c: 63401 },
      { n: 'Montenegro', c: 63470 },
      { n: 'Pijao', c: 63548 },
      { n: 'Quimbaya', c: 63594 },
      { n: 'Salento', c: 63690 }
    ]
  },
  {
    n: 'Risaralda',
    c: 66,
    m: [
      { n: 'Apía', c: 66045 },
      { n: 'Balboa', c: 66075 },
      { n: 'Belén de Umbría', c: 66088 },
      { n: 'Dosquebradas', c: 66170 },
      { n: 'Guática', c: 66318 },
      { n: 'La Celia', c: 66383 },
      { n: 'La Virginia', c: 66400 },
      { n: 'Marsella', c: 66440 },
      { n: 'Mistrató', c: 66456 },
      { n: 'Pereira', c: 66001 },
      { n: 'Pueblo Rico', c: 66572 },
      { n: 'Quinchía', c: 66594 },
      { n: 'Santa Rosa de Cabal', c: 66682 },
      { n: 'Santuario', c: 66687 }
    ]
  },
  {
    n: 'Santander',
    c: 68,
    m: [
      { n: 'Aguada', c: 68013 },
      { n: 'Albania', c: 68020 },
      { n: 'Aratoca', c: 68051 },
      { n: 'Barbosa', c: 68077 },
      { n: 'Barichara', c: 68079 },
      { n: 'Barrancabermeja', c: 68081 },
      { n: 'Betulia', c: 68092 },
      { n: 'Bolívar', c: 68101 },
      { n: 'Bucaramanga', c: 68001 },
      { n: 'Cabrera', c: 68121 },
      { n: 'California', c: 68132 },
      { n: 'Capitanejo', c: 68147 },
      { n: 'Carcasí', c: 68152 },
      { n: 'Cepitá', c: 68160 },
      { n: 'Cerrito', c: 68162 },
      { n: 'Charalá', c: 68167 },
      { n: 'Charta', c: 68169 },
      { n: 'Chimá', c: 68176 },
      { n: 'Chipatá', c: 68179 },
      { n: 'Cimitarra', c: 68190 },
      { n: 'Concepción', c: 68207 },
      { n: 'Confines', c: 68209 },
      { n: 'Contratación', c: 68211 },
      { n: 'Coromoro', c: 68217 },
      { n: 'Curití', c: 68229 },
      { n: 'El Carmen de Chucurí', c: 68235 },
      { n: 'El Guacamayo', c: 68245 },
      { n: 'El Peñón', c: 68250 },
      { n: 'El Playón', c: 68255 },
      { n: 'Encino', c: 68264 },
      { n: 'Enciso', c: 68266 },
      { n: 'Floridablanca', c: 68276 },
      { n: 'Florián', c: 68271 },
      { n: 'Galán', c: 68296 },
      { n: 'Gambita', c: 68298 },
      { n: 'Girón', c: 68307 },
      { n: 'Guaca', c: 68318 },
      { n: 'Guadalupe', c: 68320 },
      { n: 'Guapotá', c: 68322 },
      { n: 'Guavatá', c: 68324 },
      { n: 'Güepsa', c: 68327 },
      { n: 'Hato', c: 68344 },
      { n: 'Jesús María', c: 68368 },
      { n: 'Jordán', c: 68370 },
      { n: 'La Belleza', c: 68377 },
      { n: 'La Paz', c: 68397 },
      { n: 'Landázuri', c: 68385 },
      { n: 'Lebríja', c: 68406 },
      { n: 'Los Santos', c: 68418 },
      { n: 'Macaravita', c: 68425 },
      { n: 'Matanza', c: 68444 },
      { n: 'Mogotes', c: 68464 },
      { n: 'Molagavita', c: 68468 },
      { n: 'Málaga', c: 68432 },
      { n: 'Ocamonte', c: 68498 },
      { n: 'Oiba', c: 68500 },
      { n: 'Onzaga', c: 68502 },
      { n: 'Palmar', c: 68522 },
      { n: 'Palmas del Socorro', c: 68524 },
      { n: 'Piedecuesta', c: 68547 },
      { n: 'Pinchote', c: 68549 },
      { n: 'Puente Nacional', c: 68572 },
      { n: 'Puerto Parra', c: 68573 },
      { n: 'Puerto Wilches', c: 68575 },
      { n: 'Páramo', c: 68533 },
      { n: 'Rionegro', c: 68615 },
      { n: 'Sabana de Torres', c: 68655 },
      { n: 'San Andrés', c: 68669 },
      { n: 'San Benito', c: 68673 },
      { n: 'San Gil', c: 68679 },
      { n: 'San Joaquín', c: 68682 },
      { n: 'San José de Miranda', c: 68684 },
      { n: 'San Miguel', c: 68686 },
      { n: 'San Vicente de Chucurí', c: 68689 },
      { n: 'Santa Bárbara', c: 68705 },
      { n: 'Santa Helena del Opón', c: 68720 },
      { n: 'Simacota', c: 68745 },
      { n: 'Socorro', c: 68755 },
      { n: 'Suaita', c: 68770 },
      { n: 'Sucre', c: 68773 },
      { n: 'Suratá', c: 68780 },
      { n: 'Tona', c: 68820 },
      { n: 'Valle de San José', c: 68855 },
      { n: 'Vetas', c: 68867 },
      { n: 'Villanueva', c: 68872 },
      { n: 'Vélez', c: 68861 },
      { n: 'Zapatoca', c: 68895 }
    ]
  },
  {
    n: 'Sucre',
    c: 70,
    m: [
      { n: 'Buenavista', c: 70110 },
      { n: 'Caimito', c: 70124 },
      { n: 'Chalán', c: 70230 },
      { n: 'Coloso', c: 70204 },
      { n: 'Corozal', c: 70215 },
      { n: 'Coveñas', c: 70221 },
      { n: 'El Roble', c: 70233 },
      { n: 'Galeras', c: 70235 },
      { n: 'Guaranda', c: 70265 },
      { n: 'La Unión', c: 70400 },
      { n: 'Los Palmitos', c: 70418 },
      { n: 'Majagual', c: 70429 },
      { n: 'Morroa', c: 70473 },
      { n: 'Ovejas', c: 70508 },
      { n: 'Palmito', c: 70523 },
      { n: 'Sampués', c: 70670 },
      { n: 'San Benito Abad', c: 70678 },
      { n: 'San Juan de Betulia', c: 70702 },
      { n: 'San Luis de Sincé', c: 70742 },
      { n: 'San Marcos', c: 70708 },
      { n: 'San Onofre', c: 70713 },
      { n: 'San Pedro', c: 70717 },
      { n: 'Santiago de Tolú', c: 70820 },
      { n: 'Sincelejo', c: 70001 },
      { n: 'Sucre', c: 70771 },
      { n: 'Tolú Viejo', c: 70823 }
    ]
  },
  {
    n: 'Tolima',
    c: 73,
    m: [
      { n: 'Alpujarra', c: 73024 },
      { n: 'Alvarado', c: 73026 },
      { n: 'Ambalema', c: 73030 },
      { n: 'Anzoátegui', c: 73043 },
      { n: 'Armero', c: 73055 },
      { n: 'Ataco', c: 73067 },
      { n: 'Cajamarca', c: 73124 },
      { n: 'Carmen de Apicala', c: 73148 },
      { n: 'Casabianca', c: 73152 },
      { n: 'Chaparral', c: 73168 },
      { n: 'Coello', c: 73200 },
      { n: 'Coyaima', c: 73217 },
      { n: 'Cunday', c: 73226 },
      { n: 'Dolores', c: 73236 },
      { n: 'Espinal', c: 73268 },
      { n: 'Falan', c: 73270 },
      { n: 'Flandes', c: 73275 },
      { n: 'Fresno', c: 73283 },
      { n: 'Guamo', c: 73319 },
      { n: 'Herveo', c: 73347 },
      { n: 'Honda', c: 73349 },
      { n: 'Ibagué', c: 73001 },
      { n: 'Icononzo', c: 73352 },
      { n: 'Lérida', c: 73408 },
      { n: 'Líbano', c: 73411 },
      { n: 'Mariquita', c: 73443 },
      { n: 'Melgar', c: 73449 },
      { n: 'Murillo', c: 73461 },
      { n: 'Natagaima', c: 73483 },
      { n: 'Ortega', c: 73504 },
      { n: 'Palocabildo', c: 73520 },
      { n: 'Piedras', c: 73547 },
      { n: 'Planadas', c: 73555 },
      { n: 'Prado', c: 73563 },
      { n: 'Purificación', c: 73585 },
      { n: 'Rio Blanco', c: 73616 },
      { n: 'Roncesvalles', c: 73622 },
      { n: 'Rovira', c: 73624 },
      { n: 'Saldaña', c: 73671 },
      { n: 'San Antonio', c: 73675 },
      { n: 'San Luis', c: 73678 },
      { n: 'Santa Isabel', c: 73686 },
      { n: 'Suárez', c: 73770 },
      { n: 'Valle de San Juan', c: 73854 },
      { n: 'Venadillo', c: 73861 },
      { n: 'Villahermosa', c: 73870 },
      { n: 'Villarrica', c: 73873 }
    ]
  },
  {
    n: 'Valle del Cauca',
    c: 76,
    m: [
      { n: 'Alcalá', c: 76020 },
      { n: 'Andalucía', c: 76036 },
      { n: 'Ansermanuevo', c: 76041 },
      { n: 'Argelia', c: 76054 },
      { n: 'Bolívar', c: 76100 },
      { n: 'Buenaventura', c: 76109 },
      { n: 'Bugalagrande', c: 76113 },
      { n: 'Caicedonia', c: 76122 },
      { n: 'Cali', c: 76001 },
      { n: 'Calima', c: 76126 },
      { n: 'Candelaria', c: 76130 },
      { n: 'Cartago', c: 76147 },
      { n: 'Dagua', c: 76233 },
      { n: 'El Cairo', c: 76246 },
      { n: 'El Cerrito', c: 76248 },
      { n: 'El Dovio', c: 76250 },
      { n: 'El Águila', c: 76243 },
      { n: 'Florida', c: 76275 },
      { n: 'Ginebra', c: 76306 },
      { n: 'Guacarí', c: 76318 },
      { n: 'Guadalajara de Buga', c: 76111 },
      { n: 'Jamundí', c: 76364 },
      { n: 'La Cumbre', c: 76377 },
      { n: 'La Unión', c: 76400 },
      { n: 'La Victoria', c: 76403 },
      { n: 'Obando', c: 76497 },
      { n: 'Palmira', c: 76520 },
      { n: 'Pradera', c: 76563 },
      { n: 'Restrepo', c: 76606 },
      { n: 'Riofrío', c: 76616 },
      { n: 'Roldanillo', c: 76622 },
      { n: 'San Pedro', c: 76670 },
      { n: 'Sevilla', c: 76736 },
      { n: 'Toro', c: 76823 },
      { n: 'Trujillo', c: 76828 },
      { n: 'Tuluá', c: 76834 },
      { n: 'Ulloa', c: 76845 },
      { n: 'Versalles', c: 76863 },
      { n: 'Vijes', c: 76869 },
      { n: 'Yotoco', c: 76890 },
      { n: 'Yumbo', c: 76892 },
      { n: 'Zarzal', c: 76895 }
    ]
  },
  {
    n: 'Vaupés',
    c: 97,
    m: [
      { n: 'Caruru', c: 97161 },
      { n: 'Mitú', c: 97001 },
      { n: 'Pacoa', c: 97511 },
      { n: 'Papunaua', c: 97777 },
      { n: 'Taraira', c: 97666 },
      { n: 'Yavaraté', c: 97889 }
    ]
  },
  {
    n: 'Vichada',
    c: 99,
    m: [
      { n: 'Cumaribo', c: 99773 },
      { n: 'La Primavera', c: 99524 },
      { n: 'Puerto Carreño', c: 99001 },
      { n: 'Santa Rosalía', c: 99624 }
    ]
  }
];
