// Tabla 07 - Calificación - CAL
// TransUnion - Tablas de convenciones - Información Comercial - Versión 1.34

const calificacion = {
  A: 'Categoría “A”',
  AA: 'Categoría “AA”',
  B: 'Categoría “B”',
  BB: 'Categoría “BB”',
  C: 'Categoría “C”',
  CC: 'Categoría “CC”',
  D: 'Categoría “D”',
  E: 'Categoría “E”',
  K: 'Categoría “K”',
  NC: 'No calificado'
};

export default calificacion;
