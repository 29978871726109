import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { UserInfoResult } from '../../services/usersMeGet';
import { AppState } from '../../store/store';
import ValidatePendingEmail from './ValidatePendingEmail';
import ValidatePendingPhone from './ValidatePendingPhone';

interface Props extends RouteComponentProps {
  user: UserInfoResult['user'] | false;
}

const ValidatePending = ({ user, location }: Props) => {
  const excludeRoutes = ["/validacion-identidad", "/validacion", "/informacion-basica"];

  if (
    !user ||
    excludeRoutes.includes(location.pathname) ||
    (user.emailValidated && user.phoneValidated)
  )
    return null;

  if (!user.emailValidated && user.email)
    return <ValidatePendingEmail />;

  if (!user.phoneValidated)
    return <ValidatePendingPhone />;

  return null;
};

const mapStateToProps = (state: AppState) => ({
  user:
    (state.user.status === 'loaded' || state.user.status === 'updating') &&
    state.user.payload
});

export default withRouter(connect(mapStateToProps)(ValidatePending));
