import * as fetchMock from "fetch-mock";
import { Product, ReportDetailResult, url } from "../services/useReportsDetailGet";
import withDelay from "./helpers/withDelay";

const creditCard: ReportDetailResult["products"] = {
  consolidated: {
    numeroObligacionesDia: 2,
    saldoObligacionesDia: 963,
    cuotaObligacionesDia: 83,

    cantidadObligacionesMora: 1,
    saldoObligacionesMora: 127,
    cuotaObligacionesMora: 136,

    numeroObligaciones: 3,
    totalSaldo: 1090,
    totalCuota: 83 + 136, // coutaObligacionesDia + coutaObligacionesMora

    participacionDeuda: 29,
    valorMora: 136
  },
  owned: {
    upToDate: [
      {
        IdentificadorLinea: 20165146,
        FechaCorte: "31/05/2017",
        EstadoTitular: "NORM",
        NumeroCuotasMora: 0,
        Calidad: "PRIN",
        ClaseTarjeta: "SFN",
        SaldoObligacion: 963,
        MarcaTarjeta: "SFN",
        PaqueteInformacion: "0002",
        ValorMora: 0,
        Reestructurado: "NO",
        FechaApertura: "10/04/2007",
        MoraMaxima: "",
        NumeroCuotasPactadas: "",
        Comportamientos:
          "N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |",
        NombreEntidad: "SERFINANSA- SERVICIOS FINANCIE",
        FechaPermanencia: "",
        FechaPago: "",
        NumeroReestructuraciones: "",
        ParticipacionDeuda: 88,
        TipoEntidad: "CFC",
        TipoContrato: "CRE",
        ValorInicial: 1580,
        ModoExtincion: "",
        Ciudad: "SINCELEJO",
        CuotasCanceladas: "",
        ModalidadCredito: "CONS",
        Sucursal: "SINCELEJO",
        EstadoObligacion: "VIGE",
        EstadoContrato: "VIGE",
        NaturalezaReestructuracion: "",
        NumeroObligacion: 530639,
        LineaCredito: "TCR",
        ValorCuota: 83,
        TipoPago: ""
      },
      {
        IdentificadorLinea: 55742457,
        FechaCorte: "30/04/2017",
        EstadoTitular: "NORM",
        NumeroCuotasMora: 0,
        Calidad: "PRIN",
        ClaseTarjeta: "SFN",
        SaldoObligacion: 0,
        MarcaTarjeta: "SFN",
        PaqueteInformacion: "0002",
        ValorMora: 0,
        Reestructurado: "NO",
        FechaApertura: "10/04/2007",
        MoraMaxima: "",
        NumeroCuotasPactadas: "",
        Comportamientos:
          "|  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |N |N |N |N |N |N |R |",
        NombreEntidad: "SERFINANSA- SERVICIOS FINANCIE",
        FechaPermanencia: "",
        FechaPago: "",
        NumeroReestructuraciones: "",
        ParticipacionDeuda: 0,
        TipoEntidad: "CFC",
        TipoContrato: "CRE",
        ValorInicial: 0,
        ModoExtincion: "",
        Ciudad: "BARRANQUILLA",
        CuotasCanceladas: "",
        ModalidadCredito: "CONS",
        Sucursal: "PPAL BARRANQUILL",
        EstadoObligacion: "VIGE",
        EstadoContrato: "VIGE",
        NaturalezaReestructuracion: "",
        NumeroObligacion: 577822,
        LineaCredito: "TCR",
        ValorCuota: 0,
        TipoPago: ""
      }
    ],
    overdue: [
      {
        IdentificadorLinea: 24437869,
        FechaCorte: "31/05/2017",
        EstadoTitular: "NORM",
        NumeroCuotasMora: 4,
        Calidad: "PRIN",
        ClaseTarjeta: "CLA",
        SaldoObligacion: 127,
        MarcaTarjeta: "EXI",
        PaqueteInformacion: "0002",
        ValorMora: 136,
        Reestructurado: "NO",
        FechaApertura: "05/10/2006",
        MoraMaxima: "",
        NumeroCuotasPactadas: "",
        Comportamientos:
          "N |N |N |N |N |N |N |N |N |N |N |N |1 |1 |1 |1 |2 |3 |R |R |R |R |R |R |",
        NombreEntidad: "TUYA S.A. COMPAÑIA  DE  FINANC",
        FechaPermanencia: "",
        FechaPago: "",
        NumeroReestructuraciones: 0,
        ParticipacionDeuda: 12,
        TipoEntidad: "CFC",
        TipoContrato: "CRE",
        ValorInicial: 2149,
        ModoExtincion: "",
        Ciudad: "MEDELLIN",
        CuotasCanceladas: "",
        ModalidadCredito: "CONS",
        Sucursal: "REGIONAL MEDELLI",
        EstadoObligacion: "VIGE",
        EstadoContrato: "VIGE",
        NaturalezaReestructuracion: "",
        NumeroObligacion: 397783,
        LineaCredito: "TCR",
        ValorCuota: 136,
        TipoPago: ""
      }
    ]
  },
  coSigned: {
    upToDate: [],
    overdue: []
  }
};
const credit: ReportDetailResult["products"] = {
  consolidated: {
    numeroObligacionesDia: 1,
    saldoObligacionesDia: 0,
    cuotaObligacionesDia: 0,

    cantidadObligacionesMora: 0,
    saldoObligacionesMora: 0,
    cuotaObligacionesMora: 0,

    numeroObligaciones: 1,
    totalSaldo: 0,
    totalCuota: 0,

    participacionDeuda: 0,
    valorMora: 0
  },
  owned: {
    upToDate: [
      {
        IdentificadorLinea: 59525477,
        FechaCorte: "31/05/2017",
        EstadoTitular: "OTRO",
        NumeroCuotasMora: 0,
        Calidad: "PRIN",
        SaldoObligacion: 0,
        TipoEntidadOriginadoraCartera: "",
        PaqueteInformacion: "0006",
        ValorMora: 0,
        FechaTerminacion: "30/06/2021",
        Reestructurado: "NO",
        FechaApertura: "04/06/2008",
        EntidadOriginadoraCartera: "",
        MoraMaxima: "",
        NumeroCuotasPactadas: 0,
        Comportamientos:
          "N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |",
        NombreEntidad: "AV VILLAS",
        FechaPermanencia: "",
        FechaPago: "",
        NumeroReestructuraciones: "",
        Periodicidad: "MEN",
        ParticipacionDeuda: 0,
        TipoEntidad: "BCO",
        TipoContrato: "CRE",
        ValorInicial: 1200,
        ModoExtincion: "",
        ProbabilidadNoPago: 100,
        Ciudad: "SINCELEJO",
        CuotasCanceladas: "",
        ModalidadCredito: "CONS",
        Calificacion: "AA",
        Sucursal: "SINCELEJO",
        CubrimientoGarantia: "",
        EstadoObligacion: "VIGE",
        TipoGarantia: "",
        EstadoContrato: "VIGE",
        NaturalezaReestructuracion: "",
        NumeroObligacion: "5115TC",
        LineaCredito: "ROTA",
        ValorCuota: 0,
        TipoMoneda: "ML",
        TipoPago: ""
      }
    ],
    overdue: []
  },
  coSigned: {
    upToDate: [],
    overdue: []
  }
};
const contractsServices: ReportDetailResult["products"] = {
  consolidated: {
    numeroObligacionesDia: 1,
    saldoObligacionesDia: 187,
    cuotaObligacionesDia: 187,

    cantidadObligacionesMora: 1,
    saldoObligacionesMora: 424,
    cuotaObligacionesMora: 0,

    numeroObligaciones: 2,
    totalSaldo: 611,
    totalCuota: 187 + 0, // coutaObligacionesDia + coutaObligacionesMora

    participacionDeuda: 36,
    valorMora: 424
  },
  owned: {
    upToDate: [
      {
        IdentificadorLinea: 61641793,
        FechaCorte: "30/06/2012",
        ChequesDevueltos: "",
        EstadoTitular: "",
        NumeroCuotasMora: 0,
        NumeroMesesClausula: 0,
        Calidad: "PRIN",
        SaldoObligacion: 187,
        PaqueteInformacion: "0012",
        ValorMora: 0,
        FechaTerminacion: "05/07/2012",
        Reestructurado: "NO",
        FechaApertura: "14/06/2012",
        MoraMaxima: "",
        NumeroCuotasPactadas: 1,
        Comportamientos:
          "|  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |N |",
        NombreEntidad: "C.I. DOLCE S.A.",
        ValorCargoFijo: 0,
        FechaPermanencia: "",
        FechaPago: "",
        Periodicidad: "MEN",
        ParticipacionDeuda: 7,
        TipoEntidad: "FTEX",
        Plazo: "",
        TipoContrato: "CRE",
        ValorInicial: 187,
        DiasCartera: "",
        ModoExtincion: "",
        Vigencia: "IND",
        Ciudad: "MEDELLIN",
        CuotasCanceladas: 1,
        ClausulaPermanencia: 0,
        Sucursal: "PRINCIPAL",
        NumeroMesesContrato: "",
        EstadoObligacion: "VIGE",
        EstadoContrato: "VIGE",
        NumeroObligacion: 560286,
        LineaCredito: "ROTA",
        ValorCuota: 187,
        TipoPago: ""
      }
    ],
    overdue: [
      {
        IdentificadorLinea: 35022729,
        FechaCorte: "30/06/2012",
        ChequesDevueltos: "",
        EstadoTitular: "",
        NumeroCuotasMora: 0,
        NumeroMesesClausula: 0,
        Calidad: "PRIN",
        SaldoObligacion: 424,
        PaqueteInformacion: "0011",
        ValorMora: 424,
        Reestructurado: "NO",
        FechaApertura: "30/04/2008",
        MoraMaxima: "",
        NumeroCuotasPactadas: 0,
        Comportamientos:
          "N |N |1 |2 |3 |4 |5 |X |6 |11|11|11|11|11|12|12|12|12|12|12|12|12|12|R |",
        NombreEntidad: "COMCEL - COMUNICACION CELULAR",
        ValorCargoFijo: 0,
        FechaPermanencia: "",
        FechaPago: "",
        Periodicidad: "MEN",
        ParticipacionDeuda: 16,
        TipoEntidad: "CCEL",
        TipoContrato: "SRV",
        ValorInicial: 0,
        ModoExtincion: "",
        Vigencia: "IND",
        Ciudad: "BOGOTA",
        CuotasCanceladas: 0,
        ClausulaPermanencia: 0,
        Sucursal: "CREDITO Y ACTIVA",
        NumeroMesesContrato: "",
        EstadoObligacion: "CAST",
        EstadoContrato: "VIGE",
        NumeroObligacion: 464553,
        LineaCredito: "TELC",
        ValorCuota: 0,
        TipoPago: ""
      }
    ]
  },
  coSigned: {
    upToDate: [],
    overdue: [
      {
        IdentificadorLinea: 43597045,
        FechaCorte: "31/05/2012",
        ChequesDevueltos: "",
        EstadoTitular: "",
        NumeroCuotasMora: 4,
        NumeroMesesClausula: 0,
        Calidad: "CODE",
        SaldoObligacion: 2016,
        PaqueteInformacion: "0012",
        ValorMora: 2016,
        FechaTerminacion: "07/01/2011",
        Reestructurado: "NO",
        FechaApertura: "07/07/2010",
        MoraMaxima: "",
        NumeroCuotasPactadas: 6,
        Comportamientos:
          "|N |N |N |N |N |N |1 |2 |3 |5 |6 |7 |8 |9 |10|11|12|12|12|12|12|12|12|",
        NombreEntidad: "DISTRIBUIDORA  RAYCO  S.A.",
        ValorCargoFijo: 637,
        FechaPermanencia: "",
        FechaPago: "",
        Periodicidad: "MNF",
        ParticipacionDeuda: 77,
        TipoEntidad: "CPMM",
        Plazo: "120+",
        TipoContrato: "CRE",
        ValorInicial: 4821,
        DiasCartera: "",
        ModoExtincion: "",
        Vigencia: "DEF",
        Ciudad: "SINCELEJO",
        CuotasCanceladas: 2,
        ClausulaPermanencia: 0,
        Sucursal: "SINCELEJO",
        NumeroMesesContrato: 6,
        EstadoObligacion: "DUDO",
        EstadoContrato: "VIGE",
        NumeroObligacion: 918800,
        LineaCredito: "NORM",
        ValorCuota: 637,
        TipoPago: ""
      }
    ]
  }
};
const accounts: ReportDetailResult["products"] = {
  owned: {
    upToDate: [
      {
        IdentificadorLinea: 11040798,
        TipoEntidad: "BCO",
        FechaCorte: "31/05/2017",
        ChequesDevueltos: 0,
        TipoContrato: "CTE-INDIVIDUAL",
        ValorInicial: 0,
        DiasCartera: 0,
        Ciudad: "BOGOTA",
        PaqueteInformacion: "0001",
        Sucursal: "BS SINCELEJO",
        FechaTerminacion: "",
        FechaApertura: "09/07/2008",
        EstadoObligacion: "INACT",
        Comportamientos: "IIIIIIIIIIII",
        NombreEntidad: "DAVIVIENDA S.A.",
        NumeroObligacion: "003066",
        FechaPermanencia: ""
      },
      {
        IdentificadorLinea: 55543801,
        TipoEntidad: "BCO",
        FechaCorte: "31/05/2017",
        ChequesDevueltos: "",
        TipoContrato: "AHO-INDIVIDUAL",
        ValorInicial: "",
        DiasCartera: "",
        Ciudad: "BOGOTA",
        PaqueteInformacion: "0023",
        Sucursal: "BS SINCELEJO",
        FechaTerminacion: "",
        FechaApertura: "12/03/2008",
        EstadoObligacion: "INACT",
        Comportamientos: "IIIIIIIIIIII",
        NombreEntidad: "DAVIVIENDA S.A.",
        NumeroObligacion: "007537",
        FechaPermanencia: ""
      },
      {
        IdentificadorLinea: 33107479,
        TipoEntidad: "BCO",
        FechaCorte: "31/05/2017",
        ChequesDevueltos: "",
        TipoContrato: "AHO-INDIVIDUAL",
        ValorInicial: "",
        DiasCartera: "",
        Ciudad: "SINCELEJO",
        PaqueteInformacion: "0023",
        Sucursal: "SINCELEJO",
        FechaTerminacion: "",
        FechaApertura: "23/05/2006",
        EstadoObligacion: "INACT",
        Comportamientos: "IIIIIIIIIIII",
        NombreEntidad: "AV VILLAS",
        NumeroObligacion: 781989,
        FechaPermanencia: ""
      }
    ]
  }
};

fetchMock.get(
  `express:${process.env.REACT_APP_API_PATH}/${url}/:product`,
  matchedUrl => {
    const product = matchedUrl.split("/").slice(-1)[0] as Product;
    let products;
    switch (product) {
      case "credit-card":
        products = creditCard;
        break;

      case "credit":
        products = credit;
        break;

      case "contracts-services":
        products = contractsServices;
        break;

      case "accounts":
        products = accounts;
        break;

      default:
        return withDelay({
          status: 400,
          body: {
            errors: []
          }
        });
        break;
    }

    return withDelay({
      status: 200,
      body: {
        payload: {
          products
        }
      }
    });
  }
);