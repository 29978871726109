import React from "react";
import { connect } from "react-redux";
import Icon from "../../../components/UI/Icon";
import { getHref } from "../../../helpers/apiWhatsApp";
import { capitalizeEachWord } from "../../../helpers/capitalize";
import isSubscribed from "../../../helpers/isSubscribed";
import { AppState } from "../../../store/store";
import styles from "./ContactContainer.module.scss";

interface Props {
  user: {
    name: string;
    uuid: string;
    document: string;
    documentType: string;
  };
}

const ContactContainer = ({ user }: Props) => {
  const { name, uuid, document, documentType } = user;
  const msg = "Necesito ayuda";
  const data = [
    { label: "Nombre", value: capitalizeEachWord(name) },
    { label: documentType, value: document },
    { label: "Usuario", value: uuid },
  ];
  const userIsSubscribed = isSubscribed();

  return (
    <>
      <p className={styles.title}>
        Contactar un asesor
      </p>
      {userIsSubscribed && (
        <>
          <div className={styles.element}>
            <div className={styles.background}>
              <Icon
                icon="chat"
                className={styles.icon}
              />
            </div>
            <div className={styles.text}>
              <p className={styles.subtitle}>
                Contactar vía WhatsApp
              </p>
              <p className={styles.description}>
                <span>
                  Horario de atención:{" "}
                </span>
                <br />
                Lunes a Viernes de 8:00 am a 6:00 pm
                <br />
                Sábados de 10:00 am a 1:00 pm
              </p>
            </div>
            <a
              className={`btn btn-primary ${styles.btn}`}
              href={getHref(msg, data)}
              rel="noopener noreferrer"
              target="_blank"
              title="Contactar"
            >
              Contactar
            </a>
          </div>
          <hr className={styles.hr} />
        </>
      )}
      <div className={styles.element}>
        <div className={`${styles.background} ${styles.disabled}`}>
          <Icon
            icon="smart_toy"
            className={styles.icon}
          />
        </div>
        <div className={styles.text}>
          <p className={styles.subtitle}>
            Chatbot (Beta)
          </p>
          <p className={styles.description}>
            Próximamente con inteligencia artificial...
          </p>
        </div>
        <button
          className={`btn btn-secondary ${styles.btn}`}
          disabled
        >
          No disponible
        </button>
      </div>
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  user:
    state.user.status === "loaded"
      ? {
        uuid: state.security.userId,
        document: state.user.payload.documentNumber,
        documentType: state.user.payload.documentType,
        name: `${state.user.payload.name} ${state.user.payload.lastname}`,
      }
      : {
        uuid: "",
        name: "",
        document: "",
        documentType: "",
      }
});

export default connect(mapStateToProps)(ContactContainer);