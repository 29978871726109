import React from "react";
import { Route, Switch } from "react-router-dom";
import Page from "../../components/Layout/Page";
import Tabs from "../../components/UI/Tabs";
import AccountPreferencesContainer from "./containers/AccountPreferencesContainer";
import PasswordPreferencesContainer from "./containers/PasswordPreferencesContainer";
import ProfilePreferencesContainer from "./containers/ProfilePreferencesContainer";

const PreferencesView = () => {
  const tabs = [
    { to: "/preferencias/perfil", label: "Perfil" },
    { to: "/preferencias/cuenta", label: "Cuenta" },
    { to: "/preferencias/password", label: "Contraseña" },
  ];

  return (
    <Page back>
      <Tabs tabs={tabs} />
      <Switch>
        <Route
          path="/preferencias/cuenta"
          component={AccountPreferencesContainer}
        />
        <Route
          path="/preferencias/perfil"
          component={ProfilePreferencesContainer}
        />
        <Route
          path="/preferencias/password"
          component={PasswordPreferencesContainer}
        />
      </Switch>
    </Page >
  )
};

export default PreferencesView;
