import api from './api';

export interface SubProductType {
  value: string;
  label: string;
}
export interface ProductType {
  value: string;
  name: string;
  subProductsType: SubProductType[];
}

export interface Result {
  payload: ProductType[];
}

export const url = 'products/public/types';

const merchantsProductSubtypesGet = async (): Promise<ProductType[]> => {
  const response = await api.get(url, false, {}, true);
  return response.payload;
};

export default merchantsProductSubtypesGet;
