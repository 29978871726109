import * as Sentry from '@sentry/browser';

const removePassword = (obj: object) => {
  if (obj !== null && typeof obj === 'object') {
    Object.keys(obj).forEach(key => {
      if (obj[key] !== null && typeof obj[key] === 'object') {
        removePassword(obj[key]);
      } else {
        if (key.match(/password/gi)) {
          obj[key] = '';
        }
      }
    });
  }
};

// Use it only for unexpected errors
const logError = (error: string | object) => {
  const isProduction = process.env.NODE_ENV === "production";
  if (typeof error === 'object') {
    removePassword(error);
  }
  if (isProduction) {
    Sentry.captureException(error);
  } else {
    console.info("--- Sentry Capture Exception ---");
    console.log("Error: ", error);
  }
};

export default logError;
