import AccountProducts from "../types/report/AccountProducts";
import ConsolidatedItem from "../types/report/ConsolidatedItem";
import Footprint from "../types/report/Footprint";
import Products, { ProductsDetail } from "../types/report/Products";
import Report from "../types/report/Report";
import Score from "../types/report/Score";

/**
 * Transforma la información retornada por el web service en un formato
 * más manejable para presentar la información.
 * @param report objeto del web service
 */
const transformReport = (report: any): Report => {
  return {
    score: getScore(report),
    date: `${report.Fecha} ${report.Hora}`,
    creditCards: getPackage(report, "Tarjetas De Credito"),
    credits: getPackage(report, "Sector Financiero"),
    contractsAndServices: getPackage(report, "Sector Real"),
    accounts: getAccountPackage(report),

    consolidados: report.Consolidado && getConsolidados(report.Consolidado),
    historicalQuery:
      report.HuellaConsulta && getFootprints(report.HuellaConsulta)
  };
};

const getAccountPackage = (report: any): AccountProducts | undefined => {
  const open = getSectorItems(report, "CuentasVigentes");
  const closed = getSectorItems(report, "CuentasExtinguidas");

  return {
    open,
    closed
  };
};

/**
 * Get the report for credit cards, credits and contracts and services
 * @param report response from web service
 * @param packageName name of the package to extract from the report
 */
const getPackage = (report: any, packageName: string): Products | undefined => {
  if (!report.Consolidado) return;

  const record = report.Consolidado.ResumenPrincipal.Registro.find(
    (record: any) => record.PaqueteInformacion === packageName
  );

  if (!record) {
    return;
  }

  const consolidated: ConsolidatedItem = {
    // TODO: remove next element
    numeroObligacionesDia: record.NumeroObligacionesDia,
    saldoObligacionesDia: record.SaldoObligacionesDia,
    cuotaObligacionesDia: record.CuotaObligacionesDia,
    cantidadObligacionesMora: record.CantidadObligacionesMora,
    saldoObligacionesMora: record.SaldoObligacionesMora,
    cuotaObligacionesMora: record.CuotaObligacionesMora,
    numeroObligaciones: record.NumeroObligaciones,
    totalSaldo: record.TotalSaldo,
    totalCuota: record.CuotaObligacionesDia + record.CuotaObligacionesMora,
    participacionDeuda: record.ParticipacionDeuda,
    valorMora: record.ValorMora
  };

  const { owned, coSigned } = getPackageDetail(report, packageName);

  return {
    consolidated,
    owned,
    coSigned
  };
};

const getPackageDetail = (
  report: any,
  packageName: string
): { owned: ProductsDetail; coSigned: ProductsDetail } => {
  const isSectorReal = packageName === "Sector Real";
  const isCreditCard = packageName === "Tarjetas De Credito";

  const upToDate = getSectorItems(
    report,
    isSectorReal ? "SectorRealAlDia" : "SectorFinancieroAlDia"
  );
  const overdue = getSectorItems(
    report,
    isSectorReal ? "SectorRealEnMora" : "SectorFinancieroEnMora"
  );
  const closed = getSectorItems(
    report,
    isSectorReal ? "SectorRealExtinguidas" : "SectorFinancieroExtinguidas"
  );

  const filterPackage = (sectorItem: any) =>
    (sectorItem.PaqueteInformacion === "0002") === isCreditCard;

  const upToDateCreditCard =
    upToDate && (isSectorReal ? upToDate : upToDate.filter(filterPackage));
  const overdueCreditCard =
    overdue && (isSectorReal ? overdue : overdue.filter(filterPackage));
  const closedCreditCard =
    closed && (isSectorReal ? closed : closed.filter(filterPackage));

  const upToDateOwned =
    upToDateCreditCard && getSectorItemsByOwnership(upToDateCreditCard, true);
  const overdueOwned =
    overdueCreditCard && getSectorItemsByOwnership(overdueCreditCard, true);
  const closedOwned =
    closedCreditCard && getSectorItemsByOwnership(closedCreditCard, true);

  const upToDateCoSigned =
    upToDateCreditCard && getSectorItemsByOwnership(upToDateCreditCard, false);
  const overdueCoSigned =
    overdueCreditCard && getSectorItemsByOwnership(overdueCreditCard, false);
  const closedCoSigned =
    closedCreditCard && getSectorItemsByOwnership(closedCreditCard, false);

  return {
    owned: {
      upToDate: upToDateOwned,
      overdue: overdueOwned,
      closed: closedOwned
    },
    coSigned: {
      upToDate: upToDateCoSigned,
      overdue: overdueCoSigned,
      closed: closedCoSigned
    }
  };
};

/**
 *
 * @param sector
 * @param isOwned tell if owned products must be returned or products that
 *                belong to a third party. To determine if a product is owned,
 *                check table 2 on TransUnion docs (see dictionaries/report/calidad)
 */
const getSectorItemsByOwnership = (
  sector: object[],
  isOwned: boolean
): object[] => {
  return sector.filter(
    (sectorItem: any) =>
      ["PRIN", "ARRE"].includes(sectorItem.Calidad) === isOwned
  );
};

/**
 * Extract a group of sector items from the report by their name. On the web
 * service return, the sector will be an array if multiple sectors are present
 * or an object if only one is present. This method convert the second case to
 * an array.
 * @param report object from the webservice
 * @param sectorName name of the sector to extract
 */
const getSectorItems = (
  report: any,
  sectorName: string
): object[] | undefined => {
  if (report[sectorName] && report[sectorName].Obligacion) {
    return Array.isArray(report[sectorName].Obligacion)
      ? report[sectorName].Obligacion
      : [report[sectorName].Obligacion];
  } else {
    return undefined;
  }
};

/**
 * Obtiene el score del historial. Si no está presente o no es válido, puntaje
 * será NaN
 * @param report objeto del web service
 */
const getScore = (report: any): Score => {
  if (report.Score) {
    return {
      puntaje: report.Score.Puntaje ? report.Score.Puntaje : NaN
    };
  }
  return {
    puntaje: NaN
  };
};

/**
 * Obtiene los consolidados del historial.
 * @param consolidado objeto del web service
 */
const getConsolidados = (consolidado: any): Report["consolidados"] => {
  const consolidados = {
    "Tarjetas De Credito": "tarjetas de crédito",
    "Sector Financiero": "sector financiero",
    "Sector Solidario": "sector solidario",
    "Sector Real": "sector real",
    Otros: "Obligaciones con línea de crédito Factoring"
  };

  const registrosPrincipal =
    consolidado.ResumenPrincipal && consolidado.ResumenPrincipal.Registro;

  const principal = registrosPrincipal
    .filter((registro: any) => !!consolidados[registro.PaqueteInformacion])
    .map((registro: any) =>
      transformItemConsolidado(
        consolidados[registro.PaqueteInformacion],
        registro
      )
    );

  const participacionDeuda = principal.map((registro: ConsolidatedItem) => {
    return {
      value: registro.participacionDeuda
    };
  });

  return {
    total: transformItemConsolidado("Resumen total", consolidado.Registro),
    principal,
    participacionDeuda
  };
};

/**
 * Transforma un registro de consolidado del formato en que viene del
 * web service al formato como se usa en la aplicación
 * @param consolidado registro como viene del web service
 */
const transformItemConsolidado = (
  titulo: string,
  consolidado: any
): ConsolidatedItem => {
  return {
    numeroObligacionesDia: consolidado.NumeroObligacionesDia,
    saldoObligacionesDia: consolidado.SaldoObligacionesDia,
    cuotaObligacionesDia: consolidado.CuotaObligacionesDia,
    cantidadObligacionesMora: consolidado.CantidadObligacionesMora,
    saldoObligacionesMora: consolidado.SaldoObligacionesMora,
    cuotaObligacionesMora: consolidado.CuotaObligacionesMora,
    numeroObligaciones: consolidado.NumeroObligaciones,
    totalSaldo: consolidado.TotalSaldo,
    totalCuota:
      consolidado.CuotaObligacionesDia + consolidado.CuotaObligacionesMora,
    participacionDeuda: consolidado.ParticipacionDeuda,
    valorMora: consolidado.ValorMora
  };
};

/**
 * Obtiene el histórico de consultas del historial.
 * @param huellaConsulta objeto del web service
 */
export const getFootprints = (huellaConsulta: any): Footprint[] => {
  if (huellaConsulta && huellaConsulta.Consulta) {
    const consulta = huellaConsulta.Consulta;
    if (Array.isArray(consulta)) {
      return consulta.map((item: any) => ({
        fechaConsulta: item.FechaConsulta,
        nombreEntidad: item.NombreEntidad,
        nombreTipoEntidad: item.NombreTipoEntidad,
        ciudad: item.Ciudad,
        motivoConsulta: item.MotivoConsulta
      }));
    } else if (
      consulta.FechaConsulta ||
      consulta.NombreEntidad ||
      consulta.NombreTipoEntidad ||
      consulta.Ciudad ||
      consulta.MotivoConsulta
    ) {
      return [
        {
          fechaConsulta: consulta.FechaConsulta,
          nombreEntidad: consulta.NombreEntidad,
          nombreTipoEntidad: consulta.NombreTipoEntidad,
          ciudad: consulta.Ciudad,
          motivoConsulta: consulta.MotivoConsulta
        }
      ];
    }
  }
  return [];
};

export default transformReport;
