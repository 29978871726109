import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { clearAllMessages } from '../store/message/actions';
import store from '../store/store';


const ClearAllMessages: React.FC<RouteComponentProps> = ({ location }) => {
    useEffect(() => {
        store.dispatch(clearAllMessages())    
    }, [location.pathname])
    return null;
}

export default withRouter(ClearAllMessages);