import api from './api';

export interface ValidationEmailParams {
  code: string;
}

export const url = 'validation/email';

export const validationEmailPost = async (
  postData: ValidationEmailParams
): Promise<any> => {
  return await api.post(url, false, postData);
};
