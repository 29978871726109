import api from './api';

export interface ValidateCodeWaParams {}

export interface ValidateCodeWaResponse {
  token: string;
}

export const url = 'validate/code/wa';

const validateCodeWaPost = async (
  data: ValidateCodeWaParams
): Promise<ValidateCodeWaResponse> => {
  const response = await api.post(url, true, data);
  return response.payload;
};

export default validateCodeWaPost;
