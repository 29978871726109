import React from "react";
import { Alert as AlertType } from '../../../services/alertsGet';
import Alert from "./Alert";
import styles from "./DriverAlerts.module.scss";

export interface Props {
  alerts: AlertType[];
}

const DriverAlerts = ({ alerts }: Props) => {
  // Remove alert with code "19"
  const driverAlerts = alerts.filter(({ code }) => code !== "19")
  return (
    <div className="eb-card">
      <p className={styles.title}>
        A tu licencia de conducción
      </p>
      {driverAlerts.length
        ? (
          <>
            {driverAlerts.map(({ code, title, datetime, description }) => (
              <Alert
                key={code}
                title={title}
                datetime={datetime}
                description={description}
              />
            ))}
          </>
        )
        : (
          <p className={styles.text}>
            No tienes alertas para mostrar
          </p>
        )
      }
    </div>
  );
};

export default DriverAlerts;
