import api from './api';

export interface ChangePasswordParams {
  oldPassword: string;
  newPassword: string;
}

export const url = 'users/change-password';

const usersChangePasswordPost = (
  postData: ChangePasswordParams
): Promise<void> => {
  return api.post(url, true, postData);
};

export default usersChangePasswordPost;
