import { Form, Formik, FormikActions, FormikProps } from "formik";
import React from "react";
import * as yup from "yup";
import FormErrors from "../../../components/Form/FormErrors";
import InputField from "../../../components/Form/InputField";
import SubmitButton from "../../../components/Form/SubmitButton";
import Success from "../../../components/UI/Success";
import { data } from "../../../helpers/fixReport";
import processSubmitErrors from "../../../helpers/processSubmitErrors";
import reportsFixPost from "../../../services/reportsFixPost";
import styles from "./FixReportForm.module.scss";

export interface FormValues {
  message: string;
}

const FixReportForm = () => {
  const initialValues: FormValues = { message: "" };

  const validationSchem = yup.object().shape({
    message: yup.
      string().
      required("Debe ingresar el error a revisar en su historial crediticio")
  });

  const handleSubmit = async (
    values: FormValues,
    {
      setErrors,
      setStatus,
      setSubmitting,
      resetForm
    }: FormikActions<FormValues>
  ) => {
    try {
      setStatus({ sent: false });
      await reportsFixPost(values);
      resetForm();
      setStatus({ sent: true });
    } catch (error) {
      processSubmitErrors(error, values, setErrors, setStatus);
    }
    setSubmitting(false);
  }

  return (
    <div className={styles.content}>
      <p className={styles.title}>
        Reclamaciones
      </p>
      <p className={styles.text}>
        {data.description}
      </p>
      <p className={styles.title}>
        ¿Cómo funciona?
      </p>
      <ul className={styles.list}>
        {data.steps.map(step => (
          <li
            key={step}
            className={styles.text}
          >
            {step}
          </li>
        ))}
      </ul>
      <p className={styles.title}>
        Descripción
      </p>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchem}
        onSubmit={handleSubmit}
        render={({ status }: FormikProps<FormValues>) => (
          <Form className={styles.form}>
            <InputField
              name="message"
              label="Describe la corrección a solicitar"
              type="textarea"
              className={styles.field}
            />
            <FormErrors />
            <div className={styles.container}>
              <SubmitButton
                id="fix-report"
                text="Enviar"
                className={styles.button}
              />
            </div>
            {status && status.sent && (
              <Success className="m-0 mt-3 text-center">
                La solicitud se ha enviado
              </Success>
            )}
          </Form>
        )}
      />
    </div>
  )
};

export default FixReportForm;