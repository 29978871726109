import * as fetchMock from 'fetch-mock';
import { Payload, url } from '../services/useReportsSharedIdGet';
import withDelay from './helpers/withDelay';

const result: { [key: string]: Payload } = {
  preview: {
    id: 'preview',
    message:
      'Magna ullamco sit est exercitation nostrud reprehenderit reprehenderit excepteur eiusmod ad proident mollit dolor.',
    report: {
      date: "08/02/2019",
      document: '1000000',
      documentStatus: 'Vigente',
      lastname: 'Mi apellido',
      name: 'Yo mismo',
      email: 'hi@random.com',
      phone: '300123459',
      validated: true,
      financialProducts: '1',
      city: 'Pompeya',
      scoreRating: 'alto',
      seniorityBuro: '124 años'
    }
  },
  id01: {
    id: 'id01',
    message:
      'Magna ullamco sit est exercitation nostrud reprehenderit reprehenderit excepteur eiusmod ad proident mollit dolor.',
    report: {
      date: "08/02/2019",
      document: '12345678',
      documentStatus: 'Vigente',
      lastname: 'Rosa',
      name: 'Maria',
      email: 'test@gmail.com',
      phone: '300123459',
      validated: true,
      financialProducts: '7',
      city: 'Medellin',
      scoreRating: 'medio',
      seniorityBuro: '2 años'
    }
  },
  id02: {
    id: 'id02',
    message:
      'Magna ullamco sit est exercitation nostrud reprehenderit reprehenderit excepteur eiusmod ad proident mollit dolor.',
    report: {
      date: "08/02/2019",
      document: '12345678',
      documentStatus: 'Vigente',
      lastname: 'Jose',
      name: 'Juan',
      email: 'test2@gmail.com',
      phone: '305123459',
      validated: true,
      financialProducts: '2',
      city: 'Bogota',
      scoreRating: 'bajo-sin-historial',
      seniorityBuro: '1 año'
    }
  }
};

fetchMock.get(
  `express:${process.env.REACT_APP_API_PATH}/${url}/:id`,
  (matchedUrl, options) => {
    const id = matchedUrl.split('/').slice(-1)[0];

    if (!Object.keys(result).includes(id)) {
      return withDelay(
        {
          status: 460,
          body: {}
        },
        2000
      );
    }

    return withDelay(
      {
        status: 200,
        body: {
          payload: {
            ...result[id]
          }
        }
      },
      2000
    );
  }
);
