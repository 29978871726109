import api from './api';

export interface ValidationMobileParams {
  code: string;
}

export const url = 'validation/mobile';

export const validationMobilePost = async (
  postData: ValidationMobileParams
): Promise<any> => {
  return await api.post(url, true, postData);
};
