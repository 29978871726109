import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router';
import Rol from '../enums/Rol';
import { AppState } from '../store/store';

interface Props extends RouteProps {
  token?: string;
  currentRoles: Rol[];
}

const RedirectIfLoggedInRoute = React.memo(
  ({ token, currentRoles, component: Component, ...rest }: Props) => (
    <Route
      {...rest}
      render={props => {
        if (Component) {
          if (token) {
            return <Redirect to="/" />;
          }
          return <Component {...props} />;
        } else {
          throw new Error(
            'render and children props not implemented. Only component prop is supported.'
          );
        }
      }}
    />
  ),
  (prevProps: RouteProps, nextProps: RouteProps) => {
    return prevProps.path === nextProps.path;
  }
);

const mapStateToProps = (state: AppState) => {
  return {
    token: state.security.token,
    currentRoles: state.security.roles
  };
};

export default connect(mapStateToProps)(RedirectIfLoggedInRoute);
