import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikActions,
  FormikProps
} from "formik";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { Link } from "react-router-dom";
import { Button, FormFeedback, FormGroup, Input } from "reactstrap";
import * as yup from "yup";
import FormErrors from "../../../components/Form/FormErrors";
import SubmitButton from "../../../components/Form/SubmitButton";
import processSubmitErrors from "../../../helpers/processSubmitErrors";
import translateError from "../../../helpers/translateError";
import sendOtpPost from "../../../services/sendOtpPost";
import ApiError from "../../../types/ApiError";
import { ServiceError } from "../../../types/ServiceError";
import styles from "./LoginForm.module.scss";

export interface FormValues {
  documentId: string;
  password: string;
  code: string;
}

export interface Props {
  onSubmit: (data: FormValues) => void;
}

type Type = "password" | "code";
type Status = "init" | "sending" | "sent" | "error";
const delayAttemptTime = 30; // 30s


const LogInForm = ({ onSubmit }: Props) => {

  const [type, setType] = useState<Type>("code");
  const [inputCode, setInputCode] = useState(false);
  const [status, setStatus] = useState<Status>("init");

  const [error, setError] = useState("");
  const [timeLeft, setTimeLeft] = useState(0);
  const [enabledSendCode, setEnabledSendCode] = useState(true);
  const [timer, setTimer] = useState<NodeJS.Timer | null>(null);

  const initialValues: FormValues = {
    documentId: localStorage ? localStorage.getItem("_nbc_id_") || "" : "",
    password: "",
    code: "",
  };

  const validationSchema = yup.object().shape({
    documentId: yup
      .number()
      .typeError("Tu usuario es tu número de cédula")
      .required("Este campo es requerido"),
    code: yup.string().when("password", {
      is: value => !value,
      then: yup.string().required("Este campo es requerido").length(6, "El código tiene 6 dígitos"),
      otherwise: yup.string()
    }),
  });

  const onFormSubmit = async (
    values: FormValues,
    { setErrors, setStatus, setSubmitting }: FormikActions<FormValues>
  ) => {
    try {
      setStatus({ error: "" });
      await onSubmit(values);
    } catch (error) {
      processSubmitErrors(error, values, setErrors, setStatus);
      setSubmitting(false);
    }
  };

  const sendCode = async (document: string) => {
    if (status === "sending" || timeLeft) return;
    try {
      setError("");
      setStatus("sending");
      await sendOtpPost({ document });
      setInputCode(true);
      setStatus("sent");
      setTimeLeft(delayAttemptTime);
    } catch (error) {
      if (
        error instanceof ApiError &&
        error.response &&
        error.response.errors
      ) {
        const serviceErrors = error.response.errors as ServiceError[];
        const errors = translateError(serviceErrors);
        if (errors.length) {
          setError(errors[0]);
        } else {
          setError("Ocurrió un error inesperado, inténtalo más tarde");
        }
        if (error.code === 429) {
          setEnabledSendCode(false);
        }
      } else {
        setError("Ocurrió un error inesperado, inténtalo más tarde");
      }
      setStatus("error");
    }
  };

  useEffect(() => {
    if (timeLeft && !timer) {
      const interval = setInterval(() => setTimeLeft(prev => prev - 1), 1000)
      setTimer(interval)
    }
    else if (!timeLeft && timer) {
      clearInterval(timer)
      setTimer(null);
    }
  }, [timeLeft]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onFormSubmit}
      validationSchema={validationSchema}
      render={({
        values,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        setFieldError,
      }: FormikProps<FormValues>) => (
        <Form>
          <FormErrors />

          {/* Document */}
          {!inputCode &&
            <FormGroup>
              <p className={styles.label}>
                Documento de identidad
              </p>
              <Input
                type="text"
                name="documentId"
                tag={Field}
                placeholder="Documento"
                autoCapitalize="off"
                invalid={!!(errors.documentId && touched.documentId)}
                autoFocus
              />
              <ErrorMessage name="documentId" component={FormFeedback} />
            </FormGroup>
          }

          {/* OTP Code */}
          {inputCode &&
            <FormGroup>
              <p className={styles.label}>
                Ingresa el código que te enviamos a WhatsApp
              </p>
              <OtpInput
                value={values.code}
                onChange={(value) => {
                  setFieldValue("password", "")
                  setFieldValue("code", value);
                }}
                numInputs={6}
                renderInput={(props) => <input {...props} />}
                inputStyle={styles.inputCode}
                containerStyle={styles.containerCode}
                inputType="tel"
                shouldAutoFocus
              />
            </FormGroup>
          }

          {/* Error service OTP */}
          {type === "code" && error &&
            <p className={styles.error}>
              {error}
            </p>
          }

          {/* Password */}
          {type === "password" &&
            <FormGroup>
              <p className={styles.label}>
                Contraseña
              </p>
              <Input
                type="password"
                name="password"
                tag={Field}
                placeholder="Contraseña"
                invalid={!!(errors.password && touched.password)}
                onInput={() => setFieldValue("code", "")}
                autoFocus
              />
              <ErrorMessage name="password" component={FormFeedback} />
            </FormGroup>
          }

          {/* Send OTP code */}
          {type === "code" && !inputCode &&
            <Button
              color="primary"
              disabled={!values.documentId || status === "sending" || !!timeLeft || !enabledSendCode}
              className="w-100"
              onClick={() => {
                setFieldError("code", "");
                sendCode(values.documentId);
              }}
            >
              {status === "sending"
                ? <div className="spinner-border spinner-border-sm" role="status" />
                : "Enviar código de inicio de sesión"
              }
            </Button>
          }

          {/* Login Button */}
          {(type === "password" || inputCode) &&
            <SubmitButton
              className="w-100"
              text="Iniciar sesión"
              disabled={
                isSubmitting ||
                (type === "code" && values.code.length !== 6) ||
                (type === "password" && !values.password)
              }
            />
          }

          {/* Divider */}
          <div className={styles.divider}>O</div>

          {/* Change type */}
          <Button
            outline
            color="secondary"
            className="w-100"
            onClick={() => {
              setInputCode(false);
              setType(type === "code" ? "password" : "code");
            }}
          >
            Usar {type === "code" ? "contraseña" : "un código de inicio de sesión"}
          </Button>

          {/* Change password */}
          {type === "password" &&
            <Link to="/recuperar-contrasena" className={styles.action}>
              ¿Olvidaste tu contraseña?
            </Link>
          }

          {/* Change phone number */}
          {type === "code" && !inputCode &&
            <Link to="/recuperar-telefono" className={styles.action}>
              ¿Cambiaste tu número?
            </Link>
          }

          {/* Resent code */}
          {type === "code" && inputCode && enabledSendCode &&
            <p
              className={styles.action}
              style={{ cursor: status !== "sending" && !timeLeft ? "pointer" : "default" }}
              onClick={() => sendCode(values.documentId)}
            >
              {
                status === "sending"
                  ? "Enviando código..."
                  : timeLeft
                    ? `Enviar otro código en ${timeLeft} segundo${timeLeft !== 1 ? "s" : ""}`
                    : "Reenviar código"
              }
            </p>
          }

          {/* Signup */}
          <p className={`${styles.action} text-left mb-0 mt-4`}>
            ¿Primera vez en Enbanca? <Link to="/registro">Regístrate ahora.</Link>
          </p>
        </Form>
      )}
    />
  );
};

export default LogInForm;
