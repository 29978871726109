// Tabla 05 - Línea de crédito, categoría o ramo - LINEACR
// TransUnion - Tablas de convenciones - Información Comercial - Versión 1.34

const lineaCredito = {
  // Sector financiero, solidario
  '50RE': '50/50 Redescuento',
  APAG: 'Acuerdo',
  AEXC: 'Asociado Excelente',
  BACO: 'Banca Comunal',
  BISE: 'Bienes Y Servicios',
  BISO: 'Bienestar Social',
  CALA: 'Calamidad',
  CALK: 'Credialkosto',
  CALP: 'Creditos ALP',
  CCAR: 'Compra Cartera',
  CCFU: 'Compra de café a futuro',
  CCON: 'Celulares Conexel',
  CEXI: 'Crediexito',
  CDFA: 'CREDIFACIL',
  CRSI: 'CREDITO SI',
  CFEN: 'Capitalización Fomento Entidades',
  CFID: 'Comisiones De Fideicomiso',
  CICG: 'Cartera ordinaria ICG',
  CNXC: 'Conexel',
  CRLZ: 'Consolidado Rotativo Libranza',
  // CONS: 'Consumo',
  CONT: 'Constructor',
  CPRI: 'Credi-Prima',
  CRAP: 'Credirápido',
  CROT: 'Consolidado Rotativo',
  CDEM: 'Comercial Desarrollo Empresarial',
  COMT: 'Comercial Tesoreria',
  // CALP: 'Créditos ALP',
  // CCON: 'Crediconsumo',
  CEMP: 'Crediempresa',
  CNEG: 'Credinegocios',
  CDBM: 'Creditos de Bajo Monto',
  // CRSI: 'Crédito SI',
  CSAL: 'Crédito de Salud',
  CYA: 'Credi-Ya',
  CORE: 'Con Recurso',
  EDEX: 'Educativo del Exterior',
  EDUC: 'Educativo',
  EMER: 'Emergente',
  EMPL: 'Empleado',
  ENOA: 'Empleados no Asociados',
  ESPC: 'Especializaciones',
  EXLI: 'Exito Libre Inversión',
  EXNO: 'Exito Normal',
  EXVH: 'Exito Vehículo',
  FACT: 'Factoring',
  FAGU: 'Fondo del Agua',
  FDPT: 'Fomento Departamento',
  FEFU: 'Fertifuturo',
  FENT: 'Fomento Entidades',
  FIDU: 'Fiduciaria',
  FGRO: 'Finagro',
  FMUN: 'Fomento Municipios',
  FOGA: 'Fogafin',
  FSEG: 'Financiación De Seguros',
  GFNG: 'Garantías Pagadas al Fondo Nacional de Garantías',
  GRUP: 'Grupal',
  GSOL: 'Grupo Solidario',
  HOSP: 'Hospitalización',
  IDON: 'Idónea',
  INDV: 'Individual',
  INMD: 'Inmediato',
  ICDC: 'Insumos Cultivos de Cafe',
  LAPA: 'Leasing Apalancado',
  LBAC: 'Lease-Back',
  LCEN: 'Linea Caf Entidades',
  LCMU: 'Linea Caf Municipios',
  LEAS: 'Leasing',
  LEXP: 'Leasing de Exportación',
  LEXS: 'Leasing de Exportación Sindicado',
  LFIN: 'Leasing Financiero',
  LFIS: 'Leasing Financiero Sindicado',
  LIBZ: 'Libranza',
  LBAG: 'Libranza Agropecuario',
  LBCC: 'Libranza Compra de Cartera',
  LBCL: 'Libranza Calamidad',
  LBCO: 'Libranza Comercio',
  LBCF: 'Libranza Coonfisocial',
  LICN: 'Libranza Credito Navideño',
  LBDE: 'Libranza Destinación Específica',
  LBED: 'Libranza Educativo',
  LBEE: 'Libranza Educativo en el Exterior',
  LBEG: 'Libranza Emergente',
  LBEM: 'Libranza Empleado',
  LBFS: 'Libranza Financiación de Seguros',
  LBIN: 'Libranza Industria',
  LBLD: 'Libranza Libre Destino',
  LBLI: 'Libranza Libre Inversión',
  LBMB: 'Libranza Viaje Mundial Brasil',
  LBME: 'Libranza Muebles y Enseres',
  LBMC: 'Libranza Microcredito',
  LBNO: 'Libranza Normal',
  LMOF: 'Libranza Oficial',
  LBOR: 'Libranza Ordinario',
  LBPD: 'Libranza Pago de Deudas',
  LBRC: 'Libranza Recreación',
  LBRM: 'Libranza Reestructuraciones por Mora',
  LBRT: 'Libranza Rotativo',
  LBSA: 'Libranza Salud',
  LBSM: 'Libranza Suministro',
  LBVH: 'Libranza Vehículo',
  LBVI: 'Libranza Vivienda',
  LIMP: 'Leasing de Importación',
  LIMS: 'Leasing de Importación Sindicado',
  LINF: 'Leasing de Infraestructura',
  LINM: 'Leasing Inmobiliario',
  LINS: 'Leasing Internacional Sindicado',
  LINT: 'Leasing Internacional',
  LINV: 'Libre Inversión',
  LMUN: 'Leasing Municipal',
  LOPE: 'Leasing Operativo',
  LOPS: 'Leasing Operativo Sindicado',
  LSIN: 'Leasing Sindicado',
  LHAB: 'Leasing Habitacional',
  MYPE: 'Micros y Pequeñas Empresas',
  MEXT: 'Moneda Extranjera',
  NMCY: 'Nuevo Milenio Crediya Seccional',
  NMIL: 'Nuevo Milenio',
  NORM: 'Normal',
  OCPR: 'Órdenes de Compra',
  OICG: 'Otros ICG',
  OPLZ: 'Operaciones a Plazo',
  ORDI: 'Ordinario',
  ORES: 'Operaciones Respo',
  OSIM: 'Operaciones Simultaneas',
  OTRO: 'Otro',
  PAGD: 'Pago de Deudas',
  PESP: 'Pago en Especie',
  PENS: 'Pensionado',
  PPCR: 'Prestamo Personal Credifacil',
  PIGN: 'Pignoración',
  RECR: 'Recreación',
  REDE: 'Redescuento',
  REES: 'Reestructurados',
  REPL: 'Reparaciones Locativas Y Mejoras De Vivienda',
  RFCA: 'Redescuento Findeter Capitalizado',
  RFEN: 'Redescuento Findeter Entidades',
  RFMU: 'Redescuento Findeter Municipios',
  ROTA: 'Rotativo',
  SAME: 'Política de saldos menores banco de Bogotá',
  SARR: 'Servicio de Arrendamiento',
  SIRE: 'Sin Recurso',
  SICG: 'Sobregiro ICG',
  SOBR: 'Sobregiro',
  SOLI: 'Solidario',
  SUBL: 'Subleasing',
  SUEM: 'Sufi Empleado',
  SULE: 'Sufi Leasing',
  SULI: 'Sufi Libre Inversión',
  SULM: 'Sufi Leasing de Importación',
  SUMI: 'Suministro',
  SUNO: 'Sufi Normal',
  SURO: 'Sufi Rotativo',
  SUVE: 'Sufi Vehículo',
  TACN: 'Taquilla Credito Navideño',
  TADE: 'Taquilla Destinacion Especifica',
  TAMC: 'Taquilla Microcredito',
  TAOF: 'Taquilla Oficial',
  TARM: 'Taquilla Reestructuraciones por Mora',
  TCR: 'Tarjeta De Crédito',
  TESE: 'Tesorería Entidades',
  TESM: 'Tesorería Municipios',
  TITU: 'TITUL',
  TRNS: 'Transitorio',
  UNIV: 'Universidad',
  UROT: 'Utilización Rotativo',
  VBIE: 'Venta de Bienes',
  VCAR: 'Venta Cartera',
  VEHI: 'Vehículo',
  VCDC: 'Vivienda Compra De Cartera',
  VIS: 'Vivienda de Interés Social',
  VIVI: 'Vivienda',
  MICR: 'Microcrédito',
  PDIR: 'Pago Directo',
  ANOM: 'Adelanto De Nomina',
  AFAC: 'Anticipo De Facturas',

  // Sector real
  AINM: 'Arrendamiento Inmobiliario',
  APOR: 'Aportes Parafiscales',
  ASES: 'Asesorías',
  CABL: 'Cable',
  // CCAR: 'Compra de Cartera',
  CCMP: 'Caja de Compensación',
  CFAC: 'Compra por Facturas',
  CLUB: 'Club Social',
  CMPR: 'Comparendos Tránsito Y Transporte',
  CPOS: 'Cheques Posfechados',
  CRED: 'Cupo Crédito',
  // EMPL: 'Empleado',
  EMPR: 'Empresarial',
  FCON: 'Facturas Consolidadas',
  FGNV: 'Financiación Gnv',
  FINS: 'Fallos de Inspección',
  GASO: 'Gasodomesticos',
  HOTE: 'Hotelería y turismo',
  IEDU: 'Institución Educativa',
  IMPU: 'Impuestos',
  INET: 'Internet',
  INFR: 'Infraestructura',
  // LIBZ: 'Libranza',
  LOGI: 'Libre Inversión LINV Logistica',
  MDIO: 'Sector Medios',
  MEDI: 'Medidor',
  MENS: 'Mensajería especializada',
  MTEC: 'Multas Revisión Técnico mecánica',
  MTPU: 'Multas Empresas Transporte Publico',
  NORE: 'No Reportada',
  // NORM: 'Normal',
  PGRE: 'Pagaré',
  // RECR: 'Recreación',
  RENT: 'Renting',
  // ROTA: 'Rotativo',
  SAGU: 'Servicio de Agua',
  SALD: 'Servicios de Salud',
  SBEE: 'Servicio Beeper',
  SEGU: 'Seguridad',
  SENE: 'Servicio Energía',
  SGAS: 'Servicio de Gas',
  SPUB: 'Servicios Públicos',
  STEL: 'Servicio Teléfono',
  SUSC: 'Suscripciones',
  TCEL: 'Telefonía Celular',
  TCIA: 'Teléfono Comercial',
  // TCR: 'Tarjeta de Crédito Sector Real',
  TELC: 'Telecomunicaciones',
  TELF: 'Teléfono',
  TMER: 'Transporte de Mercancias',
  // VCAR: 'Venta de Cartera',
  // VEHI: 'Vehículo',
  // VIVI: 'Vivienda',
  VPRV: 'Vigilancia Privada',
  VEDI: 'Venta directa',
  VDAC: 'Venta directa',
  VDAR: 'Venta directa rotativo',
  SEPM: 'Saldos Adeudados EPM',
  TEMP: 'Servicios Temporales de Personal',
  RESF: 'Responsabilidad Fiscal',
  // AINM: 'Afianzamiento Inmobiliario',
  ARRT: 'Arrendamiento',
  ALQE: 'Alquiler de equipos para construcción',
  MSVE: 'Monitoreo satelital vehicular',
  LDSF: 'Libranza de Servicios Funerarios',
  SFUN: 'Servicios Funerarios',
  LISE: 'Libranza',
  LAAJ: 'Libranza en Asesoria y Asistencia Juridica',
  MCRES: 'Microcredito',
  SRDO: 'Servicio de reparaciones domiliciarias',
  FEQM: 'Financiacion equipo movil',
  // CONS: 'Consumo',
  // Sector asegurador
  ACCP: 'Accidentes Personales',
  ACUI: 'Acuicultura',
  AGRI: 'Agrícola',
  AUTO: 'Automóviles',
  AVIA: 'Aviación',
  BARR: 'Bienes en Arrendamiento',
  CCIA: 'Crédito Comercia',
  CDEB: 'Corriente Débil',
  CEXP: 'Crédito Exporta',
  CINS: 'Crédito por Insolven',
  CMPL: 'Cumplimiento',
  CVID: 'Colectivo Vida',
  DESE: 'Desempleo',
  ECOS: 'Enfermedad Costo',
  EQEL: 'Equipo Electrónico',
  EQMA: 'Equipo y Maquinaria',
  ESCA: 'Estabilidad y Calidad',
  EXEQ: 'Exequias',
  GEDU: 'Grupo Educativo',
  GRAN: 'Granizo',
  HOGR: 'Hogar',
  IFAM: 'Integral Familiar',
  INCE: 'Incendio',
  JUDI: 'Judicial',
  LCES: 'Lucro Cesante',
  MANJ: 'Manejo',
  MCOM: 'Multiriesgo Comercial',
  MIND: 'Multiriesgo Industrial',
  MPET: 'Minas Y Petróleos',
  MROT: 'Mont Y Rot Maq',
  NCAS: 'Navegación Y Casco',
  OCIV: 'Obras Civiles Termin',
  // OTRO: 'Otro',
  P100: 'Pensiones Ley100',
  PJUB: 'Pensiones de Jubilacion',
  PVOL: 'Pensiones Voluntarias',
  RCIV: 'Responsabilidad Civil',
  RCON: 'Riesgo Contratista',
  RMON: 'Todo Riesgo Montaje',
  RPRO: 'Riesgos Profesionales',
  SALU: 'Salud',
  SEMO: 'Semovientes',
  SOAT: 'Soat',
  SPRE: 'Seguros Previsión',
  SROY: 'Seguro Contra Roya',
  SUST: 'Sustracción',
  TERR: 'Terremoto',
  // TITU: 'TITUL',
  TRAN: 'Transporte',
  VGRU: 'Vida Grupo',
  VIDR: 'Vidrios',
  VIND: 'Vida Individual',
  LIBR: 'Libranza'
};

export default lineaCredito;
