import React from "react";
import Icon from "../../../components/UI/Icon";
import styles from "./HeaderValidation.module.scss";

interface Props {
  message: string;
}

const HeaderValidation = ({ message }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.shape}>
        <Icon
          icon="how_to_reg"
          className={styles.icon}
          fill
        />
      </div>
      <p className={styles.title}>
        Validación de identidad
      </p>
      <p className={styles.message}>
        {message}
      </p>
    </div>
  )
}

export default HeaderValidation;