import api from "./api";

export interface PlanResponse {
  uuid: string;
  name: string;
  description: string;
  days: number;
  cost: number;
  isDefault: boolean;
  profits: string;
}

export const url = "plans";

const plansGet = async (): Promise<PlanResponse[]> => {
  return await api.get(`${url}`, false);
};

export default plansGet;
