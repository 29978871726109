import React, { useEffect, useState } from "react";
import Loading from "../../../../../components/UI/Loading";
import offersGet, { Offer as OfferType } from "../../../../../services/offersGet";
import Message from "../Message";
import Offer from "./Offer";

const OffersContainer = () => {
  const [loading, setLoading] = useState(true);
  const [offers, setOffers] = useState<OfferType[]>([]);

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const offers = await offersGet();
        setOffers(offers);
        setLoading(false);
      } catch {
        setLoading(false);
      }
    }
    fetchOffers();
  }, [])

  if (loading)
    return <Loading />

  if (!offers.length) {
    return (
      <Message
        title="No hay productos financieros"
        description="En este momento no hay productos financieros de nuestros aliados que puedas solicitar."
      />
    )
  }

  return (
    <>
      {offers.map(offer => (
        <Offer
          key={offer.offerId}
          offer={offer}
        />
      ))}
    </>
  )
}

export default OffersContainer;