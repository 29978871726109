import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { Data, getHref } from "../../../helpers/apiWhatsApp";
import { capitalizeEachWord } from "../../../helpers/capitalize";
import { AppState } from "../../../store/store";
import styles from "./FailedValidation.module.scss";

interface Props {
  user: {
    name: string;
    uuid: string;
    document: string;
    documentType: string;
  };
  tryAgain: () => void;
}

const FailedValidation = ({ user, tryAgain }: Props) => {
  const msg = "Tengo problemas para validar mi identidad";
  const [data, setData] = useState<Data[]>([]);

  useEffect(() => {
    setData([
      { label: "Nombre", value: capitalizeEachWord(user.name) },
      { label: user.documentType, value: user.document },
      { label: "Usuario", value: user.uuid },
    ])
  }, [user]);

  return (
    <div className={styles.container}>
      <p className={styles.text}>
        Ocurrió un error durante la validación.
      </p>
      <div className={styles.grid}>
        <Button
          outline
          color="primary"
          id="eid-try-again"
          onClick={tryAgain}
          className={styles.btn}
        >
          Intentar de nuevo
        </Button>
        <a
          target="_blank"
          title="WhatsApp"
          href={getHref(msg, data)}
          rel="noopener noreferrer"
          id="eid-manual-validation"
          className={`btn btn-primary ${styles.btn}`}
        >
          Comunicarme con un asesor
        </a>
      </div>
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  user:
    state.user.status === "loaded"
      ? {
        name: `${state.user.payload.name} ${state.user.payload.lastname}`,
        uuid: state.security.userId,
        document: state.user.payload.documentNumber,
        documentType: state.user.payload.documentType,
      }
      : {
        name: "",
        uuid: state.security.userId,
        document: "",
        documentType: "",
      }
});

export default connect(mapStateToProps)(FailedValidation);