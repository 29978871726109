import React, { useState } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { Dispatch } from "redux";
import hasCamera from "../../../helpers/hasCamera";
import hasMicrophone from "../../../helpers/hasMicrophone";
import { setHasPermissions as setHasPermissionsAction } from "../../../store/UI/actions";
import Device from "./Device";
import styles from "./PermissionsValidation.module.scss";

interface Props {
  onPermisions: (hasPermissions: boolean) => void;
  setHasPermissions: (hasPermission: boolean) => void;
}

type Device = "camera" | "microphone";

const PermissionsValidation = ({ onPermisions, setHasPermissions }: Props) => {
  const [enabled, setEnabled] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [permissions, setPermissions] = useState({ camera: false, microphone: false });
  const devices: Device[] = ["microphone", "camera"];

  const getPermissions = async () => {
    setRequesting(true);
    setEnabled(false);
    const cameraPermissions = await hasCamera();
    const microphonePermissions = await hasMicrophone();
    setPermissions({
      camera: cameraPermissions.allowCamera,
      microphone: microphonePermissions.allowMicrophone
    })
    setRequesting(false);
    const allow = cameraPermissions.allowCamera && microphonePermissions.allowMicrophone;
    setHasPermissions(allow);
    onPermisions(allow);
  }

  const refresh = () => location.reload();

  return (
    <div className={styles.container}>
      <p className={styles.text}>
        Necesitamos acceder a tu cámara y micrófono para verificar tu identidad personal.
      </p>
      <div className={styles.permissions}>
        {devices.map(device => (
          <div
            key={device}
            className={styles.device}
          >
            {
              requesting
                ? <div className={`spinner-border text-primary ${styles.requesting}`} role="status" />
                : <Device device={device} permission={permissions[device]} />
            }
          </div>
        ))}
      </div>
      {enabled && (
        <Button
          color="primary"
          id="eid-permissions"
          onClick={getPermissions}
        >
          Otorgar permisos
        </Button>
      )}
      {!requesting && !enabled && (!permissions.camera || !permissions.microphone) && (
        <>
          <Button
            color="primary"
            id="eid-refresh"
            onClick={refresh}
          >
            Volver a cargar
          </Button>
          <p className={styles.text}>
            Vuelve a cargar la aplicación y asegúrate de otorgar los permisos necesarios para continuar.
          </p>
        </>
      )}
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setHasPermissions: (show: boolean) => dispatch(setHasPermissionsAction(show))
  }
}

export default connect(null, mapDispatchToProps)(PermissionsValidation);