import { useEffect, useState } from "react";
import serviceBuilder from "../helpers/serviceBuilder";
import { ServiceStatus } from "../types/ServiceStatus";
import Products from "../types/report/Products";

export type Product =
  | "accounts"
  | "credit-card"
  | "credit"
  | "contracts-services";

export interface Params {
  product: Product;
  dispatch: boolean;
}

export interface ReportDetailResult {
  products: Products;
}

export const url = "reports/detail";

const useReportsDetailGet = ({ product, dispatch }: Params) => {
  const [report, setReport] = useState<ServiceStatus<ReportDetailResult>>({
    status: "init"
  });

  useEffect(() => {
    const { start, abort } = serviceBuilder<Params, ReportDetailResult>("get", {
      url: `${url}/${product}`,
      set: setReport,
      auth: true
    });
    dispatch && start();
    return () => abort();
  }, [product, dispatch]);

  return report;
};

export default useReportsDetailGet;
