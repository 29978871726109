// Tabla 16 - Plazo
// TransUnion - Tablas de convenciones - Información Comercial - Versión 1.34

const plazo = {
  '10/D': 'A 10 días o menos',
  '120+': 'A 120 días o mas',
  '15/D': 'A 15 días',
  '30/D': 'A 30 días',
  '60/D': 'A 60 días',
  '90/D': 'A 90 días',
  '12M': 'Crédito a 12 meses o menos',
  '24M': 'Crédito a 24 meses',
  '36M': 'Crédito a 36 meses',
  '48M': 'Crédito a 48 meses o mas',
  '12/4': 'Crédito entre 12 y 24 meses',
  OTRO: 'Otro'
};

export default plazo;
