import { Field, Form, Formik } from 'formik';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import Button from 'reactstrap/lib/Button';
import styled from 'styled-components';
import { object, string } from 'yup';
import Payment from "../../../images/payment.png";
import { TextBox } from './NequiPaymentView';


const PaymentIcon = styled.div`
  height: 100px;
  width: 100px;
  background-image: url(${Payment});
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 700px) {
    height: 200px;
    width: 200px;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  label {
    color: #8c8d8e;
 }
 small{
     color:#dc3545;
     }
p{ color:#1e2c59;}
b{
    padding:10px;
   
}
`;

export const GeneralContent = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-bottom: 30px;

  > div {
    max-width: 305px;
    flex-grow: 1;
    @media (min-width: 700px) {
      max-width: 650px;
    }
  }
`;

const NumberField = styled.div`
display: flex;
justify-content:center;
flex-direction: column;
padding-bottom:10px;
align-items: center;

`;

export const InputField = styled(Field)`
  border: 1px solid #d6d6d6;
  border-radius:5px;
  padding: 0.5em;
  font-size: 15px;
  color:#8c8d8e;
  width:300px;
`;

export interface FormValues {
    phone: string;
    paymentMethod: string;
}

const SelectItems = styled.div`
display:flex;
flex-direction:column;
align-items:center;
padding:10px;
border-radius:5px;
@media (min-width: 700px) {
    background:#f8f9fa;
    color:#fff;
}

 
 div{
    text-align:left;
    width: 260px;
 
 }



`;


export const Buttons = styled.div`
display: flex;
justify-content:center;
`;




const PaymentView = ({ history }: RouteComponentProps) => {
    const initialValues: FormValues = { phone: "3002345667", paymentMethod: "" };

    return (
        <GeneralContent >

            <PaymentIcon />
            <TextBox>

                <Formik
                    initialValues={initialValues}
                    validationSchema={object().shape({
                        phone: string().matches(
                            /^\d{1,25}$/,
                            'Ingrese valor adecuado'),
                        paymentMethod: string().required('Seleccione un método de pago'),
                    })}
                    onSubmit={async values => {
                        history.push('/nequi-payment')
                    }
                    }
                >
                    {({ errors, touched, values, setFieldValue }) => (
                        <Form>

                            <InputGroup>
                                <b><p >Selecciona el servicio de pago que deseas: </p></b>
                                <SelectItems >
                                    <div>
                                        <label>
                                            <input
                                                type="radio"
                                                name="test"
                                                value="a"
                                                checked={values.paymentMethod === "a"}
                                                onChange={() => setFieldValue("paymentMethod", "a")}
                                            />&nbsp;Actualización una vez.
                                        </label>
                                        <label>
                                            <input
                                                type="radio"
                                                name="test"
                                                value="b"
                                                checked={values.paymentMethod === "b"}
                                                onChange={() => setFieldValue("paymentMethod", "b")}
                                            />&nbsp;Actualización mensual por tres meses.
                                        </label>
                                    </div>
                                </SelectItems>
                                {errors.paymentMethod && touched.paymentMethod ? (
                                    <div>
                                        <small>{errors.paymentMethod}</small>
                                    </div>
                                ) : null}
                                <b> <p> Si tu cuenta de NEQUI tiene un celular diferente al que tienes regsitrado en ENBANCA por favor ingresa el celular con el que vas a efectuar el pago.</p> </b>
                                <NumberField>
                                    <InputField className="max-width" name="phone" />
                                    {errors.phone && touched.phone ? (
                                        <div><small>{errors.phone}</small></div>
                                    ) : null}
                                </NumberField>
                            </InputGroup>

                            <Buttons >
                                <Button type="submit" color="primary">Iniciar pago</Button>
                            </Buttons>




                        </Form>
                    )}
                </Formik>
            </TextBox>
        </GeneralContent >
    );
};

export default PaymentView;