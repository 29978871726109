import React from "react";
import { Route, Switch } from "react-router-dom";
import Page from "../../components/Layout/Page";
import { assureds, credits, saves } from "../../dictionaries/reasons";
import ReasonContainer from "./containers/ReasonContainer";

const ReasonView = () => {
  return (
    <Page back>
      <Switch>
        <Route path="/motivo/seguros">
          <ReasonContainer label="seguro" reasons={assureds} />
        </Route>
        <Route path="/motivo/ahorros">
          <ReasonContainer label="ahorro" reasons={saves} />
        </Route>
        <Route path="/motivo/creditos">
          <ReasonContainer label="cédito" reasons={credits} />
        </Route>
      </Switch>
    </Page>
  )
}

export default ReasonView;