import { Form, Formik, FormikProps } from "formik";
import React from "react";
import OtpInput from "react-otp-input";
import { FormGroup } from "reactstrap";
import * as yup from 'yup';
import SubmitButton from "../../../components/Form/SubmitButton";
import styles from "./CodeInput.module.scss";

export interface Props {
  success: (code: string) => void;
}

export interface FormValues {
  code: string;
}

const CodeInput = ({ success }: Props) => {
  const icon = require("../../../images/success.svg");
  const validationSchema = yup.object().shape({
    code: yup.string()
      .required('Este campo es requerido')
      .length(6, "El código tiene 6 dígitos"),
  });

  const handleSubmit = (values: FormValues) => {
    success(values.code);
  }

  return (
    <>
      <img src={icon} />
      <Formik
        initialValues={{ code: "" }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        render={({ values, setFieldValue }: FormikProps<FormValues>) => (
          <Form className="mt-4">
            <FormGroup>
              <p className={styles.label}>
                Ingresa el código que te enviamos a WhatsApp
              </p>
              <OtpInput
                value={values.code}
                onChange={(value) => setFieldValue("code", value)}
                numInputs={6}
                renderInput={(props) => <input {...props} />}
                inputStyle={styles.inputCode}
                containerStyle={styles.containerCode}
                inputType="tel"
                shouldAutoFocus
              />
            </FormGroup>
            <SubmitButton
              className={`${styles.btn} px-5 rounded-pill`}
              text="Enviar"
              disabled={values.code.length !== 6}
            />
          </Form>
        )}
      />
    </>
  )
}

export default CodeInput;
