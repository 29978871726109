import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { showWppButton as showWppButtonAction } from "../../../store/UI/actions";
import HeaderValidation from "../components/HeaderValidation";
import Interview from "../components/Interview";
import styles from "./ValidationContainerInterview.module.scss";

interface Props {
  showWppButton: (show: boolean) => void;
}

const ValidationContainerInterview = ({ showWppButton }: Props) => {

  useEffect(() => {
    showWppButton(false);
  }, []);

  return (
    <div className={styles.container}>
      <HeaderValidation
        message="Por motivos de seguridad debemos realizar algunas validaciones adicionales."
      />
      <Interview />
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    showWppButton: (show: boolean) => dispatch(showWppButtonAction(show)),
  }
}

export default connect(null, mapDispatchToProps)(ValidationContainerInterview);