import React from 'react';
import { Table } from 'reactstrap';
import styles from "./FootprintsTable.module.scss";

export interface Data {
  date: string;
  entity: string;
  entityType: string;
  city: string;
  reason: string;
}

interface Props {
  data: Data[];
}

const FootprintsTable = ({ data }: Props) => {
  const columns = [
    { label: "Fecha", value: "date" },
    { label: "Entidad", value: "entity" },
    { label: "Tipo de entidad", value: "entityType" },
    { label: "Ciudad", value: "city" },
    { label: "Motivo de consulta", value: "reason" },
  ]

  return (
    <Table
      className={styles.table}
      hover
      responsive
    >
      <thead>
        <tr>
          {columns.map(({ label }) => (
            <th
              key={label}
              className={styles.th}
            >
              {label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((data, i) => (
          <tr
            key={i}
            className={styles.tr}
          >
            {columns.map(({ value }) => (
              <td
                key={value}
                className={styles.td}
              >
                {data[value] || value}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  )
};

export default FootprintsTable;
