import dayjs from 'dayjs';
import refreshGet from '../services/refreshGet';
import { setToken } from '../store/security/actions';
import store from '../store/store';

const refreshToken = async (onlyIfNearToExpire = false) => {
  if (onlyIfNearToExpire) {
    const { security } = store.getState();
    const { token, tokenIssueDate } = security;
    const minutes = parseInt(
      process.env.REACT_APP_AUTO_REFRESH_TOKEN_IN_MINUTES || '10',
      10
    );
    if (token && tokenIssueDate) {
      const tokenStillValid = dayjs(tokenIssueDate)
        .add(minutes, 'minute')
        .isAfter(new Date());
      if (tokenStillValid) {
        return token;
      }
    }
  }

  const response = await refreshGet();
  store.dispatch(setToken(response.payload.token));
  return response.payload.token;
};

export default refreshToken;
