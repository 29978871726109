import * as fetchMock from 'fetch-mock';
import jwt from '../helpers/jwt';
import { url, UserInfoResult } from '../services/usersMeGet';
import ResponseCreator from './helpers/responseCreator';
import withDelay from './helpers/withDelay';

const storedValue = (key: string) =>
  sessionStorage.getItem(key) === null
    ? true
    : sessionStorage.getItem(key) === '1';

(window as any).fixtures = (window as any).fixtures || {};
(window as any).fixtures.usersMe = {
  _authorized: true,
  _emailValidated: storedValue('emailValidated'),
  _phoneValidated: storedValue('phoneValidated'),
  setAuthorized(value: boolean) {
    this._authorized = value;
  },
  setEmailValidated(value: boolean) {
    this._emailValidated = value;
    sessionStorage.setItem('emailValidated', value ? '1' : '0');
  },
  setPhoneValidated(value: boolean) {
    this._phoneValidated = value;
    sessionStorage.setItem('phoneValidated', value ? '1' : '0');
  }
};

const result: UserInfoResult = {
  user: {
    documentType: 'CC',
    documentNumber: '112233',
    name: 'Pedro',
    lastname: 'Martinez',
    gender: 'M',
    birthdate: '20.07.1989',
    email: 'pedro.perez@example.com',
    phone: '573003333333',
    emailValidated: (window as any).fixtures.usersMe._emailValidated,
    phoneValidated: (window as any).fixtures.usersMe._phoneValidated,
    identityValidated: true,
    reasonQuestionAnswered: false,
    newSharedReports: 5,
    newSentReports: 2
  }
};

fetchMock.get(`end:${process.env.REACT_APP_API_PATH}/${url}`, (_, response) => {
  const autorhization = (response.headers as Headers).get('Authorization');
  if (autorhization) {
    if ((window as any).fixtures.usersMe._authorized) {
      const token = autorhization.split(' ')[1];
      const roles = jwt.decode(token).roles;

      if (!roles.includes('report')) {
        return withDelay(
          new ResponseCreator()
            .setPayload({
              user: {
                ...result.user,
                name: null,
                sentReports: null
              }
            })
            .getResponse()
        );
      }
    } else {
      return withDelay(new ResponseCreator().setStatus(401).getResponse());
    }
  }

  return withDelay({
    status: 200,
    body: {
      payload: result
    }
  });
});
