import { ServiceReturn } from '../../types/ServiceReturn';

interface FixtureError<T = any> {
  status: number;
  body: ServiceReturn;
}

export class ResponseCreator {
  response: FixtureError;

  constructor() {
    this.response = {
      status: 200,
      body: {}
    } as FixtureError;
  }

  setStatus(code: number) {
    this.response.status = code;
    return this;
  }

  setPayload(payload: any) {
    this.response.body.payload = payload;
    return this;
  }

  setBody(body: any) {
    this.response.body = body;
    return this;
  }

  addError(attribute: string, error: string) {
    if (this.response.status === 200) {
      this.response.status = 400;
    }
    this.response.body.errors = this.response.body.errors || [];
    this.response.body.errors.push({ attribute, error });
    return this;
  }

  getResponse() {
    return this.response;
  }
}

export default ResponseCreator;
