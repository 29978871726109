import React, { useEffect, useState } from "react";
import Loading from "../../../components/UI/Loading";
import { reportsDeleteSentPost } from "../../../services/reportsDeleteSentPost";
import useReportsSentGet, { Report } from "../../../services/useReportsSentGet";
import NoReport from "../components/NoReport";
import SentReport from "../components/SentReport";

const SentReportsContainer = () => {
  const service = useReportsSentGet();
  const [reports, setReports] = useState<Report[]>([]);

  useEffect(() => {
    if (service.status === 'loaded') {
      setReports(service.payload.reports);
    }
  }, [service]);

  const handleDelete = async (id: string) => {
    try {
      await reportsDeleteSentPost({ id });
      setReports(reports.filter(report => report.id !== id));
    } catch { }
  }

  if (service.status === "loading")
    return <Loading />

  if (service.status === "loaded") {
    if (!reports.length)
      return <NoReport />

    return (
      <div
        className="d-flex flex-column"
        style={{ gap: "1rem" }}
      >
        {reports.map(report => (
          <SentReport
            key={report.id}
            {...report}
            onDelete={handleDelete}
          />
        ))}
      </div>
    )
  }

  return <></>
}

export default SentReportsContainer;