export default (input: HTMLInputElement) => {
  try {
    input.select();
    input.setSelectionRange(0, 99999);
    document.execCommand('copy');
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};
