import React from "react";
import Page from "../../components/Layout/Page";
import RequestReportForm from "./components/RequestReportForm";

const RequestReportView = () => {
  return (
    <Page back>
      <div className="eb-card">
        <RequestReportForm />
      </div>
    </Page>
  );
};

export default RequestReportView;
