import React, { useState } from "react";
import { Button } from "reactstrap";
import CodeInput from "../components/CodeInput";
import CodeSentEmail from "../components/CodeSentEmail";
import ForgotPasswordForm from "../components/ForgotPasswordForm";
import ResetPasswordForm from "../components/ResetPasswordForm";
import styles from "./ForgotPasswordContainer.module.scss";


type Step = "form" | "code" | "reset";
type Type = "email" | "phone";

const ForgotPasswordContainer = () => {
  const [step, setStep] = useState<Step>("form");
  const [type, setType] = useState<Type>("email");
  const [code, setCode] = useState("");
  const [phone, setPhone] = useState("");

  const handleSelectType = (type: Type) => {
    setType(type);
    setStep("form");
  };

  const handleSuccess = (value: string) => {
    setStep("code");
    if (type === "phone") setPhone(value)
  }

  const handleSuccessCode = (code: string) => {
    setCode(code);
    setStep("reset");
  }

  return (
    <>
      {step === "form" && (
        <>
          <p className={styles.label}>
            Selecciona un método para recuperar tu contraseña
          </p>
          <div className={styles.options}>
            <Button
              outline={type !== "email"}
              color="primary"
              className={`${styles.option} ${type !== "email" && styles.outline}`}
              onClick={() => handleSelectType("email")}
            >
              Correo electrónico
            </Button>
            <Button
              outline={type !== "phone"}
              color="primary"
              className={`${styles.option} ${type !== "phone" && styles.outline}`}
              onClick={() => handleSelectType("phone")}
            >
              Teléfono móvil
            </Button>
          </div>
          <div className="mt-3">
            <ForgotPasswordForm
              type={type}
              success={handleSuccess}
            />
          </div>
        </>
      )}
      {step === "code" &&
        <>
          {type === "email" && <CodeSentEmail />}
          {type === "phone" && <CodeInput success={handleSuccessCode} />}
        </>
      }
      {step === "reset" && <ResetPasswordForm code={code} phone={phone} />}
    </>
  )
}

export default ForgotPasswordContainer;
