import api from "./api";

export interface CreditResponse {
  productName: string;
  productId: string;
  offerName: string;
  offerId: string;
  merchantName: string;
  merchantId: string;
  productType: string;
  subProductType: string;
  description: string;
  imageUrl: string;
  requestLink?: string;
  minRequirements: string;
  feeType: string;
  associatedCosts: string;
  getUserInfo: boolean;
  isInternalForm: boolean;
}

export const url = "credits";

const creditsGet = async (): Promise<CreditResponse[]> => {
  const result = await api.get(url, true);
  const resultTransformed = result.payload.map((item: any) => ({
    ...item,
    ...item.product,
    offerName: item.name,
    offerId: item.itemId,
    productName: item.product.name,
    productId: item.product.id,
    getUserInfo: item.product.getUserInfo,
    isInternalForm: item.product.isInternalForm,
  }));
  return resultTransformed;
};

export default creditsGet;
