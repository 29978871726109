import api from './api';

export interface MessageWhatsappParams {
  phone: string;
}

export const url = 'message/whatsapp';

const messageWhatsappPost = async (
  data: MessageWhatsappParams,
  authenticated: boolean
): Promise<any> => {
  return await api.post(url, authenticated, data);
};

export default messageWhatsappPost;
