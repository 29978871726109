import React, { useEffect, useState } from "react";
import ProMeter from "../../../../components/UI/ProMeter";
import styles from "./ScoreFree.module.scss";

const ScoreFree = () => {
  const min = 400;
  const max = 850;
  const [score, setScore] = useState(min);
  const [mirror, setMirror] = useState(false);

  useEffect(() => {
    let intervalScore: NodeJS.Timer;
    intervalScore = setInterval(() => {
      if (mirror) setScore(score - 10);
      else setScore(score + 10);
      if (score === max) setMirror(true);
      if (score === min) setMirror(false);
    }, 50);
    return () => {
      clearInterval(intervalScore);
    };
  }, [score]);

  return (
    <div className={`eb-card ${styles.container}`}>
      <div>
        <p className={styles.title}>
          Puntaje de crédito
        </p>
        <p className={styles.text}>
          Descubre cómo te ven los bancos
        </p>
      </div>
      <ProMeter score={score} label="?" />
      <div>
        <p className={styles.subtitle}>
          No tienes un servicio activo
        </p>
        <p className={styles.description}>
          Adquiere un servicio para ver esta información
        </p>
      </div>
    </div>
  )
}

export default ScoreFree;