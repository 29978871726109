import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

// https://reacttraining.com/react-router/web/guides/scroll-restoration
class ScrollToTop extends React.Component<RouteComponentProps<{}>> {
  componentDidUpdate(prevProps: RouteComponentProps<{}>) {
    // const isMobile =
    //   typeof window.orientation !== 'undefined' ||
    //   (navigator.userAgent.indexOf('IEMobile') !== -1 &&
    //     navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i));
    if (this.props.location !== prevProps.location) {
      // && isMobile
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
