import api from './api';

export interface ReasonParams {
  reason: string;
}

export const url = 'reason';

const reasonPost = async (postData: ReasonParams): Promise<void> => {
  await api.post(url, true, { ...postData });
  return Promise.resolve();
};

export default reasonPost;
