import { SetStateAction } from 'react';
import api from '../services/api';
import { ServiceStatus } from '../types/ServiceStatus';
import { DispatchPromise } from './useStatePromise';

interface Options<Result> {
  url: string;
  auth?: boolean;
  set:
    | DispatchPromise<ServiceStatus<Result>>
    | React.Dispatch<SetStateAction<ServiceStatus<Result>>>;
}

const serviceBuilder = <Params = undefined, Result = undefined>(
  method: 'get' | 'post',
  { url, auth = true, set }: Options<Result>
) => {
  let isAborted = false;
  const start = async (params?: Params) => {
    try {
      await set({ status: 'loading' });
      const result: { payload: Result } =
        method === 'get'
          ? await api.get(url, auth, params)
          : await api.post(url, auth, params || {});
      const value: ServiceStatus<Result> = {
        status: 'loaded',
        payload: result.payload
      };
      if (isAborted) {
        return;
      }
      await set(value);
      return value;
    } catch (error) {
      if (isAborted) {
        return;
      }
      const value: ServiceStatus = {
        status: 'error',
        error
      };

      await set(value);
      return value;
    }
  };
  const abort = () => {
    isAborted = true;
  };
  return {
    start,
    abort
  };
};

export default serviceBuilder;
