import api from "./api";

interface FootprintResponse {
  payload: any;
}

export const url = "footprint";

const footprintsGet = async (): Promise<FootprintResponse> => {
  return await api.get(url, true);
};

export default footprintsGet;
