import React from 'react';
import styles from './Loading.module.scss';

// TODO: poner un timer de milisegundos, de modo que solo se muestra que está cargando si se demora
const Loading = () => (
  <div className={styles.container}>
    <div className={styles.loader} />
  </div>
);

export default Loading;
