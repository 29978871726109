import React from "react";
import Page from "../../components/Layout/Page";
import DashboardContainer from "./containers/DashboardContainer";

const DashboardView = () => {
  return (
    <Page>
      <DashboardContainer />
    </Page>
  );
};

export default DashboardView;