import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { capitalize } from '../../helpers/capitalize';
import { AppState } from "../../store/store";
import Icon from "../UI/Icon";
import styles from "./Navbar.module.scss";

interface Props extends RouteComponentProps {
  name: string;
  lastname: string;
  onToggle: () => void;
}

const Header = ({ name, lastname, onToggle, location }: Props) => {
  const [open, setOpen] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [initials, setInitials] = useState("");
  const [formatedName, setFormatedName] = useState("");

  const items = [
    { to: "/preferencias/perfil", icon: "person", label: "Mi perfil", restricted: true },
    { to: "/logout", icon: "door_front", label: "Cerrar sesión" },
  ];

  useEffect(() => {
    if (name && lastname) {
      const sliceName = capitalize(name.split(" ")[0]);
      const sliceLastname = capitalize(lastname.split(" ")[0]);
      setFormatedName(`${sliceName} ${sliceLastname}`);
      setInitials(`${sliceName.charAt(0)}${sliceLastname.charAt(0)}`);
    }
  }, [name, lastname]);

  useEffect(() => {
    const routes = ["/validacion", "/validacion-identidad", "/informacion-basica"];
    setEnabled(!routes.includes(location.pathname))
  }, [location.pathname]);

  return (
    <>
      <nav className={styles.navbar}>
        <div className={styles.container}>
          {enabled && (
            <div
              className={styles.toggle}
              onClick={onToggle}
            >
              <Icon icon="menu" />
            </div>
          )}
          <Dropdown
            id="dropdown"
            isOpen={open}
            className={styles.dropdown}
            toggle={() => setOpen(!open)}
          >
            <DropdownToggle
              data-toggle="dropdown"
              tag="div"
              className={styles.profile}
            >
              <div className={styles.avatar}>
                <div className={styles.shape}>
                  {initials || <Icon icon="person" fill />}
                </div>
                <span className={styles.status} />
              </div>
              <p className={styles.user}>
                {formatedName || "Bienvenido!"}
              </p>
              <Icon icon={open ? "expand_less" : "expand_more"} />
              <div className={styles.empty} />
            </DropdownToggle>
            <DropdownMenu
              className={styles.menu}
              right={true}
            >
              {items.map(({ to, label, icon, restricted }) => (
                <div
                  key={to}
                  className={`${styles.item} ${restricted && !enabled ? styles.hidden : ""}`}
                >
                  <NavLink
                    to={to}
                    onClick={() => setOpen(false)}
                  >
                    <div className={styles.content}>
                      <Icon icon={icon} fill />
                      <p className={styles.label}>
                        {label}
                      </p>
                    </div>
                  </NavLink>
                </div>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>
      </nav>
    </>
  )
}

const mapStateToProps = (state: AppState) => {
  return {
    name: state.user.status === "loaded" ? state.user.payload.name : "",
    lastname: state.user.status === "loaded" ? state.user.payload.lastname : "",
  };
};

export default withRouter(connect(mapStateToProps)(Header));