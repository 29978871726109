import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import Icon from "../../../components/UI/Icon";
import { Reason } from "../../../dictionaries/reasons";
import reasonPost from "../../../services/reasonPost";
import styles from "./ReasonContainer.module.scss";

interface Props extends RouteComponentProps {
  label: string;
  reasons: Reason[];
}

const ReasonContainer = ({ label, reasons, location, history }: Props) => {
  const handleClick = async (reason: string) => {
    try {
      await reasonPost({ reason });
      if (location.state)
        history.push(location.state.redirect, { reasonAswered: true });
      else
        history.push("/dashboard");
    } catch { }
  }

  return (
    <div className="eb-card">
      <p className={styles.title}>
        Elige el producto financiero de tu interés
      </p>
      <p className={styles.text}>
        Antes de explorar opciones de {label}, es importante revisar tus finanzas y los sueños que quieres cumplir.
      </p>
      <div className={styles.container}>
        {reasons.map(({ reason, icon, label }) => (
          <div
            key={reason}
            className={styles.reason}
          >
            <div
              className={styles.shape}
              onClick={() => handleClick(reason)}
            >
              <Icon
                key={reason}
                icon={icon}
                fill
              />
            </div>
            <p className={styles.text}>
              {label}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
};

export default withRouter(ReasonContainer);