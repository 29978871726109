import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import Icon from "../../../components/UI/Icon";
import { formatPrice, getDuration, isPro } from '../../../helpers/plans';
import { PlanResponse } from "../../../services/plansGet";
import styles from "./SmallPlan.module.scss";

interface Props extends RouteComponentProps {
  plan: PlanResponse;
}

const SmallPlan = ({ plan, history }: Props) => {
  const { name, cost, days, isDefault, description } = plan;

  const isProPlan = isPro(name);

  const handleClick = () => {
    history.push({ pathname: "/comprar", state: { plan } });
  }

  return (
    <div className={`eb-card ${styles.plan}`}>
      <div className={styles.header}>
        <p className={styles.title}>
          {name}
        </p>
        {isProPlan &&
          <div
            className={styles.saving}
            onClick={() => handleClick()}
          >
            <Icon
              icon="verified"
              className={styles.icon}
              fill
            />
            Ahorra 52%
          </div>
        }
      </div>
      <p className={styles.offer}>
        {isProPlan ? "$28.000" : ""}
      </p>
      <p className={styles.price}>
        {formatPrice(cost, isDefault)}
        <span>
          {`/${getDuration(days)}`}
        </span>
      </p>
      <p className={styles.description}>
        {description}
      </p>
      <button
        id={`plan-${days}`}
        className={`
          btn
          ${styles.btn}
          ${isProPlan ? "btn-teal" : "btn-primary"}
        `}
        onClick={handleClick}
      >
        Adquirir
      </button>
    </div>
  )
}

export default withRouter(SmallPlan);