import React from "react";
import { Route, Switch } from "react-router";
import Page from "../../components/Layout/Page";
import Tabs from "../../components/UI/Tabs";
import SentReportsContainer from "./containers/SentReportsContainer";
import SharedReportsContainer from "./containers/SharedReportsContainer";

const ReportsView = () => {
  const tabs = [
    { to: "/reportes/recibidos", label: "Recibidos" },
    { to: "/reportes/enviados", label: "Enviados" },
  ];

  return (
    <Page back>
      <Tabs tabs={tabs} />
      <Switch>
        <Route
          path="/reportes/recibidos"
          component={SharedReportsContainer}
        />
        <Route
          path="/reportes/enviados"
          component={SentReportsContainer}
        />
      </Switch>
    </Page>
  )
}

export default ReportsView;