import React from "react";
import { formatPrice } from "../../../helpers/plans";
import styles from "./PaymentSummary.module.scss";

interface Props {
  name: string;
  code: string;
  value: number;
  isDefault: boolean;
}

const PaymentSummary = ({ name, code, value, isDefault }: Props) => {
  const data = [
    { label: "Estás comprando", value: name },
    { label: "Valor", value: formatPrice(value, isDefault) },
    { label: "Cupón de descuento", value: code }
  ];

  return (
    <div className={`eb-card ${styles.summary}`}>
      {data.filter(({ value }) => value).map(({ label, value }) => (
        <div key={label} className={styles.data}>
          <span>{label}</span>
          <span className={styles.value}>{value}</span>
        </div>
      ))}
    </div>
  )
}

export default PaymentSummary;