import React from "react";
import Page from "../../components/Layout/Page";
import PlanBuyConfirmContainer from "./containers/PlanBuyConfirmContainer";

const PlanBuyConfirmView = () => {
  return (
    <Page>
      <div className="eb-card">
        <PlanBuyConfirmContainer />
      </div>
    </Page>
  );
};

export default PlanBuyConfirmView;
