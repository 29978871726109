import { ErrorMessage, Field, FieldProps, Form, Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { Input } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import * as yup from 'yup';
import processSubmitErrors from '../../helpers/processSubmitErrors';
import { validationMobilePost } from '../../services/validationMobilePost';
import { fetchUserInfo as fetchUserInfoAction } from '../../store/user/actions';
import ApiError from '../../types/ApiError';
import styles from './ValidatePending.module.scss';

interface Props {
  fetchUserInfo: () => void;
  formSubmitted?: () => void;
}

const ValidatePendingCodeForm = ({ fetchUserInfo, formSubmitted }: Props) => {
  return (
    <Formik
      initialValues={{ code: '' }}
      validationSchema={yup.object().shape({
        code: yup.string().required('El código es requerido')
      })}
      onSubmit={async (values, { setStatus, setErrors, setSubmitting }) => {
        try {
          const response = await validationMobilePost({
            code: values.code
          });
          if (response.valid === false) {
            throw new ApiError('Error', 400, {
              errors: [
                {
                  attribute: 'code',
                  error: 'El código no es correcto.'
                }
              ]
            });
          }
          formSubmitted && formSubmitted();
          fetchUserInfo();
        } catch (error) {
          processSubmitErrors(error, values, setErrors, setStatus);
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, errors, touched }) => (
        <Form className={styles.form}>
          <div className={styles.inlineForm}>
            <Field name="code">
              {({ field }: FieldProps) => (
                <Input
                  value={field.value}
                  bsSize="sm"
                  type="text"
                  placeholder="Código"
                  disabled={isSubmitting}
                  invalid={touched.code && !!errors.code}
                  className={styles.field}
                  {...field}
                />
              )}
            </Field>
            <Button
              type="submit"
              size="sm"
              color="primary"
              active={!isSubmitting}
            >
              {isSubmitting ? 'Enviando...' : 'Enviar'}
            </Button>
          </div>
          <ErrorMessage name="code">
            {message => (
              <div className={`invalid-feedback ${styles.error}`}>
                {message}
              </div>
            )}
          </ErrorMessage>
        </Form>
      )}
    </Formik>
  );
};

const mapDispatchToProps = {
  fetchUserInfo: fetchUserInfoAction
};

export default connect(
  null,
  mapDispatchToProps
)(ValidatePendingCodeForm);
