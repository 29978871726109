import api from "./api";

export interface ShareReportParams {
  sendMethod: "email" | "phone";
  name: string;
  email?: string;
  phone?: string;
  message: string;
}

export const url = "reports/shared";

export const setShareReportPost = async (
  data: ShareReportParams
): Promise<any> => {
  return await api.post(url, true, data);
};
