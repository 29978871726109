import * as fetchMock from 'fetch-mock';
import jwt from '../helpers/jwt';
import tokenExpiration from '../helpers/tokenExpiration';
import { url } from '../services/refreshGet';
import store from './helpers/store';

(window as any).fixtures = (window as any).fixtures || {};
(window as any).fixtures.refresh = {
  _error: 'none',
  validToken() {
    this._error = 'none';
  },
  invalidToken() {
    this._error = 'invalidToken';
  },
  userDontExist() {
    this._error = 'userDontExist';
  }
};

fetchMock.get(`end:${process.env.REACT_APP_API_PATH}/${url}`, () => {
  switch ((window as any).fixtures.refresh._error) {
    case 'invalidToken':
      return {
        status: 401,
        body: {
          success: false,
          errors: []
        }
      };
    case 'userDontExist':
      return {
        status: 462,
        body: {
          success: false,
          errors: 'Usuario no existente'
        }
      };
    default:
      return {
        status: 200,
        body: {
          success: true,
          payload: {
            token: jwt.encode(store.roles, tokenExpiration(), 'fake-uuid')
          }
        }
      };
  }
});
