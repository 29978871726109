import * as Sentry from '@sentry/browser';

export interface Data {
  [key: string]: any;
}

const captureMessage = (message: string, data: Data = {}, tags: Data = {}) => {
  const isProduction = process.env.NODE_ENV === "production";
  if (isProduction) {
    Sentry.withScope(scope => {
      scope.setExtras(data);
      scope.setTags(tags);
      Sentry.captureMessage(message);
    })
  }
  else {
    console.info("--- Sentry Capture Message ---");
    console.info("Message: ", message);
    console.info("Data: ", data);
    console.info("Tags: ", tags);
  }
}

export default captureMessage;
