import useWizard, { Wizard } from './useWizard';
import WizardStep from './WizardStep';
import WizardSteps from './WizardSteps';
import WizardTracker from './WizardTracker';

export type WizardProps = Wizard;

export default {
  Step: WizardStep,
  Steps: WizardSteps,
  Tracker: WizardTracker,
  useWizard,
};
