import { FormikErrors } from 'formik';
import ApiError from '../types/ApiError';
import { ServiceError } from '../types/ServiceError';
import logError from './logError';
import translateError from './translateError';

export const errorMessage =
  'Ocurrió un error inesperado. Por favor inténtalo más tarde.';

const processSubmitErrors = <T>(
  error: any,
  values: T,
  setErrors: (errors: FormikErrors<T>) => void,
  setStatus: (status?: any) => void
) => {
  setStatus({ successMessage: null });
  if (error instanceof ApiError) {
    if (error.code === 401) {
      return;
    }
    if (error.code === 500) {
      setStatus({ errors: [errorMessage] });
      return;
    }
    if (!error.response || !error.response.errors) {
      setStatus({ errors: [errorMessage] });
      logError(error);
      return;
    }
    const errors = error.response.errors as ServiceError[];
    const fieldNames = Object.keys(values);

    const fieldErrors = errors
      .filter(errorItem => fieldNames.includes(errorItem.attribute))
      .reduce((accum, fieldError) => {
        accum[fieldError.attribute] = translateError(fieldError.error);
        return accum;
      }, {});
    setErrors(fieldErrors);

    const otherErrors = errors
      .filter(errorItem => !fieldNames.includes(errorItem.attribute))
      .map(errorItem => translateError(errorItem.error));
    if (otherErrors.length) {
      setStatus({ errors: otherErrors });
    }
  } else {
    setStatus({ errors: [errorMessage] });
    logError(error);
  }
};

export default processSubmitErrors;
