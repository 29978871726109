import React from "react";
import isSubscribed from "../../helpers/isSubscribed";
import NoSubscription from "./NoSubscription";

interface Props {
  title?: string;
  component: React.ComponentType,
}

const SubscriptionRequired = ({ title, component: Component }: Props) => {
  if (isSubscribed()) return <Component />
  return <NoSubscription title={title} />
}

export default SubscriptionRequired;