import React, { useEffect, useState } from "react";
import { formatDate } from "../../../../helpers/date";
import { Score } from "../../../../services/scoresGet";
import ScoreChart from "./ScoreChart";
import styles from "./ScoreHistory.module.scss";

interface Props {
  scores: Score[]
}

const ScoreHistory = ({ scores }: Props) => {
  const [date, setDate] = useState("Última consulta:");

  useEffect(() => {
    if (scores.length) {
      const score = scores[0];
      const date = formatDate(score.fecha, { in: "YYYY-MM-DD" });
      setDate(`Última consulta: ${date}`);
    }
  }, [scores]);

  return (
    <div className="eb-card">
      <div className={styles.container}>
        <div className={styles.header}>
          <p className={styles.title}>
            Histórico del puntaje
          </p>
          <p className={styles.date}>
            {date}
          </p>
        </div>
        <ScoreChart scores={scores} />
      </div>
    </div>
  )
}

export default ScoreHistory;