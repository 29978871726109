import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import jwt from "../../../helpers/jwt";
import refreshToken from "../../../helpers/refreshToken";
import { track } from "../../../helpers/track";
import { AppState } from "../../../store/store";
import { showWppButton as showWppButtonAction } from "../../../store/UI/actions";
import { Error, Result } from "../../../types/ZippsID";
import FailedValidation from "../components/FailedValidation";
import HeaderValidation from "../components/HeaderValidation";
import PermissionsValidation from "../components/PermissionsValidation";
import SavingValidation from "../components/SavingValidation";
import ZippsID from "../components/ZippsID";
import styles from "./IdentityValidationContainer.module.scss";

interface Props extends RouteComponentProps {
  uuid: string;
  showWppButton: (show: boolean) => void;
}

type Step = "permissions" | "validation" | "failed" | "saving";

const IdentityValidationContainer = ({ uuid, showWppButton, history }: Props) => {
  const [step, setStep] = useState<Step>("permissions");

  useEffect(() => {
    showWppButton(false);
  }, [])

  const handlePermissions = (hasPermissions: boolean) => {
    if (hasPermissions) {
      setStep("validation");
      // showWppButton(true);
    }
  };

  // const handleElectronicIdSuccess = async (videoId: string) => {
  //   try {
  //     setStep("saving");
  //     await preferencesAccountEIDPost({ videoId });
  //     await refreshToken();
  //     track({ userId: uuid, event: "success-validation" });
  //     history.push("/dashboard");
  //   } catch (error) {
  //     setStep("failed");
  //     track("failed-validation");
  //   }
  // };

  // const handleElectronicIdFailed = (error: any) => {
  //   preferencesAccountEIDPost({ error });
  //   setStep("failed");
  //   track({ userId: uuid, event: "failed-validation" });
  // };

  const handleTryAgain = async () => {
    try {
      const newToken = await refreshToken();
      const { eid } = jwt.decode(newToken);
      if (eid) setStep("validation");
      else {
        track({ userId: uuid, event: "READY_VALIDATION" });
        history.push("/dashboard");
      }
    } catch { }
  };

  const handleCompleted = async (result: Result) => {
    // console.log(result)
    setStep("saving");
    await refreshToken();
    track({ userId: uuid, event: "COMPLETED_VALIDATION" });
    history.push("/dashboard");
  }

  const handleError = (error: Error) => {
    // console.log(error);
    setStep("failed");
    track({ userId: uuid, event: "FAILED_VALIDATION" });
  }

  return (
    <div className={styles.container}>
      <HeaderValidation
        message="Por motivos de seguridad, debemos validar tu identidad."
      />
      {step === "permissions" && <PermissionsValidation onPermisions={handlePermissions} />}
      {step === "validation" && <ZippsID uuid={uuid} onError={handleError} onCompleted={handleCompleted} />}
      {
        step === "failed" && <FailedValidation tryAgain={handleTryAgain} />
      }
      {step === "saving" && <SavingValidation />}
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  uuid: state.security.userId || "",
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    showWppButton: (show: boolean) => dispatch(showWppButtonAction(show)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(IdentityValidationContainer));