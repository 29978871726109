import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router";
import Rol from "../enums/Rol";
import isSubscribed from "../helpers/isSubscribed";
import jwt from "../helpers/jwt";
import { AppState } from "../store/store";

export interface Props extends RouteProps {
  token?: string;
  roles?: Rol | Rol[];
  currentRoles: Rol[];
  notRoles?: Rol | Rol[];
  oneOfRoles?: Rol | Rol[];
  subscriptionProtected?: boolean;
}

const PrivateRoute = ({
  roles, // Required all roles to access the route, has precedence over oneOfRoles
  oneOfRoles, // Required one of roles to access the route
  notRoles, // Roles that can't access this route
  currentRoles, // Current roles for the user in redux
  token,
  component: Component,
  children,
  subscriptionProtected,
  ...rest
}: Props) => (
  <Route
    {...rest}
    render={props => {
      const { eid } = jwt.decode(token);
      const userIsSubscribed = isSubscribed();

      const paths = {
        "basicInfo": "/informacion-basica",
        "identityValidation": "/validacion",
        "eid": "/validacion-identidad",
      };
      const currentPath = Array.isArray(rest.path) ? rest.path[0] : rest.path || "";

      if (Component || children) {
        if (currentRoles.length > 0) {

          // User can't access this route
          if (!userIsSubscribed && subscriptionProtected)
            return <Redirect to="/planes" />;

          // The validation flow is only for users who purchased any plan (eid is true for new users)
          if (userIsSubscribed && currentPath !== "/confirmar/compra") {
            if (eid) {
              // Redirect to ElectronicID
              if (currentPath !== paths.eid) {
                return <Redirect to={paths.eid} />;
              }
            } else {
              if (!currentRoles.includes("identity")) {
                // Redirect to Confronta / InterVue
                if (currentPath !== paths.identityValidation) {
                  return <Redirect to={paths.identityValidation} />
                }
              } else {
                if (!currentRoles.includes("basic_info")) {
                  // Redirect to basic info form
                  if (currentPath !== paths.basicInfo) {
                    return <Redirect to={paths.basicInfo} />
                  }
                }
              }
            }
          }

          // Redirect when user did ElectronicID
          // if (!eid && currentPath === paths.eid)
          //   return <Redirect to="/" />;

          const routeRoles = typeof roles === "string" ? [roles] : roles;
          if (
            routeRoles &&
            routeRoles.length > 0 &&
            !routeRoles.every(rol => currentRoles.includes(rol))
          ) {
            return <Redirect to="/" />;
          }

          const oneOfRouteRoles =
            typeof oneOfRoles === "string" ? [oneOfRoles] : oneOfRoles;
          if (
            oneOfRouteRoles &&
            oneOfRouteRoles.length > 0 &&
            !oneOfRouteRoles.some(rol => currentRoles.includes(rol))
          ) {
            return <Redirect to="/" />;
          }

          const notRouteRoles =
            typeof notRoles === "string" ? [notRoles] : notRoles;
          if (
            notRouteRoles &&
            notRouteRoles.length > 0 &&
            notRouteRoles.some(rol => currentRoles.includes(rol))
          ) {
            return <Redirect to="/" />;
          }

          return Component ? (
            <Component {...props} />
          ) : typeof children === "function" ? (
            children(props)
          ) : (
            children
          );
        } else {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
      } else {
        throw new Error(
          "render is not implemented. Only component and children prop is supported."
        );
      }
    }}
  />
);

const mapStateToProps = (state: AppState) => {
  return {
    token: state.security.token,
    currentRoles: state.security.roles,
  };
};

export default connect(mapStateToProps)(PrivateRoute);
