import { ServiceReturn } from "../types/ServiceReturn";
import api from "./api";

export interface RedeemCodeParams {
  code: string;
  planUuid: string;
}

export interface RedeemCodeResponse {
  msg: string;
  value: number;
  teaser: string;
  currency: string;
}

export const url = "redeem-code";

const redeemCodePost = async (
  data: RedeemCodeParams
): Promise<ServiceReturn<RedeemCodeResponse>> => {
  const result = await api.post(url, true, { ...data });
  return Promise.resolve(result);
};

export default redeemCodePost;
