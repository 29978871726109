import React from "react";
import { NavLink } from "react-router-dom";
import Icon from "../../../components/UI/Icon";
import styles from "./NoReport.module.scss";

const NoReport = () => {
  return (
    <div className="eb-card">
      <div className={styles.container}>
        <Icon
          icon="problem"
          className={styles.icon}
        />
        <p className={styles.text}>
          En este momento no tienes reportes para mostrar.
        </p>
        <NavLink
          to={"/dashboard"}
          className="btn btn-primary"
        >
          Volver al inicio
        </NavLink>
      </div>
    </div>
  )
}

export default NoReport;