import api from './api';
import { apiCache } from './cacheStore';

interface ReportResponse {
  payload: any;
}

export const url = 'report';

const reportPost = async (): Promise<ReportResponse> => {
  const data = await apiCache<ReportResponse>(
    api.post(url, true, {}),
    'credit-report'
  );

  return data.payload;
};

export default reportPost;
