import React from "react";
import { formatDate } from '../../../helpers/date';
import { Report as ReportType } from "../../../services/useReportsSharedIdGet";
import Report from "./Report";
import styles from "./ShareReportPreview.module.scss";

interface Props {
  preview: ReportType;
}

const ShareReportPreview = ({ preview }: Props) => {
  return (
    <>
      <p className={styles.title}>
        Comparte tu reporte
      </p>
      <p className={styles.date}>
        {formatDate(preview.date)}
      </p>
      <p className={styles.description}>
        La persona que reciba tu reporte crediticio verá la siguiente información:
      </p>
      <hr className={styles.hr} />
      <Report report={preview} collapsible />
    </>
  )
}

export default ShareReportPreview;