import api from './api';

export interface Merchant {
  value: number;
  label: string;
  disabled: boolean;
}

export const url = 'merchants/public/all';

const merchantsGet = async (): Promise<Merchant[]> => {
  const reponse = await api.get(url, false, {}, true);
  return reponse.payload;
};

export default merchantsGet;
