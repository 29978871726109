import * as fetchMock from 'fetch-mock';
import { url } from '../services/reportPost';
import withDelay from './helpers/withDelay';

// @ts-ignore
// Al día 80004393 y con historial de consultas
const response1 = {
  IdentificadorLinea: 4732058,
  TipoIdentificacion: 'C.C.',
  CodigoTipoIndentificacion: 1,
  NumeroIdentificacion: 80004393,
  NombreTitular: 'RESTREPO        BARRERA       JUAN          ROBERTO',
  NombreCiiu: '',
  LugarExpedicion: 'BOGOTA D.C.',
  FechaExpedicion: '22/07/1997',
  Estado: 'VIGENTE',
  NumeroInforme: '05089109370518320064',
  EstadoTitular: '',
  RangoEdad: '36-40',
  CodigoCiiu: '',
  CodigoDepartamento: 11,
  CodigoMunicipio: 1,
  Fecha: '23/10/2018',
  Hora: '12:11:12',
  Entidad: 'E    GULUNGO COLOMBIA SAS',
  RespuestaConsulta: '02',
  Score: {
    IndicadorScore: 0,
    Observacion: '',
    Politica: '',
    SubPoblacion: '',
    IndicadorDefault: '',
    CodigoScore: 5700,
    Puntaje: 762,
    TasaMorosidad: '',
    TipoScore: 'CREDITVISION SCORE'
  },
  Consolidado: {
    Registro: {
      PaqueteInformacion: 'Total',
      ParticipacionDeuda: 100,
      ValorMora: 0,
      TotalSaldo: 112233,
      SaldoObligacionesDia: 112233,
      NumeroObligacionesDia: 10,
      CuotaObligacionesDia: 3696,
      CantidadObligacionesMora: 0,
      NumeroObligaciones: 10,
      SaldoObligacionesMora: 0,
      CuotaObligacionesMora: 0
    },
    ResumenPrincipal: {
      Registro: [
        {
          PaqueteInformacion: 'Tarjetas De Credito',
          ParticipacionDeuda: 8,
          ValorMora: 0,
          TotalSaldo: 8838,
          SaldoObligacionesDia: 8838,
          NumeroObligacionesDia: 4,
          CuotaObligacionesDia: 654,
          CantidadObligacionesMora: 0,
          NumeroObligaciones: 4,
          SaldoObligacionesMora: 0,
          CuotaObligacionesMora: 0
        },
        {
          PaqueteInformacion: 'Sector Financiero',
          ParticipacionDeuda: 92,
          ValorMora: 0,
          TotalSaldo: 103395,
          SaldoObligacionesDia: 103395,
          NumeroObligacionesDia: 5,
          CuotaObligacionesDia: 3042,
          CantidadObligacionesMora: 0,
          NumeroObligaciones: 5,
          SaldoObligacionesMora: 0,
          CuotaObligacionesMora: 0
        },
        {
          PaqueteInformacion: 'Sector Real',
          ParticipacionDeuda: 0,
          ValorMora: 0,
          TotalSaldo: 0,
          SaldoObligacionesDia: 0,
          NumeroObligacionesDia: 1,
          CuotaObligacionesDia: 0,
          CantidadObligacionesMora: 0,
          NumeroObligaciones: 1,
          SaldoObligacionesMora: 0,
          CuotaObligacionesMora: 0
        },
        {
          PaqueteInformacion: 'Subtotal Principal',
          ParticipacionDeuda: 100,
          ValorMora: 0,
          TotalSaldo: 112233,
          SaldoObligacionesDia: 112233,
          NumeroObligacionesDia: 10,
          CuotaObligacionesDia: 3696,
          CantidadObligacionesMora: 0,
          NumeroObligaciones: 10,
          SaldoObligacionesMora: 0,
          CuotaObligacionesMora: 0
        }
      ]
    }
  },
  SectorFinancieroAlDia: {
    Obligacion: [
      {
        IdentificadorLinea: 128547294,
        FechaCorte: '31/10/2017',
        EstadoTitular: 'NORM',
        NumeroCuotasMora: 0,
        Calidad: 'PRIN',
        SaldoObligacion: 0,
        TipoEntidadOriginadoraCartera: '',
        PaqueteInformacion: '0006',
        ValorMora: 0,
        FechaTerminacion: '31/10/2020',
        Reestructurado: 'NO',
        FechaApertura: '17/03/2015',
        EntidadOriginadoraCartera: '',
        MoraMaxima: '',
        NumeroCuotasPactadas: 36,
        Comportamientos:
          'N |N |N |N |N |N |N |N |N |N |R |N |N |N |N |N |N |N |N |N |N |N |N |N |',
        NombreEntidad: 'DAVIVIENDA S.A.',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        Periodicidad: 'MEN',
        ParticipacionDeuda: 0,
        TipoEntidad: 'BCO',
        TipoContrato: 'CRE',
        ValorInicial: 8000,
        ModoExtincion: '',
        ProbabilidadNoPago: 0,
        Ciudad: 'BOGOTA',
        CuotasCanceladas: 0,
        ModalidadCredito: 'CONS',
        Calificacion: 'A',
        Sucursal: 'SUCURSAL  BOGOTA',
        CubrimientoGarantia: '',
        EstadoObligacion: 'VIGE',
        TipoGarantia: '',
        EstadoContrato: 'VIGE',
        NaturalezaReestructuracion: '',
        NumeroObligacion: '049865',
        LineaCredito: 'ROTA',
        ValorCuota: 0,
        TipoMoneda: 'ML',
        TipoPago: ''
      },
      {
        IdentificadorLinea: 128519206,
        FechaCorte: '31/10/2017',
        EstadoTitular: 'NORM',
        NumeroCuotasMora: 0,
        Calidad: 'PRIN',
        SaldoObligacion: 16042,
        TipoEntidadOriginadoraCartera: '',
        PaqueteInformacion: '0006',
        ValorMora: 0,
        FechaTerminacion: '05/03/2020',
        Reestructurado: 'NO',
        FechaApertura: '05/03/2015',
        EntidadOriginadoraCartera: '',
        MoraMaxima: '',
        NumeroCuotasPactadas: 60,
        Comportamientos:
          'N |N |N |N |N |N |N |N |N |N |R |N |N |N |N |N |N |N |N |N |N |N |N |N |',
        NombreEntidad: 'DAVIVIENDA S.A.',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        Periodicidad: 'MEN',
        ParticipacionDeuda: 14,
        TipoEntidad: 'BCO',
        TipoContrato: 'CRE',
        ValorInicial: 30000,
        ModoExtincion: '',
        ProbabilidadNoPago: 0,
        Ciudad: 'BOGOTA',
        CuotasCanceladas: 32,
        ModalidadCredito: 'CONS',
        Calificacion: 'A',
        Sucursal: 'SUCURSAL  BOGOTA',
        CubrimientoGarantia: 0,
        EstadoObligacion: 'VIGE',
        TipoGarantia: 'OTRA',
        EstadoContrato: 'VIGE',
        NaturalezaReestructuracion: '',
        NumeroObligacion: '049871',
        LineaCredito: 'ORDI',
        ValorCuota: 677,
        TipoMoneda: 'ML',
        TipoPago: ''
      },
      {
        IdentificadorLinea: 117070501,
        FechaCorte: '31/10/2017',
        EstadoTitular: 'OTRO',
        NumeroCuotasMora: 0,
        Calidad: 'PRIN',
        SaldoObligacion: 10812,
        TipoEntidadOriginadoraCartera: '',
        PaqueteInformacion: '0006',
        ValorMora: 0,
        FechaTerminacion: '04/04/2019',
        Reestructurado: 'NO',
        FechaApertura: '04/04/2014',
        EntidadOriginadoraCartera: '',
        MoraMaxima: '',
        NumeroCuotasPactadas: 60,
        Comportamientos:
          'N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |',
        NombreEntidad: 'BBVA  COLOMBIA',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        Periodicidad: 'MEN',
        ParticipacionDeuda: 10,
        TipoEntidad: 'BCO',
        TipoContrato: 'CRE',
        ValorInicial: 30000,
        ModoExtincion: '',
        ProbabilidadNoPago: '',
        Ciudad: 'BOGOTA',
        CuotasCanceladas: 42,
        ModalidadCredito: 'CONS',
        Calificacion: 'A',
        Sucursal: 'TOBERIN',
        CubrimientoGarantia: 0,
        EstadoObligacion: 'VIGE',
        TipoGarantia: 'NOID',
        EstadoContrato: 'VIGE',
        NaturalezaReestructuracion: '',
        NumeroObligacion: 248721,
        LineaCredito: 'NORM',
        ValorCuota: 655,
        TipoMoneda: 'ML',
        TipoPago: ''
      },
      {
        IdentificadorLinea: 51972881,
        FechaCorte: '31/10/2017',
        EstadoTitular: 'NORM',
        NumeroCuotasMora: 0,
        Calidad: 'PRIN',
        ClaseTarjeta: 'NAI',
        SaldoObligacion: 966,
        MarcaTarjeta: 'MAS',
        PaqueteInformacion: '0002',
        ValorMora: 0,
        Reestructurado: 'NO',
        FechaApertura: '01/02/2016',
        MoraMaxima: '',
        NumeroCuotasPactadas: '',
        Comportamientos:
          '|  |N |N |N |N |N |N |N |N |N |N |N |N |N |N |R |N |N |N |N |N |N |N |',
        NombreEntidad: 'OCCI-CREDENCIAL',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        ParticipacionDeuda: 1,
        TipoEntidad: 'BCO',
        TipoContrato: 'CRE',
        ValorInicial: 11000,
        ModoExtincion: '',
        Ciudad: 'BOGOTA',
        CuotasCanceladas: '',
        ModalidadCredito: 'CONS',
        Sucursal: 'CLL 100 BTA',
        EstadoObligacion: 'VIGE',
        EstadoContrato: 'VIGE',
        NaturalezaReestructuracion: '',
        NumeroObligacion: 150365,
        LineaCredito: 'TCR',
        ValorCuota: 102,
        TipoPago: ''
      },
      {
        IdentificadorLinea: 57397588,
        FechaCorte: '31/10/2017',
        EstadoTitular: 'NORM',
        NumeroCuotasMora: 0,
        Calidad: 'PRIN',
        ClaseTarjeta: 'INT',
        SaldoObligacion: 1695,
        MarcaTarjeta: 'DIN',
        PaqueteInformacion: '0002',
        ValorMora: 0,
        Reestructurado: 'NO',
        FechaApertura: '01/04/2006',
        MoraMaxima: '',
        NumeroCuotasPactadas: '',
        Comportamientos:
          '|  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |N |N |N |N |N |N |N |N |',
        NombreEntidad: 'DAVIVIENDA S.A.',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        ParticipacionDeuda: 2,
        TipoEntidad: 'BCO',
        TipoContrato: 'CRE',
        ValorInicial: 2710,
        ModoExtincion: '',
        Ciudad: 'NEIVA',
        CuotasCanceladas: '',
        ModalidadCredito: 'CONS',
        Sucursal: 'CARRERA  QUINTA',
        EstadoObligacion: 'VIGE',
        EstadoContrato: 'VIGE',
        NaturalezaReestructuracion: '',
        NumeroObligacion: 685377,
        LineaCredito: 'TCR',
        ValorCuota: 11,
        TipoPago: ''
      },
      {
        IdentificadorLinea: 30283965,
        FechaCorte: '30/11/2017',
        EstadoTitular: 'NORM',
        NumeroCuotasMora: 0,
        Calidad: 'PRIN',
        ClaseTarjeta: 'CGO',
        SaldoObligacion: 2582,
        MarcaTarjeta: 'CRE',
        PaqueteInformacion: '0002',
        ValorMora: 0,
        Reestructurado: 'NO',
        FechaApertura: '10/12/2010',
        MoraMaxima: '',
        NumeroCuotasPactadas: '',
        Comportamientos:
          'N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |',
        NombreEntidad: 'COLPATRIA RED MULTIBANCA COLPA',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: 0,
        ParticipacionDeuda: 2,
        TipoEntidad: 'BCO',
        TipoContrato: 'CRE',
        ValorInicial: 5418,
        ModoExtincion: '',
        Ciudad: 'NO REPORTADO',
        CuotasCanceladas: '',
        ModalidadCredito: 'CONS',
        Sucursal: 'NO REPORTADO',
        EstadoObligacion: 'VIGE',
        EstadoContrato: 'VIGE',
        NaturalezaReestructuracion: '',
        NumeroObligacion: 952595,
        LineaCredito: 'TCR',
        ValorCuota: 352,
        TipoPago: ''
      },
      {
        IdentificadorLinea: 51968510,
        FechaCorte: '31/10/2017',
        EstadoTitular: 'NORM',
        NumeroCuotasMora: 0,
        Calidad: 'PRIN',
        ClaseTarjeta: 'CLA',
        SaldoObligacion: 3595,
        MarcaTarjeta: 'CRE',
        PaqueteInformacion: '0002',
        ValorMora: 0,
        Reestructurado: 'NO',
        FechaApertura: '01/02/2016',
        MoraMaxima: '',
        NumeroCuotasPactadas: '',
        Comportamientos:
          '|  |  |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |',
        NombreEntidad: 'DE OCCIDENTE - VISA',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        ParticipacionDeuda: 3,
        TipoEntidad: 'BCO',
        TipoContrato: 'CRE',
        ValorInicial: 11000,
        ModoExtincion: '',
        Ciudad: 'BOGOTA',
        CuotasCanceladas: '',
        ModalidadCredito: 'CONS',
        Sucursal: 'CLL 100 BTA',
        EstadoObligacion: 'VIGE',
        EstadoContrato: 'VIGE',
        NaturalezaReestructuracion: '',
        NumeroObligacion: '071498',
        LineaCredito: 'TCR',
        ValorCuota: 189,
        TipoPago: ''
      },
      {
        IdentificadorLinea: 78577781,
        FechaCorte: '28/11/2017',
        EstadoTitular: 'NORM',
        NumeroCuotasMora: 0,
        Calidad: 'PRIN',
        SaldoObligacion: 72068,
        TipoEntidadOriginadoraCartera: '',
        PaqueteInformacion: '0006',
        ValorMora: 0,
        FechaTerminacion: '27/08/2025',
        Reestructurado: 'NO',
        FechaApertura: '27/08/2010',
        EntidadOriginadoraCartera: '',
        MoraMaxima: '',
        NumeroCuotasPactadas: 182,
        Comportamientos:
          'N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |R |R |R |R |R |',
        NombreEntidad: 'BANCOLOMBIA',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        Periodicidad: 'MEN',
        ParticipacionDeuda: 64,
        TipoEntidad: 'BCO',
        TipoContrato: 'CRE',
        ValorInicial: 104312,
        ModoExtincion: '',
        ProbabilidadNoPago: 0,
        Ciudad: 'NO REPORTADO',
        CuotasCanceladas: 87,
        ModalidadCredito: 'VIVI',
        Calificacion: 'A',
        Sucursal: 'QUINTA CAMACHO',
        CubrimientoGarantia: 314.1,
        EstadoObligacion: 'VIGE',
        TipoGarantia: 'HIPO',
        EstadoContrato: 'VIGE',
        NaturalezaReestructuracion: '',
        NumeroObligacion: 131659,
        LineaCredito: 'VIVI',
        ValorCuota: 1165,
        TipoMoneda: 'ML',
        TipoPago: ''
      },
      {
        IdentificadorLinea: 43147502,
        FechaCorte: '31/10/2017',
        EstadoTitular: 'NORM',
        NumeroCuotasMora: 0,
        Calidad: 'PRIN',
        SaldoObligacion: 4473,
        TipoEntidadOriginadoraCartera: '',
        PaqueteInformacion: '0006',
        ValorMora: 0,
        FechaTerminacion: '06/09/2018',
        Reestructurado: 'NO',
        FechaApertura: '06/09/2006',
        EntidadOriginadoraCartera: '',
        MoraMaxima: '',
        NumeroCuotasPactadas: 143,
        Comportamientos:
          'N |N |N |N |N |N |N |N |N |N |R |N |N |N |N |N |N |N |N |N |N |N |N |N |',
        NombreEntidad: 'DAVIVIENDA S.A.',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        Periodicidad: 'MEN',
        ParticipacionDeuda: 4,
        TipoEntidad: 'BCO',
        TipoContrato: 'CRE',
        ValorInicial: 38000,
        ModoExtincion: '',
        ProbabilidadNoPago: 0,
        Ciudad: 'BOGOTA',
        CuotasCanceladas: 134,
        ModalidadCredito: 'VIVI',
        Calificacion: 'A',
        Sucursal: 'SUCURSAL  BOGOTA',
        CubrimientoGarantia: 2055,
        EstadoObligacion: 'VIGE',
        TipoGarantia: 'HIPO',
        EstadoContrato: 'VIGE',
        NaturalezaReestructuracion: '',
        NumeroObligacion: 595915,
        LineaCredito: 'VIVI',
        ValorCuota: 545,
        TipoMoneda: 'ML',
        TipoPago: ''
      }
    ]
  },
  SectorFinancieroExtinguidas: {
    Obligacion: [
      {
        IdentificadorLinea: 60167183,
        FechaCorte: '29/08/2008',
        EstadoTitular: '',
        NumeroCuotasMora: '',
        Calidad: 'PRIN',
        SaldoObligacion: 0,
        TipoEntidadOriginadoraCartera: '',
        PaqueteInformacion: '0006',
        ValorMora: 0,
        FechaTerminacion: '31/08/2011',
        Reestructurado: '',
        FechaApertura: '28/08/2008',
        EntidadOriginadoraCartera: '',
        MoraMaxima: '',
        NumeroCuotasPactadas: '',
        Comportamientos:
          '|  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |N |',
        NombreEntidad: 'DAVIVIENDA S.A.',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        Periodicidad: 'MEN',
        ParticipacionDeuda: '',
        TipoEntidad: 'BCO',
        TipoContrato: 'CRE',
        ValorInicial: 2000,
        ModoExtincion: '',
        ProbabilidadNoPago: 0,
        Ciudad: 'BOGOTA',
        CuotasCanceladas: 0,
        ModalidadCredito: 'CONS',
        Calificacion: '',
        Sucursal: 'SUCURSAL  BOGOTA',
        CubrimientoGarantia: '',
        EstadoObligacion: 'SALD',
        TipoGarantia: '',
        EstadoContrato: '',
        NaturalezaReestructuracion: '',
        NumeroObligacion: 421494,
        LineaCredito: 'ROTA',
        ValorCuota: 0,
        TipoMoneda: 'ML',
        TipoPago: ''
      },
      {
        IdentificadorLinea: 44849412,
        FechaCorte: '29/08/2014',
        EstadoTitular: 'NORM',
        NumeroCuotasMora: 0,
        Calidad: 'PRIN',
        SaldoObligacion: 0,
        TipoEntidadOriginadoraCartera: '',
        PaqueteInformacion: '0006',
        ValorMora: 0,
        FechaTerminacion: '01/08/2014',
        Reestructurado: 'NO',
        FechaApertura: '14/12/2006',
        EntidadOriginadoraCartera: '',
        MoraMaxima: '',
        NumeroCuotasPactadas: '',
        Comportamientos:
          'N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |',
        NombreEntidad: 'CITIBANK',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        Periodicidad: 'VNC',
        ParticipacionDeuda: '',
        TipoEntidad: 'BCO',
        TipoContrato: 'CRE',
        ValorInicial: 1300,
        ModoExtincion: '',
        ProbabilidadNoPago: 0,
        Ciudad: 'BOGOTA',
        CuotasCanceladas: '',
        ModalidadCredito: 'CONS',
        Calificacion: '',
        Sucursal: 'PARQUE CENTRAL B',
        CubrimientoGarantia: '',
        EstadoObligacion: 'SALD',
        TipoGarantia: '',
        EstadoContrato: 'NVIG',
        NaturalezaReestructuracion: '',
        NumeroObligacion: 598017,
        LineaCredito: 'SOBR',
        ValorCuota: 0,
        TipoMoneda: 'ML',
        TipoPago: 'VOL'
      },
      {
        IdentificadorLinea: 130942907,
        FechaCorte: '31/05/2017',
        EstadoTitular: 'OTRO',
        NumeroCuotasMora: 0,
        Calidad: 'PRIN',
        SaldoObligacion: 0,
        TipoEntidadOriginadoraCartera: '',
        PaqueteInformacion: '0006',
        ValorMora: 0,
        FechaTerminacion: '01/05/2017',
        Reestructurado: 'NO',
        FechaApertura: '30/04/2015',
        EntidadOriginadoraCartera: '',
        MoraMaxima: '',
        NumeroCuotasPactadas: 24,
        Comportamientos:
          'N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |R |N |N |N |N |N |N |N |N |',
        NombreEntidad: 'DAVIVIENDA S.A.',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        Periodicidad: 'MEN',
        ParticipacionDeuda: '',
        TipoEntidad: 'BCO',
        TipoContrato: 'CRE',
        ValorInicial: 2089,
        ModoExtincion: '',
        ProbabilidadNoPago: 5,
        Ciudad: 'BOGOTA',
        CuotasCanceladas: 25,
        ModalidadCredito: 'CONS',
        Calificacion: '',
        Sucursal: 'SUCURSAL  BOGOTA',
        CubrimientoGarantia: '',
        EstadoObligacion: 'SALD',
        TipoGarantia: '',
        EstadoContrato: 'NVIG',
        NaturalezaReestructuracion: '',
        NumeroObligacion: '052073',
        LineaCredito: 'ORDI',
        ValorCuota: 0,
        TipoMoneda: 'ML',
        TipoPago: 'VOL'
      },
      {
        IdentificadorLinea: 83480484,
        FechaCorte: '30/05/2014',
        EstadoTitular: 'OTRO',
        NumeroCuotasMora: 0,
        Calidad: 'PRIN',
        SaldoObligacion: 0,
        TipoEntidadOriginadoraCartera: '',
        PaqueteInformacion: '0006',
        ValorMora: 0,
        FechaTerminacion: '25/03/2015',
        Reestructurado: 'NO',
        FechaApertura: '25/03/2011',
        EntidadOriginadoraCartera: '',
        MoraMaxima: '',
        NumeroCuotasPactadas: 48,
        Comportamientos:
          'N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |',
        NombreEntidad: 'DAVIVIENDA S.A.',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        Periodicidad: 'MEN',
        ParticipacionDeuda: '',
        TipoEntidad: 'BCO',
        TipoContrato: 'CRE',
        ValorInicial: 13100,
        ModoExtincion: '',
        ProbabilidadNoPago: 3,
        Ciudad: 'BOGOTA',
        CuotasCanceladas: 38,
        ModalidadCredito: 'CONS',
        Calificacion: '',
        Sucursal: 'SUCURSAL  BOGOTA',
        CubrimientoGarantia: '',
        EstadoObligacion: 'SALD',
        TipoGarantia: 'OTRA',
        EstadoContrato: 'NVIG',
        NaturalezaReestructuracion: '',
        NumeroObligacion: 122046,
        LineaCredito: 'ORDI',
        ValorCuota: 0,
        TipoMoneda: 'ML',
        TipoPago: 'VOL'
      },
      {
        IdentificadorLinea: 77597284,
        FechaCorte: '31/08/2011',
        EstadoTitular: 'NORM',
        NumeroCuotasMora: 0,
        Calidad: 'PRIN',
        SaldoObligacion: 0,
        TipoEntidadOriginadoraCartera: '',
        PaqueteInformacion: '0006',
        ValorMora: 0,
        FechaTerminacion: '06/07/2011',
        Reestructurado: 'NO',
        FechaApertura: '06/07/2010',
        EntidadOriginadoraCartera: '',
        MoraMaxima: '',
        NumeroCuotasPactadas: 12,
        Comportamientos:
          '|  |  |  |  |  |  |  |  |  |N |N |N |N |N |N |N |N |N |N |N |N |N |N |',
        NombreEntidad: 'DAVIVIENDA S.A.',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        Periodicidad: 'MEN',
        ParticipacionDeuda: '',
        TipoEntidad: 'BCO',
        TipoContrato: 'CRE',
        ValorInicial: 7000,
        ModoExtincion: '',
        ProbabilidadNoPago: 3,
        Ciudad: 'BOGOTA',
        CuotasCanceladas: 12,
        ModalidadCredito: 'CONS',
        Calificacion: '',
        Sucursal: 'SUCURSAL  BOGOTA',
        CubrimientoGarantia: '',
        EstadoObligacion: 'SALD',
        TipoGarantia: '',
        EstadoContrato: 'NVIG',
        NaturalezaReestructuracion: '',
        NumeroObligacion: 140003,
        LineaCredito: 'ORDI',
        ValorCuota: 0,
        TipoMoneda: 'ML',
        TipoPago: 'VOL'
      },
      {
        IdentificadorLinea: 59377852,
        FechaCorte: '17/06/2011',
        EstadoTitular: 'NORM',
        NumeroCuotasMora: '',
        Calidad: 'PRIN',
        SaldoObligacion: 0,
        TipoEntidadOriginadoraCartera: '',
        PaqueteInformacion: '0006',
        ValorMora: 0,
        FechaTerminacion: '04/07/2011',
        Reestructurado: '',
        FechaApertura: '04/07/2008',
        EntidadOriginadoraCartera: '',
        MoraMaxima: '',
        NumeroCuotasPactadas: '',
        Comportamientos:
          'N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |R |R |N |',
        NombreEntidad: 'DAVIVIENDA S.A.',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        Periodicidad: 'MEN',
        ParticipacionDeuda: '',
        TipoEntidad: 'BCO',
        TipoContrato: 'CRE',
        ValorInicial: 20955,
        ModoExtincion: '',
        ProbabilidadNoPago: 3,
        Ciudad: 'BOGOTA',
        CuotasCanceladas: 35,
        ModalidadCredito: 'CONS',
        Calificacion: '',
        Sucursal: 'SUCURSAL  BOGOTA',
        CubrimientoGarantia: '',
        EstadoObligacion: 'SALD',
        TipoGarantia: '',
        EstadoContrato: '',
        NaturalezaReestructuracion: '',
        NumeroObligacion: 140650,
        LineaCredito: 'ORDI',
        ValorCuota: 543,
        TipoMoneda: 'ML',
        TipoPago: ''
      },
      {
        IdentificadorLinea: 42028705,
        FechaCorte: '31/07/2008',
        EstadoTitular: '',
        NumeroCuotasMora: 0,
        Calidad: 'PRIN',
        SaldoObligacion: 0,
        TipoEntidadOriginadoraCartera: '',
        PaqueteInformacion: '0006',
        ValorMora: 0,
        FechaTerminacion: '11/07/2011',
        Reestructurado: '',
        FechaApertura: '11/07/2006',
        EntidadOriginadoraCartera: '',
        MoraMaxima: '',
        NumeroCuotasPactadas: '',
        Comportamientos:
          'N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |',
        NombreEntidad: 'DAVIVIENDA S.A.',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        Periodicidad: 'MEN',
        ParticipacionDeuda: '',
        TipoEntidad: 'BCO',
        TipoContrato: 'CRE',
        ValorInicial: 10000,
        ModoExtincion: '',
        ProbabilidadNoPago: 0,
        Ciudad: 'NEIVA',
        CuotasCanceladas: 24,
        ModalidadCredito: 'CONS',
        Calificacion: '',
        Sucursal: 'SUCURSAL NEIVA',
        CubrimientoGarantia: '',
        EstadoObligacion: 'SALD',
        TipoGarantia: '',
        EstadoContrato: '',
        NaturalezaReestructuracion: '',
        NumeroObligacion: 280480,
        LineaCredito: 'ORDI',
        ValorCuota: 0,
        TipoMoneda: 'ML',
        TipoPago: ''
      },
      {
        IdentificadorLinea: 45060520,
        FechaCorte: '31/07/2008',
        EstadoTitular: '',
        NumeroCuotasMora: 0,
        Calidad: 'PRIN',
        SaldoObligacion: 0,
        TipoEntidadOriginadoraCartera: '',
        PaqueteInformacion: '0006',
        ValorMora: 0,
        FechaTerminacion: '13/12/2011',
        Reestructurado: '',
        FechaApertura: '13/12/2006',
        EntidadOriginadoraCartera: '',
        MoraMaxima: '',
        NumeroCuotasPactadas: '',
        Comportamientos:
          '|  |  |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |',
        NombreEntidad: 'DAVIVIENDA S.A.',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        Periodicidad: 'MEN',
        ParticipacionDeuda: '',
        TipoEntidad: 'BCO',
        TipoContrato: 'CRE',
        ValorInicial: 15000,
        ModoExtincion: '',
        ProbabilidadNoPago: 0,
        Ciudad: 'BOGOTA',
        CuotasCanceladas: 19,
        ModalidadCredito: 'CONS',
        Calificacion: '',
        Sucursal: 'SUCURSAL  BOGOTA',
        CubrimientoGarantia: '',
        EstadoObligacion: 'SALD',
        TipoGarantia: '',
        EstadoContrato: '',
        NaturalezaReestructuracion: '',
        NumeroObligacion: 725191,
        LineaCredito: 'ORDI',
        ValorCuota: 0,
        TipoMoneda: 'ML',
        TipoPago: ''
      },
      {
        IdentificadorLinea: 52067122,
        FechaCorte: '31/07/2008',
        EstadoTitular: '',
        NumeroCuotasMora: 0,
        Calidad: 'PRIN',
        SaldoObligacion: 0,
        TipoEntidadOriginadoraCartera: '',
        PaqueteInformacion: '0006',
        ValorMora: 0,
        FechaTerminacion: '30/08/2009',
        Reestructurado: '',
        FechaApertura: '30/08/2007',
        EntidadOriginadoraCartera: '',
        MoraMaxima: '',
        NumeroCuotasPactadas: '',
        Comportamientos:
          '|  |  |  |  |  |  |  |  |  |  |N |N |N |N |N |N |N |N |N |N |N |N |N |',
        NombreEntidad: 'DAVIVIENDA S.A.',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        Periodicidad: 'MEN',
        ParticipacionDeuda: '',
        TipoEntidad: 'BCO',
        TipoContrato: 'CRE',
        ValorInicial: 2000,
        ModoExtincion: '',
        ProbabilidadNoPago: 0,
        Ciudad: 'BOGOTA',
        CuotasCanceladas: 10,
        ModalidadCredito: 'CONS',
        Calificacion: '',
        Sucursal: 'SUCURSAL  BOGOTA',
        CubrimientoGarantia: '',
        EstadoObligacion: 'SALD',
        TipoGarantia: '',
        EstadoContrato: '',
        NaturalezaReestructuracion: '',
        NumeroObligacion: 384351,
        LineaCredito: 'ORDI',
        ValorCuota: 0,
        TipoMoneda: 'ML',
        TipoPago: ''
      },
      {
        IdentificadorLinea: 59264292,
        FechaCorte: '31/08/2009',
        EstadoTitular: '',
        NumeroCuotasMora: 0,
        Calidad: 'PRIN',
        SaldoObligacion: 0,
        TipoEntidadOriginadoraCartera: '',
        PaqueteInformacion: '0006',
        ValorMora: 0,
        FechaTerminacion: '05/08/2009',
        Reestructurado: '',
        FechaApertura: '11/07/2008',
        EntidadOriginadoraCartera: '',
        MoraMaxima: '',
        NumeroCuotasPactadas: 13,
        Comportamientos:
          '|  |  |  |  |  |  |  |  |  |N |N |N |N |N |N |N |N |N |N |N |N |N |N |',
        NombreEntidad: 'DE BOGOTA',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        Periodicidad: 'MEN',
        ParticipacionDeuda: '',
        TipoEntidad: 'BCO',
        TipoContrato: 'CRE',
        ValorInicial: 6300,
        ModoExtincion: '',
        ProbabilidadNoPago: '',
        Ciudad: 'NO REPORTADO',
        CuotasCanceladas: 13,
        ModalidadCredito: 'CONS',
        Calificacion: '',
        Sucursal: 'CRA 7. CALLE 67',
        CubrimientoGarantia: '',
        EstadoObligacion: 'SALD',
        TipoGarantia: 'NOID',
        EstadoContrato: 'NVIG',
        NaturalezaReestructuracion: '',
        NumeroObligacion: '000168',
        LineaCredito: 'NORM',
        ValorCuota: 0,
        TipoMoneda: 'ML',
        TipoPago: ''
      },
      {
        IdentificadorLinea: 59264290,
        FechaCorte: '15/07/2008',
        EstadoTitular: '',
        NumeroCuotasMora: '',
        Calidad: 'PRIN',
        SaldoObligacion: 0,
        TipoEntidadOriginadoraCartera: '',
        PaqueteInformacion: '0006',
        ValorMora: 0,
        FechaTerminacion: '28/07/2009',
        Reestructurado: '',
        FechaApertura: '10/07/2008',
        EntidadOriginadoraCartera: '',
        MoraMaxima: '',
        NumeroCuotasPactadas: '',
        Comportamientos:
          '|  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |N |',
        NombreEntidad: 'DE BOGOTA',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        Periodicidad: 'VNC',
        ParticipacionDeuda: '',
        TipoEntidad: 'BCO',
        TipoContrato: 'CRE',
        ValorInicial: 6300,
        ModoExtincion: '',
        ProbabilidadNoPago: 0,
        Ciudad: 'NO REPORTADO',
        CuotasCanceladas: 1,
        ModalidadCredito: 'CONS',
        Calificacion: '',
        Sucursal: 'CRA 7. CALLE 67',
        CubrimientoGarantia: '',
        EstadoObligacion: 'SALD',
        TipoGarantia: 'NOID',
        EstadoContrato: '',
        NaturalezaReestructuracion: '',
        NumeroObligacion: '000166',
        LineaCredito: 'NORM',
        ValorCuota: 0,
        TipoMoneda: 'ML',
        TipoPago: ''
      },
      {
        IdentificadorLinea: 42700809,
        FechaCorte: '31/01/2007',
        EstadoTitular: '',
        NumeroCuotasMora: '',
        Calidad: 'CODE',
        SaldoObligacion: 0,
        TipoEntidadOriginadoraCartera: '',
        PaqueteInformacion: '0021',
        ValorMora: 0,
        FechaTerminacion: '31/01/2007',
        Reestructurado: '',
        FechaApertura: '01/08/2006',
        EntidadOriginadoraCartera: '',
        MoraMaxima: '',
        NumeroCuotasPactadas: '',
        Comportamientos:
          '|  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |N |N |N |N |N |N |',
        NombreEntidad: 'COMUNA - COOP MULTIACTIVA UNIV',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        Periodicidad: 'MEN',
        ParticipacionDeuda: '',
        TipoEntidad: 'COO',
        TipoContrato: 'CRE',
        ValorInicial: 1136,
        ModoExtincion: '',
        ProbabilidadNoPago: '',
        Ciudad: 'NEIVA',
        CuotasCanceladas: 5,
        ModalidadCredito: 'CONS',
        Calificacion: '',
        Sucursal: 'NEIVA',
        CubrimientoGarantia: '',
        EstadoObligacion: 'SALD',
        TipoGarantia: 'IOTR',
        EstadoContrato: '',
        NaturalezaReestructuracion: '',
        NumeroObligacion: '077566',
        LineaCredito: 'LINV',
        ValorCuota: 238,
        TipoMoneda: 'ML',
        TipoPago: ''
      },
      {
        IdentificadorLinea: 47021407,
        FechaCorte: '31/07/2015',
        EstadoTitular: 'NORM',
        NumeroCuotasMora: 0,
        Calidad: 'PRIN',
        ClaseTarjeta: 'PLT',
        SaldoObligacion: 0,
        MarcaTarjeta: 'MAS',
        PaqueteInformacion: '0002',
        ValorMora: 0,
        Reestructurado: 'NO',
        FechaApertura: '04/03/2015',
        MoraMaxima: '',
        NumeroCuotasPactadas: '',
        Comportamientos:
          '|  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |N |N |N |N |N |',
        NombreEntidad: 'DAVIVIENDA S.A.',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        ParticipacionDeuda: '',
        TipoEntidad: 'BCO',
        TipoContrato: 'CRE',
        ValorInicial: 0,
        ModoExtincion: '',
        Ciudad: 'NO REPORTADO',
        CuotasCanceladas: '',
        ModalidadCredito: 'CONS',
        Sucursal: 'NO REPORTADO',
        EstadoObligacion: 'CVOL',
        EstadoContrato: 'NVIG',
        NaturalezaReestructuracion: '',
        NumeroObligacion: 240603,
        LineaCredito: 'TCR',
        ValorCuota: 0,
        TipoPago: 'VOL'
      },
      {
        IdentificadorLinea: 17794494,
        FechaCorte: '31/12/2011',
        EstadoTitular: 'OTRO',
        NumeroCuotasMora: 0,
        Calidad: 'PRIN',
        ClaseTarjeta: 'CGI',
        SaldoObligacion: 0,
        MarcaTarjeta: 'CRE',
        PaqueteInformacion: '0002',
        ValorMora: 0,
        Reestructurado: 'NO',
        FechaApertura: '14/07/2006',
        MoraMaxima: '',
        NumeroCuotasPactadas: '',
        Comportamientos:
          'N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |',
        NombreEntidad: 'DAVIVIENDA S.A.',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        ParticipacionDeuda: '',
        TipoEntidad: 'BCO',
        TipoContrato: 'CRE',
        ValorInicial: 2000,
        ModoExtincion: '',
        Ciudad: 'NEIVA',
        CuotasCanceladas: '',
        ModalidadCredito: 'CONS',
        Sucursal: 'CARRERA  QUINTA',
        EstadoObligacion: 'CVOL',
        EstadoContrato: 'NVIG',
        NaturalezaReestructuracion: '',
        NumeroObligacion: 666861,
        LineaCredito: 'TCR',
        ValorCuota: 0,
        TipoPago: 'VOL'
      },
      {
        IdentificadorLinea: 17170918,
        FechaCorte: '31/03/2012',
        EstadoTitular: 'OTRO',
        NumeroCuotasMora: 0,
        Calidad: 'PRIN',
        ClaseTarjeta: 'INT',
        SaldoObligacion: 0,
        MarcaTarjeta: 'DIN',
        PaqueteInformacion: '0002',
        ValorMora: 0,
        Reestructurado: 'NO',
        FechaApertura: '01/04/2006',
        MoraMaxima: '',
        NumeroCuotasPactadas: '',
        Comportamientos:
          'N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |',
        NombreEntidad: 'DAVIVIENDA S.A.',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        ParticipacionDeuda: '',
        TipoEntidad: 'BCO',
        TipoContrato: 'CRE',
        ValorInicial: 0,
        ModoExtincion: '',
        Ciudad: 'NEIVA',
        CuotasCanceladas: '',
        ModalidadCredito: 'CONS',
        Sucursal: 'CARRERA  QUINTA',
        EstadoObligacion: 'CVOL',
        EstadoContrato: 'NVIG',
        NaturalezaReestructuracion: '',
        NumeroObligacion: 641000,
        LineaCredito: 'TCR',
        ValorCuota: 0,
        TipoPago: 'VOL'
      },
      {
        IdentificadorLinea: 19085560,
        FechaCorte: '28/09/2012',
        EstadoTitular: 'NORM',
        NumeroCuotasMora: 0,
        Calidad: 'PRIN',
        ClaseTarjeta: 'VSC',
        SaldoObligacion: 0,
        MarcaTarjeta: 'VIS',
        PaqueteInformacion: '0002',
        ValorMora: 0,
        Reestructurado: 'NO',
        FechaApertura: '14/12/2006',
        MoraMaxima: '',
        NumeroCuotasPactadas: '',
        Comportamientos:
          'N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |X |',
        NombreEntidad: 'CITIBANK',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        ParticipacionDeuda: '',
        TipoEntidad: 'BCO',
        TipoContrato: 'CRE',
        ValorInicial: 3506,
        ModoExtincion: '',
        Ciudad: 'BOGOTA',
        CuotasCanceladas: '',
        ModalidadCredito: 'CONS',
        Sucursal: 'PARQUE CENTRAL B',
        EstadoObligacion: 'CROB',
        EstadoContrato: 'NVIG',
        NaturalezaReestructuracion: '',
        NumeroObligacion: 798361,
        LineaCredito: 'TCR',
        ValorCuota: 0,
        TipoPago: 'VOL'
      },
      {
        IdentificadorLinea: 34377593,
        FechaCorte: '31/03/2017',
        EstadoTitular: 'NORM',
        NumeroCuotasMora: 0,
        Calidad: 'PRIN',
        ClaseTarjeta: 'INT',
        SaldoObligacion: 0,
        MarcaTarjeta: 'DIN',
        PaqueteInformacion: '0002',
        ValorMora: 0,
        Reestructurado: 'NO',
        FechaApertura: '01/04/2006',
        MoraMaxima: '',
        NumeroCuotasPactadas: '',
        Comportamientos:
          'N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |',
        NombreEntidad: 'DAVIVIENDA S.A.',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        ParticipacionDeuda: '',
        TipoEntidad: 'BCO',
        TipoContrato: 'CRE',
        ValorInicial: 2710,
        ModoExtincion: '',
        Ciudad: 'NEIVA',
        CuotasCanceladas: '',
        ModalidadCredito: 'CONS',
        Sucursal: 'CARRERA  QUINTA',
        EstadoObligacion: 'CROB',
        EstadoContrato: 'NVIG',
        NaturalezaReestructuracion: '',
        NumeroObligacion: 169321,
        LineaCredito: 'TCR',
        ValorCuota: 0,
        TipoPago: 'VOL'
      },
      {
        IdentificadorLinea: 17794495,
        FechaCorte: '31/12/2011',
        EstadoTitular: 'OTRO',
        NumeroCuotasMora: 0,
        Calidad: 'PRIN',
        ClaseTarjeta: 'GOI',
        SaldoObligacion: 0,
        MarcaTarjeta: 'MAS',
        PaqueteInformacion: '0002',
        ValorMora: 0,
        Reestructurado: 'NO',
        FechaApertura: '14/07/2006',
        MoraMaxima: '',
        NumeroCuotasPactadas: '',
        Comportamientos:
          'N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |',
        NombreEntidad: 'DAVIVIENDA S.A.',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        ParticipacionDeuda: '',
        TipoEntidad: 'BCO',
        TipoContrato: 'CRE',
        ValorInicial: 2000,
        ModoExtincion: '',
        Ciudad: 'NEIVA',
        CuotasCanceladas: '',
        ModalidadCredito: 'CONS',
        Sucursal: 'CARRERA  QUINTA',
        EstadoObligacion: 'CVOL',
        EstadoContrato: 'NVIG',
        NaturalezaReestructuracion: '',
        NumeroObligacion: 957856,
        LineaCredito: 'TCR',
        ValorCuota: 0,
        TipoPago: 'VOL'
      },
      {
        IdentificadorLinea: 19087266,
        FechaCorte: '30/04/2010',
        EstadoTitular: 'NORM',
        NumeroCuotasMora: 0,
        Calidad: 'PRIN',
        ClaseTarjeta: 'AZL',
        SaldoObligacion: 0,
        MarcaTarjeta: 'MAS',
        PaqueteInformacion: '0002',
        ValorMora: 0,
        Reestructurado: 'NO',
        FechaApertura: '14/12/2006',
        MoraMaxima: '',
        NumeroCuotasPactadas: '',
        Comportamientos:
          'N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |X |X |',
        NombreEntidad: 'CITIBANK',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        ParticipacionDeuda: '',
        TipoEntidad: 'BCO',
        TipoContrato: 'CRE',
        ValorInicial: 0,
        ModoExtincion: '',
        Ciudad: 'BOGOTA',
        CuotasCanceladas: '',
        ModalidadCredito: 'CONS',
        Sucursal: 'BOGOTA PRINCIPAL',
        EstadoObligacion: 'CVOL',
        EstadoContrato: 'VIGE',
        NaturalezaReestructuracion: '',
        NumeroObligacion: 754090,
        LineaCredito: 'TCR',
        ValorCuota: 384,
        TipoPago: 'VOL'
      },
      {
        IdentificadorLinea: 15788697,
        FechaCorte: '03/03/2011',
        EstadoTitular: 'NORM',
        NumeroCuotasMora: 0,
        Calidad: 'PRIN',
        ClaseTarjeta: 'COR',
        SaldoObligacion: 0,
        MarcaTarjeta: 'CRE',
        PaqueteInformacion: '0002',
        ValorMora: 0,
        Reestructurado: 'NO',
        FechaApertura: '23/06/2005',
        MoraMaxima: '',
        NumeroCuotasPactadas: '',
        Comportamientos:
          'N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |R |N |',
        NombreEntidad: 'BANCOLOMBIA',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        ParticipacionDeuda: '',
        TipoEntidad: 'BCO',
        TipoContrato: 'CRE',
        ValorInicial: 4300,
        ModoExtincion: '',
        Ciudad: 'NEIVA',
        CuotasCanceladas: '',
        ModalidadCredito: 'CONS',
        Sucursal: 'PLAZA DE MERCADO',
        EstadoObligacion: 'CVOL',
        EstadoContrato: 'NVIG',
        NaturalezaReestructuracion: '',
        NumeroObligacion: 497595,
        LineaCredito: 'TCR',
        ValorCuota: 0,
        TipoPago: 'VOL'
      }
    ]
  },
  SectorRealAlDia: {
    Obligacion: {
      IdentificadorLinea: 28414681,
      FechaCorte: '15/05/2012',
      ChequesDevueltos: '',
      EstadoTitular: '',
      NumeroCuotasMora: 0,
      NumeroMesesClausula: 0,
      Calidad: 'PRIN',
      SaldoObligacion: 0,
      PaqueteInformacion: '0011',
      ValorMora: 0,
      Reestructurado: 'NO',
      FechaApertura: '30/12/2008',
      MoraMaxima: '',
      NumeroCuotasPactadas: 0,
      Comportamientos:
        'N |N |N |N |R |N |N |N |N |N |N |N |N |N |N |N |X |R |X |X |N |N |R |X |',
      NombreEntidad: 'COLOMBIA MOVIL ESP',
      ValorCargoFijo: 0,
      FechaPermanencia: '',
      FechaPago: '',
      Periodicidad: 'MEN',
      ParticipacionDeuda: '',
      TipoEntidad: 'COMU',
      TipoContrato: 'SRV',
      ValorInicial: 0,
      ModoExtincion: '',
      Vigencia: 'IND',
      Ciudad: 'BOGOTA',
      CuotasCanceladas: 0,
      ClausulaPermanencia: 0,
      Sucursal: 'BOGOTA',
      NumeroMesesContrato: '',
      EstadoObligacion: 'VIGE',
      EstadoContrato: 'VIGE',
      NumeroObligacion: 343669,
      LineaCredito: 'TELC',
      ValorCuota: 0,
      TipoPago: ''
    }
  },
  SectorRealExtinguidas: {
    Obligacion: {
      IdentificadorLinea: 32671731,
      FechaCorte: '31/07/2009',
      ChequesDevueltos: '',
      EstadoTitular: '',
      NumeroCuotasMora: 0,
      NumeroMesesClausula: 0,
      Calidad: 'PRIN',
      SaldoObligacion: 0,
      PaqueteInformacion: '0011',
      ValorMora: 0,
      Reestructurado: 'NO',
      FechaApertura: '21/11/2005',
      MoraMaxima: '',
      NumeroCuotasPactadas: 0,
      Comportamientos:
        '|  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |N |',
      NombreEntidad: 'COMCEL - COMUNICACION CELULAR',
      ValorCargoFijo: 0,
      FechaPermanencia: '',
      FechaPago: '',
      Periodicidad: 'MEN',
      ParticipacionDeuda: '',
      TipoEntidad: 'CCEL',
      TipoContrato: 'SRV',
      ValorInicial: 0,
      ModoExtincion: '',
      Vigencia: 'IND',
      Ciudad: 'BOGOTA',
      CuotasCanceladas: 0,
      ClausulaPermanencia: 0,
      Sucursal: 'CREDITO Y ACTIVA',
      NumeroMesesContrato: '',
      EstadoObligacion: 'SALD',
      EstadoContrato: 'NVIG',
      NumeroObligacion: 453394,
      LineaCredito: 'TELC',
      ValorCuota: 0,
      TipoPago: ''
    }
  },
  CuentasVigentes: {
    Obligacion: {
      IdentificadorLinea: 25927462,
      TipoEntidad: 'BCO',
      FechaCorte: '30/11/2017',
      ChequesDevueltos: '',
      TipoContrato: 'AHO-INDIVIDUAL',
      ValorInicial: '',
      DiasCartera: '',
      Ciudad: 'NO REPORTADO',
      PaqueteInformacion: '0023',
      Sucursal: 'QUINTA CAMACHO',
      FechaTerminacion: '',
      FechaApertura: '23/08/2003',
      EstadoObligacion: 'NORMA',
      Comportamientos: 'NNNNNNNNNNNN',
      NombreEntidad: 'BANCOLOMBIA',
      NumeroObligacion: 837327,
      FechaPermanencia: ''
    }
  },
  CuentasExtinguidas: {
    Obligacion: [
      {
        IdentificadorLinea: 10241895,
        TipoEntidad: 'BCO',
        FechaCorte: '29/08/2014',
        ChequesDevueltos: '',
        TipoContrato: 'CTE-INDIVIDUAL',
        ValorInicial: 0,
        DiasCartera: 0,
        Ciudad: 'BOGOTA',
        PaqueteInformacion: '0001',
        Sucursal: 'PARQUE CENTRAL B',
        FechaTerminacion: '',
        FechaApertura: '14/12/2006',
        EstadoObligacion: 'SALDA',
        Comportamientos: 'NNNNNNNNNNNS',
        NombreEntidad: 'CITIBANK',
        NumeroObligacion: 598017,
        FechaPermanencia: ''
      },
      {
        IdentificadorLinea: 9748831,
        TipoEntidad: 'BCO',
        FechaCorte: '31/10/2006',
        ChequesDevueltos: '',
        TipoContrato: 'CTE-INDIVIDUAL',
        ValorInicial: 0,
        DiasCartera: 31,
        Ciudad: 'NEIVA',
        PaqueteInformacion: '0001',
        Sucursal: 'NEIVA 2',
        FechaTerminacion: '',
        FechaApertura: '15/06/2005',
        EstadoObligacion: 'SALDA',
        Comportamientos: 'NNNSRRRRRRRR',
        NombreEntidad: 'BANCOLOMBIA',
        NumeroObligacion: 288115,
        FechaPermanencia: ''
      }
    ]
  },
  Endeudamiento: {
    EncabezadoEndeudamiento: {
      NumeroEntidadesTrimestreI: 5,
      FechaTrimestreI: '30-06-2017',
      NumeroEntidadesTrimestreII: 5,
      FechaTrimestreII: '30-09-2017',
      NumeroEntidadesTrimestreIII: 5,
      FechaTrimestreIII: '31-12-2017',
      NumeroComprasTrimestreI: 0,
      NumeroComprasTrimestreII: 0,
      NumeroComprasTrimestreIII: 0,
      NumeroReestructuracionesTrimestreI: 0,
      NumeroReestructuracionesTrimestreII: 0,
      NumeroReestructuracionesTrimestreIII: 0,
      NumeroCastigosTrimestreI: 0,
      NumeroCastigosTrimestreII: 0,
      NumeroCastigosTrimestreIII: 0
    },
    EndeudamientoTrimI: {
      Endeudamiento71: [
        {
          CubrimientoGarantiaVivienda: 99.6,
          ValorDeudaVivienda: 77.454,
          ParticipacionTotalDeudas: 94.8,
          CubrimientoGarantiaComercial: '',
          ValorDeudaComercial: 0,
          NumeroOperacionesConsumo: 7,
          Calificacion: 'A',
          NumeroOperacionesVivienda: 1,
          CubrimientoGarantiaConsumo: '.0',
          ValorDeudaMicrocredito: 0,
          Total: 133.93,
          TipoModena: 'M/L',
          ValorDeudaConsumo: 56.476,
          NumeroOperacionesComercial: 0,
          CubrimientoGarantiaMicrocredito: '',
          NumeroOperacionesMicrocredito: 0
        },
        {
          CubrimientoGarantiaVivienda: '',
          ValorDeudaVivienda: 0,
          ParticipacionTotalDeudas: 5.1,
          CubrimientoGarantiaComercial: '',
          ValorDeudaComercial: 0,
          NumeroOperacionesConsumo: 1,
          Calificacion: 'A',
          NumeroOperacionesVivienda: 0,
          CubrimientoGarantiaConsumo: '.0',
          ValorDeudaMicrocredito: 0,
          Total: 7.27,
          TipoModena: 'M/E',
          ValorDeudaConsumo: 7.27,
          NumeroOperacionesComercial: 0,
          CubrimientoGarantiaMicrocredito: '',
          NumeroOperacionesMicrocredito: 0
        },
        {
          CubrimientoGarantiaVivienda: 99.6,
          ValorDeudaVivienda: 77.454,
          ParticipacionTotalDeudas: 100,
          CubrimientoGarantiaComercial: '.0',
          ValorDeudaComercial: 0,
          NumeroOperacionesConsumo: 8,
          Calificacion: 'TOT',
          NumeroOperacionesVivienda: 1,
          CubrimientoGarantiaConsumo: '.0',
          ValorDeudaMicrocredito: 0,
          Total: 141.2,
          TipoModena: '',
          ValorDeudaConsumo: 63.747,
          NumeroOperacionesComercial: 0,
          CubrimientoGarantiaMicrocredito: '.0',
          NumeroOperacionesMicrocredito: 0
        },
        {
          CubrimientoGarantiaVivienda: 99.6,
          ValorDeudaVivienda: 77.454,
          ParticipacionTotalDeudas: 94.8,
          CubrimientoGarantiaComercial: '.0',
          ValorDeudaComercial: 0,
          NumeroOperacionesConsumo: 7,
          Calificacion: '',
          NumeroOperacionesVivienda: 1,
          CubrimientoGarantiaConsumo: '.0',
          ValorDeudaMicrocredito: 0,
          Total: 133.93,
          TipoModena: 'M/L',
          ValorDeudaConsumo: 56.476,
          NumeroOperacionesComercial: 0,
          CubrimientoGarantiaMicrocredito: '.0',
          NumeroOperacionesMicrocredito: 0
        },
        {
          CubrimientoGarantiaVivienda: '.0',
          ValorDeudaVivienda: 0,
          ParticipacionTotalDeudas: 5.1,
          CubrimientoGarantiaComercial: '.0',
          ValorDeudaComercial: 0,
          NumeroOperacionesConsumo: 1,
          Calificacion: '',
          NumeroOperacionesVivienda: 0,
          CubrimientoGarantiaConsumo: '.0',
          ValorDeudaMicrocredito: 0,
          Total: 7.27,
          TipoModena: 'M/E',
          ValorDeudaConsumo: 7.27,
          NumeroOperacionesComercial: 0,
          CubrimientoGarantiaMicrocredito: '.0',
          NumeroOperacionesMicrocredito: 0
        }
      ],
      Endeudamiento72: [
        {
          CuotaEsperada: 10.817,
          CumplimientoCuota: 71.14,
          ValorContingencias: 0,
          NumeroContingencias: '',
          TipoMoneda: 'M/L'
        },
        {
          CuotaEsperada: 1.492,
          CumplimientoCuota: 103.9,
          ValorContingencias: 0,
          NumeroContingencias: '',
          TipoMoneda: 'M/E'
        },
        {
          CuotaEsperada: 12.308,
          CumplimientoCuota: 75.11,
          ValorContingencias: 0,
          NumeroContingencias: '',
          TipoMoneda: 'TOT'
        }
      ],
      Endeudamiento73: [
        {
          TipoEntidad: 'BCO',
          TipoFideicomiso: '',
          CuotaEsperada: 3.319,
          CumplimientoCuota: '.00',
          ParticipacionTotalDeudas: 54.9,
          TipoEntidadOriginadoraCartera: '',
          NumeroFideicomiso: '',
          ModalidadCredito: 'VNIS',
          ValorDeuda: 77.454,
          Calificacion: 'A',
          NumeroOperadores: 1,
          FechaUltimoAvaluo: '06/2016',
          EntidadOriginadoraCartera: '',
          CubrimientoGarantia: 99.6,
          TipoGarantia: 'OHIPO',
          NombreEntidad: 'BANCOLOMBIA',
          TipoMoneda: 'M/L'
        },
        {
          TipoEntidad: 'BCO',
          TipoFideicomiso: '',
          CuotaEsperada: 655,
          CumplimientoCuota: 100,
          ParticipacionTotalDeudas: 11.5,
          TipoEntidadOriginadoraCartera: '',
          NumeroFideicomiso: '',
          ModalidadCredito: 'COAU',
          ValorDeuda: 16.217,
          Calificacion: 'A',
          NumeroOperadores: 1,
          FechaUltimoAvaluo: '',
          EntidadOriginadoraCartera: '',
          CubrimientoGarantia: '.0',
          TipoGarantia: 'O',
          NombreEntidad: 'BBVA  COLOMBIA',
          TipoMoneda: 'M/L'
        },
        {
          TipoEntidad: 'BCO',
          TipoFideicomiso: '',
          CuotaEsperada: 2.28,
          CumplimientoCuota: 79.77,
          ParticipacionTotalDeudas: 10.1,
          TipoEntidadOriginadoraCartera: '',
          NumeroFideicomiso: '',
          ModalidadCredito: 'COTC',
          ValorDeuda: 14.256,
          Calificacion: 'A',
          NumeroOperadores: 2,
          FechaUltimoAvaluo: '',
          EntidadOriginadoraCartera: '',
          CubrimientoGarantia: '.0',
          TipoGarantia: 'OSIN',
          NombreEntidad: 'DE OCCIDENTE',
          TipoMoneda: 'M/L'
        },
        {
          TipoEntidad: 'BCO',
          TipoFideicomiso: '',
          CuotaEsperada: 1.492,
          CumplimientoCuota: 103.9,
          ParticipacionTotalDeudas: 5.1,
          TipoEntidadOriginadoraCartera: '',
          NumeroFideicomiso: '',
          ModalidadCredito: 'COTC',
          ValorDeuda: 7.27,
          Calificacion: 'A',
          NumeroOperadores: 1,
          FechaUltimoAvaluo: '',
          EntidadOriginadoraCartera: '',
          CubrimientoGarantia: '.0',
          TipoGarantia: 'OSIN',
          NombreEntidad: 'DE OCCIDENTE',
          TipoMoneda: 'M/E'
        },
        {
          TipoEntidad: 'BCO',
          TipoFideicomiso: '',
          CuotaEsperada: 2.28,
          CumplimientoCuota: 127.2,
          ParticipacionTotalDeudas: 15.1,
          TipoEntidadOriginadoraCartera: '',
          NumeroFideicomiso: '',
          ModalidadCredito: 'COOT',
          ValorDeuda: 21.289,
          Calificacion: 'A',
          NumeroOperadores: 2,
          FechaUltimoAvaluo: '',
          EntidadOriginadoraCartera: '',
          CubrimientoGarantia: '.0',
          TipoGarantia: 'OSIN',
          NombreEntidad: 'DAVIVIENDA S.A.',
          TipoMoneda: 'M/L'
        },
        {
          TipoEntidad: 'BCO',
          TipoFideicomiso: '',
          CuotaEsperada: 1.282,
          CumplimientoCuota: 94.62,
          ParticipacionTotalDeudas: '.6',
          TipoEntidadOriginadoraCartera: '',
          NumeroFideicomiso: '',
          ModalidadCredito: 'COTC',
          ValorDeuda: 863,
          Calificacion: 'A',
          NumeroOperadores: 1,
          FechaUltimoAvaluo: '',
          EntidadOriginadoraCartera: '',
          CubrimientoGarantia: '.0',
          TipoGarantia: 'OSIN',
          NombreEntidad: 'DAVIVIENDA S.A.',
          TipoMoneda: 'M/L'
        },
        {
          TipoEntidad: 'BCO',
          TipoFideicomiso: '',
          CuotaEsperada: 1,
          CumplimientoCuota: 110.7,
          ParticipacionTotalDeudas: 2.7,
          TipoEntidadOriginadoraCartera: '',
          NumeroFideicomiso: '',
          ModalidadCredito: 'COTC',
          ValorDeuda: 3.851,
          Calificacion: 'A',
          NumeroOperadores: 1,
          FechaUltimoAvaluo: '',
          EntidadOriginadoraCartera: '',
          CubrimientoGarantia: '.0',
          TipoGarantia: 'OSIN',
          NombreEntidad: 'COLPATRIA RED M',
          TipoMoneda: 'M/L'
        }
      ]
    },
    EndeudamientoTrimII: {
      Endeudamiento81: [
        {
          CubrimientoGarantiaVivienda: 99.9,
          ValorDeudaVivienda: 149.03,
          ParticipacionTotalDeudas: 100,
          CubrimientoGarantiaComercial: '',
          ValorDeudaComercial: 0,
          NumeroOperacionesConsumo: 13,
          Calificacion: 'A',
          NumeroOperacionesVivienda: 2,
          CubrimientoGarantiaConsumo: '.0',
          ValorDeudaMicrocredito: 0,
          Total: 226.96,
          TipoModena: 'M/L',
          ValorDeudaConsumo: 77.93,
          NumeroOperacionesComercial: 0,
          CubrimientoGarantiaMicrocredito: '',
          NumeroOperacionesMicrocredito: 0
        },
        {
          CubrimientoGarantiaVivienda: 99.9,
          ValorDeudaVivienda: 149.03,
          ParticipacionTotalDeudas: 100,
          CubrimientoGarantiaComercial: '.0',
          ValorDeudaComercial: 0,
          NumeroOperacionesConsumo: 13,
          Calificacion: 'TOT',
          NumeroOperacionesVivienda: 2,
          CubrimientoGarantiaConsumo: '.0',
          ValorDeudaMicrocredito: 0,
          Total: 226.96,
          TipoModena: '',
          ValorDeudaConsumo: 77.93,
          NumeroOperacionesComercial: 0,
          CubrimientoGarantiaMicrocredito: '.0',
          NumeroOperacionesMicrocredito: 0
        },
        {
          CubrimientoGarantiaVivienda: 99.9,
          ValorDeudaVivienda: 149.03,
          ParticipacionTotalDeudas: 100,
          CubrimientoGarantiaComercial: '.0',
          ValorDeudaComercial: 0,
          NumeroOperacionesConsumo: 13,
          Calificacion: '',
          NumeroOperacionesVivienda: 2,
          CubrimientoGarantiaConsumo: '.0',
          ValorDeudaMicrocredito: 0,
          Total: 226.96,
          TipoModena: 'M/L',
          ValorDeudaConsumo: 77.93,
          NumeroOperacionesComercial: 0,
          CubrimientoGarantiaMicrocredito: '.0',
          NumeroOperacionesMicrocredito: 0
        }
      ],
      Endeudamiento83: [
        {
          TipoEntidad: 'BCO',
          TipoFideicomiso: '',
          CuotaEsperada: 3.319,
          CumplimientoCuota: '.00',
          ParticipacionTotalDeudas: 33.5,
          TipoEntidadOriginadoraCartera: '',
          NumeroFideicomiso: '',
          ModalidadCredito: 'VNIS',
          ValorDeuda: 76.01,
          Calificacion: 'A',
          NumeroOperadores: 1,
          FechaUltimoAvaluo: '06/2016',
          EntidadOriginadoraCartera: '',
          CubrimientoGarantia: 99.9,
          TipoGarantia: 'OHIPO',
          NombreEntidad: 'BANCOLOMBIA',
          TipoMoneda: 'M/L'
        },
        {
          TipoEntidad: 'BCO',
          TipoFideicomiso: '',
          CuotaEsperada: 3.319,
          CumplimientoCuota: 100,
          ParticipacionTotalDeudas: 32.2,
          TipoEntidadOriginadoraCartera: '',
          NumeroFideicomiso: '',
          ModalidadCredito: 'VNIS',
          ValorDeuda: 73.02,
          Calificacion: 'A',
          NumeroOperadores: 1,
          FechaUltimoAvaluo: '06/2017',
          EntidadOriginadoraCartera: '',
          CubrimientoGarantia: 99.9,
          TipoGarantia: 'OHIPO',
          NombreEntidad: 'BANCOLOMBIA',
          TipoMoneda: 'M/L'
        },
        {
          TipoEntidad: 'BCO',
          TipoFideicomiso: '',
          CuotaEsperada: 655,
          CumplimientoCuota: 100,
          ParticipacionTotalDeudas: 5,
          TipoEntidadOriginadoraCartera: '',
          NumeroFideicomiso: '',
          ModalidadCredito: 'COAU',
          ValorDeuda: 11.457,
          Calificacion: 'A',
          NumeroOperadores: 1,
          FechaUltimoAvaluo: '',
          EntidadOriginadoraCartera: '',
          CubrimientoGarantia: '.0',
          TipoGarantia: 'O',
          NombreEntidad: 'BBVA  COLOMBIA',
          TipoMoneda: 'M/L'
        },
        {
          TipoEntidad: 'BCO',
          TipoFideicomiso: '',
          CuotaEsperada: 655,
          CumplimientoCuota: 100,
          ParticipacionTotalDeudas: 6.5,
          TipoEntidadOriginadoraCartera: '',
          NumeroFideicomiso: '',
          ModalidadCredito: 'COAU',
          ValorDeuda: 14.685,
          Calificacion: 'A',
          NumeroOperadores: 1,
          FechaUltimoAvaluo: '',
          EntidadOriginadoraCartera: '',
          CubrimientoGarantia: '.0',
          TipoGarantia: 'O',
          NombreEntidad: 'BBVA  COLOMBIA',
          TipoMoneda: 'M/L'
        },
        {
          TipoEntidad: 'BCO',
          TipoFideicomiso: '',
          CuotaEsperada: 926,
          CumplimientoCuota: 47.92,
          ParticipacionTotalDeudas: 1.6,
          TipoEntidadOriginadoraCartera: '',
          NumeroFideicomiso: '',
          ModalidadCredito: 'COTC',
          ValorDeuda: 3.711,
          Calificacion: 'A',
          NumeroOperadores: 2,
          FechaUltimoAvaluo: '',
          EntidadOriginadoraCartera: '',
          CubrimientoGarantia: '.0',
          TipoGarantia: 'OSIN',
          NombreEntidad: 'DE OCCIDENTE',
          TipoMoneda: 'M/L'
        },
        {
          TipoEntidad: 'BCO',
          TipoFideicomiso: '',
          CuotaEsperada: 968,
          CumplimientoCuota: 999.9,
          ParticipacionTotalDeudas: 1.4,
          TipoEntidadOriginadoraCartera: '',
          NumeroFideicomiso: '',
          ModalidadCredito: 'COTC',
          ValorDeuda: 3.12,
          Calificacion: 'A',
          NumeroOperadores: 2,
          FechaUltimoAvaluo: '',
          EntidadOriginadoraCartera: '',
          CubrimientoGarantia: '.0',
          TipoGarantia: 'OSIN',
          NombreEntidad: 'DE OCCIDENTE',
          TipoMoneda: 'M/L'
        },
        {
          TipoEntidad: 'BCO',
          TipoFideicomiso: '',
          CuotaEsperada: 2.28,
          CumplimientoCuota: 105.6,
          ParticipacionTotalDeudas: 8.6,
          TipoEntidadOriginadoraCartera: '',
          NumeroFideicomiso: '',
          ModalidadCredito: 'COOT',
          ValorDeuda: 19.482,
          Calificacion: 'A',
          NumeroOperadores: 2,
          FechaUltimoAvaluo: '',
          EntidadOriginadoraCartera: '',
          CubrimientoGarantia: '.0',
          TipoGarantia: 'OSIN',
          NombreEntidad: 'DAVIVIENDA S.A.',
          TipoMoneda: 'M/L'
        },
        {
          TipoEntidad: 'BCO',
          TipoFideicomiso: '',
          CuotaEsperada: 142,
          CumplimientoCuota: 999.9,
          ParticipacionTotalDeudas: '.5',
          TipoEntidadOriginadoraCartera: '',
          NumeroFideicomiso: '',
          ModalidadCredito: 'COTC',
          ValorDeuda: 1.059,
          Calificacion: 'A',
          NumeroOperadores: 1,
          FechaUltimoAvaluo: '',
          EntidadOriginadoraCartera: '',
          CubrimientoGarantia: '.0',
          TipoGarantia: 'OSIN',
          NombreEntidad: 'DAVIVIENDA S.A.',
          TipoMoneda: 'M/L'
        },
        {
          TipoEntidad: 'BCO',
          TipoFideicomiso: '',
          CuotaEsperada: 1.589,
          CumplimientoCuota: 50.43,
          ParticipacionTotalDeudas: '.8',
          TipoEntidadOriginadoraCartera: '',
          NumeroFideicomiso: '',
          ModalidadCredito: 'COTC',
          ValorDeuda: 1.756,
          Calificacion: 'A',
          NumeroOperadores: 1,
          FechaUltimoAvaluo: '',
          EntidadOriginadoraCartera: '',
          CubrimientoGarantia: '.0',
          TipoGarantia: 'OSIN',
          NombreEntidad: 'DAVIVIENDA S.A.',
          TipoMoneda: 'M/L'
        },
        {
          TipoEntidad: 'BCO',
          TipoFideicomiso: '',
          CuotaEsperada: 1.983,
          CumplimientoCuota: 99.68,
          ParticipacionTotalDeudas: 7.3,
          TipoEntidadOriginadoraCartera: '',
          NumeroFideicomiso: '',
          ModalidadCredito: 'COOT',
          ValorDeuda: 16.55,
          Calificacion: 'A',
          NumeroOperadores: 1,
          FechaUltimoAvaluo: '',
          EntidadOriginadoraCartera: '',
          CubrimientoGarantia: '.0',
          TipoGarantia: 'OSIN',
          NombreEntidad: 'DAVIVIENDA S.A.',
          TipoMoneda: 'M/L'
        },
        {
          TipoEntidad: 'BCO',
          TipoFideicomiso: '',
          CuotaEsperada: 2.527,
          CumplimientoCuota: 37.2,
          ParticipacionTotalDeudas: 1.4,
          TipoEntidadOriginadoraCartera: '',
          NumeroFideicomiso: '',
          ModalidadCredito: 'COTC',
          ValorDeuda: 3.274,
          Calificacion: 'A',
          NumeroOperadores: 1,
          FechaUltimoAvaluo: '',
          EntidadOriginadoraCartera: '',
          CubrimientoGarantia: '.0',
          TipoGarantia: 'OSIN',
          NombreEntidad: 'COLPATRIA RED M',
          TipoMoneda: 'M/L'
        },
        {
          TipoEntidad: 'BCO',
          TipoFideicomiso: '',
          CuotaEsperada: 1.096,
          CumplimientoCuota: 93.04,
          ParticipacionTotalDeudas: 1.2,
          TipoEntidadOriginadoraCartera: '',
          NumeroFideicomiso: '',
          ModalidadCredito: 'COTC',
          ValorDeuda: 2.836,
          Calificacion: 'A',
          NumeroOperadores: 1,
          FechaUltimoAvaluo: '',
          EntidadOriginadoraCartera: '',
          CubrimientoGarantia: '.0',
          TipoGarantia: 'OSIN',
          NombreEntidad: 'COLPATRIA RED M',
          TipoMoneda: 'M/L'
        }
      ],
      Endeudamiento82: [
        {
          CuotaEsperada: 19.459,
          CumplimientoCuota: 133.15,
          ValorContingencias: 0,
          NumeroContingencias: '',
          TipoMoneda: 'M/L'
        },
        {
          CuotaEsperada: 0,
          CumplimientoCuota: '',
          ValorContingencias: 0,
          NumeroContingencias: '',
          TipoMoneda: 'M/E'
        },
        {
          CuotaEsperada: 19.459,
          CumplimientoCuota: 133.15,
          ValorContingencias: 0,
          NumeroContingencias: '',
          TipoMoneda: 'TOT'
        }
      ]
    },
    EndeudamientoTrimIII: {
      Endeudamiento92: [
        {
          CuotaEsperada: 9.934,
          CumplimientoCuota: 112.05,
          ValorContingencias: 0,
          NumeroContingencias: '',
          TipoMoneda: 'M/L'
        },
        {
          CuotaEsperada: 210,
          CumplimientoCuota: 484.65,
          ValorContingencias: 0,
          NumeroContingencias: '',
          TipoMoneda: 'M/E'
        },
        {
          CuotaEsperada: 10.145,
          CumplimientoCuota: 119.77,
          ValorContingencias: 0,
          NumeroContingencias: '',
          TipoMoneda: 'TOT'
        }
      ],
      Endeudamiento91: [
        {
          CubrimientoGarantiaVivienda: 99.9,
          ValorDeudaVivienda: 74.515,
          ParticipacionTotalDeudas: 99.4,
          CubrimientoGarantiaComercial: '',
          ValorDeudaComercial: 0,
          NumeroOperacionesConsumo: 6,
          Calificacion: 'A',
          NumeroOperacionesVivienda: 1,
          CubrimientoGarantiaConsumo: '.0',
          ValorDeudaMicrocredito: 0,
          Total: 111.478,
          TipoModena: 'M/L',
          ValorDeudaConsumo: 36.963,
          NumeroOperacionesComercial: 0,
          CubrimientoGarantiaMicrocredito: '',
          NumeroOperacionesMicrocredito: 0
        },
        {
          CubrimientoGarantiaVivienda: '',
          ValorDeudaVivienda: 0,
          ParticipacionTotalDeudas: '.6',
          CubrimientoGarantiaComercial: '',
          ValorDeudaComercial: 0,
          NumeroOperacionesConsumo: 1,
          Calificacion: 'A',
          NumeroOperacionesVivienda: 0,
          CubrimientoGarantiaConsumo: '.0',
          ValorDeudaMicrocredito: 0,
          Total: 659,
          TipoModena: 'M/E',
          ValorDeudaConsumo: 659,
          NumeroOperacionesComercial: 0,
          CubrimientoGarantiaMicrocredito: '',
          NumeroOperacionesMicrocredito: 0
        },
        {
          CubrimientoGarantiaVivienda: 99.9,
          ValorDeudaVivienda: 74.515,
          ParticipacionTotalDeudas: 100,
          CubrimientoGarantiaComercial: '.0',
          ValorDeudaComercial: 0,
          NumeroOperacionesConsumo: 7,
          Calificacion: 'TOT',
          NumeroOperacionesVivienda: 1,
          CubrimientoGarantiaConsumo: '.0',
          ValorDeudaMicrocredito: 0,
          Total: 112.137,
          TipoModena: '',
          ValorDeudaConsumo: 37.623,
          NumeroOperacionesComercial: 0,
          CubrimientoGarantiaMicrocredito: '.0',
          NumeroOperacionesMicrocredito: 0
        },
        {
          CubrimientoGarantiaVivienda: 99.9,
          ValorDeudaVivienda: 74.515,
          ParticipacionTotalDeudas: 99.4,
          CubrimientoGarantiaComercial: '.0',
          ValorDeudaComercial: 0,
          NumeroOperacionesConsumo: 6,
          Calificacion: '',
          NumeroOperacionesVivienda: 1,
          CubrimientoGarantiaConsumo: '.0',
          ValorDeudaMicrocredito: 0,
          Total: 111.478,
          TipoModena: 'M/L',
          ValorDeudaConsumo: 36.963,
          NumeroOperacionesComercial: 0,
          CubrimientoGarantiaMicrocredito: '.0',
          NumeroOperacionesMicrocredito: 0
        },
        {
          CubrimientoGarantiaVivienda: '.0',
          ValorDeudaVivienda: 0,
          ParticipacionTotalDeudas: '.6',
          CubrimientoGarantiaComercial: '.0',
          ValorDeudaComercial: 0,
          NumeroOperacionesConsumo: 1,
          Calificacion: '',
          NumeroOperacionesVivienda: 0,
          CubrimientoGarantiaConsumo: '.0',
          ValorDeudaMicrocredito: 0,
          Total: 659,
          TipoModena: 'M/E',
          ValorDeudaConsumo: 659,
          NumeroOperacionesComercial: 0,
          CubrimientoGarantiaMicrocredito: '.0',
          NumeroOperacionesMicrocredito: 0
        }
      ],
      Endeudamiento93: [
        {
          TipoEntidad: 'BCO',
          TipoFideicomiso: '',
          CuotaEsperada: 3.319,
          CumplimientoCuota: '.00',
          ParticipacionTotalDeudas: 66.4,
          TipoEntidadOriginadoraCartera: '',
          NumeroFideicomiso: '',
          ModalidadCredito: 'VNIS',
          ValorDeuda: 74.515,
          Calificacion: 'A',
          NumeroOperadores: 1,
          FechaUltimoAvaluo: '06/2017',
          EntidadOriginadoraCartera: '',
          CubrimientoGarantia: 99.9,
          TipoGarantia: 'OHIPO',
          NombreEntidad: 'BANCOLOMBIA',
          TipoMoneda: 'M/L'
        },
        {
          TipoEntidad: 'BCO',
          TipoFideicomiso: '',
          CuotaEsperada: 655,
          CumplimientoCuota: '.00',
          ParticipacionTotalDeudas: 11.7,
          TipoEntidadOriginadoraCartera: '',
          NumeroFideicomiso: '',
          ModalidadCredito: 'COAU',
          ValorDeuda: 13.098,
          Calificacion: 'A',
          NumeroOperadores: 1,
          FechaUltimoAvaluo: '',
          EntidadOriginadoraCartera: '',
          CubrimientoGarantia: '.0',
          TipoGarantia: 'O',
          NombreEntidad: 'BBVA  COLOMBIA',
          TipoMoneda: 'M/L'
        },
        {
          TipoEntidad: 'BCO',
          TipoFideicomiso: '',
          CuotaEsperada: 889,
          CumplimientoCuota: 516.4,
          ParticipacionTotalDeudas: 1.6,
          TipoEntidadOriginadoraCartera: '',
          NumeroFideicomiso: '',
          ModalidadCredito: 'COTC',
          ValorDeuda: 1.813,
          Calificacion: 'A',
          NumeroOperadores: 2,
          FechaUltimoAvaluo: '',
          EntidadOriginadoraCartera: '',
          CubrimientoGarantia: '.0',
          TipoGarantia: 'OSIN',
          NombreEntidad: 'DE OCCIDENTE',
          TipoMoneda: 'M/L'
        },
        {
          TipoEntidad: 'BCO',
          TipoFideicomiso: '',
          CuotaEsperada: 210,
          CumplimientoCuota: 484.7,
          ParticipacionTotalDeudas: '.6',
          TipoEntidadOriginadoraCartera: '',
          NumeroFideicomiso: '',
          ModalidadCredito: 'COTC',
          ValorDeuda: 659,
          Calificacion: 'A',
          NumeroOperadores: 1,
          FechaUltimoAvaluo: '',
          EntidadOriginadoraCartera: '',
          CubrimientoGarantia: '.0',
          TipoGarantia: 'OSIN',
          NombreEntidad: 'DE OCCIDENTE',
          TipoMoneda: 'M/E'
        },
        {
          TipoEntidad: 'BCO',
          TipoFideicomiso: '',
          CuotaEsperada: 1.25,
          CumplimientoCuota: 101.7,
          ParticipacionTotalDeudas: 1,
          TipoEntidadOriginadoraCartera: '',
          NumeroFideicomiso: '',
          ModalidadCredito: 'COTC',
          ValorDeuda: 1.127,
          Calificacion: 'A',
          NumeroOperadores: 1,
          FechaUltimoAvaluo: '',
          EntidadOriginadoraCartera: '',
          CubrimientoGarantia: '.0',
          TipoGarantia: 'OSIN',
          NombreEntidad: 'DAVIVIENDA S.A.',
          TipoMoneda: 'M/L'
        },
        {
          TipoEntidad: 'BCO',
          TipoFideicomiso: '',
          CuotaEsperada: 1.983,
          CumplimientoCuota: 100,
          ParticipacionTotalDeudas: 16.1,
          TipoEntidadOriginadoraCartera: '',
          NumeroFideicomiso: '',
          ModalidadCredito: 'COOT',
          ValorDeuda: 18.023,
          Calificacion: 'A',
          NumeroOperadores: 1,
          FechaUltimoAvaluo: '',
          EntidadOriginadoraCartera: '',
          CubrimientoGarantia: '.0',
          TipoGarantia: 'OSIN',
          NombreEntidad: 'DAVIVIENDA S.A.',
          TipoMoneda: 'M/L'
        },
        {
          TipoEntidad: 'BCO',
          TipoFideicomiso: '',
          CuotaEsperada: 1.838,
          CumplimientoCuota: 178.8,
          ParticipacionTotalDeudas: 2.6,
          TipoEntidadOriginadoraCartera: '',
          NumeroFideicomiso: '',
          ModalidadCredito: 'COTC',
          ValorDeuda: 2.902,
          Calificacion: 'A',
          NumeroOperadores: 1,
          FechaUltimoAvaluo: '',
          EntidadOriginadoraCartera: '',
          CubrimientoGarantia: '.0',
          TipoGarantia: 'OSIN',
          NombreEntidad: 'COLPATRIA RED M',
          TipoMoneda: 'M/L'
        }
      ]
    }
  },
  HuellaConsulta: {
    Consulta: [
      {
        FechaConsulta: '23/10/2018',
        Sucursal: 'PRINCIPAL',
        NombreEntidad: 'GULUNGO COLOMBIA SAS',
        CodigoEntidad: 178,
        NombreTipoEntidad: 'AFIS',
        Ciudad: 'BOGOTA',
        MotivoConsulta: 'OTRO',
        CodigoTipoEntidad: 149
      },
      {
        FechaConsulta: '22/10/2018',
        Sucursal: 'PRINCIPAL',
        NombreEntidad: 'GULUNGO COLOMBIA SAS',
        CodigoEntidad: 178,
        NombreTipoEntidad: 'AADA',
        Ciudad: 'BOGOTA',
        MotivoConsulta: 'OTRO',
        CodigoTipoEntidad: 149
      },
      {
        FechaConsulta: '19/10/2018',
        Sucursal: 'PRINCIPAL',
        NombreEntidad: 'GULUNGO COLOMBIA SAS',
        CodigoEntidad: 178,
        NombreTipoEntidad: 'ASAP',
        Ciudad: 'BOGOTA',
        MotivoConsulta: 'OTRO',
        CodigoTipoEntidad: 149
      },
    ]
  },
};

// @ts-ignore
// Al día y en mora 64560286
const response2 = {
  IdentificadorLinea: 22687867,
  TipoIdentificacion: 'C.C.',
  CodigoTipoIndentificacion: 1,
  NumeroIdentificacion: 64560286,
  NombreTitular: 'OSORIO          MARIN         CLAUDIA       MILENA',
  NombreCiiu: '',
  LugarExpedicion: 'SINCELEJO',
  FechaExpedicion: '26/04/1988',
  Estado: 'VIGENTE',
  NumeroInforme: '01831607610180622739',
  EstadoTitular: '',
  RangoEdad: '46-50',
  CodigoCiiu: '',
  CodigoDepartamento: 70,
  CodigoMunicipio: 1,
  Fecha: '17/11/2018',
  Hora: '11:48:59',
  Entidad: 'E    GULUNGO COLOMBIA SAS',
  RespuestaConsulta: '02',
  Score: {
    IndicadorScore: 0,
    Observacion: '',
    Politica: '',
    SubPoblacion: '',
    IndicadorDefault: '',
    CodigoScore: 5700,
    Puntaje: 746,
    TasaMorosidad: '',
    TipoScore: 'CREDITVISION SCORE'
  },
  Consolidado: {
    Registro: {
      PaqueteInformacion: 'Total',
      ParticipacionDeuda: 100,
      ValorMora: 2576,
      TotalSaldo: 3717,
      SaldoObligacionesDia: 1150,
      NumeroObligacionesDia: 4,
      CuotaObligacionesDia: 270,
      CantidadObligacionesMora: 3,
      NumeroObligaciones: 7,
      SaldoObligacionesMora: 2567000,
      CuotaObligacionesMora: 773
    },
    ResumenPrincipal: {
      Registro: [
        {
          PaqueteInformacion: 'Tarjetas De Credito',
          ParticipacionDeuda: 29,
          ValorMora: 136,
          TotalSaldo: 1090,
          SaldoObligacionesDia: 963,
          NumeroObligacionesDia: 2,
          CuotaObligacionesDia: 83,
          CantidadObligacionesMora: 1,
          NumeroObligaciones: 3,
          SaldoObligacionesMora: 127,
          CuotaObligacionesMora: 136
        },
        {
          PaqueteInformacion: 'Sector Financiero',
          ParticipacionDeuda: 0,
          ValorMora: 0,
          TotalSaldo: 0,
          SaldoObligacionesDia: 0,
          NumeroObligacionesDia: 1,
          CuotaObligacionesDia: 0,
          CantidadObligacionesMora: 0,
          NumeroObligaciones: 1,
          SaldoObligacionesMora: 0,
          CuotaObligacionesMora: 0
        },
        {
          PaqueteInformacion: 'Sector Real',
          ParticipacionDeuda: 36,
          ValorMora: 424,
          TotalSaldo: 611,
          SaldoObligacionesDia: 187,
          NumeroObligacionesDia: 1,
          CuotaObligacionesDia: 187,
          CantidadObligacionesMora: 1,
          NumeroObligaciones: 2,
          SaldoObligacionesMora: 424,
          CuotaObligacionesMora: 0
        },
        {
          PaqueteInformacion: 'Subtotal Principal',
          ParticipacionDeuda: 100,
          ValorMora: 560,
          TotalSaldo: 1701,
          SaldoObligacionesDia: 1150,
          NumeroObligacionesDia: 4,
          CuotaObligacionesDia: 270,
          CantidadObligacionesMora: 2,
          NumeroObligaciones: 6,
          SaldoObligacionesMora: 551,
          CuotaObligacionesMora: 136
        }
      ]
    },
    ResumenDiferentePrincipal: {
      Registro: [
        {
          PaqueteInformacion: 'Sector Real',
          ParticipacionDeuda: '',
          ValorMora: 2016,
          TotalSaldo: 2016,
          SaldoObligacionesDia: 0,
          NumeroObligacionesDia: 0,
          CuotaObligacionesDia: 0,
          CantidadObligacionesMora: 1,
          NumeroObligaciones: 1,
          SaldoObligacionesMora: 2016,
          CuotaObligacionesMora: 637
        },
        {
          PaqueteInformacion: 'Subtotal Codeudores',
          ParticipacionDeuda: '',
          ValorMora: 2016,
          TotalSaldo: 2016,
          SaldoObligacionesDia: 0,
          NumeroObligacionesDia: 0,
          CuotaObligacionesDia: 0,
          CantidadObligacionesMora: 1,
          NumeroObligaciones: 1,
          SaldoObligacionesMora: 2016,
          CuotaObligacionesMora: 637
        }
      ]
    }
  },
  SectorFinancieroAlDia: {
    Obligacion: [
      {
        IdentificadorLinea: 59525477,
        FechaCorte: '31/05/2017',
        EstadoTitular: 'OTRO',
        NumeroCuotasMora: 0,
        Calidad: 'PRIN',
        SaldoObligacion: 0,
        TipoEntidadOriginadoraCartera: '',
        PaqueteInformacion: '0006',
        ValorMora: 0,
        FechaTerminacion: '30/06/2021',
        Reestructurado: 'NO',
        FechaApertura: '04/06/2008',
        EntidadOriginadoraCartera: '',
        MoraMaxima: '',
        NumeroCuotasPactadas: 0,
        Comportamientos:
          'N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |',
        NombreEntidad: 'AV VILLAS',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        Periodicidad: 'MEN',
        ParticipacionDeuda: 0,
        TipoEntidad: 'BCO',
        TipoContrato: 'CRE',
        ValorInicial: 1200,
        ModoExtincion: '',
        ProbabilidadNoPago: 100,
        Ciudad: 'SINCELEJO',
        CuotasCanceladas: '',
        ModalidadCredito: 'CONS',
        Calificacion: 'AA',
        Sucursal: 'SINCELEJO',
        CubrimientoGarantia: '',
        EstadoObligacion: 'VIGE',
        TipoGarantia: '',
        EstadoContrato: 'VIGE',
        NaturalezaReestructuracion: '',
        NumeroObligacion: '5115TC',
        LineaCredito: 'ROTA',
        ValorCuota: 0,
        TipoMoneda: 'ML',
        TipoPago: ''
      },
      {
        IdentificadorLinea: 20165146,
        FechaCorte: '31/05/2017',
        EstadoTitular: 'NORM',
        NumeroCuotasMora: 0,
        Calidad: 'PRIN',
        ClaseTarjeta: 'SFN',
        SaldoObligacion: 963,
        MarcaTarjeta: 'SFN',
        PaqueteInformacion: '0002',
        ValorMora: 0,
        Reestructurado: 'NO',
        FechaApertura: '10/04/2007',
        MoraMaxima: '',
        NumeroCuotasPactadas: '',
        Comportamientos:
          'N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |',
        NombreEntidad: 'SERFINANSA- SERVICIOS FINANCIE',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        ParticipacionDeuda: 88,
        TipoEntidad: 'CFC',
        TipoContrato: 'CRE',
        ValorInicial: 1580,
        ModoExtincion: '',
        Ciudad: 'SINCELEJO',
        CuotasCanceladas: '',
        ModalidadCredito: 'CONS',
        Sucursal: 'SINCELEJO',
        EstadoObligacion: 'VIGE',
        EstadoContrato: 'VIGE',
        NaturalezaReestructuracion: '',
        NumeroObligacion: 530639,
        LineaCredito: 'TCR',
        ValorCuota: 83,
        TipoPago: ''
      },
      {
        IdentificadorLinea: 55742457,
        FechaCorte: '30/04/2017',
        EstadoTitular: 'NORM',
        NumeroCuotasMora: 0,
        Calidad: 'PRIN',
        ClaseTarjeta: 'SFN',
        SaldoObligacion: 0,
        MarcaTarjeta: 'SFN',
        PaqueteInformacion: '0002',
        ValorMora: 0,
        Reestructurado: 'NO',
        FechaApertura: '10/04/2007',
        MoraMaxima: '',
        NumeroCuotasPactadas: '',
        Comportamientos:
          '|  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |N |N |N |N |N |N |R |',
        NombreEntidad: 'SERFINANSA- SERVICIOS FINANCIE',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        ParticipacionDeuda: 0,
        TipoEntidad: 'CFC',
        TipoContrato: 'CRE',
        ValorInicial: 0,
        ModoExtincion: '',
        Ciudad: 'BARRANQUILLA',
        CuotasCanceladas: '',
        ModalidadCredito: 'CONS',
        Sucursal: 'PPAL BARRANQUILL',
        EstadoObligacion: 'VIGE',
        EstadoContrato: 'VIGE',
        NaturalezaReestructuracion: '',
        NumeroObligacion: 577822,
        LineaCredito: 'TCR',
        ValorCuota: 0,
        TipoPago: ''
      }
    ]
  },
  SectorFinancieroEnMora: {
    Obligacion: {
      IdentificadorLinea: 24437869,
      FechaCorte: '31/05/2017',
      EstadoTitular: 'NORM',
      NumeroCuotasMora: 4,
      Calidad: 'PRIN',
      ClaseTarjeta: 'CLA',
      SaldoObligacion: 127,
      MarcaTarjeta: 'EXI',
      PaqueteInformacion: '0002',
      ValorMora: 136,
      Reestructurado: 'NO',
      FechaApertura: '05/10/2006',
      MoraMaxima: '',
      NumeroCuotasPactadas: '',
      Comportamientos:
        'N |N |N |N |N |N |N |N |N |N |N |N |1 |1 |1 |1 |2 |3 |R |R |R |R |R |R |',
      NombreEntidad: 'TUYA S.A. COMPAÑIA  DE  FINANC',
      FechaPermanencia: '',
      FechaPago: '',
      NumeroReestructuraciones: 0,
      ParticipacionDeuda: 12,
      TipoEntidad: 'CFC',
      TipoContrato: 'CRE',
      ValorInicial: 2149,
      ModoExtincion: '',
      Ciudad: 'MEDELLIN',
      CuotasCanceladas: '',
      ModalidadCredito: 'CONS',
      Sucursal: 'REGIONAL MEDELLI',
      EstadoObligacion: 'VIGE',
      EstadoContrato: 'VIGE',
      NaturalezaReestructuracion: '',
      NumeroObligacion: 397783,
      LineaCredito: 'TCR',
      ValorCuota: 136,
      TipoPago: ''
    }
  },
  SectorFinancieroExtinguidas: {
    Obligacion: [
      {
        IdentificadorLinea: 58915603,
        FechaCorte: '30/09/2010',
        EstadoTitular: 'OTRO',
        NumeroCuotasMora: 0,
        Calidad: 'PRIN',
        SaldoObligacion: 0,
        TipoEntidadOriginadoraCartera: '',
        PaqueteInformacion: '0006',
        ValorMora: 0,
        FechaTerminacion: '10/06/2011',
        Reestructurado: 'NO',
        FechaApertura: '04/06/2008',
        EntidadOriginadoraCartera: '',
        MoraMaxima: '',
        NumeroCuotasPactadas: 36,
        Comportamientos:
          'N |N |N |N |N |N |N |N |N |R |R |N |N |N |N |N |N |N |N |N |N |N |N |N |',
        NombreEntidad: 'AV VILLAS',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        Periodicidad: 'MEN',
        ParticipacionDeuda: '',
        TipoEntidad: 'BCO',
        TipoContrato: 'CRE',
        ValorInicial: 3500,
        ModoExtincion: '',
        ProbabilidadNoPago: 100,
        Ciudad: 'SINCELEJO',
        CuotasCanceladas: 30,
        ModalidadCredito: 'CONS',
        Calificacion: '',
        Sucursal: 'SINCELEJO',
        CubrimientoGarantia: '',
        EstadoObligacion: 'SALD',
        TipoGarantia: '',
        EstadoContrato: 'NVIG',
        NaturalezaReestructuracion: '',
        NumeroObligacion: '3021KV',
        LineaCredito: 'ORDI',
        ValorCuota: 0,
        TipoMoneda: 'ML',
        TipoPago: 'VOL'
      },
      {
        IdentificadorLinea: 40376765,
        FechaCorte: '30/06/2008',
        EstadoTitular: '',
        NumeroCuotasMora: '',
        Calidad: 'PRIN',
        SaldoObligacion: 0,
        TipoEntidadOriginadoraCartera: '',
        PaqueteInformacion: '0006',
        ValorMora: 0,
        FechaTerminacion: '10/06/2008',
        Reestructurado: '',
        FechaApertura: '23/05/2006',
        EntidadOriginadoraCartera: '',
        MoraMaxima: '',
        NumeroCuotasPactadas: '',
        Comportamientos:
          'N |N |N |N |N |N |N |N |N |N |N |N |N |R |N |N |N |N |N |N |N |N |N |N |',
        NombreEntidad: 'AV VILLAS',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        Periodicidad: 'MEN',
        ParticipacionDeuda: '',
        TipoEntidad: 'BCO',
        TipoContrato: 'CRE',
        ValorInicial: 2000,
        ModoExtincion: '',
        ProbabilidadNoPago: '',
        Ciudad: 'SINCELEJO',
        CuotasCanceladas: 24,
        ModalidadCredito: 'CONS',
        Calificacion: '',
        Sucursal: 'SINCELEJO',
        CubrimientoGarantia: '',
        EstadoObligacion: 'SALD',
        TipoGarantia: '',
        EstadoContrato: '',
        NaturalezaReestructuracion: '',
        NumeroObligacion: '9820KV',
        LineaCredito: 'ORDI',
        ValorCuota: 0,
        TipoMoneda: 'ML',
        TipoPago: ''
      },
      {
        IdentificadorLinea: 71619513,
        FechaCorte: '31/12/2012',
        EstadoTitular: 'NORM',
        NumeroCuotasMora: 0,
        Calidad: 'PRIN',
        SaldoObligacion: 0,
        TipoEntidadOriginadoraCartera: '',
        PaqueteInformacion: '0006',
        ValorMora: 0,
        FechaTerminacion: '05/01/2013',
        Reestructurado: 'NO',
        FechaApertura: '03/12/2009',
        EntidadOriginadoraCartera: '',
        MoraMaxima: '',
        NumeroCuotasPactadas: 36,
        Comportamientos:
          'N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |N |',
        NombreEntidad: 'FINANCIERA JURISCOOP COOPERATI',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        Periodicidad: 'MNF',
        ParticipacionDeuda: '',
        TipoEntidad: 'CFIN',
        TipoContrato: 'CRE',
        ValorInicial: 6000,
        ModoExtincion: '',
        ProbabilidadNoPago: 0,
        Ciudad: 'SINCELEJO',
        CuotasCanceladas: 36,
        ModalidadCredito: 'CONS',
        Calificacion: '',
        Sucursal: 'SINCELEJO',
        CubrimientoGarantia: '',
        EstadoObligacion: 'SALD',
        TipoGarantia: 'PLIB',
        EstadoContrato: 'NVIG',
        NaturalezaReestructuracion: '',
        NumeroObligacion: 806876,
        LineaCredito: 'LINV',
        ValorCuota: 0,
        TipoMoneda: 'ML',
        TipoPago: ''
      },
      {
        IdentificadorLinea: 23170205,
        FechaCorte: '31/05/2012',
        EstadoTitular: 'NORM',
        NumeroCuotasMora: 0,
        Calidad: 'PRIN',
        ClaseTarjeta: 'STA',
        SaldoObligacion: 0,
        MarcaTarjeta: 'MAS',
        PaqueteInformacion: '0002',
        ValorMora: 0,
        Reestructurado: 'NO',
        FechaApertura: '04/06/2008',
        MoraMaxima: '',
        NumeroCuotasPactadas: '',
        Comportamientos:
          'R |R |R |R |R |R |R |R |R |R |N |R |R |R |R |R |R |R |R |R |R |R |R |N |',
        NombreEntidad: 'AV VILLAS',
        FechaPermanencia: '',
        FechaPago: '',
        NumeroReestructuraciones: '',
        ParticipacionDeuda: '',
        TipoEntidad: 'BCO',
        TipoContrato: 'CRE',
        ValorInicial: 1900,
        ModoExtincion: '',
        Ciudad: 'SINCELEJO',
        CuotasCanceladas: '',
        ModalidadCredito: 'CONS',
        Sucursal: 'SINCELEJO',
        EstadoObligacion: 'CVOL',
        EstadoContrato: 'NVIG',
        NaturalezaReestructuracion: '',
        NumeroObligacion: '7070TC',
        LineaCredito: 'TCR',
        ValorCuota: 0,
        TipoPago: 'VOL'
      }
    ]
  },
  SectorRealAlDia: {
    Obligacion: {
      IdentificadorLinea: 61641793,
      FechaCorte: '30/06/2012',
      ChequesDevueltos: '',
      EstadoTitular: '',
      NumeroCuotasMora: 0,
      NumeroMesesClausula: 0,
      Calidad: 'PRIN',
      SaldoObligacion: 187,
      PaqueteInformacion: '0012',
      ValorMora: 0,
      FechaTerminacion: '05/07/2012',
      Reestructurado: 'NO',
      FechaApertura: '14/06/2012',
      MoraMaxima: '',
      NumeroCuotasPactadas: 1,
      Comportamientos:
        '|  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |  |N |',
      NombreEntidad: 'C.I. DOLCE S.A.',
      ValorCargoFijo: 0,
      FechaPermanencia: '',
      FechaPago: '',
      Periodicidad: 'MEN',
      ParticipacionDeuda: 7,
      TipoEntidad: 'FTEX',
      Plazo: '',
      TipoContrato: 'CRE',
      ValorInicial: 187,
      DiasCartera: '',
      ModoExtincion: '',
      Vigencia: 'IND',
      Ciudad: 'MEDELLIN',
      CuotasCanceladas: 1,
      ClausulaPermanencia: 0,
      Sucursal: 'PRINCIPAL',
      NumeroMesesContrato: '',
      EstadoObligacion: 'VIGE',
      EstadoContrato: 'VIGE',
      NumeroObligacion: 560286,
      LineaCredito: 'ROTA',
      ValorCuota: 187,
      TipoPago: ''
    }
  },
  SectorRealEnMora: {
    Obligacion: [
      {
        IdentificadorLinea: 43597045,
        FechaCorte: '31/05/2012',
        ChequesDevueltos: '',
        EstadoTitular: '',
        NumeroCuotasMora: 4,
        NumeroMesesClausula: 0,
        Calidad: 'CODE',
        SaldoObligacion: 2016,
        PaqueteInformacion: '0012',
        ValorMora: 2016,
        FechaTerminacion: '07/01/2011',
        Reestructurado: 'NO',
        FechaApertura: '07/07/2010',
        MoraMaxima: '',
        NumeroCuotasPactadas: 6,
        Comportamientos:
          '|N |N |N |N |N |N |1 |2 |3 |5 |6 |7 |8 |9 |10|11|12|12|12|12|12|12|12|',
        NombreEntidad: 'DISTRIBUIDORA  RAYCO  S.A.',
        ValorCargoFijo: 637,
        FechaPermanencia: '',
        FechaPago: '',
        Periodicidad: 'MNF',
        ParticipacionDeuda: 77,
        TipoEntidad: 'CPMM',
        Plazo: '120+',
        TipoContrato: 'CRE',
        ValorInicial: 4821,
        DiasCartera: '',
        ModoExtincion: '',
        Vigencia: 'DEF',
        Ciudad: 'SINCELEJO',
        CuotasCanceladas: 2,
        ClausulaPermanencia: 0,
        Sucursal: 'SINCELEJO',
        NumeroMesesContrato: 6,
        EstadoObligacion: 'DUDO',
        EstadoContrato: 'VIGE',
        NumeroObligacion: 918800,
        LineaCredito: 'NORM',
        ValorCuota: 637,
        TipoPago: ''
      },
      {
        IdentificadorLinea: 35022729,
        FechaCorte: '30/06/2012',
        ChequesDevueltos: '',
        EstadoTitular: '',
        NumeroCuotasMora: 0,
        NumeroMesesClausula: 0,
        Calidad: 'PRIN',
        SaldoObligacion: 424,
        PaqueteInformacion: '0011',
        ValorMora: 424,
        Reestructurado: 'NO',
        FechaApertura: '30/04/2008',
        MoraMaxima: '',
        NumeroCuotasPactadas: 0,
        Comportamientos:
          'N |N |1 |2 |3 |4 |5 |X |6 |11|11|11|11|11|12|12|12|12|12|12|12|12|12|R |',
        NombreEntidad: 'COMCEL - COMUNICACION CELULAR',
        ValorCargoFijo: 0,
        FechaPermanencia: '',
        FechaPago: '',
        Periodicidad: 'MEN',
        ParticipacionDeuda: 16,
        TipoEntidad: 'CCEL',
        TipoContrato: 'SRV',
        ValorInicial: 0,
        ModoExtincion: '',
        Vigencia: 'IND',
        Ciudad: 'BOGOTA',
        CuotasCanceladas: 0,
        ClausulaPermanencia: 0,
        Sucursal: 'CREDITO Y ACTIVA',
        NumeroMesesContrato: '',
        EstadoObligacion: 'CAST',
        EstadoContrato: 'VIGE',
        NumeroObligacion: 464553,
        LineaCredito: 'TELC',
        ValorCuota: 0,
        TipoPago: ''
      }
    ]
  },
  SectorRealExtinguidas: {
    Obligacion: [
      {
        IdentificadorLinea: 27485292,
        FechaCorte: '30/04/2010',
        ChequesDevueltos: '',
        EstadoTitular: '',
        NumeroCuotasMora: '',
        NumeroMesesClausula: 0,
        Calidad: 'PRIN',
        SaldoObligacion: 0,
        PaqueteInformacion: '0011',
        ValorMora: 0,
        Reestructurado: '',
        FechaApertura: '21/10/2008',
        MoraMaxima: '',
        NumeroCuotasPactadas: '',
        Comportamientos:
          '|  |  |N |N |X |N |N |N |N |X |X |N |X |N |N |X |N |N |X |N |N |X |N |',
        NombreEntidad: 'COLOMBIA MOVIL ESP',
        ValorCargoFijo: '',
        FechaPermanencia: '',
        FechaPago: '',
        Periodicidad: 'MEN',
        ParticipacionDeuda: '',
        TipoEntidad: 'COMU',
        TipoContrato: 'SRV',
        ValorInicial: 0,
        ModoExtincion: '',
        Vigencia: 'IND',
        Ciudad: 'BOGOTA',
        CuotasCanceladas: '',
        ClausulaPermanencia: 0,
        Sucursal: 'BOGOTA',
        NumeroMesesContrato: '',
        EstadoObligacion: 'SALD',
        EstadoContrato: '',
        NumeroObligacion: '035023',
        LineaCredito: 'TELC',
        ValorCuota: 0,
        TipoPago: ''
      },
      {
        IdentificadorLinea: 30732268,
        FechaCorte: '30/04/2010',
        ChequesDevueltos: '',
        EstadoTitular: '',
        NumeroCuotasMora: 0,
        NumeroMesesClausula: 0,
        Calidad: 'PRIN',
        SaldoObligacion: 0,
        PaqueteInformacion: '0011',
        ValorMora: 0,
        Reestructurado: '',
        FechaApertura: '16/05/2009',
        MoraMaxima: '',
        NumeroCuotasPactadas: '',
        Comportamientos:
          '|  |  |  |  |  |  |N |X |X |N |X |X |N |X |X |X |N |N |X |N |N |X |N |',
        NombreEntidad: 'COLOMBIA MOVIL ESP',
        ValorCargoFijo: '',
        FechaPermanencia: '',
        FechaPago: '',
        Periodicidad: 'MEN',
        ParticipacionDeuda: '',
        TipoEntidad: 'COMU',
        TipoContrato: 'SRV',
        ValorInicial: 0,
        ModoExtincion: '',
        Vigencia: 'IND',
        Ciudad: 'BOGOTA',
        CuotasCanceladas: '',
        ClausulaPermanencia: 0,
        Sucursal: 'BOGOTA',
        NumeroMesesContrato: '',
        EstadoObligacion: 'SALD',
        EstadoContrato: '',
        NumeroObligacion: 786484,
        LineaCredito: 'TELC',
        ValorCuota: 0,
        TipoPago: ''
      }
    ]
  },
  CuentasVigentes: {
    Obligacion: [
      {
        IdentificadorLinea: 11040798,
        TipoEntidad: 'BCO',
        FechaCorte: '31/05/2017',
        ChequesDevueltos: 0,
        TipoContrato: 'CTE-INDIVIDUAL',
        ValorInicial: 0,
        DiasCartera: 0,
        Ciudad: 'BOGOTA',
        PaqueteInformacion: '0001',
        Sucursal: 'BS SINCELEJO',
        FechaTerminacion: '',
        FechaApertura: '09/07/2008',
        EstadoObligacion: 'INACT',
        Comportamientos: 'IIIIIIIIIIII',
        NombreEntidad: 'DAVIVIENDA S.A.',
        NumeroObligacion: '003066',
        FechaPermanencia: ''
      },
      {
        IdentificadorLinea: 55543801,
        TipoEntidad: 'BCO',
        FechaCorte: '31/05/2017',
        ChequesDevueltos: '',
        TipoContrato: 'AHO-INDIVIDUAL',
        ValorInicial: '',
        DiasCartera: '',
        Ciudad: 'BOGOTA',
        PaqueteInformacion: '0023',
        Sucursal: 'BS SINCELEJO',
        FechaTerminacion: '',
        FechaApertura: '12/03/2008',
        EstadoObligacion: 'INACT',
        Comportamientos: 'IIIIIIIIIIII',
        NombreEntidad: 'DAVIVIENDA S.A.',
        NumeroObligacion: '007537',
        FechaPermanencia: ''
      },
      {
        IdentificadorLinea: 33107479,
        TipoEntidad: 'BCO',
        FechaCorte: '31/05/2017',
        ChequesDevueltos: '',
        TipoContrato: 'AHO-INDIVIDUAL',
        ValorInicial: '',
        DiasCartera: '',
        Ciudad: 'SINCELEJO',
        PaqueteInformacion: '0023',
        Sucursal: 'SINCELEJO',
        FechaTerminacion: '',
        FechaApertura: '23/05/2006',
        EstadoObligacion: 'INACT',
        Comportamientos: 'IIIIIIIIIIII',
        NombreEntidad: 'AV VILLAS',
        NumeroObligacion: 781989,
        FechaPermanencia: ''
      }
    ]
  },
  HuellaConsulta: {
    Consulta: {
      // FechaConsulta: '17/11/2018',
      // Sucursal: 'PRINCIPAL',
      // NombreEntidad: 'GULUNGO COLOMBIA SAS',
      // CodigoEntidad: 178,
      // NombreTipoEntidad: 'E',
      // Ciudad: 'BOGOTA',
      // MotivoConsulta: 'OTRO',
      // CodigoTipoEntidad: 149
    }
  }
};

const withHistory = true;

fetchMock.post(
  `end:${process.env.REACT_APP_API_PATH}/${url}`,
  withDelay(() => {
    return withHistory 
      ? {
          payload: response1,
          errors: []
        }
      : {
          payload: response2,
          errors: []
        }
  },
    2000
  )
);
