// Tabla 01 - Tipo de contrato - TIPO CONT
// TransUnion - Tablas de convenciones - Información Comercial - Versión 1.34

const tipoContrato = {
  // Cuentas
  'CTE-COLECTIVA': 'Cuenta corriente colectiva',
  'CTE-CONJUNTA': 'Cuenta corriente conjunta',
  'CTE-INDIVIDUAL': 'Cuenta corriente individual',
  'CTE-JURIDICA': 'Cuenta corriente jurídica',
  'AHO-COLECTIVA': 'Cuenta de ahorros colectiva',
  'AHO-CONJUNTA': 'Cuenta de ahorros conjunta',
  'AHO-INDIVIDUAL': 'Cuenta de ahorros individual',
  'AHO-JURIDICA': 'Cuenta de ahorros jurídica',
  // Sector financiero y real
  CRE: 'Crédito',
  FID: 'Fiducia',
  LEA: 'Leasing',
  OTR: 'Otros',
  SRV: 'Prestación de servicios',
  SEG: 'Seguros',
  BIE: 'Adquisición de Bienes',
  FING: 'Factoring'
};

export default tipoContrato;
