import api from './api';

export interface ForgotPasswordParams {
  type: 'email' | 'phone';
  value: string;
  url: string | undefined;
}

export const url = 'users/forgot-password';

const usersForgotPasswordPost = (
  postData: ForgotPasswordParams
): Promise<void> => {
  return api.post(url, false, postData);
};

export default usersForgotPasswordPost;
