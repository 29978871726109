import api from './api';

export interface AccountParams {
  name: string;
  lastname: string;
}

export const url = 'preferences/account';

const preferencesAccountPost = async (
  postData: AccountParams
): Promise<void> => {
  return await api.post(url, true, { ...postData });
};

export default preferencesAccountPost;
