import { Field, FieldProps } from 'formik';
import React from 'react';

const FormSuccess = () => (
  <Field>
    {({ form }: FieldProps) =>
      !!form &&
      !!form.status &&
      !!form.status.successMessage && <p>{form.status.successMessage}</p>
    }
  </Field>
);

export default FormSuccess;
