import Rol from '../enums/Rol';
import tokenExpiration from '../helpers/tokenExpiration';
import { setToken } from '../store/security/actions';
import store from '../store/store';
import jwt from './jwt';

export default () => {
  if (
    process.env.NODE_ENV !== 'production' ||
    process.env.REACT_APP_DEV_BUILD === 'true'
  ) {
    (window as any).token = {
      set roles(roles: Rol[] | Rol | 'all') {
        roles =
          roles === 'all' ? ['user', 'credit', 'report', 'basic_info'] : roles;
        roles = Array.isArray(roles) ? roles : [roles];
        const token = jwt.encode(roles, tokenExpiration());
        store.dispatch(setToken(token));
      },
      get roles() {
        return jwt.decode(store.getState().security.token).roles;
      },
      get props() {
        return jwt.decode(store.getState().security.token);
      }
    };
  }
};
