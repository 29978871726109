import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import Icon from "../../../components/UI/Icon";
import isLogged from "../../../helpers/isLogged";
import { formatPrice, getDuration, isPro } from "../../../helpers/plans";
import { PlanResponse } from "../../../services/plansGet";
import styles from "./Plan.module.scss";

interface Props extends RouteComponentProps {
  plan: PlanResponse;
}

const Plan = ({ plan, history }: Props) => {
  const { name, cost, days, isDefault, profits } = plan;

  const isProPlan = isPro(name);
  const userIsLogged = isLogged();
  const collapsible = !userIsLogged;

  const [open, setOpen] = useState(userIsLogged);

  const formatProfits = profits
    .split(".")
    .map(profit => {
      const text = profit.trim();
      const initial = text[0];
      const disabled = initial === "#";
      const highlight = initial === "*";
      return {
        text: disabled || highlight ? text.substring(1).trim() : text,
        disabled,
        highlight,
      }
    })
    .filter(({ text }) => text);

  const handleClick = () => {
    history.push({ pathname: "/comprar", state: { plan } });
  }

  return (
    <div className={`
      eb-card
      ${styles.plan}
      ${collapsible ? styles.collapsible : ""}
      ${isProPlan ? styles.pro : ""}
    `}>
      <div className={styles.header}>
        <p className={styles.title}>
          {name}
        </p>
        {isProPlan && (
          <div
            className={styles.saving}
            onClick={() => userIsLogged ? handleClick() : setOpen(!open)}
          >
            <Icon
              icon="verified"
              className={styles.icon}
              fill
            />
            Ahorra 52%
          </div>
        )
        }
      </div>
      <p className={styles.offer}>
        {isProPlan ? "$28.000" : ""}
      </p>
      <p className={styles.price}>
        {formatPrice(cost, isDefault)}
        <span>
          {`/${getDuration(days)}`}
        </span>
      </p>
      {collapsible && (
        <button
          className={`
            btn
            ${styles.btnCollapsible}
            ${isProPlan ? "btn-teal" : "btn-primary"}
          `}
          onClick={() => setOpen(!open)}
        >
          <p>
            Ver beneficios
          </p>
          <Icon icon={open ? "arrow_drop_up" : "arrow_drop_down"} />
        </button>
      )}
      {open && (
        <div className={styles.profits}>
          {formatProfits.map(({ text, disabled, highlight }) => (
            <div
              key={text}
              className={styles.profit}
            >
              {highlight
                ? (
                  <div className={styles.highlight}>
                    <Icon
                      icon="local_fire_department"
                      className={styles.highlightIcon}
                      fill
                    />
                  </div>
                )
                : (
                  <Icon
                    icon={disabled ? "disabled_by_default" : "check_box"}
                    className={`${styles.profitIcon} ${disabled ? styles.profitDisabled : ""}`}
                    fill
                  />
                )
              }
              <p className={`${styles.profitText} ${disabled ? styles.profitDisabled : ""}`}>
                {text}
              </p>
            </div>
          ))}
        </div>
      )}
      {userIsLogged && (
        <button
          id={`plan-${days}`}
          className={`
            btn
            ${styles.btn}
            ${isProPlan ? "btn-teal" : "btn-primary"}
          `}
          onClick={handleClick}
        >
          Adquirir
        </button>
      )}
    </div>
  )
}

export default withRouter(Plan);