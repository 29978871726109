import { ErrorMessage, Field, Form, Formik, FormikActions, FormikProps } from "formik";
import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Button, FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import * as yup from 'yup';
import SubmitButton from "../../../components/Form/SubmitButton";
import ErrorMessages from "../../../components/UI/ErrorMessages";
import { getHref } from "../../../helpers/apiWhatsApp";
import resetPhonePost from "../../../services/resetPhonePost";
import ApiError from "../../../types/ApiError";
import styles from "./ResetPhoneForm.module.scss";

interface FormValues {
  document: string;
  oldPhone: string;
  newPhone: string;
}

const ResetPhoneForm = ({ history }: RouteComponentProps) => {
  const [error, setError] = useState("");
  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikActions<FormValues>) => {
    try {
      setError("");
      const { document, oldPhone, newPhone } = values;
      await resetPhonePost({ document, oldPhone, newPhone });
      const url = getHref(
        "Quiero cambiar mi número de teléfono",
        [
          { label: "Documento", value: document },
          { label: "Número actual", value: oldPhone },
          { label: "Numero nuevo", value: newPhone },
        ]
      )
      // Safari is blocking any call to window.open() which is made inside an async call.
      // setTimeout code runs on the main thread
      setTimeout(() => {
        window.open(url, "_blank");
        history.push("/");
      })
    } catch (error) {
      setSubmitting(false);
      setError(
        error instanceof ApiError && error.code === 404
          ? "El documento de identidad ingresado no corresponde a un usuario registrado"
          : "Ocurrió un error inesperado, inténtalo más tarde"
      )
    }
  }

  const initialValues: FormValues = {
    document: "",
    oldPhone: "",
    newPhone: "",
  }

  const validationSchema = yup.object().shape({
    document: yup
      .number()
      .typeError('Ingrese el número de su documento de identidad')
      .required('Este campo es requerido'),
    oldPhone: yup
      .string()
      .required("El móvil es requerido")
      .matches(/^(\d{10})$/, "El número de móvil no es válido"),
    newPhone: yup
      .string()
      .required("El móvil es requerido")
      .matches(/^(\d{10})$/, "El número de móvil no es válido"),
  });

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      render={({ errors, touched }: FormikProps<FormValues>) => (
        <Form>
          <FormGroup>
            <p className={styles.label}>
              Documento de identidad
            </p>
            <Input
              type="text"
              name="document"
              tag={Field}
              placeholder="1234567890"
              autoCapitalize="off"
              invalid={!!(errors.document && touched.document)}
              autoFocus
            />
            <ErrorMessage name="document" component={FormFeedback} />
          </FormGroup>
          <FormGroup>
            <p className={styles.label}>
              Teléfono actual
            </p>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText className={styles.addon}>
                  +57
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="tel"
                name="oldPhone"
                tag={Field}
                placeholder="3101234567"
                invalid={!!(errors.oldPhone && touched.oldPhone)}
              />
              <ErrorMessage name="oldPhone" component={FormFeedback} />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <p className={styles.label}>
              Teléfono nuevo
            </p>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText className={styles.addon}>
                  +57
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="tel"
                name="newPhone"
                tag={Field}
                placeholder="3101234567"
                invalid={!!(errors.newPhone && touched.newPhone)}
              />
              <ErrorMessage name="newPhone" component={FormFeedback} />
            </InputGroup>
          </FormGroup>
          <div className="d-flex">
            <span className={styles.text}>
              Te pondremos en contacto con un asesor/a para verificar el cambio de tu número de teléfono
            </span>
          </div>
          {error &&
            <div className="mt-2">
              <ErrorMessages errorMessages={[error]} />
            </div>
          }
          <div className={styles.actions}>
            <Button
              outline
              color="primary"
              className={`${styles.btn} rounded-pill`}
              onClick={() => history.goBack()}
            >
              Volver
            </Button>
            <SubmitButton
              className={`${styles.btn} rounded-pill`}
              text="Enviar"
            />
          </div>
        </Form>

      )}
    />
  )
}

export default withRouter(ResetPhoneForm);
