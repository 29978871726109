import * as fetchMock from 'fetch-mock';
import { ChangePhoneParams, url } from '../services/preferencesChangePhonePost';
import ResponseCreator from './helpers/responseCreator';
import withDelay from './helpers/withDelay';

fetchMock.post(
  `end:${process.env.REACT_APP_API_PATH}/${url}`,
  (_, fetchData) => {
    const data = JSON.parse(fetchData.body as string) as ChangePhoneParams;

    if (data.phone === '573116249359') {
      return withDelay(
        new ResponseCreator()
          .addError(
            'Phone already exists in the repository',
            'Phone already exists in the repository'
          )
          .getResponse()
      );
    } else if (data.password === 'wrongpassword') {
      return withDelay(
        new ResponseCreator()
          .addError('Bad password', 'Bad password')
          .getResponse()
      );
    }
    return withDelay(new ResponseCreator().getResponse());
  }
);
