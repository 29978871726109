import React from "react";
import BackButton from "../UI/BackButton";
import styles from "./Page.module.scss";

export interface Props {
  children: React.ReactNode;
  className?: string;
  back?: boolean;
}

const Page = ({ children, className, back }: Props) => {
  return (
    <div className={`${styles.container} ${className || ""}`}>
      {back && <BackButton />}
      {children}
    </div>
  );
};

export default Page;