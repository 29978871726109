import React from "react";
import styles from "./Message.module.scss";

interface Props {
  title: string;
  description: string;
}

const Message = ({ title, description }: Props) => {
  return (
    <div className="eb-card">
      <div className={styles.container}>
        <p className={styles.title}>
          {title}
        </p>
        <p className={styles.description}>
          {description}
        </p>
      </div>
    </div>
  )
}

export default Message;