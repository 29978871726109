import React from "react";
import { Overdue as OverdueType } from "../../../../../services/reportsSummaryGet";
import Overdue from "./Overdue";
import styles from "./Overdues.module.scss";

interface Props {
  overdues: OverdueType[];
}

const Overdues = ({ overdues }: Props) => {
  return (
    <div className="eb-card">
      <div className={styles.container}>
        {overdues.map(overdue => (
          <Overdue key={overdue.lender} overdue={overdue} />
        ))}
      </div>
    </div>
  )
}

export default Overdues;