import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { Dispatch } from 'redux';
import useValidationMobileGet from '../../services/useValidationMobileGet';
import { fetchUserInfo } from '../../store/user/actions';
import styles from './ValidatePending.module.scss';
import ValidatePendingCodeForm from './ValidatePendingCodeForm';

interface Props {
  fetchUserInfoAction: () => void;
}

const ValidatePendingPhone = ({ fetchUserInfoAction }: Props) => {
  const { service, sendCode } = useValidationMobileGet();
  const [validationFinished, setValidationFinished] = useState(false);

  if (validationFinished) {
    return null;
  }

  return (
    <Alert
      color="warning"
      className={styles.validatePending}
      data-cy="validate-phone"
    >
      {service.status === 'init' && (
        <>
          Aun no has validado tu teléfono móvil. Haz clic{' '}
          <a href="#" onClick={() => sendCode()} data-cy="send-code">
            aquí
          </a>{' '}
          para recibir un código de validación.
        </>
      )}
      {service.status === 'loading' && <>Enviando...</>}
      {service.status === 'loaded' && (
        <>
          <div className={styles.inlineFormContainer}>
            <div>Ingresa el código que te hemos enviado</div>
            <ValidatePendingCodeForm
              formSubmitted={() => setValidationFinished(true)}
            />
            <div>
              Si no recibiste el código, haz clic{' '}
              <a href="#" onClick={() => sendCode()}>
                aquí
              </a>
              .
            </div>
          </div>
        </>
      )}
      {service.status === 'error' && (
        <div>Ocurrió un error enviando. Por favor inténtalo más tarde</div>
      )}
    </Alert>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchUserInfoAction: () => dispatch(fetchUserInfo())
});

export default connect(
  null,
  mapDispatchToProps
)(ValidatePendingPhone);
