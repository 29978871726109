import React from "react";
import ResetPhoneForm from "./components/ResetPhoneForm";
import TemplateForm from "./components/TemplateForm";

const ResetPhoneView = () => {
  return (
    <TemplateForm title="Cambiar teléfono">
      <ResetPhoneForm />
    </TemplateForm>
  )
}

export default ResetPhoneView;
