import api from './api';

export interface Params {
  document: string;
  oldPhone: string;
  newPhone: string;
}

export const url = 'reset-phone';

const resetPhonePost = async (
  postData: Params
): Promise<void> => {
  return await api.post(url, false, { ...postData });
};

export default resetPhonePost;
