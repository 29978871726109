import { all } from "redux-saga/effects";
import watchFetchAlerts from "./alerts";
import watchFetchFootprints from "./footprints";
import watchSendMessage from "./messages";
import watchFetchPaymentsGateway from "./paymentsGateway";
import watchFetchPlans from "./plans";
import watchFetchReportSummary from "./reportSummary";
import watchFetchScores from "./scores";
import watchFetchSocialSecurityInfo from "./socialSecurity";
import watchFetchUserInfo from "./usersMeGet";

export default function* rootSaga() {
  yield all([
    watchFetchAlerts(),
    watchFetchUserInfo(),
    watchSendMessage(),
    watchFetchSocialSecurityInfo(),
    watchFetchReportSummary(),
    watchFetchScores(),
    watchFetchPlans(),
    watchFetchFootprints(),
    watchFetchPaymentsGateway()
  ]);
}
