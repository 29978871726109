// Tabla 13 - Clase tarjeta
// TransUnion - Tablas de convenciones - Información Comercial - Versión 1.34

const claseTarjeta = {
  AGR: 'Agropecuaria',
  AMX: 'American Express',
  AMG: 'Amigabcs',
  AZL: 'Azul',
  BAS: 'Basica',
  BLA: 'Black',
  BLU: 'Blue',
  BUS: 'Bussines Card',
  CAF: 'Cafetera',
  CDI: 'Cdistbcs Clasica Distribución',
  CSI: 'Clasibcs',
  CSC: 'Clasibcs Clasica',
  CLA: 'Clasica / Clasico',
  CGO: 'Clasica Gold',
  CGI: 'Clasica Gold Internacional',
  CIN: 'Clasica Individual',
  COR: 'Clasica Oro',
  CLO: 'Clasicol',
  CLC: 'Clasicol Clasica',
  CDS: 'Codensa',
  CLP: 'Colpatria',
  COL: 'Coltefinanciera',
  COM: 'Compras Institucionales',
  CPR: 'Citi Prestige',
  CRP: 'Crediproyecto',
  DIN: 'Distribucion Nacional',
  EBC: 'Emprebcs',
  EBE: 'Emprebcs Empresarial',
  ECL: 'Emprecol',
  ECE: 'Emprecol Empresarial',
  EMP: 'Empresarial',
  FAL: 'Falabella Nacional E Internacional',
  GCO: 'Visa Gold Comcel',
  GOL: 'Gold',
  GOI: 'Gold Internacional',
  GON: 'Gold Nacional',
  GLC: 'Goldcol',
  GLG: 'Goldcol Gold',
  GRE: 'Green',
  IND: 'Individual',
  IAT: 'Infinite Avianca Taca',
  INF: 'Infinitee-Card',
  INT: 'Internacional',
  INV: 'Invercredito',
  MCG: 'La Polar TLP Master Card Gold',
  MCS: 'Master Card Silver',
  MGA: 'Mastercard Gold Aadvantag',
  MPL: 'Mastercard Plain',
  MPA: 'Mastercard Platinum Aadva',
  MIN: 'Micro Independiente',
  MIT: 'Mitarcol',
  NAL: 'Nacional',
  NAI: 'Nacional E Internacional',
  NIC: 'Na-Incol Nacional E Internacional',
  NOE: 'No Especificada',
  ORO: 'Oro Gold',
  ORB: 'Orobcs',
  ORG: 'Orobcs Oro',
  OTR: 'Otro',
  PLA: 'Plata',
  PLT: 'Platinum',
  PAT: 'Platinum Avianca Taca',
  PRV: 'Privada',
  PRT: 'Privada Irotama',
  PTN: 'Privada Telas Del Norte',
  ROJ: 'Roja',
  SFN: 'Serfinansa',
  SIG: 'Signatur',
  STA: 'Standard',
  VAG: 'Visa Aadvantage Gold',
  VGO: 'Visa Gold',
  VGC: 'Visa Gold Citibusiness',
  VIS: 'Visa Internacional',
  VPL: 'Visa Platinum',
  VSC: 'Visa Silver Classic'
};

export default claseTarjeta;
