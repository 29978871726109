// Based on the GIST: https://gist.github.com/dphrag/4db3b453e02567a0bb52592679554a5b
import { connect } from 'formik';
import React from 'react';

class ErrorFocus extends React.Component<any> {
  componentDidUpdate(prevProps: any) {
    const { isSubmitting, isValidating, errors } = prevProps.formik;
    const keys = Object.keys(errors);

    if (keys.length > 0 && isSubmitting && !isValidating) {
      const errorElement = document.getElementsByName(
        keys[0]
      )[0] as HTMLElement;
      const scrollElement =
        document.scrollingElement || document.documentElement;

      if (errorElement && scrollElement) {
        const listener = () => {
          const rect = errorElement.getBoundingClientRect();
          scrollElement.scrollTop = rect.top + scrollElement.scrollTop - 100;
          errorElement.removeEventListener('focus', listener);
        };
        errorElement.addEventListener('focus', listener);
        errorElement.focus();
      }
    }
  }

  render() {
    return null;
  }
}

export default connect(ErrorFocus);
