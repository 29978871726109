import React from 'react';
import EmailForm from '../components/EmailForm';
import styles from "./PreferencesContainer.module.scss";


const AccountPreferencesContainer = () => {
  return (
    <div className="eb-card">
      <p className={styles.title}>
        Correo
      </p>
      <EmailForm />
    </div>
  );
};


export default AccountPreferencesContainer;
