const getAuthToken = () => {
  let token: string | null;
  if (localStorage) {
    token = localStorage.getItem('token');
  } else {
    token = document.cookie.replace(
      /(?:(?:^|.*;\s*)token\s*\=\s*([^;]*).*$)|^.*$/,
      '$1'
    );
  }
  return token;
};

export default getAuthToken;
