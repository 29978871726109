import { DittofeedSdk } from "@dittofeed/sdk-web";

type UserID = string;
type PropertyValue = string | number | boolean | null | undefined;
type Properties = Record<string, PropertyValue>;

interface Traits {
  email: string;
  phone: string;
  lastName: string;
  firstName: string;
  deviceId: string;
}

interface IdentifyParams {
  userId: UserID;
  traits: Traits;
}

interface TrackParams {
  userId: UserID;
  event: string;
  properties?: Properties;
}

interface PageParams {
  userId: UserID;
  properties?: Properties;
}

export const init = async () =>
  await DittofeedSdk.init({
    host: process.env.REACT_APP_DITTOFEED_HOST,
    writeKey: `Basic ${process.env.REACT_APP_DITTOFEED_WRITE_KEY}`
  });

export const identify = (params: IdentifyParams) =>
  DittofeedSdk.identify(params);

export const track = (params: TrackParams) => DittofeedSdk.track(params);

export const page = (params: PageParams) => DittofeedSdk.page(params);

export const flush = async () => await DittofeedSdk.flush();
