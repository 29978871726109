import * as fetchMock from 'fetch-mock';
import { Payload, url } from '../services/useReportsSharedGet';
import withDelay from './helpers/withDelay';

const reports: Payload['reports'] = [
  {
    id: 'id01',
    ownerName: 'Maria Rosa',
    message: 'Qui mollit ipsum do et amet aliqua dolore culpa.'
  },
  {
    id: 'id02',
    ownerName: 'Juan Jose',
    message: 'Ex ipsum amet laborum duis.'
  }
];

fetchMock.get(`end:${process.env.REACT_APP_API_PATH}/${url}`, url => {
  return withDelay(
    {
      status: 200,
      body: {
        payload: {
          reports
        }
      }
    },
    2000
  );
});
