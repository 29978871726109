import React, { useEffect } from "react";
import { connect } from "react-redux";
import Loading from "../../../components/UI/Loading";
import { fetchReportSummary as fetchReportSummaryAction } from "../../../store/reportSummary/actions";
import { ReportSummaryState } from "../../../store/reportSummary/types";
import { AppState } from "../../../store/store";
import DashboardHeader from "../components/Header/DashboardHeader";
import DashboardReport from "../components/Report/DashboardReport";
import DashboardScore from "../components/Score/DashboardScore";
import DashboardTools from "../components/Tools/DashboardTools";

interface Props {
  reportSummaryState: ReportSummaryState;
  fetchReportSummary(): void;
}

const DashboardContainer = ({ reportSummaryState, fetchReportSummary }: Props) => {
  useEffect(() => {
    fetchReportSummary();
  }, []);

  if (reportSummaryState.status === "loading")
    return <Loading />

  if (reportSummaryState.status === "loaded" || reportSummaryState.status === "updating") {
    return (
      <>
        <DashboardHeader />
        <DashboardScore />
        <DashboardReport />
        <DashboardTools />
      </>
    )
  }

  return <></>
}

const mapStateToProps = (state: AppState) => ({
  reportSummaryState: state.reportSummary,
});

const mapDispatchToProps = {
  fetchReportSummary: fetchReportSummaryAction
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);