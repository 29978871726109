import React from 'react';
import Error from './Error';
import styles from './ErrorMessages.module.scss';

interface Props {
  errorMessages: string[];
}

const ErrorMessages = ({ errorMessages }: Props) => {
  if (errorMessages.length > 1) {
    return (
      <Error>
        <ul className={styles.errorsList}>
          {errorMessages.map((errorMessage, i) => (
            <li key={i}>{errorMessage}</li>
          ))}
        </ul>
      </Error>
    );
  } else if (errorMessages.length === 1) {
    return <Error>{errorMessages[0]}</Error>;
  } else {
    return null;
  }
};

export default ErrorMessages;
