import * as fetchMock from 'fetch-mock';
import { ResetPasswordParams, url } from '../services/usersResetPasswordPost';
import ResponseCreator from './helpers/responseCreator';
import withDelay from './helpers/withDelay';

fetchMock.post(
  `end:${process.env.REACT_APP_API_PATH}/${url}`,
  (_, response) => {
    const data = JSON.parse(response.body as string) as ResetPasswordParams;

    if (data.code === 'invalid') {
      return withDelay(
        new ResponseCreator().addError('error', 'Invalid code').getResponse()
      );
    }

    return withDelay(new ResponseCreator().getResponse());
  }
);
