import React from "react";
import Icon from "../../../../../components/UI/Icon";
import { Overdue as OverdueType } from "../../../../../services/reportsSummaryGet";
import styles from "./Overdue.module.scss";

interface Props {
  overdue: OverdueType;
}

const Overdue = ({ overdue }: Props) => {
  const { lender, urlImage, urlRedirect } = overdue;
  return (
    <div className={styles.container}>
      <div className={styles.shape}>
        <img src={urlImage} alt={lender} />
      </div>
      <div className={styles.grid}>
        <p className={styles.lender}>
          {lender}
        </p>
        <p className={styles.description}>
          ¡Tienes novedades! ingresa ahora y revisa tu perfil con nuestro aliado
        </p>
      </div>
      <a
        href={urlRedirect}
        target="_blank"
        className={styles.info}
      >
        <p className={styles.text}>
          Más información
        </p>
        <Icon icon="arrow_right_alt" className={styles.icon} />
      </a>
    </div>
  )
}

export default Overdue;