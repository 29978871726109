import { Field, FieldProps } from 'formik';
import React from 'react';
import slugify from '../../helpers/slugify';
import FieldContainer from './FieldContainer';
import styles from './RadioButtonsField.module.scss';

interface Option {
  label: string;
  value: string;
}

interface Props {
  label: string;
  name: string;
  options: Option[];
  direction?: 'row' | 'column';
  className?: string;
}

const RadioButtonsField = ({
  label,
  name,
  options,
  className,
  direction = 'row'
}: Props) => {
  const renderInput = ({ field, form }: FieldProps, option: Option) => {
    const id = slugify(name + option.value);
    return (
      <div className={styles.radioButton}>
        <input
          type="radio"
          name={name}
          id={id}
          value={option.value}
          // tslint:disable-next-line: triple-equals
          checked={field.value == option.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
        />
        <label htmlFor={id}>{option.label}</label>
      </div>
    );
  };

  return (
    <FieldContainer className={className} label={label} name={name}>
      <div
        className={`${styles.radioButtons} ${direction === 'column' ? styles.column : ''}`}
      >
        {options.map(option => (
          <Field name={name} key={option.value}>
            {(props: FieldProps) => renderInput(props, option)}
          </Field>
        ))}
      </div>
    </FieldContainer>
  );
};

export default RadioButtonsField;
