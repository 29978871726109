const formatEmail = (value: string) => {
  return value
    .replace('á', 'a')
    .replace('é', 'e')
    .replace('í', 'i')
    .replace('ó', 'o')
    .replace('ú', 'u')
    .replace('ñ', 'n')
    .replace(/[^a-zA-Z0-9@!#$%&'*+-/=?^_`{|}~]/g, '');
};
export default formatEmail;
