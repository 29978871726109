import { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import isLogged from '../helpers/isLogged';
import { page } from '../helpers/track';
import { AppState } from '../store/store';
// import usersTracingPost from '../services/usersTracingPost';

interface Props extends RouteComponentProps {
  userId: string;
}

const TrackUser = ({ userId, location }: Props) => {
  useEffect(() => {
    const path = (window as any).location.origin + location.pathname + location.search;
    // usersTracingPost({ url: path }).catch(error => {});
    if (isLogged()) {
      page({ userId, properties: { url: path } });
    }
  }, [location.pathname]);

  return null;
};

const mapStateToProps = (state: AppState) => ({
  userId: state.security.userId,
});

export default withRouter(connect(mapStateToProps)(TrackUser as FunctionComponent<RouteComponentProps>));
