import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loading from "../../../../../components/UI/Loading";
import { Overdue } from "../../../../../services/reportsSummaryGet";
import { ReportSummaryState } from "../../../../../store/reportSummary/types";
import { AppState } from "../../../../../store/store";
import Message from "../Message";
import Overdues from "./Overdues";

interface Props {
  reportSummaryState: ReportSummaryState;
}

const OverduesContainer = ({ reportSummaryState }: Props) => {
  const [overdues, setOverdues] = useState<Overdue[]>([]);

  useEffect(() => {
    if (reportSummaryState.status === "loaded") {
      const { payload } = reportSummaryState;
      const { overdues } = payload;
      setOverdues(overdues || []);
    }
  }, [reportSummaryState.status]);

  if (reportSummaryState.status === "loading")
    return <Loading />

  if (!overdues.length) {
    return (
      <Message
        title="Sin novedades"
        description="En este momento no tienes novedades con nuestros aliados."
      />
    )
  }

  return <Overdues overdues={overdues} />
}

const mapStateToProps = (state: AppState) => ({
  reportSummaryState: state.reportSummary,
});

export default connect(mapStateToProps)(OverduesContainer);