import { Reducer } from 'redux';
import { MessageAction, MessageState } from './types';

export const initialState: MessageState = {
  messages: []
};

const reducer: Reducer<MessageState, MessageAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'message/REGISTER_MESSAGE':
      return state.messages.find((message) => {
        return message.message === action.message.message
      }) 
        ? {
            messages: [...state.messages]
          }
        : {
            messages: [...state.messages, action.message]
          }
    case 'message/CLEAR_MESSAGE':
      return {
        messages: state.messages.filter(message => message !== action.message)
      };
    case 'message/CLEAR_ALL_MESSAGES':
      return {
        messages: []
      }
    default:
      return state
  }
};

export default reducer;
