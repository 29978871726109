import { Field, FieldProps } from 'formik';
import React from 'react';

interface Props {
  visible: boolean | ((values: {}) => boolean);
  children: React.ReactNode;
}

const FieldVisible = ({ visible, children }: Props) => {
  return (
    <Field>
      {({ form }: FieldProps) => {
        const isVisible =
          visible instanceof Function ? visible(form.values) : visible;

        if (!isVisible) {
          return null;
        }

        return children;
      }}
    </Field>
  );
};

export default FieldVisible;
