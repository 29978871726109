import React, { useEffect, useState } from "react";
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import Icon from "../../../components/UI/Icon";
import Loading from "../../../components/UI/Loading";
import translateError from "../../../helpers/translateError";
import { validationEmailPost } from "../../../services/validationEmailPost";
import ApiError from "../../../types/ApiError";
import { ServiceError } from "../../../types/ServiceError";
import styles from "./EmailValidationContainer.module.scss";

const EmailValidationContainer = ({ history, location }: RouteComponentProps) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleValidation = async (code: string) => {
    setLoading(true);
    try {
      await validationEmailPost({ code });
      history.push("/logout");
    } catch (error) {
      if (error instanceof ApiError) {
        const errors = error.response.errors as ServiceError[];
        setError(translateError(errors)[0]);
      } else {
        setError("Ocurrió un error inesperado");
      }
      setLoading(false);
    }
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    if (code) handleValidation(code);
    else setError("No tienes un código de validación");
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.shape}>
        <Icon icon="contact_mail" className={styles.icon} />
      </div>
      <div>
        <p className={styles.title}>
          Validación de correo
        </p>
        {loading
          ? <Loading />
          : (
            <p className={styles.error}>
              {error}
            </p>
          )
        }
      </div>
      {error && (
        <NavLink
          to={"/"}
          className="btn btn-primary"
        >
          Volver al inicio
        </NavLink>
      )}
    </div>
  )
}

export default withRouter(EmailValidationContainer);
