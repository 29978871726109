import * as Sentry from "@sentry/browser";
import { put, takeLeading } from "redux-saga/effects";
import { identify } from "../../helpers/track";
import { getDeviceId } from "../../helpers/zipps";
import usersMeGet, { UserInfoResult } from "../../services/usersMeGet";
import store from "../store";
import { setFetchInfoFailed, setFetchInfoSucceeded } from "../user/actions";

function* fetchUsersMe() {
  try {
    const userInfoResult = yield usersMeGet();
    const user = (userInfoResult.payload as UserInfoResult).user;
    yield put(setFetchInfoSucceeded(user));

    const userId = store.getState().security.userId || "";
    const deviceId = yield getDeviceId();
    const traits = {
      email: user.email,
      phone: user.phone,
      firstName: user.name,
      lastName: user.lastname,
      deviceId
    };

    // Sentry User
    Sentry.setUser({ id: userId, ...traits });

    // Identify user
    identify({ userId, traits });
  } catch (error) {
    yield put(setFetchInfoFailed());
    return;
  }
}

export default function* watchFetchUserInfo() {
  yield takeLeading("user/FETCH_INFO", fetchUsersMe);
}
