import * as Fingerprint2 from 'fingerprintjs2';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Rol from '../enums/Rol';
import { setFingerprint } from '../store/security/actions';
import { AppState } from '../store/store';
import { fetchUserInfo } from '../store/user/actions';
import LoadingSite from './UI/LoadingSite';

interface Props {
  children: React.ReactNode;
  token: string;
  roles: Rol[];
  fetchUserInfo: () => void;
  setFingerprint: (fingerprint: object) => void;
}

interface State {
  fingerprintGenerated: boolean;
}

class AppInit extends React.Component<Props, State> {
  state: State = {
    fingerprintGenerated: false
  };

  loadUserInfo = async () => {
    if (this.props.token && this.props.roles.includes('validated')) {
      this.props.fetchUserInfo();
    }
  };

  componentDidMount() {
    const generateFingerprint = () => {
      Fingerprint2.get({ excludes: { audio: true } }, components => {
        const values = components.map(component => component.value);
        const terminal = Fingerprint2.x64hash128(values.join(''), 31);
        const cookie = Fingerprint2.x64hash128(document.cookie, 31);
        const userAgentComponents = components.find(({ key }) => key === "userAgent") || { value: "" };
        const userAgentValues = userAgentComponents.value;
        const browser = Fingerprint2.x64hash128(userAgentValues, 31);
        const webglComponents = components.find(({ key }) => key === "webgl") || { value: [] };
        // String(webglComponents.value) // This add a comma
        const webglValues = webglComponents.value.join
          ? webglComponents.value.join("")
          : webglComponents.value || "";
        const webgl = Fingerprint2.x64hash128(webglValues, 31);
        const canvasComponents = components.find(({ key }) => key === "canvas") || { value: [] };
        const canvasValues = canvasComponents.value.join
          ? canvasComponents.value.join("")
          : canvasComponents.value || "";
        const canvas = Fingerprint2.x64hash128(canvasValues, 31);
        const fingerprint = {
          cookie,
          browser,
          webgl,
          canvas,
          terminal
        };
        this.props.setFingerprint(fingerprint);
        this.setState({ fingerprintGenerated: true }, () =>
          this.loadUserInfo()
        );
      });
    };
    if ((window as any).requestIdleCallback) {
      (window as any).requestIdleCallback(() => generateFingerprint());
    } else {
      setTimeout(() => generateFingerprint(), 500);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.state.fingerprintGenerated &&
      prevProps.token !== this.props.token &&
      prevProps.roles.length !== this.props.roles.length
    ) {
      this.loadUserInfo();
    }
  }

  render() {
    return this.state.fingerprintGenerated ? (
      this.props.children
    ) : (
      <LoadingSite />
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    token: state.security.token,
    roles: state.security.roles
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchUserInfo: () => dispatch(fetchUserInfo()),
    setFingerprint: (fingerprint: object) =>
      dispatch(setFingerprint(fingerprint))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppInit);
