import React, { useState } from "react";
import Icon from "../../../../components/UI/Icon";
import styles from "./Product.module.scss";
import ProductDetail from "./ProductDetail";

interface Props {
  product: any;
  closed?: boolean;
  overdue?: boolean;
}

const Product = ({ product, overdue, closed }: Props) => {
  const [open, setOpen] = useState(false);
  const status = {
    icon: closed ? "do_not_disturb_on" : overdue ? "error" : "check_circle",
    className: closed ? styles.normal : overdue ? styles.danger : styles.success,
  };
  const debtSharing = `Participación de la deuda: ${product.ParticipacionDeuda || 0}%`;

  return (
    <div className={styles.container}>
      <div
        className={styles.content}
        onClick={() => setOpen(!open)}
      >
        <div className={styles.shape}>
          <Icon
            icon={status.icon}
            className={`${styles.status} ${status.className}`}
            fill
          />
        </div>
        <div className={styles.grid}>
          <p className={styles.label}>
            {product.NombreEntidad}
          </p>
          <p className={styles.description}>
            {debtSharing}
          </p>
        </div>
        <Icon
          icon={open ? "expand_less" : "expand_more"}
          className={styles.icon}
        />
      </div>
      {open && <ProductDetail product={product} />}
    </div>
  )
}

export default Product;