import api from './api';

interface SecurityQuestionEnabledRequest {
  name?: string;
  lastname?: string;
}

export interface SecurityQuestionEnabledResponse {
  enabled: boolean;
  requestFullName: boolean;
  requireElectronicID: boolean;
  token: string;
  waitTime?: number;
}

export const url = 'security-question-enabled';

const securityQuestionEnabledPost = async (
  data: SecurityQuestionEnabledRequest
): Promise<SecurityQuestionEnabledResponse> => {
  return await api.post(url, true, data);
};

export default securityQuestionEnabledPost;
