const copyToClipboard = () => {
  const aux = document.createElement('input');
  aux.setAttribute('value', '');
  document.body.appendChild(aux);
  aux.select();
  document.execCommand('copy');
  document.body.removeChild(aux);
};

window.addEventListener('keyup', e => {
  if (e.keyCode === 44) {
    copyToClipboard();
    alert('Por tu seguridad, la función de copiar no está permitida.');
  }
});

export default undefined;
