import { Reducer } from "redux";
import { ReportSummaryAction, ReportSummaryState } from "./types";

const initialState: ReportSummaryState = {
  status: "init"
};

const reducer: Reducer<ReportSummaryState, ReportSummaryAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "reportSummary/FETCH_INFO":
      if (state.status === "loaded") {
        return {
          ...state,
          status: "updating"
        };
      } else {
        return {
          status: "loading"
        };
      }
    case "reportSummary/FETCH_INFO_SUCCEEDED":
      return {
        status: "loaded",
        payload: action.reportSummaryInfo
      };
    case "reportSummary/FETCH_INFO_FAILED":
      return {
        status: "error",
        error: action.error
      };

    default:
      return state;
  }
};

export default reducer;
