import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Tooltip } from "reactstrap";
import Icon from "../../../../components/UI/Icon";
import Loading from "../../../../components/UI/Loading";
import { formatDate } from "../../../../helpers/date";
import { ReportSummaryResponse } from "../../../../services/reportsSummaryGet";
import { Product } from "../../../../services/useReportsDetailGet";
import { ReportSummaryState } from "../../../../store/reportSummary/types";
import { AppState } from "../../../../store/store";
import styles from "./ProductsFree.module.scss";

interface Props {
  product: Product;
  reportSummaryState: ReportSummaryState;
}

const ProductsFree = ({ product, reportSummaryState }: Props) => {
  const [date, setDate] = useState("");
  const [message, setMessage] = useState("");
  const [products, setProducts] = useState(0);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const getProducts = (reportSummary: ReportSummaryResponse, product: Product) => {
    const reportSummaryKeys = {
      "accounts": "accounts",
      "credit-card": "creditCards",
      "credit": "credits",
      "contracts-services": "contractsAndServices"
    };
    const key = reportSummaryKeys[product];
    const products = reportSummary[key] || [];
    return products.length;
  }

  useEffect(() => {
    if (reportSummaryState.status === "loaded") {
      const { payload } = reportSummaryState;
      const { date } = payload;
      setDate(date);
      setProducts(getProducts(payload, product));
      setMessage(`
        El número de productos está basado en una consulta del 
        ${formatDate(date, { in: "DD/MM/YYYY HH:mm:ss" })}. 
        Puede que la información que veas esté desactualizada.
      `);
    }
  }, [reportSummaryState.status]);

  if (reportSummaryState.status === "loading")
    return <Loading />

  if (reportSummaryState.status === "loaded" || reportSummaryState.status === "updating") {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <p className={styles.title}>
            Productos
          </p>
        </div>
        <div className={styles.content}>
          {date && (
            <div className={styles.shape}>
              {products}
            </div>
          )}
          <div className={styles.grid}>
            <p className={styles.label}>
              No tienes un servicio activo
            </p>
            <p className={styles.description}>
              Adquiere un servicio para ver los detalles de tus productos.
            </p>
          </div>
          {date && (
            <>
              <Icon
                id={`${product}-product`}
                icon="info"
                className={styles.icon}
              />
              <Tooltip
                target={`${product}-product`}
                placement="left"
                isOpen={tooltipOpen}
                toggle={() => setTooltipOpen(!tooltipOpen)}
              >
                {message}
              </Tooltip>
            </>
          )}
        </div>
      </div>
    )
  }

  return <></>
}

const mapStateToProps = (state: AppState) => ({
  reportSummaryState: state.reportSummary,
});

export default connect(mapStateToProps)(ProductsFree);