import React, { useState } from "react";
import { connect } from "react-redux";
import Icon from "../../../../../components/UI/Icon";
import { capitalize } from "../../../../../helpers/capitalize";
import { track } from "../../../../../helpers/track";
import creditsIdGet from "../../../../../services/creditsIdGet";
import { Offer as OfferType } from "../../../../../services/offersGet";
import { AppState } from "../../../../../store/store";
import styles from "./Offer.module.scss";

interface Props {
  userId: string;
  offer: OfferType;
}

const Offer = ({ userId, offer }: Props) => {
  const [open, setOpen] = useState(false);

  const productType = capitalize(offer.productType);
  const subProductType = capitalize(offer.subProductType.replace("_", " "));

  const data = [
    { label: "Requisitos mínimos", value: offer.minRequirements },
    { label: "Costos asociados", value: offer.associatedCosts },
    { label: "Tipo de cuenta", value: capitalize(offer.feeType.replace("_", " ")) },
  ];

  const info = data.filter(({ value }) => value);

  const handleClick = () => {
    creditsIdGet({ offerId: offer.offerId });
    const properties = {
      productName: offer.productName,
      merchantName: offer.merchantName,
      productType: offer.productType,
      subProductType: offer.subProductType,
      description: offer.description,
      imageUrl: `${process.env.REACT_APP_PRODUCT_IMAGES}/${offer.imageUrl}`,
      requestLink: offer.requestLink,
    };
    track({ userId, event: "VIEW_MERCHANT_OFFER", properties });
  }

  return (
    <div className="eb-card">
      <div className={styles.container}>
        <div className={styles.shape}>
          <img
            src={`${process.env.REACT_APP_PRODUCT_IMAGES}/${offer.imageUrl}`}
            alt={offer.productName}
          />
        </div>
        <div className={styles.offer}>
          <p className={styles.title}>
            {offer.productName} - {offer.merchantName}
          </p>
          <p className={styles.type}>
            {productType} - {subProductType}
          </p>
          <p className={styles.description}>
            {offer.description}
          </p>
        </div>
        <div className={styles.buttons}>
          {!!info.length && (
            <button
              className={`btn btn-outline-primary ${styles.btn}`}
              onClick={() => setOpen(!open)}
            >
              Más información
              <Icon
                icon={open ? "expand_less" : "expand_more"}
                className={styles.icon}
              />
            </button>
          )}
          <a
            href={offer.requestLink}
            target="_blank"
            className="btn btn-primary"
            onClick={handleClick}
          >
            Solicitar
          </a>
        </div>
      </div>
      {open && !!info.length && (
        <>
          <hr className={styles.hr} />
          <div className={styles.info}>
            {info.map(({ label, value }) => (
              <div
                key={label}
                className={styles.element}
              >
                <p className={styles.label}>
                  {label}
                </p>
                <p className={styles.value}>
                  {value}
                </p>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  userId: state.security.userId,
});

export default connect(mapStateToProps)(Offer);