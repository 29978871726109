import { Field, FieldProps } from 'formik';
import React from 'react';
import { Input } from 'reactstrap';
import { InputType } from 'reactstrap/lib/Input';
import FieldContainer from './FieldContainer';
import styles from './formStyles.module.scss';

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
  type?: InputType;
  description?: string;
  className?: string;
  innerDivClassName?: string;
  rightSlot?: React.ReactNode;
  validate?: (value: any) => string | Promise<void> | undefined;
  formatValue?: (value: string) => string;
  render?: (renderInputProps: any) => React.ReactNode;
}

export interface RenderInputProps {
  fieldProps: FieldProps;
}

const InputField = ({
  label,
  name,
  validate,
  type = 'text',
  description,
  className,
  innerDivClassName,
  formatValue,
  render,
  rightSlot,
  ...restProps
}: Props) => {
  const renderField = ({ field, form }: FieldProps) => {
    const invalid = form.touched[field.name] && !!form.errors[field.name];
    const id = `field-${name}`;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.target.value = formatValue
        ? formatValue(e.target.value)
        : e.target.value;
      field.onChange(e);
    };
    return (
      <FieldContainer
        label={label}
        name={name}
        className={className}
        description={description}
      >
        {render ? (
          render({
            ...restProps,
            ...field,
            onChange: handleChange,
            type,
            id,
            invalid,
            className: styles.input
          })
        ) : (
          <div className={innerDivClassName || ""}>
            <Input
              {...restProps}
              {...field}
              onChange={handleChange}
              type={type}
              id={id}
              invalid={invalid}
              className={styles.input}
            />
            {rightSlot && rightSlot}
          </div>
        )}
      </FieldContainer>
    );
  };

  return (
    <Field name={name} validate={validate}>
      {(props: FieldProps) => renderField(props)}
    </Field>
  );
};

export default InputField;
