export const genders = [
  { value: "M", label: "Masculino" },
  { value: "F", label: "Femenino" }
];

export const dependents = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2-3", label: "2 - 3" },
  { value: "3-", label: "4+" }
];

export const houseTypes = [
  { value: "own", label: "Propia" },
  { value: "rent", label: "Arrendada" },
  { value: "family", label: "Familiar" }
];

export const socioEconomicLevels = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" }
];

export const activityTypes = [
  { value: "employee", label: "Empleado" },
  { value: "grocer", label: "Tendero" },
  { value: "independent", label: "Independiente" },
  { value: "microentrepreneur", label: "Microempresario" },
  { value: "pensioner", label: "Pensionado" },
  { value: "housewife", label: "Ama de casa" },
  { value: "student", label: "Estudiante" },
  { value: "looking-job", label: "En busca de empleo" },
  { value: "other", label: "Otro" }
];

export const monthlyIncomes = [
  { value: "0-1", label: "Menos de 1 SMMLV" },
  { value: "1-2", label: "Entre 1 y 2 SMMLV" },
  { value: "2-4", label: "Entre 2 y 4 SMMLV" },
  { value: "4-", label: "Más de 4 SMMLV" }
]
