import React from 'react';
import Page from '../../components/Layout/Page';
import FixReportForm from './components/FixReportForm';

const FixReportView = () => {
  return (
    <Page back>
      <div className="eb-card">
        <FixReportForm />
      </div>
    </Page>
  )
}

export default FixReportView;
