import { put, select, takeLeading } from "redux-saga/effects";
import scoresGet from "../../services/scoresGet";
import { setFetchInfoFailed, setFetchInfoSucceeded } from "../scores/actions";
import { ScoresState } from "../scores/types";
import { AppState } from "../store";

const getScores = (store: AppState) => store.scores;

function* fetchScores() {
  try {
    const scoresState: ScoresState = yield select(getScores);
    if (scoresState.status !== "loaded") {
      const scores = yield scoresGet();
      yield put(setFetchInfoSucceeded(scores));
    }
  } catch (error) {
    yield put(setFetchInfoFailed(error));
    return;
  }
}

export default function* watchFetchScores() {
  yield takeLeading("scores/FETCH_INFO", fetchScores);
}
