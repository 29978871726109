import React from "react";
import { capitalize } from '../../../helpers/capitalize';
import { Alert as AlertType, Vehicle } from "../../../services/alertsGet";
// import Alerts from "./Alerts";
import { formatDate } from "../../../helpers/date";
import Alert from "./Alert";
import styles from "./VehiclesAlerts.module.scss";

export interface Props {
  vehicles: Vehicle[];
}

const VehiclesAlerts = ({ vehicles }: Props) => {
  const vehiclesAlerts: Vehicle[] = vehicles.map(vehicle => {
    // Remove alert with code "11"
    let alerts = vehicle.alerts.filter(({ code }) => code !== "11");
    if (vehicle.info && vehicle.info.soat.status === "VIGENTE") {
      const successAlert: AlertType = {
        code: "Success-Enbanca",
        datetime: `${vehicle.info.soat.ValidUntil}T23:59:59.999Z`,
        description: "Vence",
        title: "SOAT activo",
        type: "success",
      }
      alerts = [successAlert, ...alerts];
    }
    return { ...vehicle, alerts }
  });

  return (
    <>
      {vehiclesAlerts.map(({ info, alerts, licenseDate, licensePlate }) => (
        <div
          key={licensePlate}
          className="eb-card"
        >
          <div className={styles.header}>
            <div className={styles.plate}>
              <div className={styles.border}>
                <div className={styles.text}>
                  {licensePlate.slice(0, 3)}
                  <span className={styles.dot} />
                  {licensePlate.slice(3)}
                </div>
              </div>
            </div>
            <div className={styles.info}>
              {info ? (
                <>
                  <p className={styles.vehicle}>
                    {capitalize(info.brand)}&nbsp;
                    <span>
                      {`${capitalize(info.model)} (${info.year})`}
                    </span>
                  </p>
                  <p className={styles.date}>
                    Mi&nbsp;
                    <span>
                      {info.vehicleType.toLowerCase()}&nbsp;
                    </span>
                    desde&nbsp;
                    <span>
                      {formatDate(licenseDate, { in: "YYYY-MM-DD" })}
                    </span>
                  </p>
                </>) : (
                <p> Información no disponible</p>
              )}
            </div>
          </div>
          {alerts.map(({ code, type, title, datetime, description }) => (
            <Alert
              key={code}
              type={type}
              title={title}
              datetime={datetime}
              description={description}
            />
          ))}
        </div>
      ))}
    </>
  )
};

export default VehiclesAlerts;