import React, { useEffect, useState } from "react";
import Icon from "../../../../components/UI/Icon";
import isSubscribed from "../../../../helpers/isSubscribed";
import useReportsDetailGet, { Product } from "../../../../services/useReportsDetailGet";
import ProductsType from "../../../../types/report/Products";
import styles from "./ProductReport.module.scss";
import Products from "./Products";
import ProductsFree from "./ProductsFree";

export interface Props {
  icon: string;
  label: string;
  product: Product;
}

const ProductReport = ({ icon, label, product }: Props) => {
  const userIsSubscribed = isSubscribed();
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState<ProductsType>({});
  const service = useReportsDetailGet({ product, dispatch: userIsSubscribed });

  useEffect(() => {
    if (service && service.status === "loaded") {
      const { payload } = service;
      setProducts(payload.products);
    }
  }, [service]);

  return (
    <div className={styles.container}>
      <div
        className={styles.content}
        onClick={() => setOpen(!open)}
      >
        <div className={styles.shape}>
          <Icon icon={icon} />
        </div>
        <p className={styles.label}>
          {label}
        </p>
        <Icon
          icon={open ? "expand_less" : "expand_more"}
          className={styles.icon}
        />
      </div>
      {open && userIsSubscribed && <Products products={products} />}
      {open && !userIsSubscribed && <ProductsFree product={product} />}
    </div>
  )
}

export default ProductReport;