// import "material-symbols/outlined.scss";
import "@material-symbols/font-600/outlined.scss";
import * as Sentry from "@sentry/browser";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import setFixtures from "./fixtures/setFixtures";
import "./helpers/BlockClipboard";
import setTokenDevel from "./helpers/setTokenDevel";
import { init } from "./helpers/track";
import { initZipps } from "./helpers/zipps";
import "./index.scss";

const isProduction = process.env.NODE_ENV === "production";

Sentry.init({
  // When dsn is undefined, no errors will be sent to Sentry
  dsn: isProduction
    ? "https://ccb82396c37d41b0b3177dd963a618a3@sentry.tools.vertical.com.co/19"
    : undefined,
  release: process.env.REACT_APP_GIT_VERSION,
  beforeBreadcrumb(breadcrumb) {
    return breadcrumb.category === "ui.click" ? null : breadcrumb;
  }
});

setFixtures();
setTokenDevel();
initZipps();
init();

ReactDOM.render(<App />, document.getElementById("root") as HTMLElement);
