import * as fetchMock from 'fetch-mock';
import { Payload, url } from '../services/useReportsSentGet';
import withDelay from './helpers/withDelay';

const reports: Payload['reports'] = [
  {
    id: '418827d4-06ac-4557-87f0-c672b0f91930',
    name: 'Chester Roberts',
    message: 'Similique sed non excepturi.',
    viewed: false,
    email: 'Domenico.Lehner54@gmail.com',
    sendMethod: 'email'
  },
  {
    id: '1ff6001f-0036-443d-8f5e-84cbf64486ab',
    name: 'Lilliana Beahan',
    message: 'In odit omnis quis recusandae.',
    viewed: true,
    phone: '641-986-3566',
    sendMethod: 'phone'
  },
  {
    id: '8d9d36f1-6272-477c-b5d6-20e50f8edbeb',
    name: 'Jana Keeling',
    message:
      'Pariatur omnis corporis aut occaecati atque rerum tempore alias qui. Error pariatur autem. Quasi aperiam et quae hic eius assumenda nostrum. Quis atque voluptatem id eos. Nihil debitis soluta unde ullam placeat laborum porro. Quisquam harum et.',
    viewed: true,
    email: 'Francisca.Kertzmann@hotmail.com',
    sendMethod: 'email'
  },
  {
    id: '66f1c55f-8873-4b5a-af5a-85e94fa81893',
    name: 'Karl Weimann',
    message: '',
    viewed: false,
    phone: '(030) 570-0451',
    sendMethod: 'phone'
  }
];

fetchMock.get(`end:${process.env.REACT_APP_API_PATH}/${url}`, () => {
  return withDelay(
    {
      status: 200,
      body: {
        payload: {
          reports
        }
      }
    },
    1000
  );
});
