import React, { useState } from 'react';
import { Modal as RModal, ModalBody, ModalProps } from 'reactstrap';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';

interface Props extends ModalProps {
  children: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  onToggle?: (state: boolean) => void;
}

const Modal = ({ children, header, footer, onToggle, ...rest }: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => {
    onToggle && onToggle(!isOpen);
    setIsOpen(prevState => !prevState);
  };

  return (
    <RModal isOpen={isOpen} toggle={toggle} {...rest}>
      {!!header && <ModalHeader>{header}</ModalHeader>}
      <ModalBody>{children}</ModalBody>
      {!!footer && <ModalFooter>{footer}</ModalFooter>}
    </RModal>
  );
};

export default Modal;
