import * as fetchMock from 'fetch-mock';
import { ChangeEmailParams, url } from '../services/preferencesChangeEmailPost';
import ResponseCreator from './helpers/responseCreator';
import withDelay from './helpers/withDelay';

fetchMock.post(
  `end:${process.env.REACT_APP_API_PATH}/${url}`,
  (_, fetchData) => {
    const data = JSON.parse(fetchData.body as string) as ChangeEmailParams;

    if (data.email === 'test3@example.com') {
      return withDelay(
        new ResponseCreator()
          .addError(
            'Email already exists in the repository',
            'Email already exists in the repository'
          )
          .getResponse()
      );
    } else if (data.code === 'wrongpassword') {
      return withDelay(
        new ResponseCreator()
          .addError('Bad password', 'Bad password')
          .getResponse()
      );
    }
    return withDelay(new ResponseCreator().getResponse());
  }
);
