export interface Legend {
  value: string;
  label: string;
}

export const legends: Legend[] = [
  { value: "N", label: "Normal - Al día, no presenta mora" },
  { value: "E", label: "Inactiva embargada" },
  { value: "I", label: "Inactiva" },
  { value: "A", label: "Activa embargada" },
  { value: "S", label: "Saldada" },
  { value: "T", label: "Saldada unilateralmente por la entidad" },
  { value: "X", label: "Registro inconsistente" },
  { value: "R", label: "Registro no reportada" },
  { value: "M", label: "Mal manejo" },
  { value: "B", label: "Saldada extravió robo" },
  { value: "1", label: "Mora de 30 - 59 días" },
  { value: "2", label: "Mora de 60 - 89 días" },
  { value: "3", label: "Mora de 90 - 119 días" },
  { value: "4", label: "Mora de 120 - 149 días" },
  { value: "5", label: "Mora de 150 - 179 días" },
  { value: "6", label: "Mora de 180 - 209 días" },
  { value: "7", label: "Mora de 210 - 239 días" },
  { value: "8", label: "Mora de 240 - 269 días" },
  { value: "9", label: "Mora de 270 - 299 días" },
  { value: "10", label: "Mora de 300 - 329 días" },
  { value: "11", label: "Mora de 330 - 359 días" },
  { value: "12", label: "Mora de 360 - 539 días" },
  { value: "13", label: "Mora de 540 - 729 días" },
  { value: "14", label: "Mora de 730 días en adelante" },
  { value: "n", label: "Alivio por ley" }
];