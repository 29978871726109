import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Card } from 'reactstrap';
import Page from '../../components/Layout/Page';
import cardStyles from '../../components/UI/Card.module.scss';
import img from '../../images/banner6.jpg';
import styles from './Signup.module.scss';
import SignupInfo from './components/SignupInfo';
import SignupContainer from './containers/SignupContainer';

interface Params {
  from: string;
  user: string;
  callback: string;
}

const SignupView: React.FC<RouteComponentProps<Params>> = ({ match }) => (
  <Page>
    <Card className={`rounded-lg mb-5 ${cardStyles.smallShadow} mb-5`}>
      <div className={styles.row}>
        <div className={styles.colInfo}>
          <div
            className={styles.img}
            style={{ backgroundImage: `url(${img})` }}
          />
          <SignupInfo />
        </div>
        <div className={styles.colRegister}>
          <div className={styles.subtitle}>
            Para <b>registrarte</b>, debes tener tu documento de identidad a la
            mano
          </div>
          <SignupContainer />
          <p className={styles.action}>
            ¿Ya tienes usuario? <Link to="/login">Ingresa aquí</Link>
          </p>
        </div>
      </div>
    </Card>
  </Page>
);

export default SignupView;
