import React, { useEffect, useState } from "react";
import { formatDate } from "../../../../../helpers/date";
import { riskLabelByScore } from "../../../../../helpers/scoreLevels";
import { Score } from "../../../../../services/scoresGet";
import styles from "./Risk.module.scss";

interface Props {
  scores: Score[];
}

const Risk = ({ scores }: Props) => {
  const [risk, setRisk] = useState("");
  const [date, setDate] = useState("");
  const [riskStyle, setRiskStyle] = useState("");

  useEffect(() => {
    const score = scores[0];
    if (score) {
      const { fecha, puntaje } = score;
      const risk = riskLabelByScore(puntaje);
      const date = formatDate(fecha.slice(0, 19), {
        in: "YYYY-MM-DD[T]HH:mm:ss",
        out: "D [de] MMMM YYYY [a las] HH:mm a"
      });
      setRisk(`Riesgo ${risk}`);
      setDate(`Fecha de generación: ${date}`);
      // Style
      if (risk === "alto") setRiskStyle(styles.high);
      else if (risk === "medio alto") setRiskStyle(styles.mediumHigh);
      else if (risk === "medio") setRiskStyle(styles.medium);
      else if (risk === "medio bajo") setRiskStyle(styles.mediumLow);
      else setRiskStyle(styles.low);
    } else {
      setDate("");
      setRisk("Sin historial");
      setRiskStyle(styles.high);
    }

  }, [scores]);

  return (
    <div className="eb-card">
      <div className={styles.container}>
        <div className={styles.header}>
          <p className={styles.title}>
            Escala de riesgo
          </p>
          <p className={styles.text}>
            Tu calificación es muy importante para acceder a un crédito.
            Te mostramos cuál es tu escala de riesgo y cómo interpretarla.
          </p>
        </div>
        <hr className={styles.hr} />
        <div className={`${styles.risk} ${riskStyle}`}>
          <p className={styles.label}>
            {risk}
          </p>
          <p className={styles.date}>
            {date}
          </p>
          <div className={styles.bar}>
            <div className={styles.riskBar} />
          </div>
          <p className={styles.text}>
            Un nivel de riesgo bajo te permite ahorrar dinero sin intereses y
            acceder a una mejor oferta de productos financieros. Además te abre
            las puertas de otras entidades con las que puedes tener una relación
            comercial como compañías de telecomunicaciones, servicios públicos,
            entre otras.
          </p>
        </div>
        <hr className={styles.hr} />
        <p className={styles.title}>
          Escala basada en al menos una central de riesgo
        </p>
        <p className={styles.text}>
          Tu nivel de riesgo es afectado por la calidad de tu historial crediticio y
          predice tu probabilidad de pago.
          <br />
          Entran en consideración factores como: antigüedad en el buró,
          número de productos financieros, historiales positivos y negativos,
          moras y endeudamiento.
          <br />
          Clientes sin información en las centrales de riesgo tienen nivel de
          riesgo alto.
        </p>
      </div>
    </div>
  )
}

export default Risk;