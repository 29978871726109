import React from "react";
import { Button } from "reactstrap";
import useValidationEmailGet from "../../services/useValidationEmailGet";
import Icon from "../UI/Icon";
import styles from "./ValidatePendingEmail.module.scss";

const ValidatePendingEmail = () => {
  const { service, sendCode } = useValidationEmailGet();
  const handleClick = () => sendCode({ url: `${location.origin}/registro/codigo-email` });

  return (
    <div className={styles.container}>
      <div className="eb-card">
        <div className={styles.content}>
          <div className={styles.badge}>
            <Icon
              icon="mail"
              className={styles.icon}
            />
            <span className={styles.status} />
          </div>
          <div className={styles.text}>
            <p className={styles.title}>
              Aún no has validado tu correo electrónico.
            </p>
            <p className={styles.subtitle}>
              {service.status === "loaded"
                ? "Te hemos enviado un correo electrónico con un enlace de validación."
                : "Ingresa a tu correo electrónico y haz clic en el enlace de validación que te hemos enviado."
              }
            </p>
          </div>
          {service.status !== "loaded" &&
            <Button
              color="primary"
              onClick={handleClick}
              className={styles.btn}
              disabled={service.status === "loading"}
            >
              {service.status === "loading" &&
                <div className="spinner-border spinner-border-sm mr-2" role="status" />
              }
              Reenviar enlance
            </Button>
          }
        </div>
      </div>
    </div>
  )
};

export default ValidatePendingEmail;
