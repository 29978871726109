import React from "react";
import styles from "./SavingValidation.module.scss";

const SavingValidation = () => {
  return (
    <div className={styles.container}>
      <div
        className={`spinner-border text-primary ${styles.spinner}`}
        role="status"
      />
      <p className={styles.text}>
        Cargando
      </p>
    </div>
  )
}

export default SavingValidation;
