import React from "react";
import Loading from "../../../components/UI/Loading";
import useReportsSharedIdGet from "../../../services/useReportsSharedIdGet";
import ShareReportForm from "../components/ShareReportForm";
import ShareReportPreview from "../components/ShareReportPreview";

const ShareReportContainer = () => {
  const preview = useReportsSharedIdGet({ id: "preview" });

  if (preview.status === "loading")
    return <Loading />

  if (preview.status === "loaded" || preview.status === "updating") {
    return (
      <>
        <div className="eb-card">
          <ShareReportPreview preview={preview.payload.report} />
        </div>
        <div className="eb-card mt-3">
          <ShareReportForm />
        </div>
      </>
    )
  }

  return <></>
};

export default ShareReportContainer;
