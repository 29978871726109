import * as fetchMock from 'fetch-mock';
import {
  SocialSecurityResponse,
  url
} from '../services/reportsSocialSecurityInfoGet';
import ResponseCreator from './helpers/responseCreator';
import withDelay from './helpers/withDelay';

const socialSecurityInfo: SocialSecurityResponse = {
  periods: [
    { periodoCotizacion: 201904 },
    { periodoCotizacion: 201903 },
    { periodoCotizacion: 201902 },
    { periodoCotizacion: 201901 },
    { periodoCotizacion: 201812 },
    { periodoCotizacion: 201811 },
    { periodoCotizacion: 201810 },
    { periodoCotizacion: 201809 },
    { periodoCotizacion: 201808 },
    { periodoCotizacion: 201807 },
    { periodoCotizacion: 201806 },
    { periodoCotizacion: 201805 },
    { periodoCotizacion: 201804 },
    { periodoCotizacion: 201803 },
    { periodoCotizacion: 201802 }
  ],
  details: [
    {
      period: "202310",
      baseIncome: 3000000,
      contributorDetail: "Dependiente"
    },
  ]
};

fetchMock.get(`end:${process.env.REACT_APP_API_PATH}/${url}`, () => {
  return withDelay(
    new ResponseCreator().setPayload(socialSecurityInfo).getResponse()
  );
});
