import * as qs from 'query-string';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import CodeNotFound from './components/CodeNotFound';
import ResetPasswordForm from './components/ResetPasswordForm';
import TemplateForm from './components/TemplateForm';


const ResetPasswordView = ({ match, location }: RouteComponentProps) => {
  const query = qs.parse(location.search);
  const code = query["code"] as string;
  const path = query["path"] as string | undefined;
  const phone = query["phone"] as string | undefined;
  const title = match.path === "/asignar-contrasena"
    ? "Asignar contraseña"
    : "Recuperar contraseña";

  return (
    <TemplateForm title={title}>
      {code
        ? <ResetPasswordForm code={code} path={path} phone={phone} />
        : <CodeNotFound />
      }
    </TemplateForm >
  );
};

export default withRouter(ResetPasswordView);
