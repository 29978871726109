import React from 'react';
import Alert from 'reactstrap/lib/Alert';

export interface Props {
  children: React.ReactNode;
}

const Error = ({ children }: Props) => (
  <Alert color="danger text-danger">{children}</Alert>
);

export default Error;
