import { Reducer } from 'redux';
import getAuthToken from '../../helpers/getAuthToken';
import jwt from '../../helpers/jwt';
import { SecurityAction, SecurityState } from './types';

const initialState: SecurityState = {
  roles: []
};
const token = getAuthToken();
if (token) {
  initialState.token = token;
  const { roles, sub } = jwt.decode(token);
  initialState.roles = roles;
  initialState.userId = sub;
}

const reducer: Reducer<SecurityState, SecurityAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'security/SET_TOKEN':
      return {
        ...state,
        token: action.token,
        roles: action.roles,
        userId: action.userId,
        tokenIssueDate: action.tokenIssueDate
      };
    case 'security/LOGOUT':
      return {
        ...state,
        token: undefined,
        roles: [],
        tokenIssueDate: undefined,
      };
    case 'security/SET_FINGERPRINT':
      return {
        ...state,
        fingerprint: action.fingerprint
      };
    default:
      return state;
  }
};

export default reducer;
