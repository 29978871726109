import * as fetchMock from 'fetch-mock';
import jwt from '../helpers/jwt';
import tokenExpiration from '../helpers/tokenExpiration';
import { SignupParams, url } from '../services/signupPost';
import { ServiceError } from '../types/ServiceError';
import ResponseCreator from './helpers/responseCreator';
import store from './helpers/store';
import withDelay from './helpers/withDelay';

fetchMock.post(
  `end:${process.env.REACT_APP_API_PATH}/${url}`,
  (_, fetchData) => {
    store.intent = undefined;
    store.basicInfoSet = false;
    store.creditReasonSet = false;
    store.resetRoles();

    const data = JSON.parse(fetchData.body as string) as SignupParams;

    const errors: ServiceError[] = [];

    if (data.document === '500') {
      return withDelay(
        new ResponseCreator()
          .addError('Server error', 'Server error')
          .setStatus(500)
          .getResponse()
      );
    }

    if (data.email === 'alreadyexist@test.com') {
      errors.push({
        attribute: 'email',
        error: 'Email already exists in the repository'
      });
    }

    if (data.phone === '573001111111') {
      errors.push({
        attribute: 'phone',
        error: 'Phone already exists in the repository'
      });
    }

    if (data.document === '22222222') {
      errors.push({
        attribute: 'document',
        error: 'Document already exists in the repository'
      });
    }

    if (errors.length > 0) {
      return {
        status: 400,
        body: {
          errors
        }
      };
    }

    store.roles = ['user'];
    return {
      status: 200,
      body: {
        payload: {
          token: jwt.encode(store.roles, tokenExpiration(), 'fake-uuid')
        }
      }
    };
  }
);
