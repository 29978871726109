import { Field, FieldProps } from 'formik';
import React from 'react';
import { Button } from 'reactstrap';
import Icon from '../UI/Icon';
import styles from './formStyles.module.scss';

export interface Props {
  text: string;
  className?: string;
  disabled?: boolean;
  disabledIfSuccess?: boolean;
  disabledIfNotValid?: boolean;
  loading?: boolean;
  onclick?: any;
  id?: string;
}

const SubmitButton = ({
  text,
  className = 'px-5',
  disabled = false,
  disabledIfSuccess,
  disabledIfNotValid,
  onclick,
  id,
}: Props) => (
  <Field>
    {({ form }: FieldProps) => (
      <div className={styles.submitButton}>
        <Button
          id={id || ""}
          onClick={onclick}
          type="submit"
          className={`btn-rounded ${styles.button} ${className}`}
          color="primary"
          disabled={
            disabled ||
            form.isSubmitting ||
            (disabledIfSuccess && !!form.status && form.status.success) ||
            (disabledIfNotValid && !form.isValid)
          }
        >
          {!form.isSubmitting ? (
            text
          ) : (
            <div className="spinner-border spinner-border-sm" role="status" />
          )}
        </Button>

        {!form.isSubmitting && !!form.status && form.status.success && (
          <Icon
            icon="check"
            className={`text-success ${styles.iconSuccess}`}
          />
        )}
      </div>
    )}
  </Field>
);

export default SubmitButton;
