import { formatMoney } from "./money";

export const formatPrice = (price: number, isDefault = false) => {
  if (isDefault) return "Gratis";
  return formatMoney(price);
};

export const getDuration = (days: number) => {
  const months = Math.ceil(days / 30);
  return `${months} ${months === 1 ? "Mes" : "Meses"}`;
};

export const isPro = (name: string) => name === "Tu Bienestar";
