import api from "./api";

export interface ReportsRequestParams {
  sendMethod: "email" | "phone";
  name: string;
  email?: string;
  phone?: string;
}

export const url = "reports/share-request";

const reportsShareRequestPost = async (data: ReportsRequestParams) => {
  return await api.post(url, true, data);
};

export default reportsShareRequestPost;
