import { Form, Formik, FormikActions, FormikProps } from 'formik';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import Button from 'reactstrap/lib/Button';
import * as yup from 'yup';
import FormErrors from '../../../components/Form/FormErrors';
import InputField from '../../../components/Form/InputField';
import Loading from '../../../components/UI/Loading';
import Modal from '../../../components/UI/Modal';
import { getHref } from '../../../helpers/apiWhatsApp';
import { capitalizeEachWord } from '../../../helpers/capitalize';
import formatEmail from '../../../helpers/formatEmail';
import processSubmitErrors from '../../../helpers/processSubmitErrors';
import { validEmail } from '../../../helpers/valid';
import { validateEmail } from '../../../helpers/zipps';
import preferencesChangeEmailPost from '../../../services/preferencesChangeEmailPost';
import sendOtpPost from '../../../services/sendOtpPost';
import { AppState } from '../../../store/store';
import { changeEmail as changeEmailAction } from '../../../store/user/actions';
import ValidationStatusField from '../../Signup/components/ValidationStatusField';
import styles from "./EmailForm.module.scss";
import ModalOtp from './ModalOtp';

export interface FormValues {
  email: string;
  code: string;
}

interface Props {
  loaded: boolean;
  user: {
    uuid: string;
    name: string;
    email: string;
    document: string;
    documentType: string;
    emailValidated: boolean;
  }
  changeEmail: (email: string) => void;
}

const EmailForm = ({
  user,
  loaded,
  changeEmail,
}: Props) => {

  const { uuid, name, email, document, documentType, emailValidated } = user;

  const [isValid, setIsValid] = useState(false);
  const [lastValue, setLastValue] = useState(email);
  const [isValidating, setIsValidating] = useState(false);

  const [error, setError] = useState("");
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const initialValues: FormValues = { email, code: "" };
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email('El correo no es válido')
      .matches(
        /^[a-zA-Z0-9@!#$%&'*+-/=?^_`{|}~]*$/,
        'El correo no es válido'
      )
      .required('El correo es requerido'),
    code: yup
      .string()
      .required('Este campo es requerido')
      .length(6, "El código tiene 6 dígitos"),
  })

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, setErrors, setStatus, setFieldValue }: FormikActions<FormValues>
  ) => {
    try {
      setStatus({ error: '' });
      await preferencesChangeEmailPost({
        ...values,
        url: `${location.origin}/registro/codigo-email`
      });
      changeEmail(values.email);
      setShowConfirmModal(true);
      setFieldValue("code", "");
    } catch (error) {
      processSubmitErrors(error, values, setErrors, setStatus);
    } finally {
      setShowOtpModal(false);
      setSubmitting(false);
    }
  }

  const handleContact = () => {
    const msg = "Quiero cambiar mi correo";
    const data = [
      { label: "Nombre", value: capitalizeEachWord(name) },
      { label: documentType, value: document },
      { label: "Usuario", value: uuid }
    ];
    const url = getHref(msg, data);
    window.open(url, "_blank");
  }

  const onConfirmModalClose = (isOpen: boolean) =>
    !isOpen && setShowConfirmModal(false);

  const sendCode = async () => {
    try {
      setError("");
      await sendOtpPost({ document });
      setShowOtpModal(true);
    } catch (error) {
      setError('Ocurrió un error inesperado al enviar el código, inténtalo más tarde');
    }
  };

  const validate = async (value: string) => {
    setLastValue(value);
    if (value) {
      if (value !== lastValue) {
        if (validEmail(value)) {
          setIsValidating(true);
          setIsValid(false);
          const { valid } = await validateEmail(value);
          setIsValidating(false);
          setIsValid(valid);
          return valid ? "" : "El correo que ingresaste no existe o no es válido";
        } else {
          setIsValid(false);
          return "El correo no es válido";
        }
      }
    } else {
      setIsValid(false);
    }
    return "El correo es requerido"
  }

  if (!loaded)
    return <Loading />

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        render={({ values, setFieldError, submitForm }: FormikProps<FormValues>) => (
          <Form>
            <div>
              <div
                onBlur={async () => {
                  const validation = await validate(values.email);
                  validation && setFieldError("email", validation)
                }}
              >
                <InputField
                  name="email"
                  label=""
                  disabled={emailValidated}
                  formatValue={formatEmail}
                  innerDivClassName="position-relative"
                  rightSlot={<ValidationStatusField isValid={isValid} isValidating={isValidating} />}
                />
              </div>
              <div className={styles.invalidField}>
                {error}
              </div>
              <FormErrors />
              {emailValidated
                ? (
                  <>
                    <p className={styles.text}>
                      Por el momento no es posible modificar un correo validado.
                    </p>
                    <div className={styles.content}>
                      <Button
                        color="primary"
                        onClick={handleContact}
                      >
                        Comunicarme con un asesor
                      </Button>
                    </div>
                  </>
                )
                : (
                  <div className={styles.content}>
                    <Button
                      color="primary"
                      onClick={sendCode}
                      data-cy="change-email"
                      disabled={!isValid || email === values.email}
                    >
                      Modificar correo
                    </Button>
                  </div>
                )
              }
            </div>
            {showOtpModal && (
              <ModalOtp
                title="Confirma tu nuevo correo"
                onConfirm={submitForm}
                onClose={() => setShowOtpModal(false)}
              />
            )}
          </Form>
        )}
      />
      {showConfirmModal && (
        <Modal
          onToggle={onConfirmModalClose}
          footer={
            <Button color="primary" onClick={() => setShowConfirmModal(false)}>
              Cerrar
            </Button>
          }
        >
          <div>
            Tu correo ha sido modificado, haz clic en el enlace que te hemos
            enviado para validarlo.
          </div>
        </Modal>
      )}
    </>
  )
};

const mapStateToProps = (state: AppState) => ({
  loaded: state.user.status === 'loaded',
  user:
    state.user.status === "loaded"
      ? {
        uuid: state.security.userId,
        name: `${state.user.payload.name} ${state.user.payload.lastname}`,
        email: state.user.payload.email,
        document: state.user.payload.documentNumber,
        documentType: state.user.payload.documentType,
        emailValidated: state.user.payload.emailValidated,
      }
      : {
        uuid: "",
        name: "",
        document: "",
        documentType: "",
        email: "",
        emailValidated: false,
      },
});

const mapDispatchToProps = {
  changeEmail: changeEmailAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailForm);
