import { put, select, takeLeading } from "redux-saga/effects";
import plansGet from "../../services/plansGet";
import {
  setFetchPlansFailed,
  setFetchPlansSucceeded
} from "../plans/action";
import { PlansState } from "../plans/types";
import { AppState } from "../store";

const getPlans = (store: AppState) => store.plans;

function* fetchPlans() {
  try {
    const plansState: PlansState = yield select(getPlans);
    if (plansState.status !== "loaded") {
      const plans = yield plansGet();
      yield put(setFetchPlansSucceeded(plans.payload));
    }
  } catch (error) {
    yield put(setFetchPlansFailed(error));
    return;
  }
}

export default function* watchFetchPlans() {
  yield takeLeading("plans/FETCH_INFO", fetchPlans);
}
