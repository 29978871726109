import api from "./api";
import { CreditResponse } from "./creditsGet";

export interface Product {
  id: number;
  name: string;
  getUserInfo: boolean;
  merchantName: string;
  merchantId: number;
  productType: string;
  subProductType: string;
  description: string;
  rateFrom: number;
  rateTo: number;
  amountTo: number;
  associatedCosts: string;
  feeType: string;
  minRequirements: string;
  minIncomes: string;
  imageUrl: string;
  requestLink: string;
}

export const url = "products/public/all";

const merchantsProductsGet = async (): Promise<CreditResponse[]> => {
  const result = await api.get(url, false, { type: "app" }, true);
  return result.payload.map((item: Product) => ({
    ...item,
    productId: item.id,
    productName: item.name,
    offerName: item.name,
    offerId: item.id,
    getUserInfo: item.getUserInfo
  }));
};

export default merchantsProductsGet;
