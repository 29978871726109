import React from "react";
import Icon from "../../../components/UI/Icon";
import styles from "./PaymentSecurity.module.scss";

const PaymentSecurity = () => {
  return (
    <div className={styles.security}>
      <Icon
        icon="lock"
        className={styles.icon}
        fill
      />
      Realiza pagos de manera segura
    </div>
  )
}

export default PaymentSecurity;