import React, { useEffect, useState } from "react";
import { Redirect, RouteComponentProps, withRouter } from "react-router";
import Loading from "../../../components/UI/Loading";
import { push } from "../../../helpers/gtm";
import jwt from "../../../helpers/jwt";
import { getTransaction } from "../../../helpers/planTransaction";
import refreshToken from "../../../helpers/refreshToken";
import { track } from "../../../helpers/track";
import type { Transaction } from "../../../types/Transaction";
import PaymentBill from "../components/PaymentBill";

const PlanBuyConfirmContainer = ({ location }: RouteComponentProps) => {
  const queryString = location.search;
  const [loading, setLoading] = useState(true);
  const [transaction, setTransaction] = useState<Transaction | null>(null);

  useEffect(() => {
    fetchTransaction(queryString);
  }, []);

  useEffect(() => {
    if (transaction) refreshTokenAndTrack();
  }, [transaction]);

  const fetchTransaction = async (queryString: string) => {
    const transaction = await getTransaction(queryString);
    setTransaction(transaction);
    setLoading(false);
  }

  const refreshTokenAndTrack = async () => {
    try {
      const KEY = "__eb_txn_id__";
      const token = await refreshToken();
      const { cpl, pex, sub } = jwt.decode(token);
      const previousPaymentId = localStorage.getItem(KEY);
      if (transaction && previousPaymentId !== transaction.id) {
        localStorage.setItem(KEY, transaction.id);
        const properties = {
          plan: cpl, // cpl is a new plan (override any previous active plan)
          exp: `${pex}T00:00:00.000-05:00`,
          ...transaction.eventData
        };
        track({ userId: sub, event: "PLAN_TRANSACTION", properties });
        const event = transaction.approved ? "pago_exitoso" : "pago_no_exitoso";
        push(event, properties); // GTM custom event

      }
    } catch { }
  }

  if (loading)
    return <Loading />

  if (!transaction)
    return <Redirect to="/planes" />

  return <PaymentBill data={transaction.paymentData} approved={transaction.approved} />
}

export default withRouter(PlanBuyConfirmContainer);