import React from "react";
import Page from "../../components/Layout/Page";
import SubscriptionRequired from "../../components/Subscription/SubscriptionRequired";
import FootprintsContainer from "./containers/FootprintsContainer";

const HistoryView = () => {
  return (
    <Page back>
      <SubscriptionRequired
        title="Historial de consultas"
        component={FootprintsContainer}
      />
    </Page>
  )
};

export default HistoryView;
