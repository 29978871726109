import { ErrorMessage } from 'formik';
import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import formStyles from './formStyles.module.scss';

interface Props {
  label: string;
  name: string;
  description?: string;
  className?: string;
  children: React.ReactNode;
}

const FieldContainer = ({
  label,
  name,
  description,
  className,
  children
}: Props) => {
  return (
    <FormGroup className={`${formStyles.field} ${className ? className : ''}`}>
      <Label className={formStyles.label} htmlFor={`field-${name}`}>
        {label}
      </Label>
      {children}
      <ErrorMessage name={name}>
        {msg => <div className={formStyles.errorFeedback}>{msg}</div>}
      </ErrorMessage>
      {!!description && (
        <div className={formStyles.description}>{description}</div>
      )}
    </FormGroup>
  );
};

export default FieldContainer;
