import fetchMock from 'fetch-mock';
import { SecurityAnswerParams, url } from '../services/securityAnswerPost';
import store from './helpers/store';
import withDelay from './helpers/withDelay';

// TODO:
// "notSessionInitialized", "User does not have initialized session" > no rellenó el formulario, retornarlo

fetchMock.post(
  `end:${process.env.REACT_APP_API_PATH}/${url}`,
  (_, fetchData) => {
    const data = JSON.parse(fetchData.body as string) as SecurityAnswerParams;

    if (data.questionId === '4') {
      if (data.answerId === '7521069') {
        if (store.intent === 'report') {
          store.roles = ['report'];
        } else if (store.intent === 'credit') {
          store.roles = ['credit', 'report'];
        }
        return withDelay({ status: 200, body: { verified: true } });
      } else {
        return withDelay({ status: 200, body: { verified: false } });
      }
    }

    return withDelay({ status: 200, body: { verified: true } }, 2000);
  }
);
